﻿
// =======================================================================================
// === [ FLYTANDE BLOCK ] ===
// =======================================================================================
.block-flyt-i-text-hoger,
.block-flyt-i-text-vanster {
    margin-bottom: 2rem !important;
}

// =======================================================================================
// Min-width 768px
// =======================================================================================
@media (min-width: 768px) {
    .block-flyt-i-text-hoger,
    .block-flyt-i-text-vanster {
        width: 50%;
        margin-top: .7rem;
        margin-bottom: 2rem !important;

        > img {
            margin: 0 !important;
        }
    }

    .block-flyt-i-text-hoger {
        float: right;
        margin-left: 2rem !important;
    }

    .block-flyt-i-text-vanster {
        float: left;
        margin-right: 2rem !important;
    }
}
