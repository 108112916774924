﻿/// <reference path="../base/mixins" />
/// <reference path="../components/bas-mixin" />

// =======================================================================================
// === [ COOKIE BANNER ] ===
// =======================================================================================
.cookie-banner {
    .modal {
        padding-left: 1rem !important;
        padding-right: 1rem !important;
        background-color: $cookie-banner-bg;
    }

    .modal-dialog {
        width: 100%;
        max-width: 550px;
        margin: 5rem auto 2.5rem !important;
        background-color: transparent;

        .modal-content {
            background-color: $default-bg-color;
            border-radius: 3px;

            .modal-header {
                display: flex;
                justify-content: center;
                background: none;
                padding: 0;

                img {
                    height: 150px;
                    margin-top: 2rem;
                }
            }

            .modal-body {
                padding: 1rem 2rem 2rem;

                h2 {
                    @include alecta-font($alecta-freight, 500, normal);
                    margin-bottom: rem(10);
                    font-size: rem(32);
                    line-height: rem(34);
                }

                p {
                    @include alecta-font($alecta-freight, 400, normal);
                    margin-bottom: rem(20);
                    font-size: rem(16);
                    line-height: rem(20);
                }

                form {
                    margin-bottom: 5px;
                    padding: 0;
                    background: none;

                    button {
                        width: 100%;
                        max-width: 289px;
                        height: 50px;
                        margin: 1rem auto;
                        padding: 0;
                        line-height: 18.53px;
                    }
                }
            }
        }
    }

    .flex-wrapper {
        display: flex;
        flex-direction: column-reverse;
        justify-content: center;
    }
}

// =======================================================================================
// Min-width 320px
// =======================================================================================
@media (min-width: 320px) {
    .cookie-banner {
        .modal {
            padding-left: 2rem !important;
            padding-right: 2rem !important;

            .modal-dialog {
                margin: 6rem auto 2.5rem !important;
            }
        }
    }
}

// =======================================================================================
// Min-width 768px
// =======================================================================================
@media (min-width: 768px) {
    .cookie-banner {
        .modal {
            .modal-dialog {
                max-width: 658px;
                margin: 10rem auto 2.5rem !important;

                .modal-content {
                    .modal-header {
                        img {
                            height: 258px;
                            margin-top: 28px;
                        }
                    }

                    .modal-body {
                        padding: 1rem 3rem 2.5rem;

                        h2 {
                            font-size: rem(26);
                            line-height: rem(30);
                        }

                        form {
                            button {
                                margin: 1rem 0;
                            }
                        }
                    }
                }
            }

            .flex-wrapper {
                flex-direction: row;
                justify-content: space-between;
            }
        }
    }
}

// =======================================================================================
// Min-width 992px
// =======================================================================================
//@media (min-width: 992px) {
//    .cookie-banner {
//    }
//}
