﻿
// =======================================================================================
// === [ TABELL MED KANTER & ALECTA-GRÖN ] ===
// =======================================================================================
.tabellblock {
    position: relative;
    float: none;
    margin-bottom: $block-margin-bottom-320;

    &.col-sm-6,
    &.col-sm-4 {
        display: inline-block;
    }

    &.table-2-big {
        &::after {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            width: rem(40);
            background-image: url('../img/table-2-big-gradient.png');
        }
    }
}

.table-kanter-alecta-gron {
    padding: rem(23) 0 $block-margin-bottom-320;
    background-color: $table-block-bg;
    clear: both;

    &.tabellwrapper {
        @include overflow-x(auto);
        width: 100%;

        .tabellwrapper-inner {
            display: inline-block;
            min-width: 100%;
            padding-right: $margin-right-left-320;
            padding-left: $margin-right-left-320;

            h2,
            h4,
            p {
                margin-right: 0;
                margin-left: 0;
            }

            h4 {
                margin-bottom: rem(23);
                text-align: left;
            }
        }
    }

    table {
        width: initial;
        max-width: unset;
        min-width: initial;
        margin-bottom: 0;
        font-size: rem(15);

        &.columns2,
        &.columns3 {
            thead {
                tr {
                    td,
                    th {
                        width: rem(200);
                    }
                }
            }
        }

        // === [ TABELL HEAD ] ===========================================================
        thead {
            tr {
                td,
                th {
                    @include border(top bottom, solid 1px, $table-gron-border);
                    @include border(right, solid 1px, $default-bg-color);
                    @include border(left, solid 1px, $table-gron-border);
                    @include versala-siffror;
                    min-width: rem(80);
                    padding: rem(20);
                    font-weight: 600;
                    line-height: rem(16);
                    vertical-align: middle;
                    background-color: $table-gron-border;

                    &:last-of-type {
                        @include border(right, solid 1px, $table-gron-border);
                    }

                    p {
                        @include alecta-font($alecta-freight, 600, normal);
                        @include versala-siffror;
                        margin-bottom: rem(15);
                        padding: 0;
                        font-size: em(15);
                    }
                }
            }
        }

        // === [ TABELL BODY ] ===========================================================
        tbody {
            tr {
                td,
                th {
                    @include border(all, solid 1px, $table-gron-border);
                    @include versala-siffror;
                    padding: rem(11) rem(20);
                    font-weight: 500;
                    line-height: rem(16);
                    vertical-align: middle;
                    background-color: $white;

                    a {
                        @include versala-siffror;
                        font-size: rem(15);
                        font-weight: 500;

                        &.loginlank {
                            &.loginlanklock {
                                padding-left: rem(14);
                                background-size: 8px auto;
                            }
                        }
                    }

                    p {
                        @include alecta-font($alecta-freight, 500, normal);
                        @include versala-siffror;
                        margin: 0;
                        margin-bottom: rem(15);
                        padding: 0;
                        font-size: rem(15);
                        line-height: rem(16);
                    }

                    ul {
                        padding-left: rem(10);
                    }
                }
            }
        }

        // === [ TABELL FOOT ] ===========================================================
        tfoot {
            td {
                padding-top: rem(16);
                font-size: rem(14);
                line-height: rem(18);
                background-color: $table-block-bg;
            }

            &.summa {
                tr {
                    td {
                        @include border(all, solid 1px, $table-gron-border);
                        @include versala-siffror;
                        min-width: rem(80);
                        padding: rem(10) rem(20);
                        font-weight: 600;
                        line-height: rem(16);
                        vertical-align: middle;
                        white-space: nowrap;
                        background-color: $white;

                        &:first-of-type {
                            white-space: normal;
                        }

                        &:last-of-type {
                            @include border(right, solid 1px, $table-gron-border);
                        }
                    }
                }
            }
        }
    }
}

// =======================================================================================
// Tabell Jämnförande Blå
// =======================================================================================
.table-jamnforande-bla {
    padding: rem(23) 0 0;
    background-color: transparent;
    border: 0;

    &.tabellwrapper {
        @include overflow-x(auto);
        width: auto;

        .tabellwrapper-inner {
            display: inline-block;
            min-width: 100%;
            padding-right: $margin-right-left-320;
            padding-left: $margin-right-left-320;

            h2 {
                margin-right: 0;
                margin-bottom: rem(32);
                margin-left: 0;
                text-align: left;
            }
        }
    }

    table {
        width: 99.9999%;
        max-width: unset;
        min-width: 99.9999%;
        margin-bottom: 0;
        font-size: rem(15);
        background-color: $table-jamn-bg;
        border: 0;

        &.columns2 {
            tbody {
                td,
                th {
                    width: 50%;
                }
            }
        }

        &.columns3 {
            tbody {
                td,
                th {
                    width: 33.3333%;
                }
            }
        }

        &.columns4 {
            tbody {
                td,
                th {
                    width: 25%;
                }
            }
        }

        &.columns5 {
            tbody {
                td,
                th {
                    width: 20%;
                }
            }
        }

        &.columns6 {
            tbody {
                td,
                th {
                    width: 16.6666%;
                }
            }
        }

        // === [ TABELL HEAD ] ===========================================================
        thead {
            tr {
                position: relative;

                td,
                th {
                    @include versala-siffror;
                    padding: rem(20);
                    font-size: rem(20);
                    font-weight: 600;
                    line-height: rem(24);
                    text-align: center;
                    vertical-align: middle;
                    background-color: $table-jamn-header-bg;
                    border: 0;

                    p {
                        @include alecta-font($alecta-freight, 600, normal);
                        @include versala-siffror;
                        margin-bottom: rem(15);
                        padding: 0;
                        font-size: rem(15);
                    }
                }
            }
        }

        // === [ TABELL HELRAD ] =========================================================
        [colspan='2'],
        [colspan='3'],
        [colspan='4'],
        [colspan='5'],
        [colspan='6'],
        [colspan='7'],
        [colspan='8'],
        [colspan='9'],
        [colspan='10'] {
            @include versala-siffror;
            padding: rem(20) rem(30);
            text-align: left;
            text-transform: uppercase;
            vertical-align: middle;
        }

        // === [ TABELL BODY ] ===========================================================
        tbody {
            tr {
                position: relative;

                &.tabell-jamnforande {
                    + tr {
                        td {
                            @include border(top, solid 1px, $table-jamn-header-bg);
                        }
                    }
                }

                td,
                th {
                    @include border(right bottom, solid 1px, $table-jamn-header-bg);
                    @include versala-siffror;
                    padding: rem(26) rem(30);
                    font-weight: 500;
                    line-height: rem(20);
                    vertical-align: top;

                    &:last-of-type {
                        border-right: 0;
                    }

                    h4 {
                        margin-bottom: rem(7);
                        padding: 0;
                        font-size: rem(15);
                        font-weight: 500;
                        line-height: rem(20);
                        text-align: center;
                    }

                    a {
                        @include versala-siffror;
                        font-size: rem(15);
                    }

                    p {
                        @include alecta-font($alecta-freight, 500, normal);
                        @include versala-siffror;
                        margin: 0;
                        margin-bottom: rem(15);
                        padding: 0;
                        font-size: rem(15);
                        line-height: rem(20);
                    }

                    ul {
                        margin: 0;
                        padding-left: 0;

                        li {
                            @include alecta-font($alecta-freight, 500, normal);
                            font-size: rem(15);
                            line-height: rem(20);

                            &::after {
                                top: rem(8);
                                background-size: 5px 5px;
                            }
                        }
                    }

                    ol {
                        margin: 0;

                        li {
                            @include alecta-font($alecta-freight, 500, normal);
                            font-size: rem(15);
                            line-height: rem(20);
                        }
                    }
                }

                &:last-of-type {
                    td,
                    th {
                        border-bottom: 0;
                    }
                }
            }
        }
    }
}

.accordion-inner {
    .table-jamnforande-bla {
        &.tabellwrapper {
            .tabellwrapper-inner {
                padding: 0;
            }
        }
    }
}

// =======================================================================================
// Min-width 768px
// =======================================================================================
@media (min-width: 768px) {
    .tabellblock {
        margin-right: 0;
        margin-bottom: $block-margin-bottom-768;
        margin-left: 0;
    }

    // === [ TABELL MED KANTER & ALECTA-GRÖN ] ===========================================
    .table-kanter-alecta-gron {
        margin-top: 0;
        padding: rem(23) 0 $block-margin-bottom-768;
        background-color: transparent;

        &.tabellwrapper {
            .tabellwrapper-inner {
                padding-right: $margin-right-left-768;
                padding-left: $margin-right-left-768;

                h4 {
                    text-align: center;
                }
            }
        }

        table {
            margin: 0 auto;

            tfoot {
                td {
                    padding-top: rem(16);
                    font-size: rem(14);
                    line-height: rem(18);
                    background-color: $default-bg-color;
                }
            }

            &.summa {
                tr {
                    td {
                        background-color: $white;

                        &:last-of-type {
                            @include border(right, solid 1px, $table-gron-border);
                        }
                    }
                }
            }
        }
    }

    // === [ TABELL JÄMNFÖRANDE BLÅ ] ====================================================
    .table-jamnforande-bla {
        margin-right: $margin-right-left-768;
        margin-left: $margin-right-left-768;
        padding: rem(23) 0 0;

        &.tabellwrapper {
            .tabellwrapper-inner {
                padding: 0;

                h2 {
                    text-align: center;
                }

                table {
                    tbody {
                        tr {
                            td {
                                ul {
                                    padding-left: rem(10);

                                    li {
                                        margin: 0 0 0 rem(20);
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        // === [ TABELL HELRAD ] =========================================================
        table {
            [colspan='2'],
            [colspan='3'],
            [colspan='4'],
            [colspan='5'],
            [colspan='6'],
            [colspan='7'],
            [colspan='8'],
            [colspan='9'],
            [colspan='10'] {
                position: relative;
                text-align: center;
            }
        }
    }

    .accordion-inner {
        .table-kanter-alecta-gron {
            table {
                tfoot {
                    td {
                        background-color: $accord-inner-bg;
                    }
                }
            }
        }

        .table-jamnforande-bla {
            &.tabellwrapper {
                margin: 0;
            }
        }
    }
}

// =======================================================================================
// Min-width 992px
// =======================================================================================
@media (min-width: 992px) {
    .tabellblock {
        margin-right: 0;
        margin-bottom: $block-margin-bottom-992;
        margin-left: 0;
        padding-left: $margin-right-left-992;
    }

    .table-kanter-alecta-gron,
    .table-jamnforande-bla {
        &.tabellwrapper {
            .tabellwrapper-inner {
                padding-right: 0;
                padding-left: 0;

                table {
                    tbody {
                        tr {
                            td {
                                ul {
                                    padding-left: rem(10);
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .table-jamnforande-bla {
        margin-right: 0;
        margin-left: 0;
    }

    .accordion-inner {
        .table-kanter-alecta-gron {
            table {
                tfoot {
                    td {
                        background-color: $default-bg-color;
                    }

                    &.summa {
                        tr {
                            td {
                                background-color: $white;

                                &:last-of-type {
                                    @include border(right, solid 1px, $table-gron-border);
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
