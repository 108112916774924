﻿
// =======================================================================================
// === [ BILDBLOCK ] ===
// =======================================================================================
.bildblock {
    display: inline-block;
    float: none;
    width: 100%;
    padding-bottom: $block-margin-bottom-320;

    img {
        width: 100%;
    }

    div {
        display: inline-block;
        width: 100%;
    }

    &.col-sm-6 {
        float: left;
        width: 100%;
        padding: 0 0 $block-margin-bottom-320;

        img {
            display: inline-block;
            vertical-align: top;
        }

        + .fritextblock {
            display: inline-block;
            float: none;
        }
    }
}

// =======================================================================================
// Min-width 768px
// =======================================================================================
@media (min-width: 768px) {
    .bildblock {
        padding-bottom: $block-margin-bottom-768;

        &.col-sm-6 {
            display: inline-block;
            float: left;
            width: 50%;
            padding: 0 0 $block-margin-bottom-768 $margin-right-left-768;

            + .fritextblock {
                &.col-sm-6 {
                    padding-right: 0;
                    padding-left: 2rem;
                }
            }
        }
    }
}

// =======================================================================================
// Min-width 992px
// =======================================================================================
@media (min-width: 992px) {
    .bildblock {
        &.col-sm-6 {
            padding: 0 0 $block-margin-bottom-992 $margin-right-left-992;
        }
    }
}
