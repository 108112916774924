﻿/// <reference path="../base/mixins" />
/// <reference path="../components/bas-mixin" />

// =======================================================================================
// === [ POPOVER KNAPP ] ===
// =======================================================================================
a {
    &[data-toggle='popover'] {
        position: relative;
        display: inline-block;
        width: 1.7rem;
        height: 1.4rem;
        margin-right: .5rem;
        margin-left: .5rem;

        &::after {
            @include alecta-font($alecta-freight-text, 600, normal);
            @include border-radius(.9rem);
            content: 'i';
            position: absolute;
            top: 0;
            left: 0;
            width: 1.7rem;
            height: 1.7rem;
            color: $alecta-bla-2;
            font-size: 13px;
            line-height: 16px;
            text-align: center;
            text-transform: lowercase;
            background-color: $popover-link-bg;
        }

        &.active {
            &::after {
                @include border(all, solid 1px, $alecta-bla-3);
                color: $white;
                background-color: $alecta-bla-3;
            }
        }
    }
}

.table {
    td {
        a {
            &[data-toggle='popover'] {
                margin-right: 0;
                margin-left: 1rem;
            }
        }
    }
}

// =======================================================================================
// === [ POPOVER ] ===
// =======================================================================================
.popover {
    @include border-radius(1.5rem 0 1.5rem 0);
    @include box-shadow(0, 0, 0, rgba(0, 0, 0, 0));
    position: absolute;
    top: 0;
    z-index: 1060;
    display: none;
    max-width: 32rem;
    padding: 0;
    color: $white;
    text-align: left;
    white-space: normal;
    background-color: $alecta-bla-3;
    border: 0;

    &.top {
        margin-top: -2.2rem;

        > .arrow {
            bottom: -1.5rem;
            left: 0 !important;
            width: 1.6rem;
            height: 1.6rem;
            margin-left: 0;
            background: transparent;
            border: 0;
            overflow: hidden;

            &::after {
                content: '';
                position: absolute;
                z-index: 1;
                width: 3.6rem;
                height: 3.6rem;
                margin-bottom: -1.1rem;
                margin-left: -1rem;
                background: transparent;
                border: solid 1rem $alecta-bla-3;
                border-top-left-radius: 3rem;
            }
        }
    }

    .popover-content {
        padding: 1.5rem 2rem;
        font-size: 1.5rem;
        line-height: 1.8rem;
    }
}

// =======================================================================================
// === [ POPOVER AVGIFTSRÄKNAREN ] ===
// =======================================================================================
.avgiftsraknarenblock {
    .popover {
        @include border-radius(0 rem(15) 0 rem(15));
        top: rem(130);
        left: 10px;
        z-index: 3001;
        display: none;
        width: 100%;
        max-width: 100%;
        margin: 0;
        padding: rem(40) 0 rem(20);
        text-align: center;
        background-color: $popover-avgift-bg;

        .arrow {
            position: absolute;
            right: 0;
            bottom: rem(-15);
            display: block;
            width: rem(16);
            height: rem(16);
            margin-left: 0;
            background: transparent;
            border: 0;
            overflow: hidden;

            &::after {
                content: '';
                position: absolute;
                z-index: 1;
                width: 36px;
                height: 36px;
                margin-top: -10px;
                margin-left: -10px;
                background: transparent;
                border: solid 10px $popover-avgift-bg;
                border-top-right-radius: 30px;
            }
        }

        .avgiftsraknaren-exempel-stang {
            position: absolute;
            top: rem(10);
            left: rem(10);
            width: rem(20);
            height: rem(20);
        }

        h4 {
            margin-bottom: rem(12);
            font-size: rem(18);
            font-weight: 600;
            line-height: rem(25);
            text-transform: unset;
            color: $default-font-color;
        }

        a {
            @include alecta-font($alecta-freight, 400, normal);
            display: block;
            margin-bottom: rem(10);
            font-size: rem(18);
            line-height: rem(25);
            color: $default-link-color;
        }

        &.avgiftsraknaren-popover-visa {
            display: block;
        }
    }
}

// =======================================================================================
// === [ ÖVRIGT ] ===
// =======================================================================================
[role='button'] {
    cursor: pointer;
}

@media all and (max-width: 767px) {
    .popover {
        max-width: 100%;
    }

}

// =======================================================================================
// Min-width 375px
// =======================================================================================
@media (min-width: 375px) {
    .avgiftsraknarenblock {
        .popover {
            width: rem(310);
            margin: 0 rem(20);
        }
    }
}

// =======================================================================================
// Min-width 480px
// =======================================================================================
@media (min-width: 480px) {
    .avgiftsraknarenblock {
        .popover {
            padding: rem(30) 0 rem(20);
        }
    }
}

// =======================================================================================
// Min-width 768px
// =======================================================================================
@media (min-width: 768px) {
    .popover {
        margin-left: rem(160);
    }

    .avgiftsraknarenblock {
        .popover {
            top: 18rem;
            left: 25rem;
        }
    }
}

// =======================================================================================
// Min-width 992px
// =======================================================================================
@media (min-width: 992px) {
    .avgiftsraknarenblock {
        .popover {
            top: 20.5rem;
        }
    }
}
