﻿
// =======================================================================================
// === [ PRESSMEDDELANDEN, ALECTA KOMMENTERAR, PENSIONSEKONOM-BLOGGEN ] ===
// =======================================================================================
.a-senaste,
.a-stornyhet {
    margin-bottom: $block-margin-bottom-320;

    .a-pressmeddelanden,
    .a-alecta-kommenterar,
    .a-pensionsekonom-bloggen,
    .feeduiblock {
        padding: 0;
        overflow: hidden;

        .a-senaste {
            margin-bottom: 0;
        }

        h2 {
            margin-right: 0;
            margin-bottom: 1.5rem;
            margin-left: 0;
            padding: 3rem 0 1.2rem;
            font-size: 1.6rem;
            font-weight: 500;
            line-height: 1.8rem;
            text-align: center;
            text-transform: uppercase;
        }

        a {
            display: block;
            margin-bottom: .8rem;
            padding-left: $margin-right-left-320;
            color: $default-link-color;
            font-size: 1.5rem;
            font-weight: 500;
            line-height: 1.9rem;
            border: 0;

            &:last-of-type {
                @include alecta-font($alecta-freight-text, 500, normal);
                position: relative;
                display: block;
                margin-bottom: 0;
                padding: .5rem $margin-right-left-320 2.3rem;
                font-style: italic;

                .a-icon-arrow {
                    display: none;
                }
            }

            span {
                display: block;
                color: $nyhetsflode-date;

                &:hover {
                    text-decoration: none;
                }
            }

            &:hover {
                span {
                    text-decoration: none;
                }
            }
        }
    }

    &.a-nyhetsfloden {
        padding-top: 3rem;
        padding-bottom: 1rem;
        background-color: $nyhetsflode-bg;

        .a-pressmeddelanden {
            .tumnagel-cirkel {
                flex-grow: 0;
                flex-shrink: 0;
                width: 10rem;
                height: 10rem;
                margin: 0 auto;
                background-position: center;
                background-repeat: no-repeat;
                background-size: cover;
                border-radius: 50%;
            }

            h2 {
                margin-right: 0;
                margin-bottom: 0;
                margin-left: 0;
                padding: 2rem 0 1.2rem;
            }

            a {
                margin-bottom: 1.5rem;
            }
        }
    }
}

// =======================================================================================
// Min-width 768px
// =======================================================================================
@media (min-width: 768px) {
    .a-senaste,
    .a-stornyhet {
        margin-bottom: 0;
        padding: 1.5rem 0 0;

        .feeduiblock,
        .pressmeddelandeblock {
            margin-bottom: $block-margin-bottom-768;
            padding: rem(15) rem(6) 0;
        }

        .a-pressmeddelanden,
        .a-alecta-kommenterar,
        .a-pensionsekonom-bloggen {
            padding: 0 .6rem;
            border: 0;

            h2 {
                padding: 0 0 1.2rem;
                font-size: 1.6rem;
            }

            a {
                padding-left: 0;

                &:last-of-type {
                    margin-bottom: 0;
                    padding: rem(5) 0 rem(20);
                }
            }
        }
    }

    .a-senaste {
        padding: 1.5rem 0 0;

        .a-nyhetsfloden & {
            padding: 0;
        }

        .a-pressmeddelanden {
            padding: 0;
        }
    }
}

// =======================================================================================
// Min-width 992px
// =======================================================================================
@media (min-width: 992px) {
    .a-senaste,
    .a-stornyhet {
        margin-bottom: $block-margin-bottom-992;

        &.a-nyhetsfloden {
            padding-right: 3.5rem;
            padding-left: 3.5rem;
        }
    }
}
