﻿/// <reference path="../base/mixins" />
/// <reference path="../components/bas-mixin" />

// =======================================================================================
// === [ LÄNKLISTABLOCK ] ===
// =======================================================================================
.lanklistablock {
    margin-bottom: 2.7rem;
    border-top: 1px solid $kanter;
    border-bottom: 1px solid $kanter;

    .lanklistablock-inner {
        padding-top: 2.5rem;
        padding-bottom: 2.5rem;
    }

    h4,
    li,
    p {
        margin-right: 0;
        margin-left: 0;
    }

    ul {
        margin: 0;
        padding-left: 0;
    }

    li {
        @include alecta-font($alecta-freight, 300, normal);
        padding-bottom: 0;
        font-size: 1.8rem;
        line-height: 2.5rem;

        &::after {
            content: none;
        }
    }

    img {
        margin-bottom: 2.5rem;
    }
}

// =======================================================================================
// Min-width 768px
// =======================================================================================
@media (min-width: 768px) {
    .lanklistablock {
        img {
            margin-bottom: 0;
        }
    }
}
