﻿/// <reference path="../base/mixins" />

// =======================================================================================
// === [ IKONTEXTBLOCK ] ===
// =======================================================================================
.ikontextblock {
    padding: 2.6rem;

    h2 {
        @include alecta-font($alecta-freight, 300, normal);
        margin-top: 1.2rem;
        font-size: 3.4rem;
        line-height: 4rem;
        text-align: center;
    }

    img {
        width: auto;
    }

    p,
    h2,
    img {
        margin-right: auto;
        margin-left: auto;
    }

    p {
        margin-bottom: 0;
    }
}
