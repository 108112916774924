﻿/// <reference path="../base/mixins" />
/// <reference path="../components/bas-mixin" />

// =======================================================================================
// === [ AVGIFTSRÄKNAREN ] ===
// =======================================================================================
.avgiftsraknarenblock {
    position: relative;
    margin: 0 auto;

    #avgiftsraknaren {
        margin-left: 2rem;
        margin-right: 2rem;
    }

    h2 {
        margin-bottom: 1rem;
        font-size: rem(22);
        line-height: rem(25);
    }

    h3 {
        margin-bottom: 3px;
        font-size: rem(16);
        font-weight: 500;
        line-height: rem(22);
        text-align: center;
    }

    .row {
        position: relative;
    }

    .col-xs-12 {
        padding: 0;
    }

    .col-xs-6 {
        padding: 0;

        &.avgiftsraknaren-tartdiagram-01,
        &.avgiftsraknaren-tartdiagram-02,
        &.avgiftsraknaren-pension-manad-01,
        &.avgiftsraknaren-pension-manad-02 {
            display: inline-block;
            float: none;
            width: 49%;
            padding-bottom: rem(5);

            .total-avgift {
                margin-top: 5px;
                font-size: rem(20);
                line-height: rem(23);
                color: $alecta-bla-1;
            }
        }

        &.avgiftsraknaren-tartdiagram-02 {
            h4 {
                text-align: right;
            }

            .total-avgift {
                color: $alecta-orange;
            }
        }

        &.avgiftsraknaren-pension-manad-01,
        &.avgiftsraknaren-pension-manad-02 {
            h3 {
                font-size: rem(20);
                line-height: rem(23);
            }
        }

        h4 {
            margin: 0;
            font-weight: 600;
            text-align: left;
            text-transform: none;
        }
    }

    #avgiftsraknaren-tartdiagram-01,
    #avgiftsraknaren-tartdiagram-02 {
        height: rem(170);
    }

    .avgiftsraknaren-popover-bakgrund {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 3000;
        display: none;
        background-color: transparent;

        &.avgiftsraknaren-popover-bakgrund-visa {
            display: block;
        }
    }

    .highcharts-container {
        text-align: center !important;
    }

    p {
        @include alecta-font($alecta-freight, 600, normal);
        @include versala-siffror;
        margin-bottom: 0;
        font-size: rem(14);
        line-height: rem(16);
        text-align: center;
        text-transform: uppercase;
    }

    .slider-rubrik {
        padding: 1.5rem 0;
    }

    .avgiftsraknaren-slider {
        margin-bottom: rem(20);
        padding: rem(3) rem(20);

        &.avgiftsraknaren-slider-text {
            margin: 0;
        }

        p {
            padding-bottom: rem(5);
        }

        .avgift-markorer {
            position: absolute;
            width: 100%;

            img {
                position: absolute;
                top: -18px;
                left: 0;
            }

            a {
                position: absolute;
                top: -18px;
                right: 0;
                display: inline-block;
                margin-bottom: rem(7);
                font-weight: 500;
            }

            .avgiftsraknaren-popover-oppna {
                @include alecta-font($alecta-freight, 500, normal);
                @include border-radius(50%);
                position: absolute;
                top: -20px;
                right: 40px;
                width: 20px;
                height: 20px;
                color: $white;
                font-size: rem(16);
                line-height: rem(22);
                text-align: center;
                text-transform: lowercase;
                background-color: $alecta-bla-2;

                &:hover,
                &:focus {
                    text-decoration: none;
                }
            }

            .procent-tecken {
                position: absolute;
                top: -4px;
                right: 20px;
                font-size: rem(16);
                line-height: rem(21);
                font-weight: 500;
            }
        }

        .ui-slider-pip {
            top: 1rem;
        }

        .ui-slider-label {
            font-size: rem(18);
            line-height: rem(21);
        }
    }

    .avgiftsraknaren-dropdowns {
        display: flex;
        flex-direction: column;
        justify-content: center;
        clear: both;

        .dropner-inner {
            display: flex;
            flex-wrap: nowrap;
            justify-content: center;
            align-items: center;

            h3 {
                font-size: rem(18);
                line-height: rem(25);
            }
        }
    }

    .form-dropnermeny {
        &.avgiftsraknaren-dropnermeny {
            margin-bottom: 10px;
            padding: 0 0 0 5px;

            .selectric-wrapper {
                .selectric {
                    height: 35px;
                    background: none;
                    padding: 5px 10px 5px 0;

                    .label {
                        position: relative;
                        top: 4px;
                        display: inline-block;
                        font-size: rem(18);
                        line-height: rem(25);
                    }

                    .button {
                        position: static;
                        display: inline-block;
                        height: 8px;
                        margin-left: 8px;
                    }
                }

                .selectric-items {
                    li {
                        height: 35px;
                        padding: 5px 40px 5px 10px;
                        border: 0;
                        font-size: rem(18);
                        line-height: rem(25);
                        white-space: nowrap;

                        &.selected {
                            background: none;
                        }
                    }
                }
            }

            &.dropnermeny-alder {
                width: 92px;
            }

            &.dropnermeny-lon {
                width: 130px;
            }
        }
    }
}

.accordion-inner {
    #avgiftsraknaren {
        margin-left: 0;
        margin-right: 0;
    }
}

.a-stornyhet {
    .block {
        &.avgiftsraknarenblock {
            padding-top: 4rem;
            background-color: $graf-avgif-bg;

            h2 {
                @include alecta-font($alecta-freight, 300, normal);
                font-size: 3.4rem;
                line-height: 4rem;
                text-align: center;

                + p {
                    @include alecta-font($alecta-freight-text, 300, normal);
                    margin-right: auto;
                    margin-left: auto;
                    padding: 0 2rem 2rem;
                    font-size: 1.9rem;
                    line-height: 2.5rem;
                    text-transform: none;
                }
            }
        }
    }
}

// === [ AVGIFTSRÄKNAREN ERROR ] =========================================================
#avgiftsraknaren,
#avgiftsraknaren2,
#avgiftsraknaren3 {
    .accordion-inner {
        position: relative;

        .avgiftsraknaren-error {
            @include alecta-font($alecta-freight, 400, normal);
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 100;
            margin-top: rem(-5);
            color: $white;
            font-size: rem(16);
            line-height: rem(19);
            text-align: center;
            background-color: $alecta-orange;

            span {
                @include vertikalt-innehall;
                top: calc(50% - 20px);
                height: 100%;
            }
        }
    }
}

.fullbredd {
    .avgiftsraknarenblock {
        margin-bottom: 0;
        padding-bottom: 3rem;
    }
}

// =======================================================================================
// Min-width 390px
// =======================================================================================
@media (min-width: 390px) {
    .avgiftsraknarenblock {
        .row {
            margin-right: -5px;
            margin-left: -15px;
        }
    }
}

// =======================================================================================
// Min-width 768px
// =======================================================================================
@media (min-width: 768px) {
    .avgiftsraknarenblock {

        .avgiftsraknaren-inner {
            width: 57rem;
            margin: 0 auto 3rem;
        }

        .row {
            margin-right: 0;
            margin-left: 0;
        }

        .avgiftsraknaren-error {
            margin: mer(-5) rem(-25) rem(-20);
        }

        #avgiftsraknaren-tartdiagram-01,
        #avgiftsraknaren-tartdiagram-02 {
            height: rem(220);
            padding-left: rem(17);
        }

        .diagram-inner {
            padding-left: 2rem;
            padding-right: 2rem;
        }

        .avgiftsraknaren-slider {
            width: 95%;
            padding: 0;

            .avgift-markorer {
                width: 57rem;

                .avgiftsraknaren-popover-oppna {
                    top: -1px;
                    right: -22px; //-50px;
                }

                .procent-tecken {
                    right: 10px; //-22px;
                }
            }
        }

        .avgiftsraknaren-dropdowns {
            flex-direction: row;
        }
    }

    .a-stornyhet {
        .block {
            &.avgiftsraknarenblock {
                width: 100%;
            }
        }

        #avgiftsraknaren {
            margin-right: auto;
            margin-left: auto;
        }
    }
}

// =======================================================================================
// Min-width 992px
// =======================================================================================
@media (min-width: 992px) {
    .avgiftsraknarenblock {
        max-width: 63.5rem;

        p {
            font-weight: 500;
        }
    }
}
