﻿
// =======================================================================================
// === [ KONTAKTA OSS ] ===
// =======================================================================================
.kontaktaossblock {
    .accordion-toggle {
        @include alpha-bg-color($alecta-bla-2, 1);
        @include border(top bottom, solid 1px, $accord-kontakt-header-border);

        .a-accordion-section-text {
            color: $white;
        }

        .a-icon-arrow-big {
            @include bg-img('../img/icon-arrow-big-white.svg', null, null, 23px 14px);
        }

        &[aria-expanded='true'],
        &.active {
            @include alpha-bg-color($alecta-bla-2, 1);

            .a-icon-arrow-big {
                @include bg-img('../img/icon-arrow-up-big-white.svg', null, null, null);
            }
        }
    }

    .accordion-group {
        &:nth-child(1) {
            .accordion-heading {
                .accordion-toggle {
                    background-color: $kontakt-accord-bg-01 !important;
                }
            }
        }

        &:nth-child(2) {
            .accordion-heading {
                .accordion-toggle {
                    background-color: $kontakt-accord-bg-02 !important;
                }
            }
        }

        &:nth-child(3) {
            .accordion-heading {
                .accordion-toggle {
                    background-color: $kontakt-accord-bg-03 !important;
                }
            }
        }

        &:nth-child(4) {
            .accordion-heading {
                .accordion-toggle {
                    background-color: $kontakt-accord-bg-04 !important;
                }
            }
        }

        &:nth-child(5) {
            .accordion-heading {
                .accordion-toggle {
                    background-color: $kontakt-accord-bg-05 !important;
                }
            }
        }
    }

    @for $antal-sektioner from 2 through 10 {
        $alpha: 1;
        $differens: calc(.45 / ($antal-sektioner - 1));

        @for $i from 2 through $antal-sektioner {
            $alpha: $alpha - $differens;

            .accordion-group {
                &:first-child {
                    &:nth-last-child(#{$antal-sektioner}) ~ .accordion-group {
                        &:nth-child(#{$i}) {
                            .accordion-toggle {
                                @include alpha-bg-color($alecta-bla-2, $alpha);
                            }
                        }
                    }
                }
            }
        }
    }

    .accordion-inner {
        position: relative;
        padding-top: 3rem;
        padding-bottom: 3rem;
        background-color: transparent;

        a {
            &[href^='tel:0'] {
                display: inline-block;
                padding: .5rem 0 1.5rem;
                color: $brodtext-link-color;
                font-size: 3.4rem;
            }
        }

        .kontaktaoss-lank {
            padding: .5rem 0 0;

            a {
                @include alecta-font($alecta-freight-text, 400, normal);
            }
        }

        .utbetalningsdagarblock {
            margin: 0;
        }

        .kontaktaoss-bild {
            position: absolute;
            top: 2rem;
            right: 0;
            display: none;
            width: initial;
            height: initial !important;

            &[src=''] {
                display: none;
            }
        }
    }
}

// =======================================================================================
// Min-width 768px
// =======================================================================================
@media (min-width: 768px) {
    .kontaktaossblock {
        .accordion-inner {
            .kontaktaoss-bild {
                display: block;
                width: 45% !important;
            }
        }
    }
}
