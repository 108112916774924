﻿
// =======================================================================================
// === [ INNEHÅLLSFÖRTECKNING ] ===
// =======================================================================================
.accordion {
    position: relative;
    z-index: 1;

    &.a-article-content {
        margin-bottom: 2.8rem;

        .accordion-no-number {
            padding: 0;
        }

        .accordion-toggle {
            @include border(top bottom, solid 1px, $innehallsforteckning-border);
            padding: .8rem 6.5rem .8rem 0;

            .a-accordion-section-text {
                padding-left: $margin-right-left-320 + 2rem !important;
                font-size: rem(15);
                font-weight: 500;
                line-height: rem(20);
                text-transform: uppercase;
            }

            .a-icon-plus {
                @include bg-img('../img/icon-plus.svg', center center, no-repeat, 9px 9px);
                right: initial;
                left: $margin-right-left-320;
                width: .9rem;
                height: 100%;
            }

            &[aria-expanded='true'] {
                padding-bottom: .5rem !important;
                background-color: $innehallsforteckning-bg;
                border-bottom-width: 0;

                .a-icon-plus {
                    @include bg-img('../img/icon-minus.svg', center center, no-repeat, 9px 9px);
                    height: 3.8rem;
                }
            }

            &.active {
                border-bottom-width: 0;
            }
        }

        .accordion-body {
            .accordion-inner {
                @include border(bottom, solid 1px, $innehallsforteckning-border);
                position: relative;
                top: -.2rem;
                padding: 0 $margin-right-left-320 1.2rem;
                background-color: $innehallsforteckning-bg;

                a {
                    display: block;
                    font-size: 1.5rem;
                    line-height: 2.8rem;
                }

                .anchor {
                    display: none;
                }
            }

            &[aria-expanded='false'] {
                .accordion-inner {
                    display: none;
                }
            }

            &[aria-expanded='rue'] {
                .accordion-inner {
                    display: block;
                }
            }
        }
    }
}

// =======================================================================================
// === [ ANKARE FÖR ALLA H2 PÅ SIDAN ] ===
// =======================================================================================
.innehallsfortecking {
    a {
        &::before {
            display: none;
        }
    }
}


// =======================================================================================
// Min-width 768px
// =======================================================================================
@media (min-width: 768px) {
    .accordion {
        &.a-article-content {

            .accordion-toggle {
                padding: .8rem 6.5rem .8rem 0;

                .a-accordion-section-text {
                    padding-left: $margin-right-left-768 + 1.7rem !important;
                }

                .a-icon-plus {
                    left: $margin-right-left-768;
                }
            }

            .accordion-body {
                .accordion-inner {
                    top: 0;
                    padding-right: $margin-right-left-768;
                    padding-left: $margin-right-left-768;
                }
            }
        }
    }
}

// =======================================================================================
// 4. Min-width 992px
// =======================================================================================
@media (min-width: 992px) {
    .accordion {
        &.a-article-content {
            .accordion-toggle {
                padding: .9rem 6.5rem .9rem 0 !important;

                .a-accordion-section-text {
                    padding-left: $margin-right-left-992 + 1.7rem !important;
                }

                .a-icon-plus {
                    left: 2rem;
                    height: 3.8rem;
                }

                &[aria-expanded='true'] {
                    padding-bottom: .5rem !important;
                    background-color: $trans;

                    .a-icon-plus {
                        height: 3.8rem;
                    }
                }
            }

            .accordion-body {
                .accordion-inner {
                    padding-right: $margin-right-left-992;
                    padding-left: $margin-right-left-992;
                    background-color: $trans;
                }
            }
        }
    }
}
