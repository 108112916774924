﻿
// =======================================================================================
// === [ MOBILNAVIGERNIG ] ===
// =======================================================================================
.navbar {
    .container-fluid {
        &:last-of-type {
            // === [ DROPDOWN ] ==========================================================
            .navbar-collapse {
                margin-right: 0;
                margin-left: 0;
                padding-right: 0;
                padding-left: 0;
                border: 0;

                &.collapsing {
                    margin: 0;
                }
                // === [ HAMBURGARE ] ====================================================
                .a-dropdown-mobil-close-top {
                    @include bg-img('../img/icon-nav-close.svg', center 12px, no-repeat, 14px 14px);
                    display: none;
                    background-color: $alecta-bla-3;

                    &:hover {
                        background-color: $alecta-bla-3 !important;
                    }

                    .language-label {
                        top: rem(3);
                    }
                }
                // === [ ALECTA-LOGGA ] ==================================================
                .a-foretag {
                    display: none;
                    width: 10rem;
                    height: 5rem;
                    padding: 1.8rem 0 0 1.5rem;
                    text-indent: -100rem;
                }

                .dropdown-menu {
                    span {
                        @include alecta-font($alecta-freight, 400, normal);
                        @include border(bottom right left, solid 1px, $nav-mobil-level-2-bg-border);
                        display: block;
                        height: 4rem;
                        margin: 0;
                        padding: 1.4rem 0 .5rem $margin-right-left-320;
                        color: $nav-mobil-sub-header;
                        font-size: 1.3rem;
                        line-height: 1.1;
                        text-transform: uppercase;
                        background-color: $white;
                    }
                }
                // === [ DROPMENY ] ======================================================
                .navbar-nav {
                    margin: 0;
                    // === [ UNDERRUBRIK ] ===============================================
                    h4,
                    .meny-rubrik {
                        @include alecta-font($alecta-freight, 400, normal);
                        @include border(bottom right left, solid 1px, $nav-mobil-level-2-bg-border);
                        display: block;
                        height: 4rem;
                        margin: 0;
                        padding: 1.4rem 0 .5rem $margin-right-left-320;
                        color: $nav-mobil-sub-header;
                        font-size: 1.3rem;
                        line-height: 1.1;
                        text-transform: uppercase;
                        background-color: $white;
                    }
                    // === [ NIVÅ 1 ] ====================================================
                    .nav-har-barn {
                        padding-bottom: 1px;
                        background-color: $nav-mobil-dropmenu-border;

                        a {
                            padding: .9rem 0 .9rem $margin-right-left-320;
                            color: $white;
                            font-size: rem(16);
                            font-weight: 600;
                            line-height: normal;
                            background-color: $alecta-bla-3;

                            &:hover,
                            &.active,
                            &:focus {
                                color: $white;
                            }
                        }

                        &.open {
                            .a-nav-side-level {
                                display: block;
                                max-height: 200rem;
                                transition-duration: 1000ms;
                            }
                        }

                        &:first-of-type {
                            @include border(top, solid 1px, $alecta-bla-1);
                        }

                        &.hanglas-ms-inloggad,
                        &.hanglas-ik-inloggad {
                            & > a {
                                padding-left: 52px;

                                &::before {
                                    @include bg-img('../img/ikon-las-oppen-vit.svg', 0 center, no-repeat, 15px 14px);
                                    content: ' ';
                                    padding-left: 2.2rem;
                                    height: 20px;
                                    width: 8px;
                                    display: block;
                                    position: absolute;
                                    top: 50%;
                                    left: 26px;
                                    transform: translateY(-50%);
                                }
                            }
                        }

                        &.hanglas-ms-utloggad,
                        &.hanglas-ik-utloggad {
                            & > a {
                                padding-left: 52px;

                                &::before {
                                    @include bg-img('../img/ikon-las-stangd-vit.svg', 0 center, no-repeat, 15px 14px);
                                    content: ' ';
                                    padding-left: 2.2rem;
                                    height: 20px;
                                    width: 8px;
                                    display: block;
                                    position: absolute;
                                    top: 50%;
                                    left: 26px;
                                    transform: translateY(-50%);
                                }
                            }
                        }
                        // === [ NIVÅ 2 ] ================================================
                        .a-nav-side-level {
                            max-height: 0;
                            background-color: $nav-mobil-level-2-bg;
                            overflow: hidden;
                            transition-duration: 500ms;

                            li {
                                a {
                                    @include border(bottom right, solid 1px, $nav-mobil-level-2-bg-border);
                                    @include border(left, solid 1px, $nav-mobil-level-3-border);
                                    min-height: rem(40);
                                    padding: .9rem $margin-right-left-320;
                                    color: $nav-mobil-level-3-text;
                                    font-size: 1.6rem;
                                    font-weight: 500;
                                    line-height: rem(21);
                                    background-color: $white;

                                    &.a-icon-arrow {
                                        @include border(all, solid 1px, $nav-mobil-level-2-bg-border);
                                        @include bg-img('../img/icon-plus.svg', center center, no-repeat, 11px 11px);
                                        position: absolute;
                                        top: 0;
                                        right: 0;
                                        width: 4.5rem;
                                        max-height: rem(61);
                                        padding: 0;
                                        border-top: 0;
                                    }
                                }

                                &.minasidor-loggain {
                                    @include border(bottom, solid 1px, $nav-mobil-level-2-bg-border);
                                    padding: rem(29) 0 rem(30);
                                    text-align: center;
                                    background-color: $white;

                                    .minasidor-knapp-loggain {
                                        @include border(all, solid 1px, $kanter);
                                        @include border-radius(.4rem);
                                        display: inline-block;
                                        padding: rem(9) rem(20) rem(11);
                                        color: $default-font-color;
                                        font-size: 1.4rem;
                                    }
                                }

                                &.open {
                                    > .a-icon-arrow {
                                        @include bg-img('../img/icon-minus.svg', center center, no-repeat, 11px 11px);
                                    }

                                    > .a-nav-side-level {
                                        @include transition-ease(400ms, ease-in-out);
                                        display: block;
                                        max-height: 1000rem;
                                    }
                                }

                                &.nav-har-barn {
                                    background-color: $white;

                                    &:first-of-type {
                                        border-top: 0;
                                    }

                                    > a {
                                        &:first-of-type {
                                            width: calc(100% - 45px);
                                            border-right: 0;
                                        }
                                    }
                                }
                            }
                            // === [ NIVÅ 3 ] ============================================
                            .a-nav-side-level {
                                @include transition(400ms cubic-bezier(0, 0, 0, 1));
                                max-height: 0;
                                background-color: $nav-mobil-level-3-bg;
                                overflow: hidden;

                                a {
                                    @include border(bottom, solid 1px, $nav-mobil-level-3-border);
                                    @include border(left, solid .6rem, $nav-mobil-level-3-border);
                                    padding: .9rem 0 .9rem $margin-right-left-320;
                                    background-color: $nav-mobil-level-3-bg;
                                }

                                .a-icon-arrow {
                                    @include border(all, solid 1px, $nav-mobil-level-3-border);
                                    border-top: 0;
                                }

                                &.open {
                                    > .a-icon-arrow {
                                        @include bg-img('../img/icon-minus.svg', center center, no-repeat, 11px 11px);
                                    }

                                    > .a-nav-side-level {
                                        @include transition-ease(400ms, ease-in-out);
                                        display: block;
                                        max-height: 1000rem;
                                    }
                                }
                                // === [ NIVÅ 4 ] ========================================
                                .a-nav-side-level {
                                    background-color: $nav-mobil-level-4-bg;

                                    a {
                                        @include border(right, solid 1px, $nav-mobil-level-4-border);
                                        @include border(left, solid 1.2rem, $nav-mobil-level-4-border);
                                        background-color: $nav-mobil-level-4-bg;
                                    }

                                    .a-icon-arrow {
                                        @include border(all, solid 1px, $nav-mobil-level-4-border);
                                        border-top: 0;
                                    }
                                    // === [ NIVÅ 5 ] ====================================
                                    .a-nav-side-level {
                                        background-color: $nav-mobil-level-5-bg;

                                        a {
                                            @include border(bottom, solid 1px, $nav-mobil-level-5-border);
                                            @include border(left, solid 1.8rem, $nav-mobil-level-5-border);
                                            background-color: $nav-mobil-level-5-bg;
                                        }

                                        .a-icon-arrow {
                                            @include border(all, solid 1px, $nav-mobil-level-5-border);
                                            border-top: 0;
                                        }
                                    }
                                }
                            }
                        }
                    }
                    // === [ PIL, UNDERST, STÄNGER MENYN ] ===============================
                    .a-dropdown-mobil-end {
                        display: none;
                    }

                    .a-dropdown-mobil-close {
                        @include bg-img('../img/icon-nav-mobile-arrow-up.svg', center center, no-repeat, 16px 10px);
                        position: relative !important;
                        right: 0 !important;
                        display: block;
                        width: 100% !important;
                        height: 8rem !important;
                        background-color: $alecta-bla-3;
                        border-bottom: 0;

                        &:hover {
                            background-color: $alecta-bla-3 !important;
                        }

                        &::after {
                            display: none;
                        }
                    }

                    .nav-stang {
                        position: fixed;
                        top: 9.6rem;
                        right: 0;
                        bottom: 0;
                        left: 0;
                        z-index: 1;

                        .a-dropdown-mobil-stang {
                            position: relative !important;
                            left: 0 !important;
                            display: block;
                            width: 100% !important;
                            height: 100% !important;
                            background-color: transparent !important;
                            border-bottom: 0;

                            &:hover,
                            &:focus {
                                background-color: transparent !important;
                            }

                            &::after {
                                display: none;
                            }
                        }
                    }
                }

                &#navbar-mina-sidor,
                &#navbar-internetkontoret,
                &.navbar-inloggat {
                    ul {
                        @include border(top, solid 1px, $alecta-bla-1);
                    }

                    li {
                        a {
                            @include border(bottom right, solid 1px, $nav-mobil-level-2-bg-border);
                            @include border(left, solid 1px, $nav-mobil-level-3-border);
                            padding: .9rem $margin-right-left-320;
                            color: $nav-mobil-level-3-text;
                            font-size: 1.6rem;
                            font-weight: 500;
                            line-height: normal;
                            background-color: $white;

                            &.a-icon-arrow {
                                @include border(all, solid 1px, $nav-mobil-level-2-bg-border);
                                @include bg-img('../img/icon-plus.svg', center center, no-repeat, 11px 11px);
                                position: absolute;
                                top: 0;
                                right: 0;
                                width: rem(45);
                                height: rem(40);
                                max-height: rem(61);
                                padding: 0;
                                border-top: 0;
                            }
                        }

                        .a-nav-side-level {
                            @include transition(400ms cubic-bezier(0, 0, 0, 1));
                            max-height: 0;
                            overflow: hidden;

                            a {
                                @include border(bottom, solid 1px, $nav-mobil-level-3-border);
                                @include border(left, solid .6rem, $nav-mobil-level-3-border);
                                padding: .9rem 0 .9rem $margin-right-left-320;
                                background-color: $nav-mobil-level-3-bg;
                            }
                        }

                        &.open {
                            > .a-nav-side-level {
                                @include transition-ease(400ms, ease-in-out);
                                display: block;
                                max-height: 1000rem;
                            }

                            > .a-icon-arrow {
                                @include bg-img('../img/icon-minus.svg', center center, no-repeat, 11px 11px);
                            }
                        }

                        &.minasidor-loggain {
                            @include border(bottom, solid 1px, $nav-mobil-level-2-bg-border);
                            padding: rem(29) 0 rem(30);
                            text-align: center;
                            background-color: $white;

                            .minasidor-knapp-loggain {
                                @include border(all, solid 1px, $kanter);
                                @include border-radius(.4rem);
                                display: inline-block;
                                padding: rem(9) rem(20) rem(11);
                                color: $default-font-color;
                                font-size: 1.4rem;
                            }
                        }

                        &.minasidor-inloggad {
                            padding-bottom: rem(14);
                            background-color: $white;

                            ul {
                                padding-left: $margin-right-left-320;
                                list-style-type: none;

                                li {
                                    padding: rem(2) 0 rem(6);
                                    color: $minasidor-rubrik;
                                    font-size: rem(14);
                                    font-weight: 500;
                                    line-height: rem(16);

                                    &.minasidor-rubrik {
                                        padding: rem(13) 0 0;
                                        font-size: rem(12);
                                        font-weight: 600;
                                        line-height: rem(15);
                                        text-transform: uppercase;
                                    }
                                }
                            }

                            .minasidor-knapp-loggaut {
                                @include border-radius(2rem);
                                position: absolute;
                                top: auto;
                                right: $margin-right-left-320;
                                bottom: 2.5rem;
                                display: inline-block;
                                padding: rem(7) rem(23);
                                color: $white;
                                background-color: $alecta-bla-2;
                                border: 0;

                                &:hover {
                                    text-decoration: none;
                                }
                            }
                        }
                    }
                }

                &#navbar-mobil {
                    .minasidor-inloggad {
                        padding-bottom: rem(14);
                        background-color: $white;

                        ul {
                            @include border(top, solid 1px, $alecta-bla-1);
                            padding-left: $margin-right-left-320;
                            list-style-type: none;

                            li {
                                padding: rem(2) 0 rem(6);
                                color: $minasidor-rubrik;
                                font-size: rem(14);
                                font-weight: 500;
                                line-height: rem(16);

                                &.minasidor-rubrik {
                                    padding: rem(13) 0 0;
                                    font-size: rem(12);
                                    font-weight: 600;
                                    line-height: rem(15);
                                    text-transform: uppercase;
                                }
                            }
                        }

                        .minasidor-knapp-loggaut {
                            @include border-radius(2rem);
                            position: absolute;
                            top: auto;
                            right: $margin-right-left-320;
                            bottom: 2.5rem;
                            display: inline-block;
                            padding: rem(7) rem(23);
                            color: $white;
                            background-color: $alecta-bla-2;
                            border: 0;

                            &:hover {
                                text-decoration: none;
                            }
                        }
                    }
                }
            }
        }
    }
}

// =======================================================================================
// Min-width 375px
// =======================================================================================
@media (min-width: 375px) {
    .navbar {
        .container-fluid {
            &:last-of-type {

                // === [ DROPDOWN ] ======================================================
                .navbar-collapse {

                    // === [ ALECTA-LOGGA ] ==============================================
                    .a-foretag {
                        height: 6rem;
                    }

                    // === [ HAMBURGARE ] ================================================
                    .a-dropdown-mobil-close-top {
                        top: rem(-60);
                        background-position: center 15px;

                        .language-label {
                            top: rem(6);
                        }
                    }
                }
            }
        }
    }
}

// =======================================================================================
// Min-width 768px
// =======================================================================================
@media (min-width: 768px) {
    .navbar {
        .container-fluid {
            &:last-of-type {
                // === [ DROPDOWN ] ======================================================
                .navbar-collapse {
                    // === [ HAMBURGARE ] ================================================
                    .a-dropdown-mobil-close-top {
                        top: rem(-70);
                        background-position: center 19px;
                        background-size: 16px 16px;

                        .language-label {
                            top: rem(12);
                        }
                    }

                    &#navbar-mina-sidor,
                    &#navbar-internetkontoret,
                    &.navbar-inloggat {
                        li {
                            a {
                                padding: .9rem $margin-right-left-768;
                            }
                        }
                    }
                    // === [ ALECTA-LOGGA ] ==============================================
                    .a-foretag {
                        width: 13.5rem;
                        height: 7rem;
                    }

                    .navbar-nav {
                        h4 {
                            padding-left: $margin-right-left-768;
                        }
                        // === [ NIVÅ 1 ] ================================================
                        .nav-har-barn {
                            a {
                                padding-left: $margin-right-left-768;
                            }

                            &.hanglas-ms-inloggad,
                            &.hanglas-ik-inloggad {
                                & > a {
                                    padding-left: 65px;

                                    &::before {
                                        left: 42px;
                                    }
                                }
                            }

                            &.hanglas-ms-utloggad,
                            &.hanglas-ik-utloggad {
                                & > a {
                                    padding-left: 65px;

                                    &::before {
                                        left: 42px;
                                    }
                                }
                            }
                            // === [ NIVÅ 2 ] ============================================
                            .a-nav-side-level {
                                li {
                                    a {
                                        padding-left: $margin-right-left-768;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
