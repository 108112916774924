﻿/// <reference path="../base/mixins" />
/// <reference path="../components/bas-mixin" />

// =======================================================================================
// === [ FAKTABLOCK ] ===
// =======================================================================================
.faktablock {
    display: inline-block;
    float: none;
    width: auto;
    margin-right: $margin-right-left-320;
    margin-bottom: $block-margin-bottom-320 - .5rem;
    margin-left: $margin-right-left-320;
    padding: 0;

    .a-faktablock {
        padding-right: 0;
        padding-left: 0;

        .a-faktablock-inner {
            padding-top: rem(23);
            padding-right: $margin-right-left-320;
            padding-bottom: 0;
            padding-left: $margin-right-left-320;
            background-color: $faktablock-bg;
        }

        .col-sm-10 {
            padding: 0;
        }

        h2,
        h3,
        h4 {
            margin-right: 0;
            margin-bottom: 1.3rem;
            margin-left: 0;
            font-weight: 500;
            text-align: center;
        }

        p {
            @include alecta-font($alecta-freight, 500, normal);
            margin-right: 0;
            margin-left: 0;
            font-size: 1.5rem;
            line-height: 2rem;
        }

        ul,
        ol {
            padding-left: 0;

            li {
                @include alecta-font($alecta-freight, 500, normal);
                font-size: 1.5rem;
                line-height: 2rem;

                &::after {
                    top: .8rem;
                    background-size: 5px 5px;
                }
            }
        }
    }
}

// =======================================================================================
// Min-width 768px
// =======================================================================================
@media (min-width: 768px) {
    .faktablock {
        width: 100%;
        margin-right: 0;
        margin-bottom: $block-margin-bottom-768 - .5rem;
        margin-left: 0;
        padding-right: $margin-right-left-768 - .5rem;
        padding-left: $margin-right-left-768 - .5rem;

        .a-faktablock {
            padding-right: 1rem;
            padding-left: 1rem;
        }

        &.col-sm-4 {
            .col-sm-1 {
                display: none;
            }

            .col-sm-10 {
                width: 100%;
                padding-right: 2rem;
                padding-left: 2rem;
            }
        }

        &.col-sm-6 {
            width: 49.6%;
            margin-right: 0;
            margin-left: 0;
            padding-right: 0;
            padding-left: $margin-right-left-768;

            .a-faktablock {
                margin-left: 0;
                padding: 0 1rem 0 0;
            }

            &:nth-of-type(2n+1) {
                padding-right: $margin-right-left-768;
                padding-left: 0;

                .a-faktablock {
                    margin-right: 0;
                    margin-left: 0;
                    padding: 0 0 0 1rem;
                }
            }
        }

        &.col-sm-12 {
            .a-faktablock-inner {
                padding-left: rem(60);
                padding-right: rem(60);
            }
        }

        ul,
        ol {
            li {
                margin-left: $margin-right-left-768 - 1rem;
            }
        }
    }
}

// =======================================================================================
// Min-width 992px
// =======================================================================================
@media (min-width: 992px) {
    .faktablock {
        width: 100%;
        margin-right: 0;
        margin-left: 0;
        padding-right: 0;
        padding-left: $margin-right-left-992;

        .a-faktablock {
            padding-right: 0;
            padding-left: 0;

            .col-sm-10 {

                h4 {
                    margin-bottom: 1rem;
                }
            }
        }

        &.col-sm-6 {
            margin-left: 0;
            padding-right: 0;
            padding-left: $margin-right-left-992;

            .a-faktablock {
                margin-left: 0;
                padding-right: 0;
            }

            &:nth-of-type(2n+1) {
                padding-right: 0;
                padding-left: 0;

                .a-faktablock {
                    margin-right: 0;
                    padding-left: $margin-right-left-992;
                }
            }
        }
    }
}
