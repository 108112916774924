﻿
// =======================================================================================
// === [ ALERT ] ===
// =======================================================================================
.a-alert {
    padding: 1.5rem 0;
    background-color: $alecta-ruby;

    h2 {
        margin: 0 0 .3rem;
        padding: 0 $margin-right-left-320 -1.5rem;
        color: $white;
        font-size: 2rem;
    }

    p,
    a {
        margin-bottom: 0;
        padding: 0;
        color: $white;
        font-size: 1.6rem;
    }

    a {
        padding: 0;
        text-decoration: underline;
    }
}

.alert {
    font-size: rem(15);
}

// =======================================================================================
// Min-width 768px
// =======================================================================================
@media (min-width: 768px) {
    .a-alert {
        padding-bottom: 1.5rem;

        p {
            padding-right: $margin-right-left-768 - 1.5rem;
            padding-left: $margin-right-left-768 - 1.5rem;
        }

        h2 {
            padding-right: $margin-right-left-768 - 1.5rem;
            padding-left: $margin-right-left-768 - 1.5rem;
        }
    }
}

// =======================================================================================
// Min-width 992px
// =======================================================================================
@media (min-width: 992px) {
    .a-alert {
        p,
        a {
            padding: 0;
        }

        h2 {
            padding: 0;
        }
    }
}
