﻿// =======================================================================================
// === [ StorNyhetspuffBlock ] ===
// =======================================================================================
.stornyhetspuffblock {
    height: 34rem;

    a {
        &:focus,
        &:hover,
        &:active,
        &:focus-visible {
            text-decoration: none;
            outline: none;
        }
    }

    .block-inner {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        height: 100%;
        align-items: center;
        overflow: hidden;

        .a-bg-image-mask {
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: 100%;
            height: 100%;
            align-items: center;
        }

        &.a-bg-image {
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;

            .a-bg-image-mask {
                background-color: $bild-mask-bla;
            }
        }

        .inner-text {
            width: 100%;
            height: 100%;
            max-width: 120rem;
            padding: 2.2rem 1rem 0;

            h2,
            p {
                @include alecta-font($alecta-freight-text, 300, normal);
                max-width: 50rem;
                margin: 0 0 1rem;
                color: $white;

                .text-center & {
                    margin-right: auto;
                    margin-left: auto;
                }

                .text-right & {
                    margin-right: 0;
                    margin-left: auto;
                }
            }

            h2 {
                @include alecta-font($alecta-freight, 300, normal);
                margin-top: 0;
                font-size: 3.4rem;
                line-height: 3.8rem;
            }
        }

        .a-vagvisare {
            width: 100%;
            margin: 0;
            padding: 0;
            text-align: inherit;

            .fullbredd & {
                margin: 0;
            }

            a {
                @include border(all, $vit, solid 1px);
                @include border-radius(.4rem);
                display: inline-block;
                width: auto;
                margin: 0 0 1rem;
                padding: 1.2rem 2.2rem 1.3rem;
                color: $white;
                font-size: 1.2rem;
                font-weight: 600;
                line-height: 1.5rem;
                text-transform: uppercase;
                background-color: transparent;

                &:hover {
                    @include alpha-bg-color($alecta-bla-1, 1);
                    @include border(all, $alecta-bla-1, solid 1px);
                    color: $vit;
                }
            }
        }

        &.a-textcolor-alecta-bla-1,
        &.a-textcolor-alecta-bla-vit,
        &.a-textcolor-alecta-ruby {
            .inner-text {
                h2,
                p {
                    color: $alecta-bla-1;
                }
            }

            .a-vagvisare {
                a {
                    @include alpha-bg-color($alecta-bla-1, 1);
                    @include border(all, $alecta-bla-1, solid 1px);
                    color: $vit;

                    &:hover {
                        @include alpha-bg-color($vit, 1);
                        @include border(all, $vit, solid 1px);
                        color: $alecta-bla-1;
                    }
                }
            }
        }

        &.a-textcolor-alecta-ruby {
            .inner-text {
                h2,
                p {
                    color: $alecta-ruby;
                }
            }
        }

        &.a-textcolor-alecta-bla-3 {
            .inner-text {
                h2,
                p {
                    color: $alecta-bla-3;
                }
            }
        }
    }
}

// =======================================================================================
// Min-width 768px
// =======================================================================================
@media (min-width: 768px) {
    .stornyhetspuffblock {
        height: 44.3rem;

        .block-inner {
            .inner-text {
                width: 100%;
                height: 100%;
                margin-bottom: 1.8rem;
                padding: 10.2rem 8rem 0;

                p {
                    margin-bottom: 2rem;
                    font-size: 19px;
                    line-height: 25px;
                }

                h2 {
                    font-size: 4.2rem;
                    line-height: 4.8rem;
                }

                .a-vagvisare {
                    margin: 1rem 0 0;
                    padding: 0;
                }
            }

            &.text-center {
                .inner-text {
                    p,
                    h2 {
                        margin-right: auto;
                        margin-left: auto;
                    }
                }
            }

            &.text-right {
                .inner-text {
                    p,
                    h2 {
                        margin-right: 0;
                        margin-left: auto;
                    }
                }
            }
        }
    }
}

// =======================================================================================
// Min-width 992px
// =======================================================================================
@media (min-width: 992px) {
    .stornyhetspuffblock {
        height: 44.3rem;

        .block-inner {
            .inner-text {
                padding: 10.2rem 13.4rem 0;
            }
        }
    }
}
