﻿
// =======================================================================================
// === [ ACCORDION ] ===
// =======================================================================================
.accordion {
    margin-bottom: 2.5rem;

    // === [ RUBRIK ] ====================================================================
    .accordion-toggle {
        @include border(top bottom, solid 1px, $accord-header-border);
        position: relative;
        display: block;
        margin-top: -1px; //Viktigt
        padding: 1.9rem 6.5rem 2.3rem 0;

        h2 {
            margin: 0;
        }

        &:hover,
        &:focus {
            text-decoration: none;
        }

        .a-accordion-section-ball,
        .a-accordion-section-text {
            color: $default-link-color;
            font-size: rem(22);
            font-weight: 400;
            line-height: rem(23);
            text-decoration: none;
        }

        .a-accordion-section-ball {
            position: absolute;
            top: 1.9rem;
            left: $margin-right-left-320;
            display: inline-block;
        }

        .a-accordion-section-text {
            display: table-cell;
            padding-left: 5rem;

            span {
                font-size: rem(20);
            }
        }

        // === [ PIL ] ===================================================================
        .a-icon-arrow-big {
            right: $margin-right-left-320;
            height: 6.2rem;
        }

        &[aria-expanded='true'],
        &.active {
            background-color: $accord-inner-bg;
            border-bottom-width: 0;

            .a-icon-arrow-big {
                @include bg-img('../img/icon-arrow-up-big.svg', null, null, null);
            }
        }

        &.active {
            &.collapsed {
                .a-icon-arrow-big {
                    @include bg-img('../img/icon-arrow-big.svg', null, null, null);
                }
            }
        }
    }

    // === [ UTAN NUMMER ] ===============================================================
    .accordion-no-number {
        .accordion-toggle {
            padding: 2rem 6.5rem 2rem 0;

            .a-accordion-section-ball {
                display: none;
            }

            .a-accordion-section-text {
                padding-left: $margin-right-left-320;
            }
        }
    }

    .accordion-inner {
        @include border(bottom, solid 1px, $accord-header-border);
        padding: 0 $margin-right-left-320 2rem;
        background-color: $accord-inner-bg;

        h1,
        h2,
        h3,
        h4,
        h5,
        p,
        .darrad,
        q,
        li {
            margin-right: 0;
            margin-left: 0;
        }

        .table-kanter-alecta-gron {
            &.tabellwrapper {
                .tabellwrapper-inner {
                    padding-right: 0;
                    padding-left: 0;
                }
            }
        }
    }
}

// =======================================================================================
// min-width: 768px
// =======================================================================================
@media (min-width: 768px) {
    .accordion {

        // === [ RUBRIK ] ================================================================
        .accordion-toggle {
            .a-accordion-section-ball,
            .a-accordion-section-text {
                font-size: rem(24);
                line-height: rem(24);
            }

            .a-accordion-section-ball {
                left: $margin-right-left-768;
            }

            .a-accordion-section-text {
                padding-left: $margin-right-left-768 + 2.5rem;
            }

            // === [ PIL ] ===============================================================
            .a-icon-arrow-big {
                right: $margin-right-left-768;
            }
        }

        // === [ UTAN NUMMER ] ===========================================================
        .accordion-no-number {
            .accordion-toggle {
                .a-accordion-section-text {
                    padding-left: $margin-right-left-768;
                }
            }
        }

        .accordion-inner {
            padding: 0 $margin-right-left-768 2rem;
        }

    }
}

// =======================================================================================
// min-width: 992px
// =======================================================================================
@media (min-width: 992px) {
    .accordion {

        // === [ RUBRIK ] ================================================================
        .accordion-toggle {
            .a-accordion-section-ball,
            .a-accordion-section-text {
                font-size: rem(26);
                line-height: rem(28);
            }

            .a-accordion-section-ball {
                left: $margin-right-left-992;
            }

            .a-accordion-section-text {
                padding-left: $margin-right-left-992 + 2.5rem;
            }

            // === [ PIL ] ===============================================================
            .a-icon-arrow-big {
                right: $margin-right-left-992;
            }

            &[aria-expanded='true'],
            &.active {
                background-color: $trans;
            }
        }

        // === [ UTAN NUMMER ] ===========================================================
        .accordion-no-number {
            .accordion-toggle {
                .a-accordion-section-text {
                    padding-left: $margin-right-left-992;
                }
            }
        }

        .accordion-inner {
            padding: 0 $margin-right-left-992 2rem;
            background-color: $trans;
        }

    }
}
