﻿/// <reference path="../base/mixins" />
/// <reference path="../components/bas-mixin" />

.innehallssida-utan-navigering {
    h1 {
        text-align: left;
    }
}

// =======================================================================================
// min-width: 992px
// =======================================================================================
@media (min-width: 992px) {
    .innehallssida-utan-navigering {
        section {
            margin-left: 2.25rem;
            margin-right: 2.25rem;
        }

        h1,
        h2,
        h3,
        h4,
        h5,
        h6,
        .toppbild,
        .block,
        .gillagillainte {
            max-width: 63.5rem;
        }

        .faktablock {
            max-width: none;

            .a-faktablock {
                max-width: 63.5rem;
            }
        }

        .tabellblock {
            max-width: none;

            .tabellwrapper {
                max-width: 63.5rem;
            }
        }

        .toppbild,
        .lanklistablock {
            margin-left: 20px;
        }
    }
}
