﻿/// <reference path="../base/mixins" />
/// <reference path="../components/bas-mixin" />

.konsolideringsgradblock {
    padding-left: rem(20);

    .table-jamnforande-bla {
        .tabellwrapper-inner {
            table {
                font-size: rem(16);

                thead {
                    tr {
                        th {
                            font-size: rem(16);
                            text-align: left;
                            background-color: $table-konsolidering-lighter-blue;
                            border: 1px solid $table-konsolidering-blue-border;
                            line-height: rem(22);
                        }
                    }
                }

                tbody {
                    tr {
                        td {
                            background-color: $vit;
                            padding: rem(20);
                            box-sizing: border-box;
                            border: 1px solid $table-konsolidering-blue-border;
                            line-height: rem(22);
                        }
                    }
                }
            }
        }
    }
}
