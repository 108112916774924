﻿
// =======================================================================================
// === [ HOPPLÄNKAR ] ===
// =======================================================================================
.hopp-lankar {
    position: relative;
    padding: 15px $mobil-padding 40px;
    text-align: center;

    h4 {
        margin: 0;
        padding-bottom: 5px;
        font-size: 16px;
        font-weight: 300;
        line-height: 20px;
        text-transform: none;
    }

    a {
        display: inline-block;
        margin: 0 10px 10px;
        padding: 0 5px;
        font-size: 16px;
        line-height: 20px;
    }
}



// =======================================================================================
// =======================================================================================
// ================== [ FÖRÄNDRARINGAR TILL ORGIONAL INSTÄLLNINGARNA ] ===================
// =======================================================================================
// =======================================================================================



// =======================================================================================
// === [ HOPPLÄNKAR ] ===
// =======================================================================================
.hopp-lankar {
    position: relative;
    margin: 0 auto;
    padding: 0 $mobil-padding rem(30);
    text-align: center;

    // === [ AVTAL ] =====================================================================
    &.hopp-lankar-avtal {
        padding-bottom: rem(29);
    }

    // === [ HISTORIK ] ==================================================================
    &.hopp-lankar-anvandare-ombud-flera {
        padding-bottom: rem(19);
    }

    // === [ KONTAKTUPPGIFTER ] ==========================================================
    &.hopp-lankar-minauppgifter {
        padding-bottom: rem(29);
    }

    // === [ MEDDELANDEN ] ===============================================================
    &.hopp-lankar-meddelandeninkorg {
        padding-bottom: rem(32);
    }

    // === [ SÖK FÖRSÄKRAD ] =============================================================
    &.hopp-lankar-sok-forsakrad-niva2 {
        //margin-top: -3px;
        padding-bottom: 20px;
    }

    // === [ HANDLÄGGARPORTALEN » FULLMAKTER ] ===========================================
    &.hopp-lankar-hpfullmakter {
        margin-top: -10px;
    }

    // === [ SÖK FÖRSÄKRAD ] =============================================================
    &.hopp-lankar-sok-forsakrad {
        margin-top: -7px;
    }
}

// =======================================================================================
// === [ MIN-WIDTH 768px ] ===
// =======================================================================================
@media (min-width: 768px) {
    .hopp-lankar {
        padding-right: $tablet-padding;
        padding-left: $tablet-padding;

        h4 {
            padding-bottom: rem(4);
        }

        // === [ AVTAL ] =================================================================
        &.hopp-lankar-avtal {
            padding-bottom: 30px;
        }

        // === [ AVTAL » STATUS ] ========================================================
        &.hopp-lankar-avtal-status {
            padding-bottom: 39px;
        }

        // === [ KONTAKTUPPGIFTER ] ======================================================
        &.hopp-lankar-minauppgifter {
            padding-bottom: 29px;
        }
    }
}

// =======================================================================================
// === [ MIN-WIDTH 992px ] ===
// =======================================================================================
@media (min-width: 992px) {
    .hopp-lankar {
        padding-bottom: rem(20);

        // === [ MEDDELANDEN ] ===========================================================
        &.hopp-lankar-meddelandeninkorg {
            padding-bottom: 19px;
        }
    }
}
