﻿
// =======================================================================================
// === [ UTBETALNINGSDAGAR ALLA ] ===
// =======================================================================================
.utbetalningsdagarallablock {
    margin-bottom: $block-margin-bottom-320;
    text-align: center;
    background-color: $kalender-alla-bg;

    .col-xs-12 {
        @include alecta-font($alecta-freight-text, 400, italic);
        padding: rem(24) rem(30) rem(18);
        color: $default-font-color;
        font-size: rem(15);
        line-height: rem(19);
        text-align: center;

        h3 {
            margin: 0;
            padding: 0 0 rem(16);
            text-align: center;
        }

        a {
            @include alecta-font($alecta-freight-text, 400, italic);
            display: block;
            padding: 0;
            color: $default-font-color;
            font-size: rem(15);
            text-align: center;
            clear: both;
        }
    }

    table {
        margin: 0 auto rem(18);

        td {
            display: inline-block;
            width: 50%;
            padding: 0 1px 1px 0;
            text-align: left;

            span {
                display: inline-block;
                width: rem(105);
                height: rem(70);
                padding-top: rem(21);
                color: $white;
                font-size: rem(15);
                line-height: rem(24);
                text-align: center;
                background-color: $alecta-bla-3;
            }

            &.active {
                span {
                    background-color: $alecta-bla-1;
                }
            }

            &:nth-child(2n+1) {
                text-align: right;
            }
        }
    }
}

// =======================================================================================
// === [ UTBETALNINGSDAG ] ===
// =======================================================================================
.utbetalningsdagarblock {
    margin-bottom: $block-margin-bottom-320;

    .col-xs-12 {
        padding: rem(24) 0 rem(18);
        background-color: $alecta-bla-1;

        div {
            padding: 0;
            text-align: center;
        }

        .utbetalningsdag {
            @include alecta-font($alecta-freight-text, 400, italic);
            max-width: rem(220);
            padding: 0 rem(20);
            color: $white;
            font-size: rem(15);

            h3 {
                margin: 0 rem(-40);
                padding: 0 0 rem(16);
                color: $white;
            }

            a {
                @include alecta-font($alecta-freight-text, 400, italic);
                display: block;
                margin: 0 rem(-40);
                color: $white;
                font-size: rem(15);
            }
        }
    }

    .kalender {
        @include border-radius(rem(4));
        margin-bottom: rem(18);
        background-color: $white;

        h4 {
            color: $default-font-color;

            &:first-of-type {
                @include border(bottom, solid 1px, $kalender-year-border);
                margin: 0;
                padding: rem(2) 0 rem(3);
            }

            &:last-of-type {
                margin: 0;
                padding: 0 0 rem(13);
            }
        }

        .kalender-dag {
            @include alecta-font($alecta-freight-text, 300, normal);
            padding-bottom: rem(28);
            color: $default-font-color;
            font-size: rem(100);
            line-height: rem(71);
        }
    }
}

// =======================================================================================
// Min-width 768px
// =======================================================================================
@media (min-width: 768px) {
    .utbetalningsdagarallablock {
        margin-bottom: $block-margin-bottom-768;

        .col-xs-12 {
            width: rem(650);
            padding-right: 0;
            padding-left: 0;
        }

        table {
            td {
                width: 16.6666%;

                span {
                    width: 100%;
                }
            }
        }
    }

    .utbetalningsdagarblock {
        margin-bottom: $block-margin-bottom-768;
    }
}

// =======================================================================================
// Min-width 992px
// =======================================================================================
@media (min-width: 992px) {
    .utbetalningsdagarallablock {
        margin-bottom: $block-margin-bottom-992;
        margin-left: $margin-right-left-992;

        .col-xs-12 {
            width: 90%;
        }
    }

    .utbetalningsdagarblock {
        margin-bottom: $block-margin-bottom-992;
        margin-left: $margin-right-left-992;
    }
}

// =======================================================================================
// Min-width 1200px
// =======================================================================================
@media (min-width: 1200px) {
    .utbetalningsdagarallablock {
        .col-xs-12 {
            width: rem(700);
        }

        table {
            width: rem(600);

            tr {
                display: flex;
                flex-flow: row wrap;
            }

            td {
                &:nth-child(2n+1) {
                    text-align: left;
                }
            }
        }
    }

    .utbetalningsdagarblock {
        .kalender {
            .kalender-dag {
                @include alecta-font($alecta-freight-text, 300, normal);
                padding-bottom: rem(28);
                font-size: rem(100);
                line-height: rem(71);
            }
        }
    }
}
