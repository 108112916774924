﻿
// =======================================================================================
// === [ DOKUMENTLISTA ] ===
// =======================================================================================
.dokumentlistablock {
    ol {
        padding: 0;
    }
}

.dokument-typ {
    @include alecta-font($alecta-freight, 500, normal);
    display: inline-block;
    padding-left: 2rem;

    &.typ-pdf {
        @include bg-img('../img/ikon-pdf.svg', 0 4px, no-repeat, 12px 15px);
    }

    &.typ-doc,
    &.typ-docx {
        @include bg-img('../img/ikon-word.svg', 0 4px, no-repeat, 12px 15px);
    }

    &.typ-xls,
    &.typ-xlsx {
        @include bg-img('../img/ikon-excel.svg', 0 4px, no-repeat, 12px 15px);
    }

    &.typ-ppt,
    &.typ-pptx {
        @include bg-img('../img/ikon-powerpoint.svg', 0 4px, no-repeat, 12px 15px);
    }
}

.dokument-storlek {
    @include alecta-font($alecta-freight, 500, normal);
}

.accordion-inner,
.dokumentlistablock {
    .dokumentlistablock {
        margin-right: 0;
        margin-bottom: 0;
        margin-left: 0;
    }
}

// =======================================================================================
// Min-width 992px
// =======================================================================================
@media (min-width: 992px) {
    .dokumentlistablock {
        margin-right: 0;
        margin-left: 0;

        ol {
            margin-right: 0;
            margin-left: 0;
        }
    }

    .accordion-inner {
        .dokumentlistablock {
            margin-right: -1rem;
            margin-left: -1rem;
        }
    }
}
