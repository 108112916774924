﻿/// <reference path="../base/mixins" />
/// <reference path="../components/bas-mixin" />

// =======================================================================================
// === [ INLOGGAD MENYRAD ] ===
// =======================================================================================
.inloggadmenyrad {
    z-index: 2000;
    height: rem(30);
    background-color: $alecta-bla-1;

    .container {
        position: relative;
        height: 100%;
        text-align: right;
    }

    .inloggad-som {
        display: none;
        margin: 0;
        padding: 0 rem(15) 0 0;
    }

    li,
    a {
        @include alecta-font($alecta-freight, 500, normal);
        display: inline-block;
        height: 100%;
        margin-left: rem(3);
        padding-top: rem(9);
        color: $white;
        font-size: rem(14);
        line-height: rem(16);

        &.inloggad-som-avbryt {
            a {
                @include border(all, solid 1px, $white);
                @include border-radius(2rem);
                position: absolute;
                top: 0;
                left: $margin-right-left-320 - 1.5rem;
                display: inline-block;
                margin-bottom: 0;
                margin-left: 0;
                padding: rem(8) rem(30) 0;
                color: $white;
                font-size: 1.4rem;
                text-align: center;
                text-decoration: none;
                white-space: nowrap;
            }
        }

        &.inloggad-som-etikett {
            margin-left: rem(15);
            color: $inloggadmenyrad-etikett;

            .digitala-brev-ja {
                @include bg-img('../img/ikon-inloggadmeny-check.svg', 0 0, no-repeat, null);
                position: relative;
                top: rem(2);
                display: inline-block;
                width: rem(16);
                height: rem(12);
            }

            .digitala-brev-nej {
                @include bg-img('../img/ikon-inloggadmeny-cross.svg', 0 0, no-repeat, null);
                position: relative;
                top: rem(2);
                display: inline-block;
                width: rem(12);
                height: rem(12);
            }
        }

        &.inloggad-meny-lank {
            background: none;
            padding-left: 3rem;

            > * {
                pointer-events: none;
            }

            &[aria-expanded='true'] {
                background-color: $inloggadmenyrad-meny-lank-active;
            }

            &[aria-expanded='false'] {
                background: none;
            }

            &:hover,
            &:focus {
                text-decoration: none;
                background-color: $inloggadmenyrad-meny-lank-hover;
            }

            .inloggad-som-etikett {
                color: $inloggadmenyrad-etikett;
            }

            .animating-chevron {
                transition: transform .4s;
                transform: rotate(180deg);
                position: relative;
                left: 5px;
            }

            &.collapsed {
                .animating-chevron {
                    transform: rotate(0);
                }
            }
        }
    }

    .inloggad-meny {
        position: absolute;
        top: rem(30);
        right: 0;
        z-index: 2001;
        width: rem(200);
        margin-bottom: 0;
        background-color: $inloggadmenyrad-menydrop-bg;

        .accordion-inner {
            padding: 0;
            border: 0;

            ul {
                margin: 0;
                padding: 0 !important;
                text-align: left;

                li {
                    display: block;
                    padding: 0 2rem;
                    color: $nyhetsflode-date;
                    font-size: rem(12);
                    font-weight: 600;
                    line-height: 0 !important;
                    text-transform: uppercase;
                    list-style: none;

                    &.inloggad-rubrik {
                        padding: 19px 20px 7px !important;
                        line-height: 15px !important;
                    }

                    a {
                        margin: 0;
                        padding: rem(6) 0;
                        color: $default-font-color;
                        font-size: rem(14);
                        font-weight: 500;
                        line-height: rem(13);
                        text-transform: none;

                        &:hover {
                            color: $minasidor-lank-hover;
                            text-decoration: none;
                        }
                    }

                    &.inloggad-loggaut-knapp {
                        width: 100%;
                        padding-top: rem(11);
                        text-align: center;

                        a {
                            @include border-radius(2rem);
                            display: inline-block;
                            padding: rem(11) rem(23);
                            color: $white;
                            background-color: $alecta-bla-2;
                        }
                    }
                }

                &.inloggad-kundnr {
                    margin-top: rem(20);
                    padding: rem(18) 0 rem(16) rem(20) !important;
                    color: $white !important;
                    font-size: rem(12) !important;
                    line-height: rem(13) !important;
                    background-color: $alecta-ruby;

                    .inloggad-rubrik {
                        display: inline-block;
                        padding-right: rem(7);
                        font-weight: 600;
                        text-transform: uppercase;
                    }

                    li {
                        display: inline-block;
                        margin-right: rem(10);
                        color: $white;

                        a {
                            color: $white;
                            text-decoration: none;
                        }
                    }

                    span {
                        display: inline-block;
                        margin-right: rem(10);
                        color: $white;
                        font-size: 12px;
                        line-height: 15px;
                    }
                }
            }
        }
    }
}

// =======================================================================================
// === [ INLOGGAD MENYRAD ] ===
// =======================================================================================
.inloggadmenyrad-ik {
    .inloggad-meny {
        background-color: transparent;

        ul {
            padding: 0 !important;
            background-color: $ik-inloggadmenyrad-bg;

            &.menyrad-mintjanstepension {
                padding-bottom: 0 !important;
                border: 0 !important;
            }

            li {
                height: auto !important;
                padding: 0 rem(20);
                font-size: 0 !important;
                line-height: 0 !important;

                &.inloggad-rubrik {
                    padding: rem(19) rem(20) rem(7) !important;
                    font-size: rem(12) !important;
                    line-height: rem(15) !important;
                }

                a {
                    @include text-truncate;
                    width: 100%;
                    height: rem(25);
                    padding: rem(6) 0 0 !important;
                }
            }
        }

        .inloggad-loggaut-knapp {
            a {
                width: auto;
                height: rem(35);
                padding: rem(11) rem(20) !important;
            }
        }

        .inloggad-kundnr {
            margin-top: rem(20);
            padding: rem(18) 0 rem(16) rem(20) !important;
            color: $vit !important;
            font-size: rem(12) !important;
            line-height: rem(13) !important;
            background-color: $alecta-ruby;

            .inloggad-rubrik {
                display: inline-block;
                padding-right: rem(7);
                font-weight: 600;
                text-transform: uppercase;
            }

            span {
                font-weight: 300 !important;
            }
        }
    }
}

.inloggadmenyrad-top {
    background: #E9671D;
    color: #fff;
    text-align: right;

    span {
        display: inline-block;
        padding: 1rem 2rem;
        font-size: 1.4rem;
        line-height: 1.6rem;

        @media (min-width: 992px) {
            padding: 1rem 1.5rem;
        }
    }
}

// =======================================================================================
// Min-width 375px
// =======================================================================================
@media (min-width: 375px) {
    .inloggadmenyrad {
        height: rem(35);

        .inloggad-meny {
            top: rem(35);
        }
    }
}

// =======================================================================================
// === [ MIN-WIDTH 768px ] ===
// =======================================================================================
@media (min-width: 768px) {

    // === [ INLOGGAD MENYRAD ] ==========================================================
    .inloggadmenyrad-ik {
        .inloggad-meny {
            width: auto;

            ul {
                display: inline-block;
                width: rem(200);
                vertical-align: top;

                &.menyrad-ik {
                    @include border(right, solid 1px, $alecta-rubin);
                }

                li {
                    font-size: 0;
                    line-height: 0;
                }
            }
        }
    }
}

// =======================================================================================
// Min-width 992px
// =======================================================================================
@media (min-width: 992px) {
    .inloggadmenyrad {
        .container {
            padding-right: 0;

            a {
                &.inloggad-som-avbryt {
                    left: $margin-right-left-992 - .5rem;
                }
            }
        }

        a {
            &.inloggad-meny-lank {
                padding-right: 2.2rem;
                text-align: left;

                .animating-chevron {
                    transition: transform .4s;
                    transform: rotate(180deg);
                    position: relative;
                    left: 5px;
                }

                &.collapsed {
                    .animating-chevron {
                        transform: rotate(0);
                    }
                }
            }
        }

        .inloggad-som {
            display: inline-block;
            background-color: transparent;
            opacity: .9999;
        }
    }
}
