﻿
// =======================================================================================
// === [ GUIDE ] ===
// =======================================================================================
.a-guide-employee {
    margin-top: rem(-30);
    margin-bottom: $block-margin-bottom-320;

    .a-guide-employee-image {
        position: relative;
        height: 23rem;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: auto 100%;
        overflow: hidden;

        img {
            position: absolute;
            top: -9999rem;
            right: -9999rem;
            bottom: -9999rem;
            left: -9999rem;
            display: block;
            width: auto !important;
            height: 100% !important;
            max-width: none !important;
            margin: auto;
        }

        .a-guide-employee-image-name {
            @include alpha-bg-color($black, .12);
            position: absolute;
            right: 0;
            bottom: 0;
            left: 0;

            h4 {
                padding: .8rem 0 .6rem;
                color: $white;
                font-size: 1.4rem;
                font-weight: 400;
                text-transform: none;
            }

            .a-guide-employee-image-name-wing {
                position: absolute;
                top: -1rem;
                right: 6rem;
                width: 7.2rem;
                height: 133%;
                background-size: 5rem auto;
            }
        }
    }

    q {
        display: inline-block;
        float: none;
        margin-bottom: -2.5rem;
        padding: 1.1rem $margin-right-left-320 2.5rem 5rem;
        text-align: right;
    }
}

// =======================================================================================
// Min-width 600px
// =======================================================================================
@media (min-width: 600px) {
    .a-guide-employee {
        .a-guide-employee-image {
            img {
                width: 101% !important;
                height: auto !important;
            }
        }
    }
}

// =======================================================================================
// Min-width 768px
// =======================================================================================
@media (min-width: 768px) {
    .a-main {
        section {

            // === [ GUIDESIDA :: GUIDEPERSON ] ==========================================
            .a-guide-employee {
                margin-top: rem(-32);
                margin-bottom: 3rem;

                .a-guide-employee-image {
                    height: 30rem;

                    img {
                        width: auto !important;
                        height: 100% !important;
                    }

                    .a-guide-employee-image-name {
                        h4 {
                            padding: .7rem 0 .6rem;
                        }

                        .a-guide-employee-image-name-wing {
                            top: -1rem;
                            width: 6rem;
                            height: 5rem;
                            background-size: 6rem 5rem;
                        }
                    }
                }

                q {
                    max-width: 100%;
                    margin-right: 0;
                    margin-left: 0;
                    padding: 1.1rem $margin-right-left-768 0 35rem;
                }
            }
        }
    }
}

// =======================================================================================
// Min-width 992px
// =======================================================================================
@media (min-width: 992px) {
    .a-main {
        section {

            // === [ GUIDESIDA :: GUIDEPERSON ] ==========================================
            .a-guide-employee {
                margin: 0 0 4rem;

                .a-guide-employee-image {
                    .a-guide-employee-image-name {
                        h4 {
                            font-size: 1.4rem;
                            font-weight: 400;
                            text-transform: none;
                        }

                        .a-guide-employee-image-name-wing {
                            top: -1rem;
                            width: 6rem;
                            height: 5rem;
                            background-size: 6rem 5rem;
                        }
                    }
                }

                q {
                    max-width: $textblock-max-bredd-desktop;
                    padding: 1.1rem $margin-right-left-992 0 5rem;
                }
            }

            // === [ RUBRIK (H1) ] =======================================================
            h1 {
                &.guidepage {
                    padding-left: 0;
                }
            }

            .a-ingress {
                padding-right: 0;
                padding-left: 0;

                &.a-ingress-guide {
                    padding-left: 0;
                }
            }
        }
    }
}
