﻿
// =======================================================================================
// === [ PAGINERING ] ===
// =======================================================================================
.pagineringblock {
    margin-right: $margin-right-left-320;
    margin-bottom: $block-margin-bottom-320;
    margin-left: $margin-right-left-320;
    padding: 2.8rem 0 0;
    text-align: center;

    .pagination {
        max-width: 100%;
        margin: 0;
        padding: 0;
        list-style: none;

        li {
            display: inline;
            margin-right: 0;
            margin-left: 0;
            padding-right: 0;
            padding-left: 0;

            a {
                @include alecta-font($alecta-freight, 500, normal);
                @include versala-siffror;
                display: inline-block;
                padding-right: .9rem;
                padding-left: .8rem;
                color: $default-link-color;
                font-size: 2rem;
            }

            &:first-of-type {
                margin-left: 0;
            }

            &::after {
                display: none;
            }

            &.prev {
                a {
                    @include bg-img('../img/icon-pagin-left.svg', 6px center, no-repeat, 7px 11px);
                    width: 3rem;
                }
            }

            &.next {
                a {
                    @include bg-img('../img/icon-pagin-right.svg', 17px center, no-repeat, 7px 11px);
                    width: 3rem;
                }
            }

            &.active {
                a {
                    font-weight: 600;
                }
            }
        }
    }

}
