
// sass-lint:disable indentation

// =======================================================================================
// === [ IMPORTERAR BOOTSTRAP ] ===
// =======================================================================================
@import 'bootstrap';

// =======================================================================================
// === [ DESSA FIL INNEHÅLLER ALLA VARIABLAR, 'MIXINS' MM ] ===
// =======================================================================================
@import 'base/mixins';

// =======================================================================================
// === [ BAS-FILEN INNEHÅLL ALLA GRUNDINSTÄLLNINGAR FÖR MOBIL, ] ===
// === [ EFTERSOM BOOTSTRAP ÄR 'MOBILE FIRST' ] ===
// =======================================================================================
@import 'base/base';

// =======================================================================================
// === [ SEN HAR VI ALLA FILER FÖR RESPEKTIVE OMRÅDE ] ===
// =======================================================================================

// === [ NAVIGERING ] ====================================================================
@import 'layout/navtop';
@import 'layout/navmobil';
@import 'layout/navdesktop';
@import 'layout/navside';
@import 'layout_minasidor/navinloggad';

// === [ TYPOGRAFI ] =====================================================================
@import 'layout/typografi';

// === [ FORMULÄR ] ======================================================================
@import 'layout/forms';

// === [ BLOCK ] =========================================================================
@import 'layout/accordion';
@import 'layout/innehallsfortackning';
@import 'layout/pressmeddelanden';
@import 'layout/paginering';
@import 'layout/citatblock';
@import 'layout/bildblock';
@import 'layout/fritextblock';
@import 'layout/motorvag';
@import 'layout/nyhetsflode';
@import 'layout/stornyhet';
@import 'layout/grafer';
@import 'layout/slider';
@import 'layout/delaknappar';
@import 'layout/galleriblock';
@import 'layout/faktablock';
@import 'layout/modal';
@import 'layout/hanvisningspuffbild';
@import 'layout/hanvisningspuffikon';
@import 'layout/infografikblock';
@import 'layout/tabell';
@import 'layout/utbetalningsdagar';
@import 'layout/videoblock';
@import 'layout/lanklistablock';
@import 'layout/pufflankblock';
@import 'layout/flytandebild';
@import 'layout/flytandeblock';
@import 'layout/ikontextblock';
@import 'layout/liggandelanklistablock';
@import 'layout/stornyhetspuffblock';
@import 'layout/nyckeltalblock';
@import 'layout/avskilljareblock';
@import 'layout/storbildpuffblock';
@import 'layout/koldioxidblock';
@import 'layout/aopblock';
@import 'layout/koldioxidavtryckblock';
@import 'layout/konsolideringsblock';
@import 'layout/cookievalblock';

@import 'layout_ik/block-hopplankar';

// === [ ELEMENT ] =======================================================================
@import 'layout/alert';
@import 'layout/popover';
@import 'layout/etikett';
@import 'layout/gillagillainte';
@import 'layout/vagvisare';
@import 'layout/sidfot';
@import 'layout/cookiebanner';
@import 'layout/grafer-externa';
@import 'layout/test-miljo';
@import 'layout/slideshow';
@import 'layout/video';

// === [ SIDOR ] =========================================================================
@import 'layout/innehallssida';
@import 'layout/404';
@import 'layout/avgiftsraknaren';
@import 'layout/guide';
@import 'layout/tema';
@import 'layout/arsredovisning';
@import 'layout/fotogalleri';

// kontaktsida används även på CGI:s BankID-sida
@import 'layout/kontaktsida';
@import 'layout/ordlista';
@import 'layout/sok';
@import 'layout/dokumentlista';
@import 'layout/kurs';
@import 'layout/itp2';
// Logga in & utloggad-sidorna
@import 'layout_minasidor/loggain';
@import 'layout_minasidor/sid-sid-mintjanstepension-graf2';
@import 'layout/utloggad';

// Styles till sök-försäkrad-sidan på alecta.se som inte finns än
//@import 'layout/sok-forsakrad';
//.sok-forsakrad {
    //@import 'layout_ik/block-belopp-boll';
    //@import 'components/block-prognos-termometer';
    //@import 'components/block-tabell';
    //@import 'layout_ik/block-dagtermometer';
    //@import 'layout_ik/block-text';
//}

// === [ PRINT ] =========================================================================
@import 'layout/print';

// === [ TABBB NAVIGERING ] ==============================================================
@import 'layout/tab';

// === [ QR-INLOGGNING ] =================================================================
@import 'layout/qr-inloggning';

// === [ JQuery Slick Slideshow ] =================================================================
@import 'slick/slick';

// =======================================================================================
// === [ SEN HAR VI LITE FIXAR FÖR EPISERVER ] ===
// =======================================================================================
@import 'layout/episerver';

// =======================================================================================
// === [ ALLA ÄNDRINGAR FÖR +320PX (HELT ONÖDIG, MEN HJÄLPER TILL MED ] ===
// === [ ATT "SE" RESONSIVITET VID UTVECKLING) LOL ] ===
// =======================================================================================
@media (min-width: 320px) {
    .tester7 {
        display: none;
    }
}

// =======================================================================================
@media (min-width: 768px) {
    body {
        &::before {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: -1;
            background-image: none;
            background-position: center -35px;
            background-repeat: no-repeat;
            opacity: 1;
        }
    }
}

@media (min-width: 992px) {
    body {
        &::before {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: -1;
            background-image: none;
            background-position: 43px 0;
            background-repeat: no-repeat;
        }
    }
}
