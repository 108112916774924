﻿
// =======================================================================================
// === [ FRITEXTBLOCK ] ===
// =======================================================================================
.fritextblock {
    display: inline-block;
    float: none;

    &.col-sm-6 {
        margin: 0;
        padding-right: 2rem;

        + .bildblock {
            float: right;
        }
    }
}

.modal-content {
    .modal-body {
        .fritextblock {
            h3,
            h4 {
                margin-right: 0;
                margin-left: 0;
            }
        }
    }
}
