﻿
// =======================================================================================
// [Innehållsförteckning]
//
// Färger
//      Alectas 6 färger
//      Generella
// Olika mått
// Fonter
// Mixins
// =======================================================================================

// =======================================================================================
// Färger
// =======================================================================================

// Färger :: Alectas 6 färger ------------------------------------------------------------
$alecta-bla-1: #0a2c5a;
$alecta-bla-2: #004c93;
$alecta-bla-3: #009ed7;
$alecta-ruby: #a03268;
$alecta-gron: #7cb001;
$alecta-orange: #e9671d;
$fel-meddelande-farg: #d0021b;
// Färger :: Generella -------------------------------------------------------------------
$beige-ljus: #f7d9c5;
$black: #000;
$block-bg: #f2f2f2;
$blue-medium: #0075b5;
$bla-ljus: #d8e4ee; //#cbe9f6;
$default-bg-color: #f7f7f7;
$default-font-color: #252525;
$default-link-color: #004d95;
$brodtext-link-color: #105ba2;
$disable: #d8d8d8;
$disable-text: #8c8c8c;
$disable-text-print: rgba(140, 140, 140, .7);
$genomskinlig: transparent;
$green-light: #a1c845;
$gra-medel-ljus: #ddd;
$grey-light: #e4e2e2;
$grey-medium: #9a9a9a;
$grey-extra-light: #f2f2f2;
$kanter: #d7d9d9;
$linje: #c4c4c4;
$nav-link-hover: #818181;
$orange-ljus: #f9decb;
$orange-text: #d24c00;
$red: #f00;
$standard-bg-farg: #f7f7f7;
$svart: #000;
$text-gray: #a7a6a6;
$trans: transparent;
$white: #fff;
$test-miljo-dev: #7a973f;
$test-miljo-localhost: #ffc0cb;
$test-miljo-major: #7d4fbd;
$test-miljo-minor: #ff9300;
$test-miljo-tst: #ffd300;
$test-miljo-uat: #891515;
$test-miljo-ogon-bg: #e6e6e6;
$test-miljo-ogon-kant: #8e8e8e;

// === [ FÄRFGER :: GENERELLA ] ==========================================================
$standard-lank-farg: #006ead;
$standard-font-farg: #252525;
$rod-murk: #a2081b;
$gra-ljus-medel: #c2c2c2;
$ik-oversikt-ikon-farg: #909090;
$inaktiverad-text: #8c8c8c;

// Färger :: Body ------------------------------------------------------------------------
$body-bg-color: $default-bg-color;

// Färger :: Tabb-navigering -------------------------------------------------------------
$tab-default-color: #ec915d;

// Färger :: Navigering ------------------------------------------------------------------
$nav-top-bg: $alecta-bla-2;
$nav-top-link: $white;
$nav-top-link-bg: $blue-medium;
$nav-bottom-bg: $alecta-bla-3;
$nav-mobile-hamburger: $white;

// === [ INLOGGAD MENYRAD ] ==============================================================
$inloggadmenyrad-kant: $alecta-bla-1;
$inloggadmenyrad-mellan-kant: #e5e3e3;
$inloggadmenyrad-bg: #ebeaea;

// Färger :: Navigering Mobil dropdown ---------------------------------------------------
$nav-mobil-sub-header: $text-gray;
$nav-mobil-dropmenu-border: #60c3e6;
$nav-mobil-level-2-bg: $white;
$nav-mobil-level-2-bg-border: #e5e3e3;
$nav-mobil-level-3-border: #dad8d8;
$nav-mobil-level-3-text: $default-font-color;
$nav-mobil-level-3-bg: #f8f8f8;
$nav-mobil-level-4-bg: #ecebeb;
$nav-mobil-level-4-border: #cecccc;
$nav-mobil-level-5-bg: #dedede;
$nav-mobil-level-5-border: #bcbcbc;

// Färger :: Navigering Desktop dropdown -------------------------------------------------
$nav-desktop-text: $white;
$nav-desktop-text-active: #14315a;
$nav-desktop-border-hover: #7ccbe7;
$nav-desktop-border-focus: $white;
$nav-desktop-dropdown-bg: $white;
$nav-desktop-dropdown-text-hover: $nav-link-hover;
$nav-desktop-dropdown-text-border: $kanter;

// Färger :: SidoNavigering --------------------------------------------------------------
$nav-side-header: $text-gray;
$nav-side-link: $default-font-color;
$nav-side-level-2-bg: #eeecec;
$nav-side-level-3-bg: $grey-light;
$nav-side-level-4-bg: #dbdbdb;
$nav-side-level-5-bg: #c7c7c7;

// Färger :: Innehållsförteckning --------------------------------------------------------
$innehallsforteckning-border: $kanter;
$innehallsforteckning-bg: $block-bg;

// Färger :: Accordion -------------------------------------------------------------------
$accord-header: $alecta-bla-2;
$accord-header-border: $kanter;
$accord-inner-bg: $block-bg;
$accord-inner-bg2: #eeeded;

// === [ SÖK FÖRSÄKRAD ] =================================================================
$mobil-padding: 2.5rem;
$tablet-padding: 4rem;
$accordion-knapp-bg-active: #909090;
$gra-ljus-ljus: #e9e9e9;
$tabell-modal-kant: #e1e1e1;
$gra-ljus: #d8d8d8;
$gra-medel: #9a9a9a;
$gra-medel-mork: #8c8c8c;
$modal-tabell-ninusvarde: $alecta-orange;
$modal-tabell-linjer: $gra-ljus;
$tabell-bla-bg: #b2e1f3;
$tabell-rod-bg: #ecd6e1;
$rapporter-bestallda-linje: #cade9a;
$tabell-thead-bg-hover: #e4eecf;
$tabell-thead-bg-active: #cfe3a1;
$tabell-thead-pil-normal: #908f8f;
$tabell-thead-pil-active: #000;
$tabell-blagra-bg: #ccdbe9;
$bla-medel: #0260a7;
$fullmakter-lank: $bla-medel;
$gra-ljus-bg: #e2e2e2;

// Färger :: Kontaktsida -----------------------------------------------------------------
$accord-kontakt-header-border: $kanter;
$kontakt-accord-bg-01: #00459a;
$kontakt-accord-bg-02: #0058a7;
$kontakt-accord-bg-03: #006eb6;
$kontakt-accord-bg-04: #0087c7;
$kontakt-accord-bg-05: #009ed6;

// Färger :: Formulär --------------------------------------------------------------------
$form-tagg: $block-bg;
$form-label-text: $default-font-color;
$form-input-text: $default-font-color;
$form-input-linje: #909090;
$form-input-linje-focus: $alecta-bla-2;
$form-input-error: #a2081b;
$form-btn-disable: $disable;
$form-btn-disable-text: $disable-text;
$form-input-bg: $block-bg;
$form-select-drop-bg: #083265;
$form-select-drop-bg-active: $alecta-bla-2;
$form-steg-border: $kanter;

$formular-textfalt-bg: #dde8ee;
$formular-label-farg: #909090;
$formular-label-farg-fokus: #006ead;
$formular-label-farg-error: #a2081b;
$formular-label-font-disable: #c3c3c3;
$formular-textfalt-kant-fokus: #006ead;
$formular-textfalt-kant-hover: #006ead;
$formular-textfalt-kant-error: #a2081b;
$formular-textfalt-font-disable: #c3c3c3;
$formular-span-farg-error: #a2081b;
$formular-text-hjalp-text: #656565;

// === [ FÄRGER :: FORMULÖR ] ============================================================
$ik-textfalt-bg: #dde8ee;
$ik-textfalt-rakna-bg: #cddcea;
$ik-textfalt-kant-fokus: $standard-lank-farg;
$ik-textfalt-kant-hover: $standard-lank-farg;
$ik-label-farg: $standard-font-farg;
$ik-label-farg-fokus: $standard-lank-farg;
$ik-label-farg-error: $rod-murk;
$ik-textfalt-kant-error: $rod-murk;
$ik-span-farg-error: $rod-murk;
$ik-text-hjalp-text: #656565;
$ik-textfalt-font-disable: $gra-ljus-medel;

// Färger :: Citat -----------------------------------------------------------------------
$citat-kanter: $kanter;

// Färger :: Personnummersök -------------------------------------------------------------
$personnummersok-bg: #e5edf4;
$personnummersok-ja-border: $kanter;
$personnummersok-ja-02-bg: #c5e2f0;

// Färger :: Dokumentlista, Sökresultat,Nyhetsflöde --------------------------------------
$presssida-border: $kanter;
$presssida-rubrik: #083265;

// Färger :: Ordlista --------------------------------------------------------------------
$ordlista-border: $kanter;

// Färger :: 404 -------------------------------------------------------------------------
$fyra04-border: $kanter;

// Färger :: Motorvägar ------------------------------------------------------------------
$motorvag-border: $kanter;
$motorvag-link: #004a96;
$focus-border: #3eccff;

// Färger :: Nyhetsflöde -----------------------------------------------------------------
$nyhetsflode-border: $kanter;
$nyhetsflode-date: #77787a;
$nyhetsflode-bg: #eeeded;

// Färger :: Stor nyhet ------------------------------------------------------------------
$stornyhet-text: $default-link-color;

// Färger :: Popover ---------------------------------------------------------------------
$popover-link-bg: $block-bg;
$popover-avgift-bg: #ecd6e1;

// Färger :: Grafer :: Avgiftsräknaren ---------------------------------------------------
$ljus-bla: #d8e4ee;
$graf-avgif-diagram-1-tartbit-0: $alecta-bla-1;
$graf-avgif-diagram-1-tartbit-1: $ljus-bla;
$graf-avgif-diagram-2-tartbit-0: $alecta-orange;
$graf-avgif-diagram-2-tartbit-1: $alecta-orange;
$graf-avgif-kant: $ljus-bla;
$graf-popover-border: #c0c0c0;
$graf-slider-linje: #b5b5b5;
$graf-avgif-bg: rgba(160, 50, 104, .1);
$graf-slider-animation-start: rgba(233, 103, 29, .7);
$graf-slider-animation-end: rgba(233, 103, 29, 0);

// Färger :: Faktablock ------------------------------------------------------------------
$faktablock-bg: $grey-light;

// Färger :: Galleriblock ----------------------------------------------------------------
$galleriblock-border: $kanter;

// Färger :: Modal -----------------------------------------------------------------------
$modal-fonster-border: #9e9e9e;
$modal-knapp-bla: rgba(10, 44, 90, .7);

// Färger :: Modal -----------------------------------------------------------------------
$infografik-border: $kanter;

// Färger :: Tabell ----------------------------------------------------------------------
$table-block-bg: $block-bg;
$table-gron-border: #dbe8bf;
$table-gron-border-top-bottom: #d7d9d9;
$table-jamn-bg: #c6e5f1;
$table-jamn-header-bg: #7bcaf1;
$table-orange-border: #f7d9c5;
$table-skugga-fran-farg: rgba(242, 242, 242, 1);
$table-skugga-till-trans: rgba(242, 242, 242, 0);
$table-konsolidering-lighter-blue: #b3e2f3;
$table-konsolidering-blue-border: #7ccbe7;
// Färger :: Kalender --------------------------------------------------------------------
$kalender-alla-bg: $grey-light;
$kalender-year-border: #7fa5c9;

// Färger :: Vad kostar ITP2? ------------------------------------------------------------
$itp2-form-bg: #e4e2e2;
$itp2-resultat-bg: #89ba17;

// Färger :: Gilla | Gilla inte ----------------------------------------------------------
$gillagillainte-border: $kanter;
$gillagillainte-btn-bg-inactive: #d0d0d0;
$gillagillainte-mobil-bg: #f2f2f2;

// Färger :: Vägvisare -------------------------------------------------------------------
$vagvisare-kanter: $kanter;
$vagvisare-font: #0260a7;
$vagvisare-kanter-hover: #009ed7;
$vagvisare-inaktiv-kanter: #eaecec;
$vagvisare-inaktiv-font: #b7b7b7;

// Färger :: Sidfot ----------------------------------------------------------------------
$sidfot-border: #cacaca;

// Färger :: Cookie alert ----------------------------------------------------------------
$cookie-alert-bg: #ecf1e0;

// Färger :: Tema  -----------------------------------------------------------------------
$bild-mask-bla: rgba(10, 44, 90, .5);
$text-yta-vit: rgba(255, 255, 255, .9);
$topp-triangel-bla: rgba(0, 158, 215, .6);
$tema-footer: #e7f1d1;

// Färger :: Årsredovisning  -----------------------------------------------------------------------
$graf-bg-gron: #89ba17;
$graf-bg-ljusgron: #d2e3c6;
$graf-bg-bla: $alecta-bla-3;
$graf-bg-lila: #a03268;
$mal-bg-active: #dde5ec;
$a-bg-ljusgron: #e0eaca;
$kantlinje-pratbubbla: #eaeef1;
$pyramid-top: rgba(0, 158, 215, .9);
$pyramid-middle: rgba(0, 76, 147, .9);
$pyramid-bottom: rgba(10, 44, 90, .9);
$bild-mask-bla-mork: rgba(10, 44, 90, .7);
$bild-mask-bla: rgba(0, 76, 147, .3);
$bild-mask-vit: rgba(255, 255, 255, .85);
$slick-slide-shadow: rgba(190, 190, 190, .5);

$koldioxidblock-bg: #e5eef2;
$visaaop-bg: #eaeef1;
$visaaop-knapp-bg-vit: #f4f6f8;
$cookie-banner-bg: rgba(10, 44, 90, .6);

// Färger :: Pressmeddelande  ---------------------------------------------------------
$pressmeddelande-text-faded: rgba(0, 0, 0, 0);

// Färger :: Fotogalleri
$fotogalleri-slideshow-toning: rgba(0, 0, 0, .6);
$fotogalleri-modal-bg: #212121;

// Färger :: Bootstrap -------------------------------------------------------------------
$alert-success-color: #3c763d;
$alert-success-bg: #dff0d8;
$alert-success-border: #d6e9c6;
$alert-info-color: #31708f;
$alert-info-bg: #d9edf7;
$alert-info-border: #bce8f1;
$alert-warning-color: #8a6d3b;
$alert-warning-bg: #fcf8e3;
$alert-warning-border: #faebcc;
$alert-danger-color: #a94442;
$alert-danger-bg: #f2dede;
$alert-danger-border: #ebccd1;

// Färger :: Inloggad :: Inloggad menyrad ------------------------------------------------
$inloggadmenyrad-etikett: #93a1b6;
$inloggadmenyrad-menydrop-bg: #ebeaea;
$inloggadmenyrad-meny-lank-hover: #0e3b78;
$inloggadmenyrad-meny-lank-active: #0e3b78;

// Färger :: Inloggad :: MegaMenyDrop ----------------------------------------------------
$minasidor-rubrik: #8c8c8c;
$minasidor-border: #d8d8d8;
$minasidor-lank-hover: #6f6f6f;

// Färger :: Inloggad :: Pensionsplaneraren
$select-uttag-ej-mojlig: #f2b48e;

// === [ KNAPP (VÅG) ] ===================================================================
$knapp-klick-effekt: rgba(0, 110, 173, .3);
$knapp-klick-effekt-vit: rgba(255, 255, 255, .3);
$knapp-klick-effekt-meddelanden: #d9d9d9;

// IK ------------------------------------------------------------------------------------
$ik-inloggadmenyrad-bg: #ebeaea;
$vit: #fff;
$alecta-rubin: #a03268;
$bg-ljus-lila: #f1ecef;

// =======================================================================================
// Olika mått
// =======================================================================================
$textblock-max-bredd-desktop: 63.5rem;

$margin-right-left-320: 2.5rem;
$margin-right-left-768: 4rem;
$margin-right-left-992: 2rem;

$block-margin-bottom-320: 2.5rem;
$block-margin-bottom-768: 2.5rem;
$block-margin-bottom-992: 3.5rem;

// =======================================================================================
// Fonter
// =======================================================================================
$alecta-freight: 'freight-sans-pro', sans-serif, 'Helvetica Neue', Helvetica, Arial;
$alecta-freight-text: 'freight-text-pro', sans-serif, 'Helvetica Neue', Helvetica, Arial;

// =======================================================================================
// Mixins
// =======================================================================================

// Bakgrundsbild -------------------------------------------------------------------------
@mixin bg-img($bg-img, $bg-position, $bg-repeat, $bg-size) {
    background-image: url($bg-img);

    @if $bg-position != '' {
        background-position: $bg-position;
    }

    @if $bg-repeat != '' {
        background-repeat: $bg-repeat;
    }

    @if $bg-size != '' {
        background-size: $bg-size;
    }
}
// Exempel, man kan använda null som värde, se nedan.
// @include bg-img('../img/demo-sprint2-hanvisning.jpg', null, null, 200px 200px);
// @include bg-img('../img/demo-sprint2-hanvisning.jpg', center center, no-repeat, 200px 200px);

// Bakgrundsfärg med transperans ---------------------------------------------------------
@mixin alpha-bg-color($bgcolor, $percentage) {
    background-color: rgba($bgcolor, $percentage);
}
// @include alpha-bg-color($white, 0.5);

// Bild "Noll-ställ" ---------------------------------------------------------------------
@mixin bild-noll-stall {
    display: inline-block;
    height: auto !important;
    max-width: none !important;
    margin-bottom: 0;
}
// @include bild-noll-stall;

// ClearFix ------------------------------------------------------------------------------
@mixin clearfix {
    &::after {
        content: '';
        display: block;
        clear: both;
    }
}
// @include clearfix;

// Fonter --------------------------------------------------------------------------------
@mixin alecta-font($family, $weight: 500, $style: normal) {
    font-family: $family;
    font-style: $style;
    font-weight: $weight;
}

$i: unquote('!Important');

@mixin alecta-font-important($family, $weight: 500, $style: normal) {
    font-family: $family $i;
    font-style: $style $i;
    font-weight: $weight $i;
}
// @include alecta-font($alecta-freight-text, 400, normal);
// @include alecta-font-important($alecta-freight-text, 400, normal);

// Hamburgare till X (transition) ---------------------------------------------------------------------
@mixin hambugare-till-x-transition($hambugare-transition-speed, $hambugare-transition-ease) {
    transition: $hambugare-transition-speed $hambugare-transition-ease;
}
// @include hambugare-till-x-transition(250ms, ease-in-out);

// Hamburgare till X (h till x) ---------------------------------------------------------------------
@mixin hambugare-till-x-origin($hambugare-transform-origin) {
    transform-origin: $hambugare-transform-origin;
}
// @include hambugare-till-x-origin(left center);

// Hamburgare till X (x till h) ---------------------------------------------------------------------
@mixin hambugare-till-x($hambugare-transform) {
    transform: rotate($hambugare-transform);
}
// @include hambugare-till-x(0deg);

// Kantlinje -----------------------------------------------------------------------------
@mixin border($border-sides, $border-color, $border-style) {
    @if ($border-style != '') {
        @if ($border-sides == '') {
            border: $border-style $border-color;
        } @else { @each $side in $border-sides {
                @if ($side == 'top' or $side == 'right' or $side == 'bottom' or $side == 'left') {
                    border-#{$side}: $border-style $border-color;
                } @else if ($side == 'all') {
                    border: $border-style $border-color;
                }
            }
        }
    }
}
// @include border(all, solid 1px, $red); // Alla kanter
// @include border(top, solid 1px, $red);
// @include border(right, solid 1px, $red);
// @include border(bottom, solid 1px, $red);
// @include border(left, solid 1px, $red);


// [ KNAPP VÅG ANIMATION ] ===============================================================
@mixin knapp-klick-effekt($kke-tid) {
    animation: ripple $kke-tid linear;
}
// @include knapp-klick-effekt(0.65s);



// [ KNAPP VÅG TRANSFORMATION ] ==========================================================
@mixin knapp-klick-effekt-transform {
    transform: scale(0);
}
// @include knapp-klick-effekt-transform;

// [ IMG Zoom TRANSFORMATION ] ==========================================================
@mixin img-effekt-transform ($trans-speed, $trans-inset: '') {
    transform: scale(1);
    transition: $trans-speed $trans-inset;
}
// @include img-effekt-transform;

@mixin img-hover-effekt-transform {
    transform: scale(1.1) rotate(.01deg);
}
// @include img-hover-effekt-transform;


// Overflow gömd :: Horizontellt & Vertikalt ---------------------------------------------
@mixin overflow($overflow-type) {
    overflow: $overflow-type;
    -webkit-overflow-scrolling: touch; // sass-lint:disable-line no-misspelled-properties
}
// @include overflow-x(auto);

// Overflow gömd :: Horizontellt ---------------------------------------------------------
@mixin overflow-x($overflow-type) {
    overflow-x: $overflow-type;
    -webkit-overflow-scrolling: touch; // sass-lint:disable-line no-misspelled-properties
}
// @include overflow-x(auto);

// Overflow gömd :: Vertikalt ------------------------------------------------------------
@mixin overflow-y($overflow-type) {
    overflow-y: $overflow-type;
    -webkit-overflow-scrolling: touch; // sass-lint:disable-line no-misspelled-properties
}
// @include overflow-y(auto);

// Pixlar ändraqs till REM ---------------------------------------------------------------
$browser-context: 10;

@function rem($pixels, $context: $browser-context) {
    @return #{calc($pixels/$context)}rem;
}
// font-size: rem(15);


// [ ROTERA ] ============================================================================
@mixin rotera($rotera) {
    transform: rotate($rotera);
}
// @include rotera(0deg);

// Rundade hörn --------------------------------------------------------------------------
@mixin border-radius($radius) {
    background-clip: padding-box;
    border-radius: $radius;
}
// @include border-radius(.5rem);

// Skugga under dropmenyn ----------------------------------------------------------------
@mixin box-shadow($top, $left, $blur, $color, $inset: '') {
    box-shadow: $top $left $blur $color #{$inset};
}
// @include box-shadow(0, 3px, 3px, rgba(0, 0, 0, 0.3));

// Text trancate... ----------------------------------------------------------------------
@mixin text-truncate {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
// @include text-truncate;

// Transition ----------------------------------------------------------------------------
@mixin transition($trans-speed, $trans-inset: '') {
    transition: max-height $trans-speed #{$trans-inset};
}
// @include transition(400ms cubic-bezier(0, 0, 0, 1));

// [ TRANSITION ] ========================================================================
@mixin transition-tid($tid) {
    transition: $tid ease;
}
// @include transition-tid(.3s);

// Transition ease-in-out ----------------------------------------------------------------
@mixin transition-ease($trans-ease-speed, $trans-ease) {
    transition: max-height $trans-ease-speed $trans-ease;
}
// @include transition-ease(400ms, ease-in-out);

// Transition Opacity --------------------------------------------------------------------
@mixin transition-opacity($trans-opacity) {
    transition: opacity $trans-opacity;
}
// @include transition-opacity(.25s);

// Versala Siffror -----------------------------------------------------------------------
@mixin versala-siffror {
    font-feature-settings: 'lnum' 1 !important;
}
// @include versala-siffror;

// Vertikalt centrerat innehåll ----------------------------------------------------------
@mixin vertikalt-innehall {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
}
// @include vertikalt-innehall;

// Keyframe animations -------------------------------------------------------------------
@mixin keyframes($animation-name) {
    @keyframes #{$animation-name} {
        @content;
    }
}

@mixin animation($str) {
    animation: #{$str};
}
//@include animation(name duration timing-function delay iteration-count direction fill-mode play-state);

@mixin bar-animation($duration, $dimension, $state) {
    $timing-function: cubic-bezier(.35, .95, .67, .99);
    $name: animate-height;

    visibility: hidden;

    @if $dimension == width {
        width: 0;
        $name: animate-width;
    }

    @if $dimension == height {
        height: 0;
    }

    animation: $name $duration $timing-function forwards;
    animation-play-state: $state;
}
//@include bar-animation(1s width paused);
//@include bar-animation(.1s height running);
