﻿
// =======================================================================================
// === [ SÖK ] ===
// =======================================================================================
.sokblock {
    position: relative;
    top: -11px;
    padding-bottom: 11px;

    form {
        position: relative;
        margin: 0;
        padding: 0;
        background-color: $trans;

        .Form__Element {
            width: auto;
            margin-bottom: 41px;

            label {
                left: 35px;
            }

            button {
                position: absolute;
                top: 0;
                right: $margin-right-left-320;
                margin: 0 !important;
                padding: .7rem 3rem;
            }

            input {
                &[type='submit'] {
                    @include bg-img('../img/ikon-sok.png', center center, no-repeat, 20px 20px);
                    @include border-radius(0 4px 0 0);
                    position: absolute;
                    top: 0;
                    right: 25px;
                    z-index: 2;
                    width: 45px;
                    height: 56px;
                    margin: 0;
                    padding: 0;
                    text-indent: rem(-1000);
                    background-color: $trans;

                    &:hover,
                    &:active,
                    &:focus {
                        background-color: $trans !important;
                    }
                }
            }

            &.knapp-oversikt,
            &.knapp-oversiktforetag {
                @include border-radius(0 .4rem 0 0);
                position: absolute;
                top: 0;
                right: $margin-right-left-320;
                z-index: 2;
                width: rem(45);
                height: rem(56) !important;
                margin: 0 !important;
                padding: 0 !important;
                text-indent: rem(-1000);
                background-color: $trans !important;

                &:hover,
                &:active,
                &:focus {
                    background-color: $form-input-bg;
                }
            }
        }

        input {
            &[type='text'] {
                width: 100%;
            }

            &:-webkit-autofill {
                background-color: $trans;
            }
        }
    }

    .sokblock-sokresultat {
        @include alecta-font($alecta-freight-text, 400, normal);
        padding-left: 25px;
        font-size: 16px;
        line-height: 20px;
    }
}

// =======================================================================================
// Min-width 768px
// =======================================================================================
@media (min-width: 768px) {
    .sokblock {
        form {
            .Form__Element {
                width: 375px;
                margin-right: 15px;
                margin-left: 15px;
                padding-right: 0;

                button,
                input {
                    right: $margin-right-left-768;

                    &[type='submit'] {
                        right: 0;
                    }
                }
            }
        }

        .sokblock-sokresultat {
            margin-right: 15px;
            margin-left: 15px;
        }
    }
}

// =======================================================================================
// Min-width 992px
// =======================================================================================
@media (min-width: 992px) {
    .sokblock {
        margin-right: $margin-right-left-992;
        margin-left: $margin-right-left-992;

        form {
            .Form__Element {
                width: 350px;
                margin-right: 0;
                margin-left: 0;
                padding-left: 0;

                label {
                    left: 10px;
                }

                button,
                input {
                    right: 0;

                    &[type='submit'] {
                        right: 0;
                    }
                }
            }
        }

        .sokblock-sokresultat {
            margin-right: 0;
            margin-left: 0;
            padding-left: 0;
        }
    }
}
