﻿/// <reference path="../base/mixins" />

// =======================================================================================
// === [ PRESSMEDDELANDE ] ===
// =======================================================================================
.pressmeddelandeblock,
.nyhetslistablock,
.sokresultatblock,
.dokumentlistablock {
    margin-top: rem(-20);
    margin-right: $margin-right-left-320;
    margin-bottom: $block-margin-bottom-320;
    margin-left: $margin-right-left-320;

    ol {
        max-width: none;
        margin-bottom: 0;
        margin-left: 0;
        padding-left: 0;
        list-style: none;

        li {
            @include border(bottom, solid 1px, $presssida-border);
            margin: 0;
            padding: rem(25) 0 rem(16);

            a {
                display: block;
                color: $default-link-color;

                h2,
                p {
                    padding: 0;
                    font-size: 16px;
                    line-height: 20px;
                }

                h2 {
                    margin: 0 0 4px;
                    color: $default-link-color;
                }

                p {
                    margin: 0;
                    color: $default-font-color;
                }

                &:hover {
                    text-decoration: none;
                }

                .active {
                    font-weight: 600;
                }

                em {
                    font-style: normal;
                    font-weight: bold;
                }
            }

            .breadcrumb {
                @include alecta-font($alecta-freight, 500, normal);
                margin: 0;
                padding-top: 5px;
                padding-right: 0;
                padding-left: 0;
                font-size: 15px;
            }
        }
    }

    .pressmeddelande-knappar {
        text-align: center;

        a {
            display: inline-block;
            width: 25rem;
            margin: 1rem 0 1.5rem;
            padding: .7rem 3rem;
            color: $default-link-color;
            font-size: 1.8rem;
            white-space: nowrap;
            background-color: $default-bg-color;
            border: solid 1px $alecta-bla-2;
            border-radius: 2rem;

            .a-icon {
                position: relative;
                right: 0;
                display: inline-block;
                width: 1.4rem;
                height: 1.4rem;
                margin-left: .5rem;
                padding: 0;
            }
        }
    }
}

.nyhetslistablock {
    margin-top: 0;

    ol {
        li {
            padding: 15px 0;
        }
    }

    .a-bg-image {
        flex-grow: 0;
        flex-shrink: 0;
        width: 100%;
        height: 100px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }

    .rubrik-nyheter {
        margin: 0 0 .3rem;
        padding: 1rem 2rem;
        border-top: 1px solid $kanter;
        border-bottom: 1px solid $kanter;
    }

    .block-inner {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: auto;
        align-items: center;
        overflow: hidden;

        h2 {
            margin-bottom: 1rem;
            font-size: 2.6rem;
            line-height: 3.1rem;
        }

        p {
            @include alecta-font($alecta-freight, 500, normal);
        }

        .text-bla {
            color: $brodtext-link-color;
            font-weight: 500;
        }

        .datum {
            padding-top: 1.5rem;
            padding-bottom: 1rem;
            font-size: 1.5rem;
            font-weight: 300;
        }
    }
}

.accordion-inner {
    .dokumentlistablock {
        ol {
            li {
                &:last-of-type {
                    border: 0;
                }
            }
        }
    }
}

.pressmeddelanden-sida {
    .pressmeddelanden {
        .col-xs-6 {
            width: 100%;
            padding-left: 20px;
            padding-right: 20px;
        }

        .bg-image-cover {
            display: block;
            width: 100%;
            height: 235px;
        }

        .pressmeddelande {
            height: 400px;
            margin-bottom: 3rem;
            padding: 1rem 1.5rem .6rem;
            background-color: $vit;

            .pressmeddelande-datum {
                @include alecta-font($alecta-freight);
                margin-bottom: .8rem;
                font-size: 1.6rem;
                line-height: 2.2rem;
            }

            h2,
            p {
                margin: 0;
            }

            h2 {
                margin-bottom: .8rem;
                line-height: 2.4rem;
            }

            p {
                max-width: none;
                font-size: 1.9rem;
                line-height: 2.5rem;
            }

            .text-faded {
                height: 350px;
                overflow: hidden;
                -webkit-mask-image: linear-gradient(180deg, $svart 78%, $pressmeddelande-text-faded 97%);
            }
        }

        hr {
            margin: 10px 20px 25px;
            border-bottom: 1px solid $kanter;
        }

        .pressmeddelande-knappar {
            text-align: center;

            a {
                @include alecta-font($alecta-freight);
                display: inline-block;
                margin: 12px;
                padding: 12px 50px;
                font-size: 16px;
                line-height: 19px;
                color: $alecta-bla-1 !important;
                border: 1px solid $alecta-bla-1;
            }
        }
    }
}


// =======================================================================================
// Min-width 768px
// =======================================================================================
@media (min-width: 768px) {
    .pressmeddelandeblock,
    .nyhetslistablock,
    .sokresultatblock,
    .dokumentlistablock {
        margin-top: rem(-7);
        margin-right: $margin-right-left-768;
        margin-bottom: $block-margin-bottom-768;
        margin-left: $margin-right-left-768;

        ol {
            margin-left: $margin-right-left-768 - 5rem;

            li {
                padding: 23px 0 15px;

                a {
                    h2 {
                        padding-right: rem(10);
                        padding-left: rem(10);
                    }

                    p {
                        padding-right: rem(10);
                        padding-left: rem(10);
                    }
                }

                .breadcrumb {
                    padding-right: rem(10);
                    padding-left: rem(10);
                }
            }
        }

        .pressmeddelande-knappar {
            a {
                width: auto;
                margin: 1rem $margin-right-left-768 - 1rem 1.5rem;
            }
        }
    }

    .nyhetslistablock {

        .rubrik-nyheter {
            margin: 0;
        }

        ol {
            li {
                padding: 15px 0;
            }
        }


        .a-bg-image {
            width: 150px;
            height: 150px;
            margin-left: 2rem;
        }

        .block-inner {
            flex-direction: row;
            justify-content: flex-start;
            height: auto;

            .datum {
                padding-top: 0;
            }
        }
    }

    .sokresultatblock {
        min-height: 45rem;
    }

    .pressmeddelanden-sida {
        .pressmeddelanden {
            padding-left: 25px;
            padding-right: 25px;

            .pressmeddelande {
                h2 {
                    font-size: 2.6rem;
                    line-height: 3rem;
                }
            }

            .col-xs-6 {
                width: 50%;
                padding-left: 15px;
                padding-right: 15px;

                &:first-child {
                    width: 100%;

                    .pressmeddelande {
                        height: 200px;
                        padding: 1rem 2rem 1.2rem;

                        .text-faded {
                            height: 160px;
                            -webkit-mask-image: linear-gradient(180deg, $default-font-color 65%, $pressmeddelande-text-faded 97%);
                        }
                    }
                }
            }

            .bg-image-cover {
                height: 253px;
            }

            hr {
                margin: 10px 15px 25px;
            }
        }
    }
}

// =======================================================================================
// Min-width 992px
// =======================================================================================
@media (min-width: 992px) {
    .pressmeddelandeblock,
    .nyhetslistablock,
    .sokresultatblock,
    .dokumentlistablock {
        margin-right: 0;
        margin-bottom: $block-margin-bottom-992;
        margin-left: 0;

        ol {
            margin-left: 0;

            li {
                a {
                    h2 {
                        padding-right: $margin-right-left-992;
                        padding-left: $margin-right-left-992;
                    }

                    p {
                        padding-right: $margin-right-left-992;
                        padding-left: $margin-right-left-992;
                    }
                }

                .breadcrumb {
                    padding-right: $margin-right-left-992;
                    padding-left: $margin-right-left-992;
                }
            }
        }
    }

    .pressmeddelanden-sida {
        h1 {
            margin-right: 0;
            margin-left: 0;
        }

        .pressmeddelanden {
            padding-left: 0;
            padding-right: 0;

            .row {
                margin-left: -15px;
                margin-right: -15px;
            }

            .col-xs-6 {
                padding-left: 15px;
                padding-right: 15px;
            }

            hr {
                margin: 10px 0 25px;
            }
        }
    }
}
