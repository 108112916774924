﻿
// =======================================================================================
// === [ MODAL ] ===
// =======================================================================================
.modal {
    @include alpha-bg-color($black, .4);
    z-index: 2001;
    padding-right: $margin-right-left-320;
    padding-left: $margin-right-left-320 !important;
}

.modal-dialog {
    display: table;
    margin: 10rem auto $margin-right-left-320;
    background-color: $modal-fonster-border;
}

.modal-content {
    position: relative;
    box-shadow: 0;
    background-clip: padding-box;
    background-color: $white;
    border: 0;
    border-radius: 0;
    outline: 0;

    .modal-header {
        position: relative;
        padding-top: 2.4rem;
        padding-right: $margin-right-left-320 * 2;
        padding-left: $margin-right-left-320;
        border-bottom: 0;

        h2 {
            margin-right: 0;
            margin-bottom: 2.5rem;
            margin-left: 0;
            margin-top: 1rem;
            font-size: 3.2rem;
            font-weight: 400;
            line-height: 3.3rem;
        }

        button {
            @include bg-img('../img/ikon-modal-stang.svg', center center, no-repeat, 20px 20px);
            position: absolute;
            top: 2.8rem;
            right: 1.3rem;
            width: 3rem;
            height: 3rem;
            margin: 0 !important;
            padding: 0;
            background-color: transparent;
            border: 0;
            border-radius: 0;
        }
    }

    .modal-body {
        padding-top: 0;
        padding-right: $margin-right-left-320;
        padding-left: $margin-right-left-320;

        p {
            margin-right: 0;
            margin-left: 0;
        }

        .laddaner {
            display: block;
            padding: 0 0 2rem;
            font-size: 1.8rem;
        }

        .dokument-typ {
            display: inline-block;
            width: 2rem;
            height: 1.8rem;
            padding-left: $margin-right-left-320;
        }

        .typ-pdf {
            @include bg-img('../img/ikon-pdf-bla.svg', 0 3px, no-repeat, 12px 15px);
        }

        .fritextblock {
            width: 100%;
        }
    }

    .modal-footer {
        padding: rem(15) $margin-right-left-320 rem(50);
        text-align: center;
        border-top: 0;

        button {
            @include border(all, solid 1px, $galleriblock-border);
            @include alecta-font($alecta-freight, 500, normal);
            margin: 0 auto !important;
            color: $default-link-color;
            font-size: 1.8rem;
            white-space: normal;
            background-color: $white;

            &:hover,
            &:active,
            &:focus {
                color: $alecta-bla-2;
                background-color: $white;
            }

            p {
                @include alecta-font($alecta-freight, 500, normal);
                margin: 0;
                padding: 0;
                font-size: 1.8rem;
            }

            a {
                @include alecta-font($alecta-freight, 500, normal);
                font-size: 1.8rem;
            }
        }

        .btn {
            &.btn-primary {
                @include alecta-font($alecta-freight, 500, normal);
                @include border(all, solid 1px, $galleriblock-border);
                @include border-radius(2rem);
                display: inline-block;
                margin: 0 auto;
                padding: rem(5) rem(20) rem(6);
                color: $default-link-color;
                font-size: rem(15);
                white-space: normal;
                background-color: $white;
                background-position: 25px center;

                p {
                    @include alecta-font($alecta-freight, 500, normal);
                    margin: 0;
                    font-size: rem(15);
                }
            }
        }
    }
}

// =======================================================================================
// Min-width 768px
// =======================================================================================
@media (min-width: 768px) {
    .modal {
        padding-right: $margin-right-left-768 !important;
        padding-left: $margin-right-left-768 !important;
    }

    .modal-content {
        .modal-header {
            padding-right: $margin-right-left-768 * 2;
            padding-left: $margin-right-left-768;

            h2 {
                margin-left: 5rem;
            }

            button {
                right: $margin-right-left-768 - .5rem;
            }
        }

        .modal-body {
            .laddaner {
                padding: 0 0 2rem $margin-right-left-768;
            }
        }
    }

    .modal-dialog {
        width: 100%;
        margin: 12rem auto $margin-right-left-768;
    }
}

// =======================================================================================
// Min-width 992px
// =======================================================================================
@media (min-width: 992px) {
    .modal-content {
        padding: 0 $margin-right-left-992;

        .modal-header {
            padding-right: $margin-right-left-992 * 2;
            padding-left: $margin-right-left-992;

            h2 {
                margin-left: 10rem;
            }

            button {
                right: $margin-right-left-992 - 1rem;
            }
        }

        .modal-body {
            padding-right: $margin-right-left-992;
            padding-left: $margin-right-left-992;

            .laddaner {
                padding: 0 0 3rem $margin-right-left-992 + 4rem;
            }
        }
    }

    .modal-dialog {
        width: 75rem;
        margin: 12rem auto $margin-right-left-992 + 4rem;
    }
}
