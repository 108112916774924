﻿/// <reference path="../base/mixins" />
/// <reference path="../components/bas-mixin" />

// =======================================================================================
// === [ SLIDER (FINNS PÅ BL A AVGIFTSNURRAN) ] ===
// =======================================================================================

// === [ SLIDE 'BANAN' SOM LIGGER BAKOM 'HANDTAGET' ] ====================================
.ui-slider-horizontal {
    height: 1.7rem;
    padding-top: .8rem;
}

// === [ HANDTAGET ] =====================================================================
.ui-slider {
    position: relative;
    margin: 0 0 2rem;
    text-align: left;

    .slider-bg {
        @include border(top, solid 1px, $graf-slider-linje);
        display: block;
        height: 1px;
    }

    .ui-slider-handle {
        @include border-radius(.8rem);
        @include box-shadow(0, 2px, 3px, rgba(0, 0, 0, .3));
        position: absolute;
        top: 0;
        z-index: 2;
        width: 1.6rem;
        height: 1.6rem;
        margin-left: -.6em;
        background: $alecta-orange;
        cursor: default;
        touch-action: none;
        -ms-touch-action: none;
        transform: scale(1);

        .slider-avgift-label {
            position: absolute;
            top: 2rem;
            display: block;
            width: 4rem;
            height: 2rem;
            color: $alecta-bla-2;
        }

        &.ui-state-hover {
            background: $alecta-orange;
            border: 0;
        }

        &.ui-state-focus {
            background: $alecta-orange;
            border: 0;
        }

        &.animate {
            animation: pulse 2s infinite;
        }
    }

    @keyframes pulse {
        0% {
            transform: scale(.95);
            box-shadow: 0 0 0 0 $graf-slider-animation-start;
        }

        70% {
            transform: scale(1);
            box-shadow: 0 0 0 7px $graf-slider-animation-end;
        }

        100% {
            transform: scale(.95);
            box-shadow: 0 0 0 0 $graf-slider-animation-end;
        }
    }
}

// === [ PIP ] ===========================================================================
.ui-slider-pips {
    .ui-slider-pip {
        position: absolute;
        top: 2rem;
        left: 1px;
        width: 2rem;
        height: 1rem;
        margin-left: -1rem;
        color: $default-font-color;
        font-size: .8rem;
        line-height: 1rem;
        text-align: center;
        overflow: visible;
        cursor: pointer;
        user-select: none;
    }

    .ui-slider-line {
        position: absolute;
        left: 50%;
        display: none;
        width: 1px;
        height: .3rem;
        background: $default-font-color;
    }

    .ui-slider-label {
        @include alecta-font($alecta-freight, 500, normal);
        position: absolute;
        top: .6rem;
        left: 50%;
        display: block;
        width: 5rem;
        margin-left: -2.5rem;
        color: $default-font-color;
        font-size: 1.5rem;
        text-align: center;

        &[data-value='0'] {
            left: 34px;
            text-align: left;
        }

        &[data-value='3'] {
            left: -15px;
            text-align: right;
        }

        &[data-value='25'] {
            left: 34px;
            text-align: left;
        }

        &[data-value='65'] {
            left: -15px;
            text-align: right;
        }

        &[data-value='20000'] {
            left: 15px;
            text-align: left;
        }

        &[data-value='60000'] {
            left: 0;
            text-align: right;
        }

        &[data-value='80000'] {
            left: -15px;
            text-align: right;
        }
    }

    .ui-slider-pip-hide {
        display: block;
    }
}

// =======================================================================================
// Min-width 768px
// =======================================================================================
@media (min-width: 768px) {
    .ui-slider-xtra-alecta {
        .ui-slider-label {
            top: 4.4rem;
        }
    }
}
