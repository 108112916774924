﻿/// <reference path="../base/mixins" />

// =======================================================================================
// === [ KOLDIOXIDBLOCK ] ===
// =======================================================================================
.koldioxidblock {
    padding: 2.6rem;
    background-color: $koldioxidblock-bg;

    .block-inner,
    .graf-yta {
        margin-right: auto;
        margin-left: auto;
    }

    .graf-yta {
        position: relative;
        left: 20%;
        width: 20rem;
        padding-top: 1rem;
        padding-bottom: 3rem;
        border: 0;
    }

    h2,
    h4 {
        @include alecta-font($alecta-freight, 300, normal);
        margin-top: 1.2rem;
        text-align: center;
    }

    h2 {
        font-size: 3.4rem;
        line-height: 4rem;
    }

    h4 {
        margin-bottom: 1rem;
        font-size: 1.6rem;
        font-weight: 500;
        line-height: 1.8rem;
    }

    p {
        margin-right: auto;
        margin-left: auto;
        text-align: center;
    }

    .bransch-yta {
        .a-vagvisare {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            margin-top: 0 !important;
            margin-right: -15px;
            margin-bottom: .5rem;
            margin-left: -15px;
            padding: 0;
            align-items: center;

            a {
                @include alecta-font($alecta-freight, 500, normal);
                @include border(all, $graf-bg-gron, solid 1px);
                display: flex;
                justify-content: center;
                width: 107px;
                height: 3rem;
                margin: .5rem 1rem 1.9rem;
                color: $default-font-color;
                font-size: 1.4rem;
                line-height: 1.6rem;
                text-transform: uppercase;
                align-items: center;

                &:hover {
                    @include border(all, $graf-bg-ljusgron, solid 1px);
                    background-color: $graf-bg-ljusgron;
                }

                &:active,
                &.selected {
                    @include border(all, $graf-bg-gron, solid 1px);
                    color: $vit;
                    background-color: $graf-bg-gron;
                }
            }
        }

        .bransch-beskrivning {
            @include alecta-font($alecta-freight, 300, normal);
            height: 3rem;
            margin-bottom: 0;
            font-size: 1.2rem;
            line-height: 1.3rem;
        }
    }

    .graf-koldioxidsnurra {
        margin-right: auto;
        margin-left: auto;
        padding: 0;

        .graf-label,
        .graf-etikett,
        .graf-diff {
            @include alecta-font($alecta-freight, 500, normal);
            display: flex;
            flex-direction: row;
            justify-content: center;
            height: 100%;
            font-size: 1.4rem;
            line-height: 1.6rem;
            white-space: nowrap;
            align-items: center;
        }

        .graf-label {
            position: absolute;
            top: 62px;
            left: -136px;
            display: block;
            text-transform: uppercase;

            &:first-child {
                top: 16px;
                left: -141px;
            }
        }

        .graf-etikett {
            padding-left: 40px;
        }

        .graf-diff {
            position: relative;
            top: -100%;
            left: 100%;
            justify-content: flex-start;
            margin-left: .5rem;
        }
    }

    .las-mer-lank {
        max-width: 63.7rem;
        margin: 3rem auto;
        padding-top: 1rem;
        font-size: 1.6rem;
        line-height: 2rem;
        text-align: center;
        border-top: 1px solid $kanter;
    }
}

// =======================================================================================
// Max-width 360px - Extra small devices like iPhone 5, Galaxy S3 etc.
// =======================================================================================
@media (max-width: 360px) {
    .koldioxidblock {
        .graf-koldioxidsnurra {
            .graf-label {
                top: 45px;
                left: -100px;
                width: 50%;
                white-space: normal;

                &:first-child {
                    top: -1px;
                    left: -100px;
                }
            }
        }
    }
}

// =======================================================================================
// Min-width 768px
// =======================================================================================
@media (min-width: 768px) {
    .koldioxidblock {
        .bransch-yta {
            .a-vagvisare {
                margin-right: 0;
                margin-left: 0;
                padding: 0 2.5rem;

                a {
                    margin-bottom: .9rem;
                }
            }
        }

        .graf-yta {
            left: 10%;
            width: 50rem;
        }
    }
}

// =======================================================================================
// Min-width 992px
// =======================================================================================
@media (min-width: 992px) {
    .koldioxidblock {

        p {
            white-space: nowrap;
        }

        .graf-yta {
            left: 2%;
        }

        .a-vagvisare {
            flex-wrap: nowrap;
        }

        .graf-koldioxidsnurra {
            width: 100%;
            padding-right: 0;
        }
    }
}
