﻿
// =======================================================================================
// === [ PRINT ] ===
// =======================================================================================

@media print {

    // === [ MARGINALER PÅ SIDAN ] =======================================================
    @page {
        margin-top: 2cm;
    }

    body {
        margin-left: 2cm;
    }

    .container-fluid {
        &.a-main {
            section {
                padding: 0;
            }
        }
    }

    .inloggadmenyrad {
        display: none;

        input {
            &[type='checkbox'],
            &[type='radio'] {
                display: none;
            }
        }

        a {
            display: none;
        }
    }

    // === [ VISAR PRINT-VÄNLIG LOGGA ] ==================================================
    .navbar {
        display: none; // Ändra till display: block; ifall loggan ska synas

        .container-fluid {
            &:first-of-type {
                display: block;

                &::before {
                    display: block;
                }

                .container {
                    display: none;
                }
            }

            &:last-of-type {
                display: none;
            }
        }
    }

    #personligtmeddelandecontainer {
        display: none;
    }

    // === [ ARTIKELSIDA :: VISA INNEHÅLLSFÖRTECKNING ] ==================================
    .accordion {
        &.a-article-content {
            .accordion-toggle {
                border-bottom: 0;

                .a-accordion-section-text {
                    padding-left: rem(24) !important;
                }
            }

            .accordion-body {
                display: block !important;
                height: auto !important;
            }
        }
    }

    // === [ VARAR PUNKTER PÅ EN UL LISTA ] ==============================================
    section {
        ul,
        ol {
            list-style-type: disc;
        }
    }

    // === [ GILLA | GILLA INTE ] ========================================================
    .gillagillainte {
        display: none;
    }

    // === [ ALERT ] =====================================================================
    .a-alert {
        border: solid 1px $kanter;
    }

    // === [ COOKIES ] ===================================================================
    .cookie-banner {
        border: solid 1px $kanter;
    }

    // === [ ETIKETT ] ===================================================================
    .etikett {
        border: solid 1px $kanter;

        .arrow {
            bottom: -1.8rem;
        }
    }

    // === [ MOTORVÄG ] ==================================================================
    .a-motorvagar {
        .motorvagblock {
            .a-motorvag {
                border: solid 1px $kanter;

                &::after {
                    content: '';
                    display: table;
                    clear: both;
                }
            }
        }
    }

    // === [ DOKUMENTLISTA ] =============================================================
    .dokumentlistablock {
        .dokument-typ {
            padding-left: 0;
        }
    }

    // === [ FAKTABLOCK ] ================================================================
    .faktablock {
        .a-faktablock {
            .a-faktablock-inner {
                border: solid 1px $kanter;
            }
        }
    }

    // === [ POPOVER ] ===================================================================
    .popover {
        &.top {
            > .arrow {
                &::after {
                    margin-bottom: rem(-12);
                    margin-left: rem(-9);
                }
            }
        }

        .popover-content {
            @include border-radius(1.5rem 0 1.5rem 0);
            position: relative;
            overflow: hidden;

            &::after {
                @include border(bottom, solid 100rem, $alecta-bla-3);
                content: '';
                position: absolute;
                top: 0;
                right: 0;
                left: 0;
                z-index: -1;
                display: block;
            }
        }
    }

    // === [ TABELL JÄMNFÖRANDE BLÅ ] ====================================================
    .table-jamnforande-bla {
        table {
            @include border(all, solid 1px, $table-jamn-header-bg);

            thead {
                tr {
                    td,
                    th {
                        @include border(right bottom, solid 1px, $table-jamn-header-bg);

                        &:last-of-type {
                            border-right: 0;
                        }
                    }
                }
            }
        }
    }

    // === [ HÄNVISNINGPUFF MED BILD ] ===================================================
    .hanvisningspuffmedbildblock {
        .a-hanvispuff-bild {
            border: solid 1px $kanter;
        }
    }

    // === [ HÄNVISNINGPUFF MED IKON ] ===================================================
    .hanvisningspuffmedikonblock {
        .a-hanvispuff-ikon {
            border: solid 1px $kanter;

            img {
                opacity: .1;
            }
        }
    }

    // === [ VAD KOSTAR ITP 2? ] =========================================================
    .itp2kostnadblock {
        border: solid 1px $kanter;
    }

    // === [ UTBETALNINGSDAGAR ] =========================================================
    .utbetalningsdagarallablock {
        .row {
            border: solid 1px $kanter;

            table {
                td {
                    span {
                        border: solid 1px $kanter;
                    }

                    &.active {
                        span {
                            border: solid 3px $kanter;
                        }
                    }
                }
            }
        }
    }

    .utbetalningsdagarblock {
        .row {
            border: solid 1px $kanter;

            .kalender {
                @include border(all, solid 1px, $kalender-year-border);
            }
        }
    }

    // === [ SIDFOT ] ====================================================================
    footer {
        display: none;
    }
}

