﻿
// =======================================================================================
// === [ NAVIGERING VIA TABB-TANGENTEN ] ===
// =======================================================================================
[data-whatinput='keyboard'] {

    // === [ TOP NAVIGERING ] ============================================================
    .navbar {
        .container-fluid {
            .navbar-nav {
                a {
                    &:focus {
                        color: $default-font-color !important;

                        background-color: $tab-default-color !important;
                        outline: 0;
                    }
                }
            }

            .navbar-brand {
                &:focus {
                    background-color: $tab-default-color !important;
                    outline: 0;
                }
            }
        }
    }

    // === [ VÄNSTER NAVIGERING ] ========================================================
    aside {
        .navbar-nav {
            li {
                text-align: right;

                a {
                    &:focus {
                        display: inline-block;
                        margin: 6px 30px 6px 0 !important;

                        padding: 4px 5px !important;

                        background-color: $tab-default-color !important;
                    }
                }
            }
        }
    }

    // === [ INPUT[TYPE=TEXT], INPUT[TYPE=EMAIL] ] =======================================
    button,
    input {

        // === [ KNAPP ] =================================================================
        // KOMMENTERADE 2018-12-14
        &[type='submit'],
        &[type='button'] {
            &:focus {
                color: $default-font-color !important;

                background-color: $tab-default-color !important;
                outline: 0;
            }
        }
    }

    // === [ Motorväg ] ==================================================================
    @media (min-width: 992px) {
        .a-motorvagar {
            .motorvagblock {
                .a-motorvag {
                    &:focus {
                        position: relative;
                        outline: 0;

                        &::after {
                            content: '';
                            position: absolute;
                            top: -5px;
                            right: -5px;
                            bottom: -5px;
                            left: -5px;
                            border: solid 4px $tab-default-color;
                            clear: both;
                        }
                    }

                    .a-motorvag-person,
                    .a-motorvag-info {
                        float: none;
                    }
                }
            }
        }
    }

    // === [ FOTEN ] =====================================================================
    footer {
        .sidfot-meny {
            span {
                a {
                    &:focus {
                        text-decoration: none;
                        background-color: $tab-default-color !important;
                        outline: 0;
                    }
                }
            }

            .a-icon-youtube,
            .a-icon-facebook,
            .a-icon-linkedin {
                &:focus {
                    background-color: $tab-default-color !important;
                    outline: 0;
                }
            }
        }
    }

    // === [ VÄGVISARE ] =================================================================
    .vagvisare,
    .a-vagvisare {
        a {
            &:focus {
                position: relative;
                background-color: $trans !important;
                outline: 0;

                &::after {
                    content: '';
                    position: absolute;
                    top: -5px;
                    right: -5px;
                    bottom: -5px;
                    left: -5px;
                    border: solid 4px $tab-default-color;
                }
            }
        }
    }

    // === [ Hopp länkar (ner på sidan) ] ================================================
    .hopp-lankar {
        a {
            &:focus {
                color: $default-font-color !important;

                background-color: $tab-default-color !important;
                outline: 0;
            }
        }
    }

    // === [ DROPBOWNMENYER ] ============================================================
    .FormSelection {
        .selectric-wrapper {
            &.selectric-focus {
                position: relative;
                background-color: $tab-default-color !important;

                &::after {
                    content: '';
                    position: absolute;
                    top: -4px;
                    right: -4px;
                    bottom: 0;
                    left: -4px;
                    border: solid 4px $tab-default-color;
                }
            }
        }

        .selectric-items {
            li {
                &.highlighted {
                    color: $default-font-color !important;

                    background-color: $tab-default-color !important;
                }
            }
        }
    }

    // === [ A LÄNKAR ] ==================================================================
    section {
        a {
            &:focus {
                background-color: $tab-default-color !important;
                outline: 0;
            }
        }
    }

    .knapp-ghost-utan-ram {
        &:focus {
            color: $default-font-color !important;
        }

        &.tillbaka,
        &.avbryt {
            &:focus {
                position: relative;
                color: $default-font-color !important;
                background-color: $tab-default-color !important;

                &::after {
                    content: '';
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: -20px;
                    border-left: solid 20px $tab-default-color;
                }
            }
        }
    }

    // === [ UTBETALNING ] ===============================================================
    .utbetalning {
        .utbetalningsdag {
            &:focus {
                position: relative;
                background-color: $trans !important;
                overflow: unset;
                outline: 0;

                &::after {
                    content: '';
                    position: absolute;
                    top: -7px;
                    right: -7px;
                    bottom: -7px;
                    left: -7px;
                    border: solid 7px $tab-default-color;
                }
            }
        }
    }

    // === [ PRATBUBBLAN ] ===============================================================
    .pratbubbla-mini {
        &:focus {
            position: relative;
            background-color: $trans !important;
            outline: 0;

            &::before {
                content: '';
                position: absolute;
                top: -4px;
                right: -4px;
                bottom: -7px;
                left: -4px;
                border: solid 10px $tab-default-color;
            }

            &::after {
                @include border-radius(50%);
                content: '';
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                width: rem(20);
                height: rem(14);
                background-color: $green-light;
            }

            span {
                &::before {
                    border-color: $tab-default-color;
                }
            }
        }
    }

    // === [ INLOGGAD MENYRAD ] ==========================================================
    .inloggadmenyrad {
        a {
            &.inloggad-meny-lank {
                &:focus {
                    color: $default-font-color !important;
                    background-color: $tab-default-color !important;
                    outline: 0;

                    .inloggad-som-etikett {
                        color: $default-font-color !important;
                    }
                }
            }
        }

        .inloggad-meny {
            .accordion-inner {
                ul {
                    li {
                        a {
                            &:focus {
                                position: relative;
                                color: $default-font-color !important;
                                background-color: $tab-default-color !important;
                                outline: 0;

                                &::after {
                                    content: '';
                                    position: absolute;
                                    top: 0;
                                    right: -7px;
                                    bottom: 0;
                                    left: -7px;
                                    border-right: solid 7px $tab-default-color;
                                    border-left: solid 7px $tab-default-color;
                                }
                            }
                        }

                        &.inloggad-loggaut-knapp {
                            a {
                                &:focus {
                                    &::after {
                                        right: 0;
                                        left: 0;
                                        border-right: 0;
                                        border-left: 0;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        li {
            &.inloggad-som-avbryt {
                a {
                    &:focus {
                        color: $default-font-color !important;
                        background-color: $tab-default-color !important;
                        outline: 0;
                    }
                }
            }
        }
    }
}
