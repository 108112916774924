﻿
// =======================================================================================
// === [ IPT 2 ] ===
// =======================================================================================
.itp2kostnadblock {
    margin-top: 4.2rem;
    margin-bottom: $block-margin-bottom-320;
    padding-top: 2.5rem;
    background-color: $block-bg;

    h3 {
        margin-bottom: 3rem !important;
        text-align: center;
    }

    // === [ FORMULÄR (GRÅ DEL) ] ========================================================
    form {
        margin: 0;
        padding: 0 5rem;
        background-color: transparent;

        .col-xs-12 {
            padding: 0;

            &:last-of-type {
                text-align: center;
            }
        }

        .Form__Element {
            margin-bottom: 2.5rem !important;
            padding: 0;

            label {
                .asterix {
                    display: inline-block;
                    padding-left: .3rem;
                }
            }

            span {
                display: block;
                font-size: 1.3rem;
            }
        }

        button {
            &[type='submit'],
            &[type='button'] {
                margin: 1.3rem auto 4rem !important;
                padding: .5rem 2.7rem;
            }
        }
    }

    // === [ FELMEDDELANDE (ORANGE DEL) ] ================================================
    .itp2-felmeddelande,
    .itp2-fel-alder,
    .itp2-fel-pensionar,
    .itp2-resultat,
    .itp2-fel-snart-pensionar {
        display: none;
        padding: rem(30) 0;
        color: $white;
        font-size: 2rem;
        text-align: center;
        background-color: $alecta-orange;
    }

    .itp2-maxbelopp {
        display: block;
        padding-bottom: 1.5rem !important;
        font-weight: 600 !important;
    }

    // === [ RESULTAT (GRÖN DEL) ] =======================================================
    .itp2-resultat {
        padding-top: 3.4rem;
        background-color: $itp2-resultat-bg;

        h4,
        li,
        p {
            color: $white;
        }

        h4 {
            margin-bottom: 2.1rem;
            font-size: 2rem;
            font-weight: 500;
            line-height: 2.4rem;
            text-align: center;
            text-transform: none;
        }

        ul {
            max-width: 100%;
            margin: 0;
            padding: 0 0 1.7rem;

            + p {
                margin-top: 0;
            }
        }

        li {
            @include bg-img('../img/ikon-itp2-li-list-bullet.svg', 0 6px, no-repeat, 6px 6px);
            @include alecta-font($alecta-freight, 400, normal);
            margin-bottom: 0;
            padding: 0 0 .5rem 1.5rem;
            font-size: 0;
            text-align: left;
            list-style: none;

            &::after {
                display: none;
            }

            &.itp2-resultat-non-dott {
                margin-left: 0;
                padding: 0 0 .5rem;
                line-height: 2.9rem !important;
                background-image: none;

                span {
                    &:first-of-type {
                        width: 67.5%;
                    }
                }
            }

            span {
                display: inline-table;
                width: 60%;
                font-size: 1.4rem;
                line-height: 1.8rem;

                &:first-of-type {
                    padding-right: 1rem;
                }
            }

            .itp2-likamed {
                width: 2%;
                font-size: 1.1rem;
            }

            .itp2-permanad {
                width: 37%;
                font-size: 1.3rem;
                font-weight: 600;
                text-align: right;
            }
        }

        p {
            @include alecta-font($alecta-freight, 400, normal);
            margin-bottom: 0;
            padding: 0 0 3.6rem;
            font-size: 1.5rem;
            line-height: 1.9rem;
            text-align: left;
        }
    }
}

// =======================================================================================
// Min-width 375px
// =======================================================================================
@media (min-width: 375px) {
    .itp2kostnadblock {

        // === [ RESULTAT (GRÖN DEL) ] ===================================================
        .itp2-resultat {
            li {
                span {
                    width: 66%;
                }

                .itp2-likamed {
                    width: 2%;
                    font-size: rem(11);
                }

                .itp2-permanad {
                    width: 32%;
                }
            }
        }
    }
}

// =======================================================================================
// Min-width 768px
// =======================================================================================
@media (min-width: 768px) {
    .itp2kostnadblock {
        margin-right: $margin-right-left-768 - 1.5rem;
        margin-bottom: $block-margin-bottom-768;
        margin-left: $margin-right-left-768 - 1.5rem;

        h3 {
            margin-bottom: 4rem !important;
        }

        form {
            padding: 0 7.5rem;

            .col-xs-12 {
                padding: 0 20px 0 0;

                &:last-of-type {
                    padding: 0;
                }
            }

            .col-sm-5 {
                width: 40%;
            }

            .col-sm-3 {
                width: 30%;
            }

            .col-sm-4 {
                width: 30%;
            }

            .Form__Element {
                margin-bottom: 4rem !important;

                input {
                    &[type='text'],
                    &[type='email'],
                    &[type='password'],
                    &[type='tel'],
                    &[type='number'] {
                        width: 100%;
                    }
                }
            }

            button {
                &[type='submit'],
                &[type='button'] {
                    margin: .8rem 0 0 !important;
                }
            }
        }

        // === [ RESULTAT (GRÖN DEL) ] ===================================================
        .itp2-resultat {
            padding: rem(34) rem(25) rem(30);

            h4 {
                margin-bottom: 3rem;
                text-align: center;
            }

            ul {
                padding: 0 5rem 1.5rem;
            }

            li {
                margin: 0;
                padding: 0 0 1rem 2.4rem;
                font-size: 1.8rem;
                background-position: 0 10px;

                &.itp2-resultat-non-dott {
                    padding: 0 0 1rem;

                    span {
                        &:first-of-type {
                            width: 28.9rem;
                        }
                    }
                }

                span {
                    width: 26.5rem;
                    font-size: 1.7rem;
                }

                .itp2-likamed {
                    width: 2.2rem;
                    font-size: 1.6rem;
                }

                .itp2-permanad {
                    width: 12rem;
                    font-size: 1.7rem;
                }
            }

            p {
                padding-bottom: 4rem;
                padding-left: 1rem;
            }
        }
    }
}

// =======================================================================================
// Min-width 992px
// =======================================================================================
@media (min-width: 992px) {
    .itp2kostnadblock {
        margin-right: 0;
        margin-left: 0;

        form {
            button {
                &[type='submit'],
                &[type='button'] {
                    padding: .5rem 1.6rem;
                }
            }
        }

        .itp2-resultat {
            p {
                padding-left: 3rem;
            }
        }
    }
}

// =======================================================================================
// Min-width 1200px
// =======================================================================================
@media (min-width: 1200px) {
    .itp2kostnadblock {
        margin-right: 0;
        margin-left: $margin-right-left-992;
    }
}
