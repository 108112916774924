﻿/// <reference path="../base/mixins" />

// =======================================================================================
// HTML/Body-tagg
// =======================================================================================
html {
    position: relative;
    min-height: 100%;
    font-size: 62.5%;
}

body {
    @include alecta-font($alecta-freight, 400, normal);
    position: static;
    min-height: 100%;
    margin: 0 0 rem(315);
    padding: 0;
    color: $default-font-color;
    font-size: 0;
    line-height: 2.4rem;
    background-color: $body-bg-color;
}

// =======================================================================================
// Huvud-div-taggar som nollställs
// =======================================================================================
.container-fluid {
    padding-right: 0;
    padding-left: 0;
}

.container {
    width: auto;
    max-width: 120rem; // Max-width för desktop (Vatendelare och Lokalhuvudmeny)
    padding-right: 0;
    padding-left: 0;
}

.row {
    margin-right: 0;
    margin-left: 0;
}

.a-main {
    aside {
        display: none;
    }

    section {
        padding: rem(30) 0 30px;
        font-size: 23px;
    }

    .fullbredd {
        section {
            padding: 0;
        }
    }
}


// =======================================================================================
// Alerts
// =======================================================================================
.alert {
    margin-bottom: 2rem;
    padding: 1.5rem;
    border: solid 1px transparent;
    border-radius: .4rem;
}

strong {
    font-weight: 600;
}

// =======================================================================================
// Bootstrap Grid, för att centrera en "col"
// =======================================================================================
.col-centered {
    float: none;
    margin: 0 auto;
}

// =======================================================================================
// Bootstrap, nollställa .block
// =======================================================================================
.block {
    padding-right: 0;
    padding-left: 0;
}

// =======================================================================================
// BOOTSTRAP KOD fix, flyttat brytpunkt för mobil/tablet, +768px till +992px
// =======================================================================================
@media (max-width: 991px) {
    .navbar-header {
        float: none;
    }

    .navbar-left,
    .navbar-right {
        float: none !important;
    }

    .navbar-toggle {
        display: block;
    }

    .navbar-collapse {
        box-shadow: inset 0 1px 0 rgba(255, 255, 255, .1);
        border-top: 1px solid transparent;

        &.collapse {
            display: none !important;
        }
    }

    .navbar-fixed-top {
        top: 0;
        border-width: 0 0 1px;
    }

    .navbar-nav {
        float: none !important;
        margin-top: .75rem;
    }

    .navbar-nav > li {
        float: none;
    }

    .navbar-nav > li > a {
        padding-top: 1rem;
        padding-bottom: 1rem;
    }

    .collapse {
        &.in {
            display: block !important;
        }
    }
}


// =======================================================================================
// Backgrundsbild
// =======================================================================================
.bg-image,
.bg-image-cover {
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    height: 100%;
}

.bg-image-cover {
    background-size: cover;
}

// =======================================================================================
// Ikoner
// =======================================================================================
.a-icon {
    position: absolute;
    top: 0;
    right: 1rem;
    width: 1.6rem;
    height: 2.8rem;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 1.2rem auto;
}

.a-icon-arrow {
    @include bg-img('../img/icon-nav-side-arrow-right.svg', null, null, null);
}

.a-icon-close-x {
    @include bg-img('../img/icon-nav-close.svg', null, null, 12px 12px);
}

.a-icon-wing {
    @include bg-img('../img/icon-guidepage-wing.svg', center bottom, null, 56px 46px);
}

.a-icon-arrow-big {
    @include bg-img('../img/icon-arrow-big.svg', null, null, 23px 14px);
    width: 4rem;
}

.a-icon-adress {
    @include bg-img('../img/icon-adress.svg', null, null, 11px 17px);
    width: 1.7rem;
}

.a-icon-rss {
    @include bg-img('../img/icon-rss.svg', null, null, 14px 14px);
    width: 1.4rem;
}

.a-icon-youtube {
    @include bg-img('../img/sprite-ikoner-socialamedier.svg', 0 0, null, 30px 90px);
    width: 3rem;
    height: 3rem;
}

.a-icon-facebook {
    @include bg-img('../img/sprite-ikoner-socialamedier.svg', 0 -30px, null, 30px 90px);
    width: 3rem;
    height: 3rem;
}

.a-icon-linkedin {
    @include bg-img('../img/sprite-ikoner-socialamedier.svg', 0 -60px, null, 30px 90px);
    width: 3rem;
    height: 3rem;
}

.avgiftsraknaren-exempel-stang {
    @include bg-img('../img/ikon-popover-avgifssnurran-stang.svg', center center, no-repeat, 7px 7px);
}

// =======================================================================================
// Bakgrundsfärger med "class", (Alectas-färger)
// =======================================================================================
.a-bgcolor-alecta-bla-1,
.alecta-bla1 {
    background-color: $alecta-bla-1;
}

.a-bgcolor-alecta-bla-2,
.alecta-bla2 {
    background-color: $alecta-bla-2;
}

.a-bgcolor-alecta-bla-3,
.alecta-bla3 {
    background-color: $alecta-bla-3;
}

.a-bgcolor-alecta-ruby,
.alecta-ruby {
    background-color: $alecta-ruby;
}

.a-bgcolor-alecta-gron,
.alecta-gron {
    background-color: $alecta-gron;
}

.a-bgcolor-alecta-orange,
.alecta-orange {
    background-color: $alecta-orange;
}

.a-bgcolor-alecta-gra-ljus,
.alecta-gra-ljus {
    background-color: $gra-ljus;
}

.a-bgcolor-alecta-bla-ljus,
.alecta-bla-ljus {
    background-color: $bla-ljus;
}


// =======================================================================================
// Småfixar för att påverka webbläsare + egna påhitt
// =======================================================================================

// Clearfix ------------------------------------------------------------------------------
.clearfix {
    &::after {
        content: '';
        display: table;
        clear: both;
    }
}

// :focus, nollställer -------------------------------------------------------------------
* {
    &:focus {
        outline: -webkit-focus-ring-color auto 0;
    }
}

// Gömma något ---------------------------------------------------------------------------
.hidden {
    display: none !important;
}

@media (max-width: 767px) {
    .hidden-xs {
        display: none !important;
    }
}
@media (min-width: 768px) and (max-width: 991px) {
    .hidden-sm {
        display: none !important;
    }
}
@media (min-width: 992px) and (max-width: 1199px) {
    .hidden-md {
        display: none !important;
    }
}
@media (min-width: 1200px) {
    .hidden-lg {
        display: none !important;
    }
}

// Laddar --------------------------------------------------------------------------------
.dynamisktinnehall-laddar,
.avgiftsraknaren-spinner {
    position: relative;
    min-height: 10rem;

    &::after {
        @include bg-img('../img/ikon-spinner-laddar.svg', center center, no-repeat, 45px 45px);
        content: '';
        position: absolute;
        top: 2rem;
        left: 50%;
        z-index: 2000;
        width: 4.5rem;
        height: 4.5rem;
        margin-left: -2.25rem;
        animation: spin 1000ms infinite linear;
    }

    @keyframes spin {
        from { transform: scale(1) rotate(0deg);}
        to { transform: scale(1) rotate(360deg);}
    }
}

.form-group {
    &.dynamisktinnehall-laddar {
        &::after {
            top: 15px;
            right: -40px;
            left: auto;
            width: 25px;
            height: 25px;
            margin: 0;
            background-size: 25px 25px;
        }
    }
}

.dynamisktinnehall-error {
    position: relative;
    min-height: 10rem;

    &::after {
        @include bg-img('../img/ikon-spinner-error.svg', center center, no-repeat, 45px 45px);
        content: '';
        position: absolute;
        top: 2rem;
        left: 50%;
        width: 4.5rem;
        height: 4.5rem;
        margin-left: -2.25rem;
    }
}

// No break ------------------------------------------------------------------------------
.nobreak {
    white-space: nowrap;
}

// No Margin -----------------------------------------------------------------------------
.no-margin-bottom {
    margin-bottom: .3rem !important;
}

// Tabb, hoppa till innehåll -------------------------------------------------------------
.tabb-hoppa-over {
    @include border(all, solid 1px, $white);
    // @include border-radius(2rem);
    position: absolute;
    top: -1000rem;
    left: -1000rem;
    height: 3.5rem !important;
    margin: 0;
    padding: .6rem 2.5rem 0 !important;
    color: $default-font-color;
    font-size: 1.5rem;
    background-color: $tab-default-color !important;
    border: 0;
    overflow: hidden;

    &:active,
    &:focus,
    &:hover {
        top: 0;
        left: 1.5rem;
        color: $default-font-color;
        text-decoration: none;
        overflow: visible;
        outline: 0 auto -webkit-focus-ring-color;
    }
}

// Utvecklingsmiljö, Rödmarkeras i miljöer förutom pro. Läggs på <body>-taggen -----------
.utvecklingsmiljo {
    .navbar {
        border-top: solid 5px $red !important;
    }
}

.Sleek {
    &.utvecklingsmiljo {
        border-top: solid 5px $red !important;
    }
}

// Versala  Siffror ----------------------------------------------------------------------
.font-lnum {
    -webkit-font-feature-settings: 'lnum' 1 !important;
    -moz-font-feature-settings:    'lnum' 1 !important;
    -ms-font-feature-settings:     'lnum' 1 !important;
    font-feature-settings:         'lnum' 1 !important;
}

// =======================================================================================
// min-width: 768px
// =======================================================================================
@media (min-width: 768px) {
    .form-group {
        &.dynamisktinnehall-laddar {
            &::after {
                right: -10px;
            }
        }
    }
}

// =======================================================================================
// min-width: 992px
// =======================================================================================
@media (min-width: 992px) {
    .a-main {
        aside {
            display: inline-block;
            padding: rem(38) 0 30px rem(15);
        }

        section {
            padding: rem(39) rem(15) 30px rem(35);
        }
    }
}
