﻿
// =======================================================================================
// === [ QR INLOGGNING ] ===
// =======================================================================================
.external-use {
    section {
        .loggain  {
            padding: 30px 0 100px;

            h1 {
                margin: 0;
                padding: 0 0 39px;
                font-size: 38px;
                font-weight: 300;
                line-height: 49px;
                text-align: center;

                + center {
                    p {
                        margin: 0;
                        padding: 0;
                    }
                }
            }

            #img-qr-code {
                display: block;
                box-shadow: 0 0 0 $white !important;
                width: 198px;
                height: 198px;
                margin: 0 auto 51px;
                border: 0 !important;
                border-radius: 0 !important;
            }

            h3,
            p {
                margin: 0;
                padding: 0 0 9px;
                font-size: 1.6rem;
                font-weight: 300;
                line-height: 2rem;
            }

            .img-bankid {
                display: block !important;
                width: 46px !important;
                height: 44px !important;
                margin: 140px auto 17px !important;
            }

            .text-hjalp {
                @include alecta-font($alecta-freight, 300, normal);
                font-size: 14px;
                line-height: 18px;

                a {
                    display: inline-block !important;
                    margin: 0 !important;
                    font-size: 14px !important;
                    line-height: 18px !important;
                }
            }
        }
    }

    footer {
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 315px;
        margin-top: 20px;
        overflow: hidden;
    }
}
