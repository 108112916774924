﻿/// <reference path="../base/mixins" />

// =======================================================================================
// === [ AVSKILLJAREBLOCK ] ===
// =======================================================================================
.avskilljareblock {
    margin-top: 1rem;
    margin-bottom: 1rem;

    .avskilljare {
        @include border(bottom, solid 1px, $kanter);
    }
}
