﻿
// =======================================================================================
// === [ ACCORDION ] ===
// =======================================================================================
.delaknapparblock {
    h4 {
        max-width: 100%;
        padding: 0 0 2rem !important;
        text-align: center;
    }

    .delaknappar-section {
        padding: 0 3.5rem;
    }

    .delaknapp-section {
        display: inline-block;
        width: 24%;
        text-align: center;

        a {
            display: inline-block;
            width: 3rem;
            padding-left: 0;
            color: $default-link-color;
            font-size: 1.8rem;
            font-weight: 500;
            text-indent: -100rem;
            overflow: hidden;
        }

        .delaknapp-twitter {
            @include bg-img('../img/sprite-ikoner-delaknappar.svg', 7px 5px, no-repeat, 20px 166px);
            padding-left: 2.7rem;
        }

        .delaknapp-linkedin {
            @include bg-img('../img/sprite-ikoner-delaknappar.svg', 6px -47px, no-repeat, 20px 166px);
        }

        .delaknapp-facebook {
            @include bg-img('../img/sprite-ikoner-delaknappar.svg', 7px -96px, no-repeat, 20px 166px);
        }

        .delaknapp-mejl {
            @include bg-img('../img/sprite-ikoner-delaknappar.svg', 5px -146px, no-repeat, 20px 166px);
        }
    }
}

// =======================================================================================
// Min-width 768px
// =======================================================================================
@media (min-width: 768px) {
    .delaknapparblock {

        .delaknappar-section {
            padding: 0 3.75rem 0 11.25rem;
        }

        .delaknapp-section {
            width: 24%;
            text-align: left;

            a {
                width: 100%;
                padding-left: 3rem;
                text-indent: 0;
            }

            .delaknapp-twitter {
                background-position: 0 5px;
            }

            .delaknapp-linkedin {
                background-position: 0 -48px;
            }

            .delaknapp-facebook {
                background-position: 0 -97px;
            }

            .delaknapp-mejl {
                background-position: 0 -146px;
            }
        }
    }
}
