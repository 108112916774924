﻿/// <reference path="../base/mixins" />
/// <reference path="../components/bas-mixin" />


// =======================================================================================
// === [ NYCKELTALBLOCK ] ===
// =======================================================================================

.nyckeltalblock {
    .belopp-rad {
        margin: 0 1rem 3rem;

        .belopp {
            @include alecta-font($alecta-freight, 300, normal);
            margin-top: 2rem;
            margin-bottom: 1rem;
            color: $alecta-bla-1;
            font-size: 9.2rem;
            font-weight: 300;
            line-height: 10.5rem;
            white-space: nowrap;
        }

        p {
            @include alecta-font($alecta-freight, 500, normal);
            color: $alecta-bla-1;
            font-size: 2.2rem;
            line-height: 2.6rem;
        }
    }
}
// =======================================================================================
// Min-width 768px
// =======================================================================================
@media (min-width: 768px) {
    .nyckeltalblock {
        .belopp-rad {
            margin-bottom: 2rem;

            .belopp {
                margin-top: 0;
            }
        }
    }
}
// =======================================================================================
// Min-width 992px
// =======================================================================================
@media (min-width: 992px) {
    .nyckeltalblock {
        .belopp-rad {
            margin-right: 13.5rem;
            margin-left: 13.5rem;
        }
    }
}
