﻿/// <reference path="../base/mixins" />

// =======================================================================================
// === [ TEMA ] ===
// =======================================================================================

section {
    .darrad-tema {
        margin-bottom: 2rem;
        padding-bottom: 2rem;
        font-size: 1.6rem;
        line-height: 2rem;
        text-align: center;
        border-bottom: 1px solid $gra-ljus;

        .darrad-tema-etikett {
            color: $gra-medel-mork;
            border: 0;
        }

        a {
            color: $svart;
        }
    }
}

.tema-footer {
    max-width: none;
    margin-left: 0;
    margin-right: 0;
    padding: 1rem;
    font-family: $alecta-freight-text;
    font-size: 1.6rem;
    font-style: italic;
    text-align: center;
    background-color: $tema-footer;

    a {
        display: block;
    }
}

.temasida {
    .topp-bild {
        padding-right: 2rem;
        padding-left: 2rem;

        img {
            width: 100%;
            margin: auto;
        }

        .movie-btn {
            &::after {
                @include bg-img('../img/icon-movie-btn.svg', center center, no-repeat, 100px 100px);
                content: '';
                position: absolute;
                top: 50%;
                left: 50%;
                display: block;
                width: 10rem;
                height: 10rem;
                margin: -5rem 0 0 -5rem;
            }
        }
    }

    .ingress {
        display: flex;
        justify-content: center;
        padding: 2.5rem 0 .5rem;

        p {
            font-family: $alecta-freight;
            font-size: 1.8rem;
            line-height: 2.4rem;
        }
    }

    .modal-dialog {
        width: 100%;

        .modal-content {
            padding: 0;

            .modal-header {
                button {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 3rem;
                    height: 3rem;
                    color: $vit;
                    background-color: $modal-knapp-bla;
                    background-image: url('/gui/img/icon-modal-close-white.svg');
                    background-size: .8rem;
                }

                &.a-bg-image {
                    height: 203px;
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: cover;
                }
            }

            .modal-body {
                padding: 2.6rem 2.5rem;

                h3,
                h4,
                h5 {
                    margin-right: 0;
                    margin-left: 0;
                }

                h2 {
                    margin: 0 0 2rem;
                    font-size: 2.4rem;
                    line-height: 2.8rem;
                }

                .block {
                    margin-right: 0;
                    margin-left: 0;
                    padding-right: 0;
                    padding-left: 0;

                    &.citatblock {
                        img {
                            max-width: 100% !important;
                        }
                    }
                }
            }
        }
    }

    section {
        .darrad-tema {
            margin-right: auto;
            margin-left: auto;
        }
    }

    .tema-footer {
        margin-right: auto;
        margin-left: auto;
    }
}

.temablock {
    margin-bottom: 2rem;
    padding: 0 2rem;

    .block-inner {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        height: 205px;
        align-items: center;
        overflow: hidden;

        .inner-text {
            width: 100%;
            padding: 2rem 1rem 1rem;

            h2 {
                color: $vit;
                font-size: 1.9rem;
                font-weight: 500;
                line-height: 2.2rem;
                text-align: center;
            }
        }

        .a-vagvisare {
            margin-top: 0 !important;
            margin-bottom: 0;
            padding: 0 2.5rem 2.5rem;
            text-align: center;

            a {
                @include border(all, $vagvisare-kanter, solid 1px);
                @include border-radius(.4rem);
                display: inline-block;
                margin: 0 0 1rem;
                padding: 1.2rem 2.5rem 1.3rem;
                color: $vit;
                font-size: 1.2rem;
                font-weight: 600;
                line-height: 1.5rem;
                text-transform: uppercase;

                &:hover {
                    @include alpha-bg-color($vit, 1);
                    @include border(all, $vit, solid 1px);
                    color: $alecta-bla-1;
                }
            }
        }

        .ikon {
            position: absolute;
            right: 20px;
            bottom: 0;
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            width: 100%;
            height: 100%;
            max-width: 120px;
            max-height: 120px;
            margin-left: 15px;
            overflow: hidden;
        }

        .a-bg-image-mask {
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: 100%;
            height: 100%;
            align-items: center;
        }

        &.a-bg-image {
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;

            .a-bg-image-mask {
                background-color: $bild-mask-bla;
            }
        }

        &.a-bg-illustration {
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
        }

        &.a-textcolor-alecta-bla-1 {
            .inner-text {
                h2 {
                    color: $alecta-bla-1;
                }

                p {
                    color: $alecta-bla-1;
                }
            }

            .a-vagvisare {
                a {
                    @include border(all, $alecta-bla-1, solid 1px);
                    @include border-radius(.4rem);
                    color: $alecta-bla-1;

                    &:hover {
                        @include alpha-bg-color($vit, 1);
                        @include border(all, $vit, solid 1px);
                        color: $alecta-bla-1;
                    }
                }
            }
        }

        &.a-textcolor-alecta-bla-3 {
            .inner-text {
                h2 {
                    color: $alecta-bla-3;
                }

                p {
                    color: $alecta-bla-3;
                }
            }

            .a-vagvisare {
                a {
                    @include border(all, $alecta-bla-3, solid 1px);
                    @include border-radius(.4rem);
                    color: $alecta-bla-3;

                    &:hover {
                        @include alpha-bg-color($vit, 1);
                        @include border(all, $vit, solid 1px);
                        color: $alecta-bla-1;
                    }
                }
            }
        }

        &.a-textcolor-alecta-bla-vit {
            .inner-text {
                h2 {
                    color: $alecta-bla-1;
                }
            }

            .a-vagvisare {
                a {
                    @include alpha-bg-color($alecta-bla-1, 1);
                    @include border(all, $alecta-bla-1, solid 1px);
                    @include border-radius(.4rem);
                    color: $vit;

                    &:hover {
                        @include alpha-bg-color($vit, 1);
                        @include border(all, $vit, solid 1px);
                        color: $alecta-bla-1;
                    }
                }
            }
        }

        &.a-textcolor-alecta-ruby {
            .inner-text {
                h2 {
                    color: $alecta-ruby;
                }

                p {
                    color: $alecta-ruby;
                }
            }

            .a-vagvisare {
                a {
                    @include border(all, $alecta-ruby, solid 1px);
                    @include border-radius(.4rem);
                    color: $alecta-ruby;

                    &:hover {
                        @include alpha-bg-color($vit, 1);
                        @include border(all, $vit, solid 1px);
                    }
                }
            }
        }

        &.temablock-lightbox {
            .inner-text {
                position: relative;
                bottom: -85px;
                cursor: pointer;

                h2 {
                    margin-bottom: 0;
                }

                .ikon-pil {
                    height: 13px;
                    background-image: url('/gui/img/icon-arrow-down.svg');
                    background-position: center center;
                    background-repeat: no-repeat;
                    background-size: 20px 13px;
                    transform-origin: bottom;

                    &.ikon-pil-ner {
                        margin: 1rem 0 3rem;

                        &.bounce {
                            @include animation('bounce 2500ms 2s infinite ease-in-out');

                            @include keyframes(bounce) {
                                0% {
                                    transform: translateY(0);
                                }

                                10% {
                                    transform: translateY(12px);
                                }

                                20% {
                                    transform: translateY(-2px);
                                }

                                30% {
                                    transform: translateY(0);
                                }

                                100% {
                                    transform: translateY(0);
                                }
                            }
                        }
                    }

                    &.ikon-pil-upp {
                        transform: scaleY(-1);
                    }
                }

                &.slide-up {
                    @include animation('slideupheader 500ms ease-in-out both');

                    @include keyframes(slideupheader) {
                        0% {
                            bottom: -70px;
                        }

                        100% {
                            bottom: 0;
                        }
                    }

                    position: relative;
                }

                &.slide-down {
                    @include animation('slidedownheader 500ms ease-in-out both');

                    @include keyframes(slidedownheader) {
                        0% {
                            bottom: -15px;
                        }

                        100% {
                            bottom: -85px;
                        }
                    }

                    position: relative;
                }
            }

            .text-yta {
                position: relative;
                bottom: -180px;
                left: 0;
                display: block;
                width: 100%;
                height: 100%;
                background-image: url('/gui/img/tema-block-polygon.svg');
                background-repeat: no-repeat;
                background-size: 100%;
                transform-origin: bottom;

                p {
                    margin: 0 1rem;
                    padding: 3rem .5rem;
                    font-family: $alecta-freight;
                    font-size: 1.6rem;
                    line-height: 2rem;
                    text-align: center;
                }

                &.slide-up {
                    @include animation('slideuptext 500ms ease-in-out both');

                    @include keyframes(slideuptext) {
                        0% {
                            bottom: -180px;
                        }

                        100% {
                            bottom: 0;
                        }
                    }
                }

                &.slide-down {
                    @include animation('slidedowntext 500ms ease-in-out both');

                    @include keyframes(slidedowntext) {
                        0% {
                            bottom: -10px;
                        }

                        100% {
                            bottom: -170px;
                        }
                    }
                }
            }
        }

        &.temablock-h1 {
            position: relative;
            top: 0;
            left: 0;
            background-image: url('/gui/img/tema-block-triangel.svg');
            background-repeat: no-repeat;
            background-size: 100%;

            .inner-text {
                padding-top: 0;


                h2 {
                    font-size: 1.6rem;
                    font-weight: 300;
                    text-align: left;
                }

                .h1 {
                    margin-top: 0;
                    font-size: 2.4rem;
                    font-weight: 300;
                    line-height: 3.1rem;

                    .belopp {
                        font-size: 6.8rem;
                        line-height: 6.8rem;
                    }
                }
            }
        }

        &.temablock-belopp {
            background-image: none;

            .inner-text {
                h2 {
                    font-size: 1.9rem;
                }
            }
        }

        &.temablock-citat {
            background-image: none;

            .inner-text {
                h2 {
                    padding-top: 2.5rem;
                    padding-left: 4rem;
                    font-family: $alecta-freight-text;
                    font-size: 1.9rem;
                    font-style: italic;
                    line-height: 2.5rem;
                    background-image: url('/gui/img/icon-quote-white.svg');
                    background-repeat: no-repeat;
                    background-size: 48px 35px;
                }
            }
        }
    }

    > a {
        &:hover {
            text-decoration: none;
        }
    }
}



// =======================================================================================
// Min-width 768px
// =======================================================================================
@media (min-width: 768px) {
    .temasida {
        .ingress {
            p {
                margin-bottom: 1.5rem;
                padding: 1rem 14rem;
                font-size: 1.9rem;
                line-height: 2.4rem;
            }
        }

        .modal-dialog {
            .modal-content {
                .modal-header {
                    button {
                        width: 4rem;
                        height: 4rem;
                    }
                }

                .modal-body {
                    padding: 2.6rem 9rem;
                }
            }
        }
    }

    .temablock {
        .block-inner {
            .inner-text {

                h2 {
                    margin: 0 2rem 1rem;
                }
            }

            .a-vagvisare {
                a {
                    padding: 1rem 2rem 1.1rem;
                    font-size: 1rem;
                    line-height: 1.3rem;
                }
            }

            &.temablock-h1 {
                .inner-text {
                    h2 {
                        margin: 0 3rem;
                    }

                    .h1 {
                        margin: 2rem 3rem 1rem;
                    }
                }
            }

            &.temablock-belopp {
                .inner-text {
                    h2 {
                        margin-bottom: 1.2rem;
                    }
                }
            }

            &.temablock-citat {
                .inner-text {
                    h2 {
                        margin: 0 2rem;
                        padding-top: 2rem;
                        font-size: 1.6rem;
                        line-height: 2rem;
                        background-size: 40px 30px;
                    }
                }
            }
        }
    }

    .tema-footer {
        margin-left: 4rem;
        margin-right: 4rem;

        a {
            display: inline;
        }

        &.center {
            margin-right: auto;
            margin-left: auto;
        }
    }
}

// =======================================================================================
// Min-width 992px
// =======================================================================================
@media (min-width: 992px) {
    .temasida {
        .topp-bild {
            img {
                width: 920px;
            }
        }

        .ingress {

            p {
                margin-bottom: 3rem;
                padding: 1.5rem 8rem .5rem;
                font-size: 2.4rem;
                line-height: 3rem;
            }
        }

        .temablocklista {
            width: 940px;
            margin-right: auto;
            margin-left: auto;
        }

        .modal-dialog {
            width: 79rem;

            .modal-content {
                padding: 0;

                .modal-body {
                    padding: 2.6rem 12.5rem .9rem;

                    h2 {
                        margin: 0 0 2rem;
                        font-size: 2.4rem;
                        line-height: 2.8rem;
                    }
                }
            }
        }
    }

    .temablock {
        margin-bottom: 3rem;
        padding: 0;

        .block-inner {
            width: 920px;
            height: 251px;
            margin: 0 auto;

            .inner-text {
                padding-top: 4rem;

                h2 {
                    font-size: 2.4rem;
                    font-weight: 500;
                    line-height: 2.8rem;
                    text-align: center;
                }
            }

            .a-vagvisare {
                a {
                    padding: 1.2rem 2.5rem 1.3rem;
                    font-size: 1.2rem;
                    line-height: 1.5rem;
                }
            }

            .ikon {
                max-width: 150px;
                max-height: 130px;
            }

            &.temablock-lightbox {
                .inner-text {
                    padding-top: 3rem;

                    .ikon-pil {
                        height: 9px;
                        background-size: 14px 9px;
                    }
                }

                .text-yta {
                    p {
                        padding: 3.5rem 6rem 2.7rem;
                    }
                }
            }

            &.temablock-h1 {
                .inner-text {
                    padding-top: 0;

                    h2 {
                        margin: 0 2rem 1rem;
                        font-size: 2.4rem;
                        font-weight: 300;
                        line-height: 3.1rem;
                        text-align: left;
                    }

                    .h1 {
                        margin-top: 1rem;
                        font-size: 3.8rem;
                        line-height: 4.9rem;

                        .belopp {
                            font-size: 9.8rem;
                        }
                    }
                }
            }

            &.temablock-citat {
                .inner-text {
                    h2 {
                        padding-top: 2.5rem;
                        padding-left: 4rem;
                        font-style: italic;
                        background-image: url('/gui/img/icon-quote-white.svg');
                        background-repeat: no-repeat;
                        background-size: 48px 35px;
                    }
                }
            }
        }

        &.col-sm-6 {
            .block-inner {
                width: 445px;
            }
        }
    }

    .tema-footer {
        max-width: 63.5rem;
        margin-left: 2rem;
        margin-right: 2rem;
    }
}
