﻿
// =======================================================================================
// === [ FLYTANDE BILD I TEXT ] ===
// =======================================================================================
img {
    &.img-float-top-left {
        margin: rem(15) auto $block-margin-bottom-320;
    }

    &.img-float-top-right {
        margin: rem(15) auto $block-margin-bottom-320;
    }
}

// =======================================================================================
// Min-width 768px
// =======================================================================================
@media (min-width: 768px) {
    img {
        &.img-float-top-left {
            float: left;
            max-width: 100% !important;
            margin: rem(6) rem(20) rem(15) 0;
        }

        &.img-float-top-right {
            float: right;
            max-width: 100% !important;
            margin: rem(6) 0 rem(15) rem(20);
        }
    }
}
