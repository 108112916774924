﻿/// <reference path="../base/mixins" />

// =======================================================================================
// === [ IKONTEXTBLOCK ] ===
// =======================================================================================
.liggandelanklistablock {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-bottom: 2rem;
    align-items: center;

    h4 {
        margin: 0 1rem 1rem 0;
        font-size: 1.6rem;
        line-height: 1.8rem;
        white-space: nowrap;
    }

    .inline-list {
        ul {
            margin-bottom: 1rem;
            padding-right: 5rem;
            padding-left: 5rem;
            line-height: 0;
            text-align: center;

            li {
                @include alecta-font($alecta-freight, 600, normal);
                display: inline;
                margin-right: .5rem;
                margin-left: 0;
                font-size: 1.6rem;
                line-height: 3.2rem; // 1.8rem + 1.4rem padding;
                text-transform: uppercase;

                &::after {
                    content: '\b7';
                    position: static;
                    padding-left: 1rem;
                    font-size: 1.8rem;
                    background: 0;
                }

                &:last-child {
                    &::after {
                        content: none;
                    }
                }
            }
        }
    }
}

// =======================================================================================
// Min-width 768px
// =======================================================================================
@media (min-width: 768px) {
    .liggandelanklistablock {
        .inline-list {
            ul {
                padding-right: 0;
                padding-left: 0;
            }
        }
    }
}

// =======================================================================================
// Min-width 992px
// =======================================================================================
@media (min-width: 992px) {
    .liggandelanklistablock {
        padding-right: 2rem;
        padding-left: 2rem;

        .inline-list {
            ul {
                max-width: 1190px;
                white-space: nowrap;
            }
        }
    }
}

// =======================================================================================
// Min-width 1200px
// =======================================================================================
@media (min-width: 1200px) {
    .liggandelanklistablock {
        flex-direction: row;
    }
}
