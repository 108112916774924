﻿
// =======================================================================================
// === [ SIDONAVIGERING ] ===
// =======================================================================================
aside {
    .navbar-nav {
        float: none;
        padding-top: 0;

        // === [ RUBRIKER ] ==============================================================
        h4,
        span {
            @include alecta-font($alecta-freight, 400, normal);
            display: block;
            margin: 2.1rem 3.5rem .5rem 0;
            color: $nav-side-header;
            font-size: 1.2rem;
            line-height: 1.1;
            text-align: right;
            text-transform: uppercase;
        }

        li {
            float: none;
            width: 100%;
            margin: 0;
            padding: 0;

            &::after {
                display: none;
            }

            a {
                &:link,
                &:visited {
                    @include alecta-font($alecta-freight, 400, normal);
                    position: relative;
                    padding: 1rem 3.5rem 1rem 0;
                    color: $nav-side-link;
                    font-size: 1.5rem;
                    line-height: normal;
                    text-align: right;
                }

                &:hover,
                &:focus {
                    color: darken($nav-side-link, 40%);
                    background-color: transparent;
                }

                &:hover {
                    color: lighten($nav-side-link, 40%);
                }

                .a-icon {
                    right: .5rem;
                    height: 3.8rem;
                }

                &.active {
                    font-weight: 600;
                }

                .a-icon-arrow {
                    @include bg-img('../img/icon-nav-side-arrow-right.svg', center bottom, null, null);
                    right: .5rem;
                    height: 2.7rem;
                    margin: 0;
                }
            }

            &.open {
                a {
                    background-color: $nav-side-level-2-bg;

                    .a-icon-arrow {
                        @include bg-img('../img/icon-nav-side-arrow-up.svg', center bottom, null, null);
                        height: 2.5rem;
                    }
                }
            }

            &.active {
                a {
                    font-weight: 600;
                }
            }

            .a-nav-side-level {
                .a-nav-side-level {
                    margin-top: -1px;
                    background-color: $nav-side-level-2-bg;

                    a {
                        padding-left: 3rem;
                    }

                    .open {
                        a {
                            background-color: $nav-side-level-3-bg;
                        }
                    }

                    .a-nav-side-level {
                        background-color: $nav-side-level-3-bg;

                        a {
                            padding-left: 4.5rem;
                        }

                        .open {
                            a {
                                background-color: $nav-side-level-4-bg;
                            }
                        }

                        .a-nav-side-level {
                            background-color: $nav-side-level-4-bg;

                            a {
                                padding-left: 6rem;
                            }

                            .open {
                                a {
                                    background-color: $nav-side-level-5-bg;
                                }
                            }

                            .a-nav-side-level {
                                background-color: $nav-side-level-5-bg;

                                a {
                                    padding-left: 7.5rem;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
