﻿/// <reference path="../base/mixins" />
/// <reference path="../components/bas-mixin" />

// =======================================================================================
// === [ AOPBLOCK ] ===
// =======================================================================================
.avkastningpremiebestamdblock {
    h2 {
        margin-bottom: 1rem;
        padding: 0;
        font-size: 2.2rem;
        line-height: 2.6rem;

        &.h1 {
            @include alecta-font($alecta-freight, 300, normal);
            font-size: 3.2rem;
            line-height: 3.3rem;
        }
    }

    .ingress {
        padding: 0;
    }

    h4,
    a,
    .start-alder,
    .diff {
        font-size: 1.6rem;
    }

    .graf-rubrik {
        display: flex;
        justify-content: space-between;
        padding-right: 2.5rem;
    }

    .block-inner {
        margin-bottom: 30px;
        padding: .5rem 0 2rem;
        background-color: $visaaop-bg;

        h4 {
            @include alecta-font($alecta-freight, 500, normal);
            margin: 2rem 1rem .5rem;
            line-height: 1.9rem;
        }

        .knappar {
            display: flex;
            flex-wrap: wrap;
            margin: 0 1rem;

            a {
                @include alecta-font($alecta-freight, 300, normal);
                @include border(top bottom right, $alecta-bla-2, solid 1px);
                display: flex;
                justify-content: center;
                width: 100%;
                height: 36px;
                color: $default-font-color;
                font-size: 1.4rem;
                line-height: 1.6rem;
                background-color: $visaaop-knapp-bg-vit;
                align-items: center;

                &.fylld,
                &.selected {
                    color: $vit;
                    font-weight: 600;
                }

                &.fylld {
                    background-color: $alecta-bla-2;
                }

                &:hover {
                    text-decoration: none;
                }

                &:active,
                &.selected {
                    background-color: $alecta-bla-2;
                }

                &:first-child {
                    @include border(left, $alecta-bla-2, solid 1px);
                    @include border-radius(3px 0 0 3px);
                }

                &:last-child {
                    @include border-radius(0 3px 3px 0);
                }
            }

            .aop-aktier,
            .aop-period {
                display: block;
                float: left;

                a {
                    border-left: 0;
                    border-radius: 0;

                    &.selected {
                        border-radius: .01px;
                    }
                }
            }

            .aop-aktier {
                a {
                    height: 46px;
                }

                &.aktier-60 {
                    width: 52%;
                }

                &.aktier-50 {
                    width: 16%;
                }

                &.aktier-40 {
                    width: 32%;
                }

                &.aktier-50,
                &.aktier-40 {
                    a {
                        span {
                            display: none;
                        }
                    }
                }

                &:first-child {
                    a {
                        @include border(left, $alecta-bla-2, solid 1px);
                        @include border-radius(3px 0 0 3px);
                    }
                }

                &:last-child {
                    a {
                        @include border-radius(0 3px 3px 0);
                    }
                }
            }

            .aop-period {
                width: 25%;
                text-align: center;

                .diff {
                    display: block;
                    margin-bottom: 1rem;
                }

                &:first-child {
                    a {
                        @include border(left, $alecta-bla-2, solid 1px);
                        @include border-radius(3px 0 0 3px);
                    }
                }

                &:last-child {
                    a {
                        @include border-radius(0 3px 3px 0);
                    }
                }

                &:nth-child(5) {
                    a {
                        @include border(left, $alecta-bla-2, solid 1px);
                    }
                }
            }
        }

        .genomsnittlig-avkastning {
            text-align: center;
        }

        &::after {
            content: '';
            position: absolute;
            left: 46%;
            z-index: -1;
            display: block;
            width: 30px;
            height: 30px;
            background-color: $visaaop-bg;
            transform: rotate(45deg);
        }

        .js-valj-period {
            &.disabled {
                background-color: $disable;
                color: $disable-text;
                pointer-events: none;
                cursor: default;
            }
        }
    }

    .inriktning-kommentar {
        padding-top: 2rem;
    }
}

// =======================================================================================
// Min-width 768px
// =======================================================================================
@media (min-width: 768px) {
    .avkastningpremiebestamdblock {
        .block-inner {
            .knappar {

                .aop-aktier {
                    &.aktier-50,
                    &.aktier-40 {
                        a {
                            span {
                                display: inline;
                            }
                        }
                    }
                }

                .aop-period {
                    width: 12.5%;

                    &:nth-child(5) {
                        a {
                            border-left: 0;
                        }
                    }
                }
            }

            &::after {
                left: 48%;
            }
        }
    }
}

// =======================================================================================
// Min-width 992px
// =======================================================================================
@media (min-width: 992px) {
    .avkastningpremiebestamdblock {
        .block-inner {
            &::after {
                left: 50%;
            }
        }
    }
}
