﻿/// <reference path="../base/mixins" />

// =======================================================================================
// === [ Storbildspuff ] ===
// =======================================================================================

.storbildpuffblock {
    margin-bottom: 2rem;
    padding: 3rem 2.6rem 0;

    a {
        &:focus,
        &:hover,
        &:active {
            text-decoration: none;
        }
    }

    .img-container {
        width: 100%;
        overflow: hidden;
    }

    img {
        @include img-effekt-transform(.3s, ease-in-out);
        width: 100%;
        height: 25.2rem;
        object-fit: cover;

        &:focus,
        &:hover,
        &:active {
            @include img-hover-effekt-transform;
        }
    }

    .puff-text {
        h2 {
            margin: 2rem 0 .6rem;
            color: $motorvag-link;
            font-size: 2.6rem;
            font-weight: 300;
            line-height: 3rem;
            text-align: left;

            &:focus,
            &:hover,
            &:active {
                text-decoration: underline;
            }
        }

        p {
            @include alecta-font($alecta-freight-text, 300, normal);
            max-width: none;
            margin: 0;
            color: $default-font-color;
            font-size: rem(19);
            line-height: rem(25);
            text-align: left;
        }
    }
}

// =======================================================================================
// Min-width 768px
// =======================================================================================
@media (min-width: 768px) {

    .storbildpuffblock {
        margin-bottom: $block-margin-bottom-768;
        padding: 2.5rem 1.5rem 0;
    }
}


// =======================================================================================
// Min-width 992px
// =======================================================================================
@media (min-width: 992px) {
    .storbildpuffblock {
        margin-bottom: $block-margin-bottom-992;

        img {
            height: 37.6rem;
        }
    }
}

// =======================================================================================
// Min-width 1200px
// =======================================================================================
@media (min-width: 1200px) {
    .fullbredd {
        .storbildpuffblock {
            left: calc(50% - 570px);
            max-width: 570px;

            &.col-sm-12 {
                max-width: 1140px;
            }
        }
    }
}
