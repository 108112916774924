﻿
// =======================================================================================
// === [ FOTEN ] ===
// =======================================================================================
footer {
    width: 100%;
    margin-top: 2rem;

    &#scroll {
        position: absolute !important;
        bottom: 0;
        width: 100%;
        height: 31.5rem;
        margin-top: 0;
        overflow: hidden;

        .scroll-pil-vanster,
        .scroll-pil-hoger {
            display: none;
        }

        .scrollWrapper {
            position: relative;
            z-index: 0;
            width: 100%;
            height: 100%;
            overflow: hidden;

            .scrollableArea {
                @include border(top, solid 1px, $sidfot-border);
                position: relative;
                width: 100%;
                height: 100%;

                img {
                    position: relative;
                    display: block;
                    float: left;
                    height: 31.5rem;
                    margin: 0;
                    padding: 0;
                    opacity: 1;
                    user-select: none;

                    &.b-loaded {
                        width: auto;
                        opacity: 1;
                    }
                }
            }
        }

        .staticWrapper {
            .scrollableArea {
                img {
                    position: absolute;
                    top: -9999rem;
                    right: -9999rem;
                    bottom: -9999rem;
                    left: -9999rem;
                    display: block;
                    width: auto !important;
                    height: 100% !important;
                    max-width: none !important;
                    margin: auto;
                }
            }
        }

        &.sidfot-no-scroll {
            .scrollableArea {
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                justify-content: center;

                img {
                    float: none;
                }
            }
        }
    }

    .sidfot-meny {
        @include alpha-bg-color($alecta-bla-2, .85);
        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 2;
        height: 11.8rem;
        padding: 0 2.5rem;
        color: $white;
        font-size: 1.1rem;
        transition: height .15s ease-out;

        &.slidedown {
            height: 4rem;
            transition: height .15s ease-out;
        }

        .sidfot-inner {
            display: flex;
            flex-direction: column;
            padding-top: 2.6rem;
            padding-bottom: 2.6rem;
        }

        div {
            padding: 0;
        }

        .sidfot-kontakt {
            order: 2;

            &.stang {
                display: none;
            }
        }

        .sidfot-socialamedier-copyright {
            display: flex;
            flex-direction: row;
            justify-content: center;
            order: 1;

            &.stang {
                display: none;
            }
        }

        .a-icon-youtube,
        .a-icon-facebook,
        .a-icon-linkedin {
            position: relative;
            right: 0;
            display: inline-block;
            margin-left: .6rem;
        }

        span {
            display: block;
            padding-right: 1.5rem;
            color: $white;
            font-size: 1.5rem;
            line-height: 1.8rem;
            white-space: nowrap;

            .sidfot-text {
                display: inline-block;
                width: rem(110);
                color: $white;
                font-size: 1.1rem;
                line-height: 1.8rem;
                text-transform: uppercase;
                white-space: nowrap;
            }

            &.sidfot-text-kontaktaoss {
                padding-top: 1rem;
            }

            a {
                color: $white;
            }
        }

        .sidfot-visa {
            padding-right: 2.5rem;

            a {
                @include bg-img('../img/icon-arrow-up-big-white.svg', center center, no-repeat, 20px 9px);
                display: block;
                height: 4rem;
            }

            &.stang {
                display: none;
            }
        }
    }
}

.external-use {
    footer {
        overflow: hidden;

        .scrollableArea {
            @include border(top, solid 1px, $sidfot-border);
            width: rem(2400);

            img {
                display: inline-block;
                height: rem(315);
            }

            #sidfot-1 {
                width: rem(430);
            }
        }
    }
}

// =======================================================================================
// Min-width 768px
// =======================================================================================
@media (min-width: 768px) {
    footer {
        .sidfot-meny {
            padding: 0 $margin-right-left-768;

            .sidfot-inner {
                flex-direction: row;
            }

            .sidfot-kontakt {
                order: 1;
                width: 80%;
            }

            .sidfot-socialamedier-copyright {
                justify-content: flex-end;
                order: 2;
                width: 20%;
            }
        }
    }
}

// =======================================================================================
// Min-width 1200px
// =======================================================================================
@media (min-width: 1200px) {
    footer {
        &#scroll {
            .scroll-pil-vanster,
            .scroll-pil-hoger {
                position: absolute;
                top: 0;
                z-index: 201;
                display: block;
                width: rem(96);
                height: rem(235);

                &::after {
                    @include bg-img('../img/ikon-sidfot-pil.svg', center 0, no-repeat, 54px 54px);
                    content: '';
                    position: absolute;
                    top: rem(130);
                    left: rem(21);
                    width: rem(54);
                    height: rem(54);
                }
            }

            .scroll-pil-vanster {
                left: 0;

                &::after {
                    @include hambugare-till-x(180deg);
                }
            }

            .scroll-pil-hoger {
                right: 0;
            }
        }

        .sidfot-meny {
            height: 8rem;
            padding: 0 $margin-right-left-992;

            .sidfot-inner {
                padding-top: 2.8rem;
                padding-bottom: 2.8rem;
            }


            span {
                display: inline-block;
                padding-right: 3.5rem;

                .sidfot-text {
                    width: auto;
                    padding-right: 1rem;
                }

                &.sidfot-text-kontaktaoss {
                    padding-top: 0;
                }
            }

            .a-icon-youtube,
            .a-icon-facebook,
            .a-icon-linkedin {
                margin-right: 1rem;
                margin-left: 0;
            }
        }
    }
}
