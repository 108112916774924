﻿/// <reference path="../base/mixins" />
/// <reference path="../components/bas-mixin" />

// =======================================================================================
// === [ FOTOGALLERI ] ===
// =======================================================================================

.fotogalleri {
    #topp-bild-slideshow {
        .block {
            height: 440px;
            padding: 0;
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;

            .inner-text {
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                align-items: flex-start;
                padding-left: 0;
                padding-right: 0;
                color: $vit;
                max-height: 200px;
                margin-top: 60px;
            }

            .inner-text-toning {
                background-color: $fotogalleri-slideshow-toning;
                padding-top: .8rem;
                padding-left: 1.7rem;
                padding-right: 2rem;
            }
        }

        .a-bg-image-mask {
            background: none;
        }
    }

    h1,
    p {
        margin-left: 0;
        margin-right: 0;
    }

    h1 {
        line-height: rem(34);
        font-weight: 400;
        margin-bottom: .6rem;
    }

    p {
        margin-bottom: 1.4rem;
    }

    .fotogalleriblocklista {
        margin: 1.65rem;
        margin-bottom: 0;

        &.botten {
            margin-top: .35rem;
        }

        .col-xs-6 {
            padding: 0;
        }
    }

    .fotogalleriblock,
    .fritextblock {
        opacity: 0;
        transform: translate(0, 100px);

        &.animate {
            opacity: 1;
            transform: translate(0);
            transition: opacity .5s, transform .5s;
        }

        &.show {
            opacity: 1;
            transform: translate(0);
        }

        .block-inner {
            display: inline-block;
            position: relative;
            margin: .35rem;
        }
    }

    .fotogalleriblock {
        line-height: 0;
    }

    .fritextblock {
        display: none;
        margin: 1.5rem;
        padding: 1rem 3rem;

        &.text-mobil-puff {
            display: block;
        }

        h2 {
            margin: 0 0 1rem;
            font-size: rem(26);
            font-weight: 400;
            line-height: rem(30);
        }
    }

    .text-puff-mobil {
        .fritextblock {
            display: block;
            opacity: 1;
            transform: none;
            padding: 1rem .6rem;
        }
    }

    .bild-text,
    .bild-text-hover {
        display: none;
    }

    .parallax-bild {
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        background-attachment: fixed;
        height: 209px;
    }
}

#fotogalleri-modal {
    padding-left: 0 !important;
    padding-right: 0 !important;

    .modal-dialog {
        display: block;
        width: 100vw;
        height: auto;
        margin: 0;
        background-color: $fotogalleri-modal-bg;
    }

    .modal-content {
        background-color: transparent;
        box-shadow: none;

        .modal-header {
            position: sticky;
            top: 0;
            z-index: 1;
            max-width: 100vw;
            display: flex;
            justify-content: flex-end;
            padding: 1rem 2rem;

            button {
                position: static;
                width: 16px;
                height: 16px;
                background-image: url('/gui/img/ikon-modal-stang-vit.svg');
                background-size: 16px;
                background-position: center;
                background-color: $fotogalleri-modal-bg;
                padding: 2rem;
            }
        }

        .modal-body {
            padding: 0;

            .foto {
                position: relative;
                max-width: 100vw;
            }

            h4,
            p {
                color: $vit;
            }

            h4 {
                font-size: 2.2rem;
                line-height: 2.4rem;
            }

            p {
                @include alecta-font($alecta-freight-text, 300, normal);
                font-size: 19px;
                line-height: 25px;
            }
        }

        .slide-buttons {
            display: flex;
            flex-direction: row;
            justify-content: center;
        }

        .slide-button-prev,
        .slide-button-next {
            height: 16px;
            width: 16px;
            margin-left: 20px;
            margin-right: 20px;
            background-image: url('/gui/img/pil-liten-vit.png');
            background-repeat: no-repeat;
            background-position: center;
            background-color: $fotogalleri-modal-bg;
            padding: 2.5rem;
            cursor: pointer;

            &.hidden-xs {
                display: none !important;
            }
        }

        .slide-button-prev {
            transform: rotate(-180deg);
        }
    }
}

#fotogalleri-slideshow {
    .slick-slide {
        .block {
            max-width: 100vw;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            padding-left: 2rem;
            padding-right: 2rem;
        }

        .bild-text {
            max-width: 1200px;
            position: relative;
            margin-top: 1.4rem;
            margin-left: auto;
            margin-right: auto;

            .col-xs-12 {
                max-width: 570px;
                padding-left: 2rem;
                padding-right: 2rem;
            }
        }

        .bild-text-hover {
            display: none;
        }
    }
}

// =======================================================================================
// Min-width 768px
// =======================================================================================
@media (min-width: 768px) and (max-width: 1399px) {
    .fotogalleri {
        #topp-bild-slideshow {
            .block {
                height: 570px;

                .inner-text {
                    margin-left: 4.1rem;
                    margin-bottom: 7.3rem;
                    max-height: 200px;
                    margin-top: 200px;
                }

                .inner-text-toning {
                    padding-top: 2.4rem;
                    padding-left: 4rem;
                    padding-right: 4rem;
                }
            }
        }

        h1 {
            font-size: rem(48);
            line-height: rem(52);
        }

        .fotogalleriblocklista {
            margin-left: 2.7rem;
            margin-right: 2.7rem;

            &.botten {
                margin-top: 1.15rem;
            }
        }

        .fotogalleriblock,
        .fritextblock {
            .block-inner {
                margin: 1.5rem;
            }
        }

        .fritextblock {
            display: block;
            margin-bottom: 0;

            &.text-mobil-puff {
                display: none;
            }
        }

        .text-puff-mobil {
            .fritextblock {
                display: none;
            }
        }

        .temablock {
            padding: 0 4.2rem;
        }

        .parallax-bild {
            height: 463px;
        }
    }

    #fotogalleri-modal {
        .modal-content {
            .modal-header {
                padding: 2.5rem 4.2rem;

                button {
                    width: 24px;
                    height: 24px;
                }
            }

            .modal-body {
                h4 {
                    line-height: 2.5rem;
                }
            }
        }
    }

    #fotogalleri-slideshow {
        .slick-slide {
            img {
                padding-left: 4.2rem;
                padding-right: 4.2rem;
            }

            .bild-text {
                margin-top: 2rem;

                .col-xs-12 {
                    padding-left: 4.2rem;
                    padding-right: 4.2rem;
                }
            }
        }
    }
}

// =======================================================================================
// Min-width 1400px
// =======================================================================================
@media (min-width: 1400px) {
    .fotogalleri {
        #topp-bild-slideshow {
            .block {
                height: calc(100vh - 107px);
            }

            .inner-text {
                margin-left: 4.1rem;
                margin-bottom: 7.3rem;
                max-height: 100px;
                margin-top: 200px;
            }
        }

        .container {
            padding-left: 0;
            padding-right: 0;
        }

        .fotogalleriblocklista {
            margin-top: 4.5rem;
            margin-left: auto;
            margin-right: auto;
        }

        .fotogalleriblock,
        .fritextblock {
            .block-inner {
                width: 570px;
                margin: 1.5rem;
            }
        }

        .fotogalleriblock {
            img {
                filter: blur(0);
                transition: filter .5s;
            }

            &:hover {
                cursor: pointer;

                img {
                    filter: blur(3px);
                }

                .bild-text-inner {
                    transform: translateY(0);
                }
            }
        }

        .fritextblock {
            display: block;
            margin-bottom: 0;

            &.text-mobil-puff {
                display: none;
            }
        }

        .text-puff-mobil {
            .fritextblock {
                display: none;
            }
        }

        .fritextblock {
            padding: 1rem 5rem 1.7rem;

            h2 {
                font-size: rem(48);
                line-height: rem(52);
            }
        }

        .temablock {
            padding-left: 15px;
            padding-right: 15px;

            .block-inner {
                width: auto;
            }
        }

        .parallax-bild {
            height: 800px;
        }

        .bild-text-hover {
            display: block;
            position: absolute;
            top: 0;
            height: 100%;
            width: 100%;
            overflow: hidden;
        }

        .bild-text-inner {
            position: absolute;
            bottom: 0;
            width: 100%;
            padding-left: 30px;
            transform: translateY(150px);
            transition: transform .7s;

            p {
                @include alecta-font($alecta-freight, 500, normal);
                max-width: 488px;
                font-size: rem(22);
                line-height: rem(25);
                color: $vit;
            }

            .pil-hoger-vit {
                position: relative;
                bottom: 10px;
                right: 10px;
                height: 20px;
                width: 100%;
                background-image: url('/gui/img/pil-liten-vit.png');
                background-repeat: no-repeat;
                background-position: 100%;
            }
        }
    }

    #fotogalleri-modal {
        height: 100vh;
        overflow-y: hidden;

        .modal-dialog {
            height: 100vh;
        }

        .modal-content {
            .modal-body {
                .fotogalleriblock {
                    height: calc(100vh - 90px);
                }

                .foto {
                    height: calc(100vh - 90px - 108px);
                }
            }

            .modal-footer {
                &.hidden-lg {
                    display: none !important;
                }
            }

            .slide-button-prev,
            .slide-button-next {
                &.visible-lg {
                    display: block !important;
                    position: absolute;
                    top: 50%;
                    left: 0;
                }
            }

            .slide-button-next {
                &.visible-lg {
                    left: 93%;
                }
            }
        }
    }

    #fotogalleri-slideshow {
        .slick-slide {
            img {
                padding-left: 19.4rem;
                padding-right: 19.4rem;
            }

            .bild-text {
                height: 88px;

                .citat-hover {
                    position: absolute;
                    bottom: 0;
                    left: 50%;
                    height: auto;
                    max-height: 108px;
                    max-width: 630px;
                    padding: 3rem;
                    background-color: $fotogalleri-modal-bg;
                    transition: max-height 1s;

                    &:hover,
                    &.scroll {
                        max-height: 100vh;
                        transition: max-height 2s;
                    }
                }
            }
        }
    }
}
