﻿
// =======================================================================================
// === [ DESKTOP, DROPDOWN ] ===
// =======================================================================================
@media (min-width: 992px) {
    .navbar {
        .container-fluid {
            &:last-of-type {
                z-index: 99;
                height: rem(72);

                // === [ MOBIL HAMBURGARE ] ==============================================
                .navbar-toggle {
                    display: none;
                }

                // === [ MOBIL DROPDOWN ] ================================================
                .nav-mobil {
                    display: none !important;
                }

                // === [ LOKAL NAVIGERING ] ==============================================
                .navbar-nav {
                    margin: 0;
                }

                .container {
                    height: 100%;

                    .row {
                        position: relative;

                        .minasidor-info {
                            &::before {
                                @include border(right, solid 1px, $disable);
                                content: '';
                                position: absolute;
                                top: 0;
                                bottom: 0;
                                left: 27.7%;
                                width: 1px;
                            }
                        }
                    }
                }

                .navbar-header {
                    position: relative;

                    // === [ ALECTA-LOGGA ] ==============================================
                    .navbar-brand {
                        left: 0;
                        margin: 0;
                        padding: 0;
                        -ms-background-position-y: center; // sass-lint:disable-line no-misspelled-properties // IE fix

                        &:hover,
                        &:focus {
                            background-color: $trans;
                        }
                    }
                }

                .navbar-collapse {
                    right: 0;
                    width: 100%;
                    height: rem(72) !important;
                    min-height: 0;
                    padding: 0;
                    background-color: $trans;
                    border: solid 1px $trans;
                    opacity: 1;

                    h3 {
                        display: none;
                    }

                    // === [ LÄNKAR I NAVIGERINGEN ] =====================================
                    .navbar-nav {
                        position: static;
                        float: right;
                        width: 95%;
                        margin: 0;
                        text-align: right;
                        border: 0;
                        overflow: visible;

                        .dropdown {
                            position: static;
                            display: inline-block;
                            float: none;

                            a {
                                height: rem(71);
                                margin-top: 0;
                                padding: 0 $margin-right-left-992 - .5rem;
                                color: $nav-desktop-text;
                                border: 0;

                                .a-dropdown-toggle-link {
                                    display: inline-block;
                                    width: 100%;
                                    height: rem(71);
                                    padding-top: 3rem;
                                    font-size: 1.9rem;
                                    font-weight: 500;
                                }

                                .a-icon-arrow {
                                    display: none;
                                }

                                &:hover {
                                    background-color: $trans;

                                    .a-dropdown-toggle-link {
                                        @include border(bottom, solid 1rem, $nav-desktop-border-hover);
                                    }

                                    &.no-dropdown {
                                        .a-dropdown-toggle-link {
                                            border: 0;
                                        }
                                    }
                                }

                                &.active {
                                    .a-dropdown-toggle-link {
                                        @include border(bottom, solid 1rem, $nav-desktop-border-hover);
                                    }
                                }

                                &.hanglas-stangd {
                                    span {
                                        @include bg-img('../img/ikon-las-stangd-vit.svg', 2px 31px, no-repeat, 15px 15px);
                                        padding-left: rem(24);
                                    }
                                }

                                &.hanglas-oppen {
                                    span {
                                        @include bg-img('../img/ikon-las-oppen-vit.svg', 0 34px, no-repeat, 15px 14px);
                                        padding-left: rem(22);
                                    }
                                }

                                &.hanglas-oppen-oppnasidor {
                                    span {
                                        @include bg-img('../img/ikon-las-oppen-vit.svg', 0 34px, no-repeat, 15px 14px);
                                        padding-left: rem(22);
                                    }
                                }
                            }

                            &.open {
                                a {
                                    color: $nav-desktop-text;
                                    background-color: $trans;

                                    .a-dropdown-toggle-link {
                                        @include border(bottom, solid 1rem, $nav-desktop-border-focus);
                                    }
                                }

                                .dropdown-menu {
                                    display: block;
                                }
                            }

                            // === [ DROPDOWN ] ==========================================
                            .dropdown-menu {
                                @include box-shadow(0, .3rem, .3rem, rgba($black, .3));
                                position: absolute;
                                top: 7.2rem;
                                display: none;
                                width: 100%;
                                padding: 24px 0 27px;
                                background-color: $nav-desktop-dropdown-bg;
                                border-width: 0;
                                border-radius: 0;

                                .container-fluid {
                                    position: relative;
                                    display: block;
                                    height: auto;
                                    background-color: $trans;

                                    .container {
                                        height: auto;
                                        padding-right: 1rem;

                                        .row {
                                            position: relative;

                                            &.publikasidor-wrapper {
                                                display: flex;
                                                justify-content: center;
                                            }
                                        }
                                    }
                                }

                                .col-xs-12 {
                                    max-width: none;
                                    padding-top: 1rem;
                                    padding-right: 3rem;
                                    padding-left: 0;

                                    .nav {
                                        padding-top: 1rem;
                                    }

                                    &:last-of-type {
                                        > .nav {
                                            margin: 0 -1rem;
                                        }

                                        li {
                                            float: left;
                                            width: 33.3333%;
                                            padding: 0 1rem;
                                        }
                                    }

                                    &.fast-bredd {
                                        max-width: 30%;

                                        li {
                                            float: none;
                                            width: 100%;
                                        }
                                    }
                                }

                                h4,
                                span {
                                    height: 2rem;
                                    padding: 0 0 .2rem;
                                    font-size: 1.2rem;
                                    text-transform: uppercase;
                                    background-color: transparent;
                                    border: 0;
                                }

                                li {
                                    width: 100%;
                                    height: auto !important;
                                    margin-top: -1px;

                                    &.ik-meny-rubrik {
                                        padding-left: 0;
                                        border: 0;
                                    }

                                    a {
                                        @include border(all, solid 1px, $nav-desktop-dropdown-text-border);
                                        height: 100%;
                                        padding: .7rem 0;
                                        color: $default-font-color;
                                        font-size: 1.4rem;
                                        text-transform: none;
                                        border-right: 0;
                                        border-left: 0;

                                        &:hover {
                                            color: $nav-desktop-dropdown-text-hover;
                                            background-color: $trans;
                                        }

                                        &:focus {
                                            background-color: $trans;
                                        }
                                    }
                                }

                                // === [ MINA SIDOR ] ====================================
                                .minasidor-lankar {
                                    display: block;
                                    float: left;
                                    width: 20.9%;
                                    margin-top: 0;
                                    padding: 0 rem(35) 0 0;
                                    background-color: transparent;

                                    ul {
                                        padding: 0;
                                    }

                                    li {
                                        vertical-align: top;
                                        background-color: transparent;
                                        overflow: hidden;
                                    }

                                    h4,
                                    .minasidor-rubrik {
                                        height: auto !important;
                                        margin: 0;
                                        padding: rem(11) 0 rem(16);
                                        color: $minasidor-rubrik;
                                        font-size: rem(12);
                                        font-weight: 400;
                                        line-height: rem(15);
                                        text-transform: uppercase;
                                        background-color: transparent;
                                    }

                                    a {
                                        display: block;
                                        padding: rem(10) 0 rem(12);
                                        font-size: rem(14);
                                        font-weight: 500;
                                        line-height: rem(16);
                                        background-color: transparent;

                                        &:hover {
                                            color: $minasidor-lank-hover;
                                            text-decoration: none;
                                        }
                                    }
                                }

                                .minasidor-info {
                                    display: inline-block;
                                    float: left;
                                    width: 31.8%;
                                    padding-top: rem(5);
                                    padding-right: 4%;

                                    .minasidor-info-inner {
                                        //@include border(right, solid 1px, $minasidor-border);
                                        height: 100%;
                                    }
                                }

                                .minasidor-inloggad {
                                    position: relative;
                                    top: rem(-5);
                                    padding: 0;

                                    li {
                                        display: block;
                                        margin-top: 0;
                                        padding: rem(2) rem(52) rem(8);
                                        color: $minasidor-rubrik;
                                        font-size: rem(14);
                                        font-weight: 500;
                                        line-height: rem(16);
                                        text-align: right;
                                        background-color: transparent;

                                        &.minasidor-rubrik {
                                            padding: rem(11) rem(52) rem(.5);
                                            font-size: rem(12);
                                            font-weight: 600;
                                            line-height: rem(15);
                                            text-transform: uppercase;
                                        }

                                        .minasidor-knapp-loggaut {
                                            @include border-radius(2rem);
                                            display: inline-block;
                                            margin: rem(14) 0 0;
                                            padding: rem(10) rem(23) rem(9);
                                            color: $white;
                                            background-color: $alecta-bla-2;
                                            border: 0;

                                            &:hover {
                                                text-decoration: none;
                                            }
                                        }
                                    }
                                }

                                .minasidor-loggain {
                                    position: relative;
                                    top: rem(-5);
                                    padding: rem(39) rem(42) 0;
                                    text-align: right;

                                    .minasidor-knapp-loggain {
                                        @include border(all, solid 1px, $kanter);
                                        @include border-radius(.4rem);
                                        display: inline-block;
                                        height: rem(33);
                                        padding: rem(6) rem(22) rem(32);
                                        color: $formular-label-farg-fokus;
                                        font-size: 1.4rem;
                                        line-height: rem(24);

                                        &:hover {
                                            color: $minasidor-lank-hover;
                                            text-decoration: none;
                                        }
                                    }
                                }
                            }

                            &.ik-beta {
                                a {
                                    .a-dropdown-toggle-link {
                                        position: relative;
                                        padding-right: 21px;

                                        &::after {
                                            content: 'BETA';
                                            position: absolute;
                                            top: 25px;
                                            right: -1px;
                                            font-size: 10px;
                                            font-weight: 500;
                                            line-height: 12px;
                                        }
                                    }
                                }
                            }

                            &.ik-meny {
                                a {
                                    &.hanglas-oppen {
                                        span {
                                            @include bg-img('../img/ikon-las-oppen-vit.svg', 0 34px, no-repeat, 15px 14px);
                                            padding-left: rem(22);
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
