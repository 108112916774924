﻿
// =======================================================================================
// === [ TEST MILJÖ ] ===
// =======================================================================================

// =======================================================================================
// Min-width 992px
// =======================================================================================
@media (min-width: 992px) {
    body {

        // === [ DEV ] ===================================================================
        &.test-miljo-dev,
        &.test-miljo-hp,
        &.test-miljo-localhost,
        &.test-miljo-major,
        &.test-miljo-minor,
        &.test-miljo-tst,
        &.test-miljo-uat {
            &::after {
                @include alecta-font($alecta-freight, 700, normal);
                position: absolute;
                top: 0;
                left: 0;
                z-index: 9999999;
                height: 35px;
                padding: 0 10px;
                color: $default-font-color;
                font-size: 26px;
                line-height: 31px;
            }
        }

        // === [ DEV ] ====================================================================
        &.test-miljo-dev {
            &::after {
                content: 'Dev';
                background-color: $test-miljo-dev;
            }
        }

        // === [ LOCALHOST ] =================================================================
        &.test-miljo-localhost {
            &::after {
                content: 'Localhost';
                background-color: $test-miljo-localhost;
            }
        }

        // === [ MAJOR ] =================================================================
        &.test-miljo-major {
            &::after {
                content: 'Major';
                color: $white;
                background-color: $test-miljo-major;
            }
        }

        // === [ MINOR ] =================================================================
        &.test-miljo-minor {
            &::after {
                content: 'Minor';
                background-color: $test-miljo-minor;
            }
        }

        // === [ TST ] ===================================================================
        &.test-miljo-tst {
            &::after {
                content: 'TST';
                background-color: $test-miljo-tst;
            }
        }

        // === [ UAT ] ===================================================================
        &.test-miljo-uat {
            &::after {
                content: 'UAT';
                color: $white;
                background-color: $test-miljo-uat;
            }
        }

        // === [ HP ] ====================================================================
        &.test-miljo-hp {
            &::after {
                height: 40px;
                line-height: 36px;
            }
        }
    }
}
