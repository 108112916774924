﻿/// <reference path="../base/mixins" />

// =======================================================================================
// === [ PUFFLÄNKBLOCK ] ===
// =======================================================================================

.pufflankblock,
.pufflankhuvudblock {
    margin-bottom: 2rem;
    padding: 0 2rem;

    .a-bg-image-mask {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        height: 100%;
        align-items: center;
    }

    .a-bg-image,
    .a-bg-illustration {
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
    }

    .a-bg-image {
        background-size: cover;

        .a-bg-image-mask {
            background-color: $bild-mask-bla;
        }
    }

    .block-inner {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 140px;
        align-items: flex-start;
        overflow: hidden;

        h2 {
            margin: 0 2rem;
            color: $gra-ljus-ljus;
            font-size: 2.2rem;
            font-weight: 300;
            line-height: 2.6rem;
        }
    }

    > a {
        &:hover {
            text-decoration: none;
        }
    }
}

.pufflankblock {
    .block-inner {
        &::after {
            content: '';
            position: absolute;
            right: 30px;
            bottom: 10px;
            width: 13px;
            height: 13px;
            background-image: url('/gui/img/ikon-lank-pil-bla.svg');
            background-repeat: no-repeat;
            background-size: 100%;
        }

        &:hover {
            &::after {
                @include animation('bounceX 2000ms normal ease-in-out');

                @include keyframes(bounceX) {
                    0% {
                        transform: translateX(0);
                    }

                    10% {
                        transform: translateX(7px);
                    }

                    20% {
                        transform: translateX(-1px);
                    }

                    30% {
                        transform: translateX(0);
                    }

                    100% {
                        transform: translateX(0);
                    }
                }
            }
        }

        &.a-bg-illustration {
            &::after {
                content: none;
            }
        }

        &.a-textcolor-alecta-vit {
            h2,
            p {
                color: $vit;
            }

            &::after {
                background-image: url('/gui/img/ikon-lank-pil-vit.svg');
            }
        }

        &.a-textcolor-alecta-bla-1 {
            h2,
            p {
                color: $alecta-bla-1;
            }
        }

        &.a-textcolor-alecta-bla-3 {
            h2,
            p {
                color: $alecta-bla-3;
            }
        }

        &.a-textcolor-alecta-ruby {
            h2,
            p {
                color: $alecta-ruby;
            }
        }
    }
}

.pufflankhuvudblock {
    .block-inner {
        height: auto;

        .a-bg-image {
            width: 100%;
            height: 132px;
            background-position: top center;
        }

        .inner-text {
            margin-top: 1rem;
        }

        .text-citat,
        span {
            color: $default-font-color;
        }

        .text-citat {
            @include alecta-font($alecta-freight-text, 300, italic);
            margin: 0 1rem 2rem;
            font-size: 2.8rem;
            line-height: 3.5rem;
        }

        span {
            font-size: 1.9rem;
            line-height: 2.5rem;
        }
    }
}

.modal-dialog {
    width: 100%;

    .modal-content {
        padding: 0;

        .modal-header {
            button {
                position: absolute;
                top: 0;
                left: 0;
                width: 3rem;
                height: 3rem;
                color: $vit;
                background-color: $modal-knapp-bla;
                background-image: url('/gui/img/icon-modal-close-white.svg');
                background-size: .8rem;
            }

            &.a-bg-image {
                height: 203px;
                background-position: center;
                background-repeat: no-repeat;
                background-size: cover;
            }
        }

        .modal-body {
            padding: 2.6rem 2.5rem;

            h3,
            h4,
            h5 {
                margin-right: 0;
                margin-left: 0;
            }

            h2 {
                margin: 0 0 2rem;
                font-size: 2.4rem;
                line-height: 2.8rem;
            }

            .block {
                margin-right: 0;
                margin-left: 0;
                padding-right: 0;
                padding-left: 0;

                &.citatblock {
                    img {
                        max-width: 100% !important;
                    }
                }
            }
        }
    }
}


// =======================================================================================
// Min-width 768px
// =======================================================================================
@media (min-width: 768px) {
    .pufflankblock,
    .pufflankhuvudblock {
        padding: 0 1.5rem;

        .block-inner {
            height: 160px;
        }
    }

    .pufflankblock {
        .block-inner {
            &::after {
                right: 30px;
            }
        }
    }

    .pufflankhuvudblock {
        .block-inner {
            flex-direction: row;
            height: 160px;
            border-top: 1px solid $gra-ljus;
            border-bottom: 1px solid $gra-ljus;

            .a-bg-image {
                height: 100%;
                max-width: 162px;
                background-position-x: 46%;
            }

            .inner-text {
                margin-top: 3rem;
            }

            .text-citat,
            span {
                padding: 0 3.5rem;
            }

            .text-citat {
                margin: 0 0 .5rem;
                font-size: 2.6rem;
                line-height: 3.2rem;
            }
        }
    }

    .modal-dialog {
        .modal-content {
            .modal-header {
                button {
                    width: 4rem;
                    height: 4rem;
                }
            }

            .modal-body {
                padding: 2.6rem 9rem;
            }
        }
    }
}

// =======================================================================================
// Min-width 992px
// =======================================================================================
@media (min-width: 992px) {

    .pufflankblock,
    .pufflankhuvudblock {
        margin-bottom: 3rem;
    }

    .modal-dialog {
        width: 79rem;

        .modal-content {
            padding: 0;

            .modal-body {
                padding: 2.6rem 12.5rem .9rem;

                h2 {
                    margin: 0 0 2rem;
                    font-size: 2.4rem;
                    line-height: 2.8rem;
                }
            }
        }
    }
}
