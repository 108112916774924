﻿/// <reference path="../base/mixins" />
/// <reference path="../components/bas-mixin" />

// =======================================================================================
// === [ COOKIEVALBLOCK ] ===
// =======================================================================================
.cookievalblock {
    form {
        margin-left: 2.5rem;
        margin-right: 2.5rem;
        padding: 2.5rem 0 1rem;
        background-color: $grey-light;

        h4 {
            font-size: rem(16);
            line-height: rem(22);
            text-align: center;
        }

        label {
            display: inline-block;
            margin-left: rem(13);
            margin-right: rem(13);
            font-size: rem(18);
            line-height: rem(23.06);
        }

        .radio-knappar-cookieval {
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin-top: rem(24);
            margin-bottom: 0;
        }
    }
}

// =======================================================================================
// Min-width 768px
// =======================================================================================
@media (min-width: 768px) {
    .cookievalblock {
        form {
            margin-left: 4rem;
            margin-right: 4rem;

            label {
                margin-left: rem(20);
                margin-right: rem(20);
            }

            .radio-knappar-cookieval {
                flex-direction: row;
            }
        }
    }
}

// =======================================================================================
// Min-width 992px
// =======================================================================================
@media (min-width: 992px) {
    .cookievalblock {
        form {
            margin-left: rem(20);
            margin-right: 0;
        }
    }
}
