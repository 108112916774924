﻿
// =======================================================================================
// === [ ETIKETER (POPOVER) ] ===
// =======================================================================================
.etikett {
    @include border-radius(1.5rem 0 1.5rem 0);
    @include box-shadow(0, 0, 0, rgba(0, 0, 0, 0));
    @include alecta-font($alecta-freight-text, 500, italic);
    position: absolute;
    top: auto;
    bottom: -1.2rem;
    left: 2rem;
    z-index: 1030;
    display: block;
    width: auto;
    max-width: 100%;
    margin: 0;
    padding: .7rem 2rem .9rem 1.7rem;
    color: $white;
    font-size: 1.8rem;
    text-align: center;

    .arrow {
        position: absolute;
        bottom: -1.5rem;
        left: 0;
        display: block;
        width: 2rem;
        height: 1.8rem;
        margin-left: 0;
        background-color: transparent;
        border: 0;
        overflow: hidden;

        &::after {
            content: '';
            position: absolute;
            z-index: 1;
            width: 6rem;
            height: 6rem;
            margin-top: -7px;
            margin-left: -20px;
            background-color: transparent;
            border-radius: 3rem;
            @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) { // IE Hack
                margin-left: -10px;
            }
        }
    }

    // === [ POPOVER (HÖGER) ] ===========================================================
    &.etikett-hoger {
        @include border-radius(0 1.5rem 0 1.5rem);
        right: 2rem;
        left: auto;

        .arrow {
            right: 0 !important;
            left: auto !important;
            margin-right: 0;

            &::after {
                margin-left: -40px;
                @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) { // IE Hack
                    margin-left: -29px;
                }
            }
        }
    }

    // === [ POPOVER (BG-FÄRGER) ] =======================================================
    &.a-bgcolor-alecta-bla-1 {
        background-color: $alecta-bla-1;

        .arrow {
            &::after {
                border: solid 1rem $alecta-bla-1;
            }
        }
    }

    &.a-bgcolor-alecta-bla-2 {
        background-color: $alecta-bla-2;

        .arrow {
            &::after {
                border: solid 1rem $alecta-bla-2;
            }
        }
    }

    &.a-bgcolor-alecta-bla-3 {
        background-color: $alecta-bla-3;

        .arrow {
            &::after {
                border: solid 1rem $alecta-bla-3;
            }
        }
    }

    &.a-bgcolor-alecta-ruby {
        background-color: $alecta-ruby;

        .arrow {
            &::after {
                border: solid 1rem $alecta-ruby;
            }
        }
    }

    &.a-bgcolor-alecta-gron {
        background-color: $alecta-gron;

        .arrow {
            &::after {
                border: solid 1rem $alecta-gron;
            }
        }
    }

    &.a-bgcolor-alecta-orange {
        background-color: $alecta-orange;

        .arrow {
            &::after {
                border: solid 1rem $alecta-orange;
            }
        }
    }
}
