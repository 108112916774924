﻿/// <reference path="../base/mixins" />

// =======================================================================================
// === [ BAKGRUNDSFÄRG ] ===
// =======================================================================================
#avgiftsraknaren-tartdiagram-01,
#avgiftsraknaren-tartdiagram-02 {
    .highcharts-background {
        fill: transparent;
    }

    .highcharts-color-0 {
        fill: $graf-avgif-diagram-1-tartbit-0;
        stroke: $graf-avgif-diagram-1-tartbit-0;
    }

    .highcharts-color-1 {
        fill: $graf-avgif-diagram-1-tartbit-1;
        stroke: $graf-avgif-diagram-1-tartbit-1;
    }

    .highcharts-tooltip {
        & > span {
            @include alpha-bg-color($white, .85);
            @include border(all, solid 1px, $graf-popover-border);
            @include border-radius(rem(3));
            padding: rem(10);
        }

        [style*='font-size'] {
            font-size: 13px !important;
        }

        [style='font-size: 10px'] {
            font-size: 13px !important;
        }
    }
}

#avgiftsraknaren-tartdiagram-02 {
    .highcharts-color-0 {
        fill: $graf-avgif-diagram-2-tartbit-0;
        stroke: $graf-avgif-diagram-2-tartbit-0;
    }
}

// =======================================================================================
// === [ GRAFSTAPEL (CSS-GRAFER) ] ===
// =======================================================================================
.graf-stapel {
    $duration: .5s;
    $delay: .1s;
    $bars: 5;
    $graph-height: 190px;

    @keyframes animate-width {
        0% {
            width: 0;
        }

        100% {
            visibility: visible;
        }
    }

    @keyframes animate-height {
        0% {
            height: 0;
        }

        100% {
            visibility: visible;
        }
    }

    #bar-chart {
        position: relative;
        width: 70%;
        height: $graph-height + 45;
        margin: 0 auto;
        clear: both;

        .graph {
            position: relative;
            left: -20px;
            width: 204px;
            height: $graph-height + 35;
            margin: 0 auto;
        }

        .bars {
            position: absolute;
            z-index: 10;
            width: 100%;
            height: $graph-height;
            padding: 0 2%;
        }

        .bar-group {
            display: flex;
            flex-direction: row;
            align-items: flex-end;
            justify-content: space-evenly;
            width: 100%;
            height: 100%;

            .bar {
                @include bar-animation($duration, height, running);
                width: 55px;
                height: 0;
                text-align: center;
                background-color: $alecta-bla-1;

                span {
                    color: $white;
                    font-size: 1.6rem;
                }
            }

            @for $i from 1 through $bars {
                .bar-#{$i} {
                    animation-delay: $delay + .1 * $i;
                }
            }
        }

        ul {
            margin: 0;
            padding: 0;
            list-style: none;
        }

        .x-axis {
            position: absolute;
            bottom: 0;
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: flex-end;
            justify-content: space-evenly;
            text-align: center;

            li {
                @include alecta-font($alecta-freight, 500, normal);
                margin: 0;
                font-size: 16px;

                &::after {
                    content: none;
                }
            }
        }

        .y-axis {
            position: absolute;
            width: 100%;
            text-align: right;

            li {
                @include alecta-font($alecta-freight, 500, normal);
                display: block;
                width: 100%;
                height: calc($graph-height / 6);
                border-top: 1px solid $gra-medel-mork;

                span {
                    display: block;
                    width: 50px;
                    margin: -14px 0 0 -60px;
                    padding: 0;
                    font-size: 14px;
                }

                &::after {
                    content: none;
                }
            }
        }
    }

    &.graf-koldioxidavtryck {
        $bars: 5;

        #bar-chart {
            width: 100%;
            height: 195px;
            margin: 2rem 1rem;

            .graph {
                left: -26px;
                width: 298px;
                height: 195px;

                .x-axis {
                    left: 8%;
                }
            }


            .bars {
                left: 8%;
                height: 160px;

                .bar-group {
                    left: 9%;

                    .bar {
                        width: 54px;

                        &.bar-1 {
                            left: 0;
                        }

                        &.bar-2 {
                            left: 20%;
                        }

                        &.bar-3 {
                            left: 40%;
                        }

                        &.bar-4 {
                            left: 60%;
                        }

                        &.bar-5 {
                            left: 80%;
                        }
                    }
                }
            }
        }

        li {
            &::after {
                content: none;
            }
        }
    }

    &.graf-avkastning {
        $bars: 4;

        #bar-chart {
            width: 100%;
            margin: 0 1rem;

            .graph {
                left: -25px;
                width: 100%;
                height: 195px;
            }

            .x-axis {
                bottom: -15%;
                left: 57%;
                display: block;

                li {
                    float: none;
                    margin: 0 16px;
                    padding: 0;
                    text-align: left;
                }

                &:first-child {
                    left: 11%;
                }
            }

            .y-axis {
                bottom: 0;
                left: -1rem;
            }

            .bars {
                display: flex;
                bottom: 31px;
                height: 156px;

                .bar-group {
                    position: relative;
                    left: 5px; //13%
                    width: 50%;

                    &:last-child {
                        left: 15px; //15%
                    }

                    .bar {
                        &.bar-1,
                        &.bar-3 {
                            left: -1.5rem;
                            background-color: $alecta-bla-2;
                        }

                        &.bar-2,
                        &.bar-4 {
                            left: 35%;
                            background-color: $gra-medel-mork;
                        }

                        &.bar-3 {
                            background-color: $alecta-bla-3;
                        }

                        .procent {
                            &::after {
                                font-size: 75%;
                            }
                        }
                    }
                }
            }
        }
    }

    &.graf-kundnojdhet-foretag,
    &.graf-kundnojdhet-privat {
        $bars: 2;

        .bar {
            height: 16px;
            border-radius: 100px 0 0 100px;

            .markor {
                position: relative;
                left: 100%;
                display: block;
                text-indent: -3rem;
            }

            .varde {
                position: relative;
                right: 16px;
                bottom: 8px;
                font-size: 1.6rem;

                &.max {
                    left: 99%;
                }
            }

            &::after {
                content: '';
                position: relative;
                left: 100%;
                border-left: 2px solid transparent;
            }

            &.bar-max {
                z-index: 1;
                background-color: $gra-medel;
                border-radius: 10px;
            }

            &.bar-utfall {
                position: relative;
                top: 16px;
                z-index: 4;
                background-color: $alecta-bla-2;

                .markor {
                    top: 30px;
                    left: 100%;
                    font-size: 4.6rem;
                    text-indent: -2.3rem;
                }

                .markor-text {
                    position: absolute;
                    top: 70px;
                    left: 100%;
                    font-size: 1.6rem;
                    text-indent: -1.2rem;
                }

                &::after {
                    bottom: 20px;
                    border-color: $alecta-bla-2;
                }
            }

            &.bar-mal {
                position: relative;
                top: 32px;
                z-index: 3;
                background: 0;

                .markor {
                    top: -45px;
                    font-size: 1.6rem;
                }

                &::after {
                    bottom: 35px;
                    border-color: $gra-medel;
                }
            }

            &.bar-mal,
            &.bar-utfall {
                @include bar-animation(1s, width, running);

                &.should-animate {
                    animation-play-state: paused;
                }
            }
        }

        &.should-animate {
            .bar {
                &.bar-mal,
                &.bar-utfall {
                    animation-play-state: paused;
                }
            }
        }

        &.animate {
            .bar {
                &.bar-mal,
                &.bar-utfall {
                    animation-play-state: running;
                }
            }
        }
    }

    &.graf-kundnojdhet-privat {
        .bar {
            &.bar-mal {
                background: 0;
                animation-delay: .2s;

                &::after {
                    border-color: $gra-medel;
                }
            }

            &.bar-utfall {
                background-color: $alecta-bla-3;
                animation-delay: .2s;

                &::after {
                    border-color: $alecta-bla-3;
                }
            }
        }
    }

    &.graf-koldioxidsnurra {
        $bars: 2;

        .bar {
            height: 30px;

            &.bar-med {
                position: relative;
                top: 16px;
                background-color: $graf-bg-gron;

                .graf-etikett {
                    color: $vit;
                }
            }

            &.bar-utan {
                position: relative;
                top: 0;
                background-color: $graf-bg-ljusgron;
            }

            &.bar-utan,
            &.bar-med {
                @include bar-animation(1s, width, running);

                &.should-animate {
                    animation-play-state: paused;
                }
            }
        }

        &.should-animate {
            .bar {
                &.bar-utan,
                &.bar-med {
                    animation-play-state: paused;
                }
            }
        }

        &.animate {
            .bar {
                &.bar-utan,
                &.bar-med {
                    animation-play-state: running;
                }
            }
        }
    }

    &.should-animate {
        #bar-chart {
            .bar-group {
                .bar {
                    animation-play-state: paused;
                }
            }
        }
    }

    &.animate {
        #bar-chart {
            .bar-group {
                .bar {
                    animation-play-state: running;
                }
            }
        }
    }
}

// =======================================================================================
// Min-width 768px
// =======================================================================================
@media (min-width: 768px) {
    .graf-stapel {
        &.graf-koldioxidavtryck {
            #bar-chart {
                width: 70%;
                margin: 2rem auto;

                .graph {
                    left: -20px;
                    width: 356px;
                }

                .bars {
                    .bar-group {
                        .bar {
                            width: 60px;
                        }
                    }
                }
            }
        }

        &.graf-avkastning {
            #bar-chart {
                .x-axis {
                    bottom: -12%;

                    &:first-child {
                        left: 26%;
                    }
                }

                .y-axis {
                    left: -.5rem;
                }

                .bars {
                    .bar-group {
                        left: 27%;

                        &:last-child {
                            left: 12%;
                        }

                        .bar {
                            position: absolute;

                            &.bar-2,
                            &.bar-4 {
                                left: 17%;
                            }
                        }
                    }
                }
            }
        }
    }
}

// =======================================================================================
// Min-width 992px
// =======================================================================================
@media (min-width: 992px) {
    .graf-stapel {
        &.graf-koldioxidavtryck {
            #bar-chart {
                .graph {
                    .x-axis {
                        left: 0;
                    }
                }

                .bars {
                    left: 0;

                    .bar-group {
                        left: 3%;
                    }
                }
            }
        }
    }
}
