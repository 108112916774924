﻿
// =======================================================================================
// === [ GALLERIBLOCK ] ===
// =======================================================================================
.galleriblock {
    display: inline-block;
    float: none;
    width: 100%;
    padding: 0 0 $block-margin-bottom-320;

    .galleriblock-inne {
        overflow: hidden;
    }

    .galleriblock-bild {
        position: relative;
        justify-content: center;
        align-items: center;

        img {
            position: relative;
            width: 100% !important;
            margin: 0;
        }

        span {
            @include alecta-font($alecta-freight-text, 500, italic);
            position: absolute;
            bottom: -.8rem;
            left: 1.8rem;
            display: block;
            width: 10.7rem;
            padding: .5rem 0;
            color: $white;
            font-size: 1.8rem;
            line-height: 1.8rem;
            text-align: center;
            transform: rotate(-15deg);
        }
    }

    .galleriblock-text {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: auto;
        padding: 1.5rem 0 0;
        text-align: center;
        background-color: $white;
        resize: vertical;

        p {
            @include alecta-font($alecta-freight, 500, normal);
            display: inline-block;
            margin-bottom: rem(20);
            padding: 0;
            font-size: rem(15);
            line-height: rem(18);
        }

        button {
            @include border(all, solid 1px, $galleriblock-border);
            display: block;
            min-width: 0;
            margin: 0 5rem 2rem !important;
            color: $default-link-color;
            background-color: $white;
            -webkit-appearance: none;

            &:hover {
                text-decoration: underline;
            }
        }

        a {
            @include border(all, solid 1px, $galleriblock-border);
            @include border-radius(2rem);
            display: inline-block;
            margin: 0 auto;
            padding: .9rem $margin-right-left-320 - .5rem;
            color: $default-link-color;
            font-size: 1.5rem;
            background-color: $white;
            background-position: 25px center;

            &.loginlank {
                position: relative;
                padding-right: 2.8rem;
                background-image: none;

                &.loginlanklock {
                    position: relative;
                    padding-left: 2rem;
                    background-image: none;

                    &::after {
                        @include bg-img('../img/icon-personummersok-las.svg', left center, no-repeat, 9px 11px);
                        content: '';
                        position: absolute;
                        top: 13px;
                        right: 15px;
                        width: 9px;
                        height: 11px;
                    }
                }
            }
        }
    }
}

// =======================================================================================
// Min-width 768px
// =======================================================================================
@media (min-width: 768px) {
    .galleriblock {
        width: calc(100% - 4rem);
        margin-right: calc($margin-right-left-768 / 2);
        margin-left: calc($margin-right-left-768 / 2);
        padding-bottom: $block-margin-bottom-768;

        // === [ TVÅ I BREDD ] ===========================================================
        &.col-sm-6 {
            width: 49.6%;
            margin-right: 0;
            margin-left: 0;
            padding-right: .6rem;
            padding-left: .6rem;
        }

        // === [ TRE I BREDD ] ===========================================================
        &.col-sm-4 {
            width: 32.8%;
            margin-right: 0;
            margin-left: 0;
            padding-right: .6rem;
            padding-left: .6rem;
        }
    }

    // === [ PÅ EN GALLERISIDA ] =========================================================
    .accordion-inner {
        .galleriblock {
            width: 100%;
            margin-right: 0;
            margin-left: 0;

            // === [ TRE I BREDD ] =======================================================
            &.col-sm-4 {
                width: 32.8%;
                padding-right: .6rem;
                padding-left: .6rem;
            }
        }
    }
}

// =======================================================================================
// Min-width 992px
// =======================================================================================
@media (min-width: 992px) {
    .galleriblock {
        width: 100%;
        margin-right: 0;
        margin-left: 0;
        padding-bottom: $block-margin-bottom-992;

        .galleriblock-text {
            button {
                display: ruby-base;
                width: auto;
            }
        }
    }
}
