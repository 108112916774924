﻿
// =======================================================================================
// === [ 404 ] ===
// =======================================================================================
.fyra-noll-fyra {
    .bild {
        @include border(bottom, solid 1px, $fyra04-border);
        margin-bottom: 2.3rem;
        text-align: center;

        img {
            display: inline-block;
        }
    }
}
