﻿
// =======================================================================================
// === [ KURSBLOCK ] ===
// =======================================================================================
.kursblock {
    h2 {
        max-width: 100% !important;
        padding: 0 0 3rem !important;
        text-align: center;
    }

    .accordion {
        .accordion-group {
            margin-bottom: .3rem;
            background-color: $white;

            .accordion-toggle {
                margin-top: 0;
                padding: 0 0 2.5rem;
                border: 0;

                .col-xs-12,
                .col-xs-6 {
                    padding: 1.9rem 0 0;
                    font-size: 1.8rem;
                    text-align: center;

                    &.kurs-stad {
                        font-weight: 600;
                        text-align: center;

                        span {
                            display: inline-block;
                            padding-left: 1rem;
                            color: $red;
                            font-weight: 400;
                        }
                    }
                }

                .col-xs-12 {
                    padding-left: 3rem;
                    text-align: left;
                }

                .kurs-btn-anmal-reserv {
                    font-size: 1.5rem;
                    line-height: 1.8rem;
                }

                .kurs-btn-avboka,
                .kurs-btn-anmal,
                .kurs-btn-anmal-reservplats {
                    @include border(all, solid 1px, $alecta-bla-2);
                    @include border-radius(2rem);
                    display: inline-block;
                    margin-bottom: 0;
                    padding: .3rem 2.8rem;
                    color: $alecta-bla-2;
                    text-align: center;
                }

                .kurs-btn-anmal {
                    width: 10.2rem;
                    margin: 0 auto;
                }

                .kurs-btn-avboka,
                .kurs-btn-anmal-reservplats {
                    margin-bottom: 0;
                    padding: .3rem 1rem;
                    border: 0;
                }

                .kurs-btn-anmal-reservplats {
                    display: block;
                    padding: 0 1rem;
                    font-size: 1.6rem;
                }

                &:hover {
                    color: $default-font-color;
                }

                .col-xs-6 {
                    padding-top: 1rem;
                    padding-left: 4rem;

                    &:nth-child(4) {
                        position: relative;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        height: 6.2rem;
                        padding-top: 0;
                        resize: vertical;
                    }

                    &:nth-child(5) {
                        padding-top: 1.6rem;
                    }

                    &:nth-child(2n+1) {
                        padding-right: 4rem;
                        padding-left: 0;
                    }
                }
            }

            .accordion-body {

                &[aria-expanded='true'] {
                    position: relative;
                    top: -6.2rem;
                }

                .accordion-inner {
                    position: relative;
                    padding: 0 0 2rem;
                    background-color: $white;
                    border: 0;

                    .kurs-btn-stang {
                        @include bg-img('../img/icon-kurser-stang.svg', center center, no-repeat, 18px 18px);
                        position: absolute;
                        top: -8rem;
                        right: 2rem;
                        display: block;
                        width: 1.8rem;
                        height: 1.8rem;
                    }

                    .kurs-adress {
                        margin-bottom: 1.5rem;
                        padding: 1rem 0 4rem;
                        font-weight: 500;
                        text-align: center;

                        a {
                            position: relative;
                            color: $brodtext-link-color;

                            span {
                                position: relative;
                                right: 0;
                                display: inline-block;
                                height: 1.7rem;
                            }
                        }
                    }

                    iframe {
                        display: none;
                    }

                    h3 {
                        font-weight: 600;

                        &.kurs-avboka-rubrik {
                            padding: 2rem 0 4rem 3rem;
                        }
                    }

                    form {
                        margin: 0;
                        padding: 0 25px;
                        background-color: transparent;

                        .Form__Element {
                            display: inline-block;
                            width: 100%;
                            margin: 0;
                            padding: 0 0 18px;

                            .selectric-wrapper {
                                .selectric {
                                    width: 100%;
                                }
                            }
                        }

                        input {
                            &[type='text'],
                            &[type='email'],
                            &[type='password'],
                            &[type='tel'],
                            &[type='number'] {
                                width: 100%;
                            }
                        }

                        button,
                        input {
                            &[type='submit'],
                            &[type='button'] {
                                display: block;
                                width: auto;
                                margin: 10px 0 19px;
                                padding: 7px 25px;
                            }
                        }

                        .FormChoice {
                            padding: 5px 0;

                            label {
                                margin-bottom: 20px;
                            }
                        }

                        .FormTextbox {
                            display: block;
                        }

                        .FormSelection {
                            display: block;
                            padding-bottom: 10px;

                            .Form__Element__Caption {
                                position: relative;
                                top: 0;
                                left: 0;
                                padding-bottom: 21px;
                                font-size: 19px;
                                line-height: 20px;
                            }
                        }

                        .FormTextbox--Textarea { // sass-lint:disable-line no-vendor-prefixes
                            display: none;

                            &.textarea-visa {
                                display: block;
                            }
                        }

                        div {
                            p {
                                span {
                                    display: block;
                                    padding-top: 2rem;
                                    line-height: 18px;
                                    text-align: center;
                                }
                            }
                        }
                    }

                    .kurs-registreringsfel {
                        padding: 3rem 0 2rem;

                        h3 {
                            color: $red;
                            font-weight: 500;
                        }

                        p {
                            @include alecta-font($alecta-freight, 500, normal);
                            color: $red;
                        }
                    }

                    .kurs-formular {
                        padding: 0;

                        h3,
                        p {
                            padding-right: $margin-right-left-320;
                            padding-left: $margin-right-left-320;
                        }

                        div {
                            h3,
                            p {
                                padding-right: 0;
                                padding-left: 0;
                            }
                        }
                    }

                    .kurs-tack {
                        padding-top: 2rem;

                        h3,
                        p {
                            padding-right: $margin-right-left-320;
                            padding-left: $margin-right-left-320;
                        }
                    }
                }
            }
        }
    }

    .kurser-ingakurser {
        padding: 3rem 2.5rem;
        font-size: 2rem;
        text-align: center;
        background-color: $white;
    }
}

// =======================================================================================
// Min-width 768px
// =======================================================================================
@media (min-width: 768px) {
    .kursblock {
        padding: 0 $margin-right-left-768 - 2rem;

        .accordion {
            .accordion-group {
                .accordion-toggle {
                    padding: 0;

                    .row {
                        height: auto;
                    }

                    .col-xs-12,
                    .col-xs-6 {

                        &.kurs-stad {
                            width: 35%;
                            padding-bottom: 1.8rem;
                            padding-left: $margin-right-left-768 - 2rem;
                            text-align: left;
                        }

                        &.kurs-avboka,
                        &.kurs-anmal {
                            width: 15%;
                        }
                    }

                    .col-xs-6 {
                        padding-top: 1.9rem;
                        padding-left: 0;

                        &:nth-child(2n+1) {
                            padding-right: 0;
                            padding-left: 0;
                        }
                    }

                    .kurs-btn-anmal-reservplats {
                        font-size: 1.8rem;
                    }
                }

                .accordion-body {

                    &[aria-expanded='true'] {
                        top: 0;
                    }

                    .accordion-inner {

                        form {
                            padding: 0 20px;

                            .Form__Element {
                                width: 50%;

                                &.FormChoice {
                                    label {
                                        left: 0;
                                    }
                                }
                            }

                            button,
                            input {
                                &[type='submit'],
                                &[type='button'] {
                                    width: auto;
                                    margin: 10px 0 19px;
                                    padding: 7px 25px;
                                }
                            }

                            div {
                                p {
                                    span {
                                        text-align: left;
                                    }
                                }
                            }
                        }

                        .kurs-btn-stang {
                            top: -4.2rem;
                            right: 2rem;
                        }

                        .kurs-adress {
                            padding: 2.5rem 0 0;

                            a {
                                pointer-events: none;
                                cursor: default;

                                span {
                                    display: none;
                                }
                            }
                        }

                        iframe {
                            display: block;
                            width: 100%;
                            height: 22.5rem;
                            margin-bottom: 2rem;
                            padding: 0 $margin-right-left-768 - 2rem;
                            border: 0;
                        }

                        .kurs-formular {
                            h3,
                            p {
                                padding-left: 20px;

                                span {
                                    padding-left: 0;
                                }
                            }

                            p {
                                margin-bottom: 19px;
                            }

                            div {
                                h3,
                                p {
                                    padding-left: 0;
                                }
                            }
                        }

                        .kurs-tack {
                            h3,
                            p {
                                padding-left: $margin-right-left-768 - 2rem;
                            }
                        }
                    }
                }
            }
        }
    }
}

// =======================================================================================
// Min-width 992px
// =======================================================================================
@media (min-width: 992px) {
    .kursblock {
        padding: 0;

        .accordion {
            .accordion-group {
                .accordion-toggle {
                    padding: 0;

                    .col-xs-12,
                    .col-xs-6 {
                        &.kurs-stad {
                            padding-left: $margin-right-left-992;
                        }
                    }
                }
            }
        }
    }
}
