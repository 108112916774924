﻿

// =======================================================================================
// === [ STORNYHET ] ===
// =======================================================================================
.a-stornyhet {
    position: relative;
    display: block;
    margin-bottom: rem(10);
    padding: 0;

    .stornyhetstartsidablock {
        a {
            @include border(top bottom, solid 1px, $kanter);
            display: table;
            width: 100%;
        }
    }

    .a-stornyhet-img {
        position: relative;
        padding: 0;
        overflow: hidden;

        img {
            width: 100%;
            height: auto;
            margin: 0;
        }
    }

    .a-stornyhet-info {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 1.5rem 0;
        resize: vertical;

        h2 {
            max-width: none;
            padding: 0;
            color: $default-font-color;
            font-size: 2.2rem;
            font-weight: 500;
            line-height: 2.7rem;
        }

        p {
            @include alecta-font($alecta-freight, 500, normal);
            max-width: none;
            margin: 0;
            padding: 0 $margin-right-left-320;
            color: $stornyhet-text;
            font-size: rem(15);
            line-height: rem(19);
        }

        h2,
        p {
            &:hover {
                text-decoration: none;
            }
        }
    }
}

.fullbredd {
    .a-stornyhet {
        margin-bottom: 0;
    }
}

.stornyhet-50-procent {
    display: inline-block;
    float: none;

    .a-stornyhet-img {
        float: right;
        width: 25% !important;
    }

    .a-stornyhet-info {
        float: left;
        width: 75% !important;
    }
}

.stornyhetstartsidablock {
    padding-bottom: rem(20);
    clear: both;

    &.col-sm-6 {
        display: inline-block;
        padding-left: 10px;
        clear: none;

        &:nth-child(odd) {
            padding-right: 10px;
            padding-left: 0;
        }

        .a-stornyhet {
            height: 100%;

            .a-stornyhet-img {
                float: right;
                width: 25% !important;
            }

            .a-stornyhet-info {
                float: left;
                width: 75% !important;
            }
        }
    }

    &.col-sm-12 {
        float: none;
    }
}

.utfallande-bild {
    .a-stornyhet-img {
        position: relative;
        text-align: center;
        overflow: visible;

        img {
            display: inline-block;
            margin: rem(-10) 0 0;
        }
    }
}

.utan-bild {
    .a-stornyhet-img {
        display: none;
    }

    .a-stornyhet-info {
        width: 100%;
        height: auto;
    }
}

// =======================================================================================
// Min-width 768px
// =======================================================================================
@media (min-width: 768px) {
    .a-stornyhet {
        margin-right: 4rem;
        margin-left: 4rem;

        .a-stornyhet-img {
            text-align: center;

            &.col-sm-6 {
                width: 100%;
            }

            img {
                display: inline-block;
                width: auto;
            }
        }

        .a-stornyhet-info {
            &.col-sm-6 {
                width: 100%;
            }

            p {
                margin-left: rem(15);
            }
        }
    }

    .fullbredd {
        .a-stornyhet {
            margin-right: 0;
            margin-left: 0;
        }
    }
}

// =======================================================================================
// Min-width 992px
// =======================================================================================
@media (min-width: 992px) {
    .a-stornyhet {
        margin-right: 0;
        margin-bottom: rem(30);
        margin-left: 0;
        padding: 0;

        .a-stornyhet-img {
            float: right;
            height: rem(175);

            &.col-sm-6 {
                width: 50%;
            }

            img {
                width: 100%;
            }
        }

        .a-stornyhet-info {
            float: left;
            height: rem(175);
            padding: 0;

            &.col-sm-6 {
                width: 50%;
            }

            h2 {
                font-size: rem(36);
                line-height: rem(39);
            }

            p {
                margin-left: 0;
                padding-left: rem(20);
            }
        }

        + .a-stornyhet {
            margin-top: rem(-10);
        }
    }

    .utfallande-bild {
        .a-stornyhet-img {
            img {
                position: absolute;
                right: 0;
                bottom: 0;
                width: auto;
                height: auto;
                max-width: 100%;
                max-height: calc(100% + 20px);
            }
        }
    }

    .stornyhet-50-procent {
        width: 49.7%;
        padding-right: rem(10);

        + .stornyhet-50-procent {
            padding-right: 0;
            padding-left: rem(10);
        }
    }

    .stornyhetstartsidablock {
        &.col-sm-6 {
            width: 49.7%;
            padding-right: rem(10);
            clear: none;

            + .stornyhetstartsidablock {
                &.col-sm-6 {
                    padding-right: 0;
                    padding-left: rem(10);
                }
            }
        }
    }

    .utan-bild {
        .a-stornyhet-info {
            height: auto;
            padding: rem(25) 0;
        }
    }

    .bild-till-vanster {
        .a-stornyhet-img {
            float: left;
        }

        .a-stornyhet-info {
            float: right;
        }
    }
}
