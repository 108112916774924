﻿
// =======================================================================================
// === [ MENY ] ===
// =======================================================================================
.navbar {
    z-index: 600;
    margin-bottom: 0;
    border: 0;

    .container-fluid {

        // === [ VATTENDELARE ] ==========================================================
        &:first-of-type {
            height: 3rem;
            background-color: $nav-top-bg;

            .navbar-nav {
                position: relative;
                display: flex;
                float: right !important;
                justify-content: space-between;
                width: 100%;
                margin: 0;
                padding: 0 1.3rem;
            }

            li {
                display: inline-block;

                &:first-child {
                    a {
                        padding-left: .7rem;
                    }
                }

                &.active {
                    a {
                        color: $nav-top-link;
                        background-color: $nav-top-link-bg;

                        &:hover,
                        &:focus {
                            color: $nav-top-link;
                            background-color: $nav-top-link-bg;
                        }
                    }
                }
            }

            a {
                height: 3rem;
                padding: .5rem .7rem;
                color: $nav-top-link;
                font-size: 1.2rem;
                text-transform: uppercase;

                &:hover {
                    color: $nav-top-link;
                    background-color: lighten($alecta-bla-2, 10%);
                }

                &:active,
                &:focus {
                    color: $nav-top-link;
                    background-color: darken($alecta-bla-2, 10%);
                }
            }

            .a-nav-link-contact,
            .a-nav-link-english,
            .a-nav-link-search {
                @include bg-img('../img/icon-nav-contact.svg', center center, no-repeat, 16px 10px);
                width: 2.5rem;
                text-indent: -300rem;
            }

            .a-nav-link-english {
                @include bg-img('../img/icon-nav-flag-eng.svg', null, null, null);
            }

            .a-nav-link-search {
                @include bg-img('../img/icon-nav-search.svg', null, null, 12px 11px);
                width: 2.5rem;
            }
        }

        // === [ LOKAL MENY ] ============================================================
        &:last-of-type {
            position: relative;
            display: block;
            width: 100%;
            min-height: rem(50);
            background-color: $nav-bottom-bg;

            .navbar-header {
                display: flex;
                flex-flow: row wrap;
                justify-content: flex-end;
                height: 5rem;
                margin: 0;
                padding-right: 15px;
            }

            // === [ HAMBURGARE ] ========================================================
            .navbar-toggle {
                position: relative;
                float: right;
                order: 2;
                height: rem(50);
                margin: 0 !important;
                padding: 12px 5px 0;
                white-space: nowrap;
                background-color: $nav-bottom-bg;
                border-width: 0;
                border-radius: 0;

                &:hover,
                &:focus {
                    background-color: $nav-bottom-bg;
                }

                // === [ HAMBURGARE TILL X ] =============================================
                .icon-bar {
                    @include hambugare-till-x(0deg);
                    @include hambugare-till-x-transition(.25s, ease-in-out);
                    position: absolute;
                    left: calc(50% - 7px);
                    display: block;
                    width: rem(15);
                    height: rem(2);
                    background-color: $nav-mobile-hamburger;

                    &:nth-child(2),
                    &:nth-child(3),
                    &:nth-child(4) {
                        @include hambugare-till-x-origin(left center);
                        top: rem(12);
                    }

                    &:nth-child(3) {
                        top: rem(13);
                    }

                    &:nth-child(4) {
                        top: rem(18);
                    }
                }

                .language-label {
                    font-size: rem(12);
                    line-height: rem(12);
                }

                // === [ X TILL HAMBURGARE ] =============================================
                &.open {
                    .icon-bar {
                        left: rem(13);
                        width: rem(14);
                        height: rem(3);

                        &:nth-child(2) {
                            @include hambugare-till-x(45deg);
                            top: rem(13);
                        }

                        &:nth-child(3) {
                            width: 1px;
                            opacity: 0;
                        }

                        &:nth-child(4) {
                            @include hambugare-till-x(-45deg);
                            top: rem(19);
                        }
                    }

                    &.collapsed {
                        .icon-bar {
                            left: rem(10);
                            width: rem(15);
                            height: rem(2);

                            &:nth-child(2) {
                                top: rem(12);
                            }

                            &:nth-child(3) {
                                top: rem(13);
                                opacity: 1;
                            }

                            &:nth-child(4) {
                                top: rem(18);
                            }

                            &:nth-child(2),
                            &:nth-child(4) {
                                @include hambugare-till-x(0);
                            }
                        }
                    }
                }

                // =======================================================================
                // === [ BETA ] ===
                // =======================================================================
                &.ik-beta {
                    position: relative;
                    order: 1;
                    background-position: 25px 14px;

                    &::after {
                        content: 'BETA';
                        position: absolute;
                        top: 19px;
                        left: 43px;
                        font-size: 7px;
                        font-weight: 500;
                        line-height: 8px;
                    }

                    &.ik-inloggad {
                        background-position: 30px 14px;
                        background-size: 11px 11px;
                    }
                }

                &.ik-mobil-meny {
                    order: 1;
                }

                &.utveckling-foretagombud {
                    order: 1;
                }
            }

            // === [ LÄNK » MINA SIDOR ] =================================================
            .mina-sidor {
                @include bg-img('../img/ikon-las-meny-stangd.svg', center 13px, no-repeat, 11px 11px);

                &.hanglas-oppen {
                    @include bg-img('../img/ikon-meny-person.svg', center 13px, no-repeat, 14px 13px);
                }

                &.hanglas-oppen-oppnasidor {
                    @include bg-img('../img/ikon-meny-person.svg', center 13px, no-repeat, 14px 13px);
                }
            }

            // === [ ALECTA LOGGA ] ======================================================
            .navbar-brand {
                @include bg-img('../img/logo-alecta.svg', center center, no-repeat, 80px 20px);
                position: absolute;
                top: 0;
                left: rem(10);
                width: rem(110);
                height: rem(50);
                margin-left: 0;
                text-indent: rem(-3000);

                &:hover,
                &:focus {
                    background-color: $trans;
                }
            }
        }
    }
}

.nav {
    &> li {
        position: relative;
        display: block;
    }
}

.external-use {
    .navbar {
        .container-fluid {
            &:first-of-type {
                height: auto;
            }
        }
    }
}

// =======================================================================================
// Min-width 375px
// =======================================================================================
@media (min-width: 375px) {
    .navbar {
        .container-fluid {

            // === [ VATTENDELARE ] ======================================================
            &:first-of-type {
                height: 3.5rem;

                .navbar-nav {
                    padding: 0 .7rem;
                }

                li {
                    &:first-child {
                        a {
                            padding-left: 1rem;
                        }
                    }
                }

                a {
                    height: 3.5rem;
                    padding: .8rem 1rem;
                    font-size: 1.5rem;
                }

                .a-nav-link-contact,
                .a-nav-link-english,
                .a-nav-link-search {
                    width: 3.3rem;
                    background-size: 1.8rem auto;
                }

                .a-nav-link-search {
                    width: 3.2rem;
                    background-size: 1.4rem auto;
                }
            }

            // === [ LOKAL MENY ] ========================================================
            &:last-of-type {
                .navbar-header {
                    height: 6rem;
                }

                // === [ HAMBURGARE ] ====================================================
                .navbar-toggle {
                    height: 6rem;

                    // === [ HAMBURGARE TILL X ] =========================================
                    .icon-bar {
                        &:nth-child(2) {
                            top: rem(15);
                        }

                        &:nth-child(3) {
                            top: rem(16);
                        }

                        &:nth-child(4) {
                            top: rem(21);
                        }
                    }

                    // === [ HAMBURGARE TILL X ] =========================================
                    &.open {
                        .icon-bar {
                            &:nth-child(2) {
                                top: rem(16);
                            }

                            &:nth-child(4) {
                                top: rem(22);
                            }
                        }

                        &.collapsed {
                            .icon-bar {
                                &:nth-child(2) {
                                    top: rem(15);
                                }

                                &:nth-child(3) {
                                    top: rem(16);
                                }

                                &:nth-child(4) {
                                    top: rem(21);
                                }
                            }
                        }
                    }

                    // ===================================================================
                    // === [ BETA ] ===
                    // ===================================================================
                    &.ik-beta {
                        &::after {
                            top: 21px;
                            left: 45px;
                        }

                        &.ik-inloggad {
                            background-position: 28px 14px;
                            background-size: 13px 13px;
                        }
                    }
                }

                // === [ ALECTA LOGGA ] ==================================================
                .navbar-brand {
                    z-index: 1;
                    width: rem(115);
                    height: rem(60);
                    background-size: rem(85) auto;
                }
            }
        }
    }

    .external-use {
        .navbar {
            .container-fluid {
                &:first-of-type {
                    height: auto;
                }
            }
        }
    }
}

// =======================================================================================
// Min-width 480px
// =======================================================================================
@media (min-width: 480px) {
    .navbar {
        .container-fluid {

            // === [ VATTENDELARE ] ======================================================
            &:first-of-type {
                .navbar-nav {
                    justify-content: flex-end;
                    margin-right: .5rem;
                    padding: 0;

                    a {
                        padding: .8rem 2rem;
                    }

                    .a-nav-link-contact,
                    .a-nav-link-english,
                    .a-nav-link-search {
                        width: 4.6rem;
                    }

                    .a-nav-link-search {
                        width: 3.6rem;
                        padding: .8rem 1.6rem;
                    }
                }
            }
        }
    }
}

// =======================================================================================
// Min-width 768px
// =======================================================================================
@media (min-width: 768px) {
    .navbar {
        .container-fluid {

            // === [ VATTENDELARE ] ======================================================
            &:first-of-type {
                .navbar-nav {
                    justify-content: flex-end;
                    margin: 0 $margin-right-left-768 - 1.5rem 0 0;

                    a {
                        padding: .8rem $margin-right-left-768 - 2.5rem 0;
                        font-size: 1.4rem;
                    }

                    .a-nav-link-contact,
                    .a-nav-link-english,
                    .a-nav-link-search {
                        width: auto;
                        text-indent: 0;
                        background-image: none;
                    }
                }
            }

            // === [ LOKAL MENY ] ========================================================
            &:last-of-type {
                .navbar-header {
                    height: 7.2rem;
                    padding-right: 30px;
                }

                // === [ HAMBURGARE ] ====================================================
                .navbar-toggle {
                    height: 7.2rem;
                    padding: 20px 10px 0;
                    background-position: center 22px;
                    background-size: 14px 12px;

                    // === [ HAMBURGARE TILL X ] =========================================
                    .icon-bar {
                        left: calc(50% - 7px);
                        width: 16px;
                        height: 2px;

                        &:nth-child(2) {
                            top: 18px;
                        }

                        &:nth-child(3) {
                            top: 20px;
                        }

                        &:nth-child(4) {
                            top: 26px;
                        }
                    }

                    .language-label {
                        font-size: 14px;
                    }

                    // === [ X TILL HAMBURGARE ] =========================================
                    &.open {
                        .icon-bar {
                            left: rem(15);
                            width: rem(16);
                            height: rem(3);

                            &:nth-child(2) {
                                top: rem(18);
                            }

                            &:nth-child(4) {
                                top: rem(25);
                            }
                        }

                        &.collapsed {
                            .icon-bar {
                                left: rem(12);
                                width: rem(22);
                                height: rem(3);

                                &:nth-child(2) {
                                    top: rem(20);
                                    width: rem(18);
                                    height: rem(2);
                                }

                                &:nth-child(3) {
                                    top: rem(21);
                                    width: rem(18);
                                    height: rem(2);
                                }

                                &:nth-child(4) {
                                    top: rem(26);
                                    width: rem(18);
                                    height: rem(2);
                                }
                            }
                        }
                    }

                    // ===================================================================
                    // === [ BETA ] ===
                    // ===================================================================
                    &.ik-beta {
                        background-position: 35px 20px;

                        &::after {
                            top: 25px;
                            left: 54px;
                            font-size: 9px;
                        }

                        &.ik-inloggad {
                            background-position: 35px 19px;
                            background-size: 14px 14px;
                        }
                    }
                }

                // === [ LÄNK » MINA SIDOR ] =============================================
                .mina-sidor {
                    &.hanglas-oppen {
                        background-position: center 20px;
                    }

                    &.hanglas-oppen-oppnasidor {
                        background-position: center 20px;
                    }
                }

                // === [ ALECTA LOGGA ] ==================================================
                .navbar-brand {
                    left: rem(25);
                    width: rem(165);
                    height: rem(70);
                    background-position: center 17px;
                    background-size: 135px 36px;
                }
            }
        }
    }
}

// =======================================================================================
// Min-width 992px
// =======================================================================================
@media (min-width: 992px) {
    .navbar {
        .container-fluid {

            // === [ VATTENDELARE ] ======================================================
            &:first-of-type {
                .navbar-nav {
                    margin: 0;
                }
            }
        }
    }
}
