﻿/// <reference path="../base/mixins" />

// =======================================================================================
// === [ Koldioxidavtryck block ] ===
// =======================================================================================
.koldioxidavtryckblock {
    margin-bottom: 2rem;

    .animering {
        width: 300px;
        height: 250px;
        margin: auto;

        #planet-idle,
        #planet-activated,
        #planet-end {
            position: absolute;
            top: 0;
            width: 300px;
            height: 250px;
            opacity: 1;

            &.stopp {
                opacity: 0;
            }
        }
    }

    .animering-alecta-belopp,
    .animering-index-belopp {
        @include alecta-font($alecta-freight, 500, normal);
        position: absolute;
        top: 60px;
        left: calc(50% - 112px);
        z-index: 60;
        width: 100px;
        color: $white;
        font-size: 20px;
        text-align: center;
        white-space: nowrap;
        opacity: 0;
    }

    .animering-index-belopp {
        top: 50px;
        left: calc(50% - 10px);
        width: 140px;
        font-size: 34px;
        font-weight: 300;
    }

    .animerings-info {
        @include alecta-font($alecta-freight, 500, normal);
        width: 100%;
        height: 13px;
        margin: 1rem auto;
        font-size: 16px;
        line-height: 18px;
        letter-spacing: 0;
        text-align: center;
    }

    form {
        background-color: transparent;

        .form-input {

            .Form__Element {
                margin-bottom: 1.8rem;

                .Form__Element__Caption {
                    left: 3.4rem;
                }
            }
        }
    }
}

// =======================================================================================
// Min-width 768px
// =======================================================================================
@media (min-width: 768px) {
    .koldioxidavtryckblock {

        form {
            padding-left: 0;

            .knapp-centrerad {
                margin-left: 2.6rem;
            }
        }
    }
}

