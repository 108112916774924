﻿
// =======================================================================================
// === [ OMSLUTARE RUNT OM SVG-FILEN ] ===
// =======================================================================================
#highcharts-ihevako {
    .highcharts-color-0 {
        fill: $alecta-bla-3;
        stroke-width: 0;
    }
}
