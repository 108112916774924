﻿
// =======================================================================================
// === [ Slideshow ] ===
// =======================================================================================

.slideshow-stor {
    #innehall {
        overflow: hidden;
    }

    section {
        padding: 0;
    }

    .slideshow {
        float: left;
        width: 100%;
        margin-bottom: 12rem;

        .stornyhetspuffblock {
            display: none;

            .slick-initialized & {
                display: inline-block;
            }
        }

        .slick-dotted {
            &.slick-slider {
                margin-bottom: 3rem;
            }
        }

        .slick-dots {
            position: absolute;
            display: flex;
            flex-direction: row;
            justify-content: center;
            width: 100%;
            margin: -7.2rem auto auto;
            padding: 0;
            text-align: center;
            overflow: hidden;
            list-style: none;

            li {
                position: relative;
                display: inline-block;
                width: 8rem;
                margin: 0;
                padding: 0 1rem 4rem 0;
                cursor: pointer;

                &::after {
                    content: none;

                }

                .tumnagel-cirkel {
                    position: relative;
                    top: 4rem;
                    flex-grow: 0;
                    flex-shrink: 0;
                    width: 6rem;
                    height: 6rem;
                    margin: 0 auto 3.5rem;
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: cover;
                    border-radius: 50%;
                }

                .namn {
                    display: none;
                }

                .titel {
                    display: none;
                }

                &.slick-active {
                    width: 14rem;
                    padding-right: 0;

                    .tumnagel-cirkel {
                        top: 3rem;
                        box-shadow: 1px 2px 4px 0 $bild-mask-bla;
                        width: 8rem;
                        height: 8rem;
                    }

                    .namn {
                        @include alecta-font($alecta-freight, 300, normal);
                        display: block;
                        color: $default-font-color;
                        font-size: 1.8rem;
                        font-weight: 500;
                        line-height: 2.1rem;
                        letter-spacing: 0;
                        border: 0;

                        &:hover,
                        &:active,
                        &:focus {
                            border: 0;
                            outline: none;
                        }
                    }

                    .titel {
                        @include alecta-font($alecta-freight, 300, normal);
                        display: block;
                        color: $default-font-color;
                        font-size: 1.4rem;
                        font-weight: 500;
                        line-height: 1.6rem;
                        letter-spacing: 0;
                        text-transform: uppercase;
                    }
                }
            }
        }

        .slick-prev,
        .slick-next {
            position: absolute;
            top: 22rem;
            z-index: 100;
            display: block;
            width: 5.4rem;
            height: 5.4rem;
            padding: 0;
            color: transparent;
            font-size: 0;
            background: transparent;
            background-repeat: no-repeat;
            border: 0;
            outline: none;
            transform: translate(0, -50%);

            &:hover,
            &:focus {
                &::before {
                    opacity: 1;
                }
            }

            &::before {
                content: none;
                opacity: .3;
            }
        }

        .slick-next {
            @include bg-img('../img/arrow-right.svg', null, null, null);
            right: 1rem;
        }

        .slick-prev {
            @include bg-img('../img/arrow-left.svg', null, null, null);
            left: 1rem;
        }

        .slick-track {
            height: 100%;
        }

        .slick-slide {
            opacity: .1;
        }

        .slick-active {
            opacity: 1;
        }

        .carousel-control {
            background-image: none;
        }
    }
}

// =======================================================================================
// Min-width 768px
// =======================================================================================
@media (min-width: 768px) {
    .slideshow-stor {

        .slideshow {
            margin-bottom: 0;

            .slick-dots {
                position: static;
                display: flex;
                flex-direction: row;
                justify-content: center;
                width: auto;
                margin: -7rem auto auto;

                li {
                    width: 14rem;
                    padding: 0 0 4rem;

                    .namn {
                        @include alecta-font($alecta-freight, 500, normal);
                        display: block;
                        font-size: 1.8rem;
                        line-height: 2.1rem;
                        letter-spacing: 0;
                        border: 0;

                        &:hover,
                        &:active,
                        &:focus {
                            border: 0;
                        }
                    }

                    .titel {
                        @include alecta-font($alecta-freight, 500, normal);
                        display: block;
                        color: $inaktiverad-text;
                        font-size: 1.4rem;
                        line-height: 1.6rem;
                        letter-spacing: 0;
                        text-transform: uppercase;
                    }

                    .tumnagel-cirkel {
                        top: 2rem;
                        margin: 2rem auto 4.5rem;
                    }

                    &.slick-active {
                        .tumnagel-cirkel {
                            top: 0;
                            width: 10rem;
                            height: 10rem;
                            margin: 2rem auto .5rem;
                        }

                        .namn {
                            color: $default-font-color;
                        }

                        .titel {
                            color: $default-font-color;
                        }
                    }
                }
            }

            .slick-next {
                right: 3.9rem;
            }

            .slick-prev {
                left: 3.9rem;
            }
        }
    }
}
// =======================================================================================
// Min-width 992px
// =======================================================================================
@media (min-width: 992px) {
    .slideshow-stor {

        #innehall {
            margin-top: 0;
        }
    }
}

// =======================================================================================
// Min-width 1200px
// =======================================================================================
@media (min-width: 1200px) {
    .fullbredd {
        .slideshow-stor {
            .slideshow {
                .slick-next {
                    right: calc(100%/ 2 - 600px);
                }

                .slick-prev {
                    left: calc(100% / 2 - 600px);
                }
            }
        }
    }
}




