﻿
// =======================================================================================
// === [ HÄNVISNINGSPUFF MED BILD ] ===
// =======================================================================================
.hanvisningspuffmedbildblock {
    display: inline-block;
    float: none;
    margin-right: 0;
    margin-bottom: $block-margin-bottom-320;
    margin-left: 0;
    padding: 0 rem(25);

    .a-hanvispuff-bild {
        display: block;
        height: 20rem;
        padding: 0;
        overflow: hidden;

        .a-hanvispuff-bild-image {
            position: relative;
            display: none;
        }

        .a-hanvispuff-bild-text {
            position: relative;
            top: 50%;
            display: inline-block;
            padding: 0 $margin-right-left-320;
            transform: translateY(-50%);

            h2 {
                margin-right: 0;
                margin-bottom: .5rem;
                margin-left: 0;
                padding: 0;
                color: $white;
                font-size: rem(32);
                font-weight: 400;
                line-height: rem(33);
            }

            p {
                @include alecta-font($alecta-freight, 400, normal);
                margin-right: 0;
                margin-bottom: 1rem;
                margin-left: 0;
                padding: 0;
                color: $white;
            }
        }

        // === [ BILDEN TILL HÖGER ] =====================================================
        &.a-hanvispuff-bild-right {
            .a-hanvispuff-bild-image {
                float: right;
            }

            .a-hanvispuff-bild-text {
                float: left;
            }
        }
    }

    // === [ 50% (UTAN BILD) ] ===========================================================
    &.col-sm-6,
    &.col-sm-5,
    &.col-sm-4,
    &.col-sm-3,
    &.col-sm-2,
    &.col-sm-1 {
        width: auto;

        .a-hanvispuff-bild {
            height: 20rem;
            padding: 0;
            overflow: hidden;

            .a-hanvispuff-bild-image {
                display: none;
            }

            .a-hanvispuff-bild-text {
                width: 100%;
            }
        }
    }
}

.accordion-inner {
    .hanvisningspuffmedbildblock {
        margin-right: 0;
        margin-left: 0;
    }
}

// =======================================================================================
// Min-width 768px
// =======================================================================================
@media (min-width: 768px) {
    .hanvisningspuffmedbildblock {
        margin-right: 0;
        margin-left: 0;
        padding-right: $margin-right-left-768;
        padding-left: $margin-right-left-768;

        .a-hanvispuff-bild {
            .a-hanvispuff-bild-image {
                display: block;
                height: 100%;
                padding: 0;

                img {
                    width: 100%;
                    height: auto;
                    margin: 0;
                }
            }

            &.utfallande-bild {
                overflow: visible;

                .a-hanvispuff-bild-image {
                    text-align: right;

                    img {
                        position: absolute;
                        right: 0;
                        bottom: 0;
                        display: inline-block;
                        width: unset;
                        height: unset;
                        max-width: 100%;
                        max-height: calc(100% + 20px);
                    }
                }
            }
        }

        // === [ 50% (UTAN BILD) ] =======================================================
        &.col-sm-6,
        &.col-sm-5,
        &.col-sm-4,
        &.col-sm-3,
        &.col-sm-2,
        &.col-sm-1 {
            width: 49.6%;

            &:nth-of-type(odd) {
                padding-left: 0;
            }

            &:nth-of-type(even) {
                padding-right: 0;
            }
        }

        &.col-sm-12 {
            display: block;
        }
    }

    .accordion-inner {
        .hanvisningspuffmedbildblock {
            padding-right: 0;
            padding-left: 0;
        }
    }
}

// =======================================================================================
// Min-width 992px
// =======================================================================================
@media (min-width: 992px) {
    .hanvisningspuffmedbildblock {
        padding-right: 0;
        padding-left: $margin-right-left-992;

        .a-hanvispuff-bild {
            &.utfallande-bild {
                margin-top: rem(40);
            }
        }

        // === [ 50% (UTAN BILD) ] =======================================================
        //&.col-sm-6,
        //&.col-sm-5,
        //&.col-sm-4,
        //&.col-sm-3,
        //&.col-sm-2,
        //&.col-sm-1 {
        //    width: calc(50% + 1rem);

        //    &:nth-of-type(odd) {
        //        width: calc(50% - 1rem);
        //    }
        //}
    }
}
