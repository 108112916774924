﻿
// =======================================================================================
// === [ FORMULÄR ] ===
// =======================================================================================
form {
    margin-bottom: $block-margin-bottom-320;
    padding: 2.5rem 0;
    background-color: $form-tagg;

    > .Form__Element {
        margin-right: 25px;
        margin-left: 25px;
        padding-right: 0;
        padding-left: 0;

        input {
            &[type='text'] {
                width: 100%;
            }
        }

        .selectric-wrapper {
            width: 100% !important;

            .selectric {
                width: 100%;
            }
        }
    }

    // === [ BESKRIVANDE TEXT EFTER KNAPP-TRYCKNING ] ====================================
    div {
        p {
            span {
                @include alecta-font($alecta-freight, 400, normal);
                display: block;
                padding: 2rem 0 0;
                font-size: 1.5rem;
                line-height: 1.8rem;
                text-align: center;
            }
        }
    }

    // === [ STEG "FÖRGÅENDE" "NÄSTA" ] ==================================================
    .Form__Description {
        margin-left: 1.5rem;
    }

    .Form__MainBody {
        padding: 0;

        .FormStep {
            padding: 0;

            .FormStep__Title {
                font-size: rem(15);
            }
        }

        .Form__Element {
            .FormParagraphText {
                padding: 0;

                p {
                    margin-right: 0;
                    margin-left: 0;
                }
            }
        }

        .Form__NavigationBar {
            @include border(top, solid 1px, $form-steg-border);
            position: relative;
            height: 14rem;

            .btnPrev,
            .btnNext {
                @include border(all, solid 0, $alecta-bla-2);
                @include border-radius(2rem);
                position: absolute;
                top: 5rem;
                left: $margin-right-left-320;
                width: auto;
                height: auto;
                max-width: none;
                margin: 0 !important;
                padding: .7rem $margin-right-left-320;
                color: $white;
                font-size: 1.5rem;
                background-color: $alecta-bla-2;
                background-image: none;

                &:disabled {
                    @include border(all, solid 0, $form-btn-disable);
                    color: $form-btn-disable-text;
                    background-color: $form-btn-disable;
                }
            }

            .btnNext {
                right: $margin-right-left-320;
                left: auto;
            }

            .Form__NavigationBar__ProgressBar {
                display: none;
            }
        }
    }
}

.Form__Element {
    position: relative;
    width: 100%;
    margin-bottom: 31px;
    padding-right: $margin-right-left-320;
    padding-left: $margin-right-left-320;

    &.error {
        label {
            color: $form-input-error;
        }

        input {
            &[type='text'],
            &[type='email'],
            &[type='tel'],
            &[type='number'] {
                @include border(bottom, solid 1px, $form-input-error);
            }
        }
    }

    .field-validation-error {
        display: block;
        padding-top: rem(3);
        color: $form-input-error;
        font-size: rem(14);
        font-style: normal !important;
        line-height: rem(14);
        text-align: left;
    }


}

// === [ MEJLA OSS - PRIVATKUND ] ========================================================
.formcontainerblock {
    form {
        padding-bottom: 30px;
    }

    .Form__MainBody {
        padding: 0 20px;

        .Form__Element {
            margin-bottom: rem(37);
            padding: 0;
            vertical-align: top;
        }

        .FormChoice {
            margin-bottom: 10px;
            padding-top: 10px;

            .Form__Element__Caption {
                padding-bottom: 0;
                border: 0;
            }
        }

        button {
            &[type='submit'] {
                margin: 0 auto rem(15);
                padding: 7px 25px;
            }
        }
    }
}

// =======================================================================================
// === [ GENERELL WRAPPER FÖR ALLA FORMULÄR ] ===
// =======================================================================================
.formular {
    position: relative;
}

// =======================================================================================
// === [ LABEL ] ===
// =======================================================================================
label {
    position: absolute;
    top: 18px;
    left: 10px;
    margin: 0;
    color: $form-label-text;
    font-size: 16px;
    font-weight: 300;
    line-height: 20px;
    cursor: text;
    transition: .3s ease;

    &.active {
        top: 11px !important;
        color: $ik-label-farg;
        font-size: 10px;
        line-height: 12px;

        &.focusIn {
            color: $ik-label-farg-fokus;
        }
    }

    .asterix {
        color: $form-input-error;
        font-style: normal !important;
    }
}

// =======================================================================================
// === [ INPUT[TYPE=TEXT], INPUT[TYPE=EMAIL] ] ===
// =======================================================================================
input {
    &[type='text'],
    &[type='email'],
    &[type='password'],
    &[type='tel'],
    &[type='number'] {
        @include border(bottom, solid 2px, $trans);
        @include border-radius(4px 4px 0 0);
        width: 100%;
        height: 58px;
        padding: 12px 0 0 10px;
        color: $default-font-color;
        font-size: 16px;
        font-weight: 300;
        line-height: 18px;
        background-color: $ik-textfalt-bg;
        border-top: 0;
        border-right: 0;
        border-left: 0;
        transition: .3s ease;

        &:focus {
            @include border(bottom, solid 2px, $ik-textfalt-kant-fokus);
        }

        &:hover,
        &.sok-over {
            @include border(bottom, solid 2px, $ik-textfalt-kant-hover);
        }

        &.validation-error {
            @include border(bottom, solid 2px, $form-input-error);
        }
    }

    // === [ INPUT » INAKTIVERAD ] =======================================================
    &[disabled] {
        color: $ik-textfalt-font-disable;

        &:hover {
            @include border(bottom, solid 2px, $trans);
        }
    }

    &::-webkit-input-placeholder,
    &:-moz-placeholder,
    &::-moz-placeholder,
    &:-ms-input-placeholder {
        color: $grey-medium;
    }

    // === [ FORM VALIDERING ] ===========================================================
    &.input-validation-error {
        border-bottom: $form-input-error solid 2px !important;

        &:focus {
            border-bottom: $form-input-error solid 2px !important;
        }

        &[style='visibility: hidden;height: 0'],
        &[style='visibility:hidden;height:0'] {
            padding: 0 !important;
            border: 0 !important;
        }
    }

    // === [ TAR BORT ÖKA/MINSKA "PILAR" I ETT NUMMERFÄLT ] ==============================
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        margin: 0;
        -webkit-appearance: none;
    }
}

// =======================================================================================
// === [ ERROR INPUT ] ===
// =======================================================================================
.falt-valliderings-fel {

    // === [ ERROR » LABEL ] =============================================================
    label {
        color: $ik-label-farg-error;

        &.active {
            color: $ik-label-farg-error;

            &.focusIn {
                color: $ik-label-farg-error;
            }
        }
    }

    // === [ ERROR » INPUT ] =============================================================
    input {
        @include border(bottom, solid 2px, $ik-textfalt-kant-error);
    }
}

.form-group {

    // === [ ERROR MEDDELANDE (UNDER INPUT FÄLTET) ] =====================================
    .field-validation-error {
        display: block;
        padding: rem(5) 0 0 rem(12) !important;
        color: $ik-span-farg-error;
        font-size: rem(12);
        font-weight: 300;
        line-height: rem(15);

        span {
            font-size: rem(12) !important;
            font-weight: 300 !important;
            line-height: rem(15) !important;
            text-align: left;
        }
    }

    // === [ INPUT » INAKTIVERAD ] =======================================================
    &.falt-inaktiverad,
    &.disabled {
        label {
            color: $ik-textfalt-font-disable;
            cursor: default;

            &.active {
                color: $ik-textfalt-font-disable;

                &.focusIn {
                    color: $ik-textfalt-font-disable;
                }
            }

            &::selection {
                background: $trans;
            }

            &::-moz-selection {
                background: $trans;
            }
        }

        .info-hjalp {
            color: $ik-textfalt-font-disable;
        }
    }
}

// =======================================================================================
// === [ HJÄLPTEXT (UNDER INPUT FÄLTET)  ] ===
// =======================================================================================
.info-hjalp {
    display: block;
    width: auto;
    padding: 1px 0 0 11px !important;
    color: $ik-text-hjalp-text;
    font-size: rem(12) !important;
    font-weight: 300 !important;
    line-height: rem(15) !important;

    .info-hjalp {
        padding-left: 0 !important;
    }

    .field-validation-error {
        padding: 0 !important;
    }
}

// =======================================================================================
// === [ TEXTAREA ] ===
// =======================================================================================
//textarea {
//    @include border(bottom, solid 1px, $form-input-linje);
//    @include border-radius(0);
//    @include box-shadow(0, 0, 0, rgba(0, 0, 0, 0));
//    display: block;
//    width: 100%;
//    height: rem(35);
//    padding: rem(13) 0 rem(1);
//    color: $form-input-text;
//    font-size: 1.9rem;
//    font-weight: 400;
//    line-height: 2rem;
//    background-color: transparent;
//    border-top: 0;
//    border-right: 0;
//    border-left: 0;
//    overflow: hidden;

//    &:focus {
//        @include border(bottom, solid 1px, $form-input-linje-focus);
//        @include box-shadow(0, 0, 0, rgba(0, 0, 0, 0));
//        outline: none;
//    }

// === [ FORM VALIDERING ] ===========================================================
//    &.input-validation-error {
//        @include border(bottom, solid 2px, $form-input-error);

//        &:focus {
//            @include border(bottom, solid 2px, $form-input-error);
//        }
//    }
//}

.form-textarea {
    label {
        top: 12px;
        text-align: left;

        &.active {
            top: 0 !important;
            right: rem(20);
            left: 0;
            padding: rem(9) 0 rem(1) rem(12);
            background-color: $ik-textfalt-bg;
        }
    }

    &.falt-valliderings-fel {
        textarea {
            @include border(bottom, solid 2px, $rod-murk);
        }
    }
}

textarea {
    @include border(bottom, solid 2px, $trans);
    @include border-radius(.4rem .4rem 0 0);
    width: 100%;
    //height: 0 !important;
    min-height: 160px;
    padding: 25px 10px 12px;
    color: $standard-font-farg;
    font-size: rem(16);
    font-weight: 300;
    line-height: rem(20);
    background-color: $ik-textfalt-bg;
    border: 0;
    overflow: auto;

    &:focus {
        @include border(bottom, solid 2px, $ik-textfalt-kant-fokus);
    }
}

// =======================================================================================
// === [ CHECKBOX/RADIOBUTTON ] ===
// =======================================================================================
.FormChoice {
    .Form__Element__Caption {
        display: inline-block;
        padding-bottom: rem(10);
        color: $form-label-text;
        font-size: rem(19);
        line-height: rem(20);

        .asterix {
            color: $form-input-error;
        }
    }

    label {
        position: relative;
        top: 0;
        left: 0;
        z-index: 1; // 2020-02-07 - FÖR ATT TABBNING SKA FUNGERA
        display: block;
        margin: rem(3) 0 rem(24);
        padding: 0;
        padding-right: 3px; // 2020-02-07 - FÖR ATT TABBNING SKA FUNGERA
        color: $form-input-text;
        cursor: default;

        .alecta-checkbox,
        .alecta-radiobutton {
            top: 2px;
            left: 0;
            display: inline-block;
            width: 15px;
            height: 15px;
        }

        // === [ CHECKBOX ] ==============================================================
        .alecta-checkbox {
            @include border-radius(2px);
            position: static !important; // 2020-02-07 - FÖR ATT TABBNING SKA FUNGERA
            display: inline-block; // 2020-02-07 - FÖR ATT TABBNING SKA FUNGERA
            width: 15px;
            height: 15px;
            margin-right: 2px; // 2020-02-07 - FÖR ATT TABBNING SKA FUNGERA
            margin-bottom: -2px; // 2020-02-07 - FÖR ATT TABBNING SKA FUNGERA
            background-image: none;
            border: solid 1px $alecta-bla-2;

            &.checked {
                background-color: $alecta-bla-2;
                background-image: url('/gui/img/ikon-check.png');
                background-position: center center;
                background-repeat: no-repeat;
                background-size: 19px 19px;
            }
        }

        // === [ RADIOBUTTON ] ===========================================================
        .alecta-radiobutton {
            @include border-radius(50%);
            position: static; // 2020-02-07 - FÖR ATT TABBNING SKA FUNGERA
            display: inline-block; // 2020-02-07 - FÖR ATT TABBNING SKA FUNGERA
            margin-right: 2px; // 2020-02-07 - FÖR ATT TABBNING SKA FUNGERA
            margin-bottom: -2px; // 2020-02-07 - FÖR ATT TABBNING SKA FUNGERA
            background-image: none;
            border: solid 1px $alecta-bla-2;

            &.checked {
                background-image: none;
                border: solid 1px $alecta-bla-2;
                border-width: 5px !important;
            }
        }
    }
}

[data-whatinput='keyboard'] {
    .FormChoice {
        label {
            // === [ CHECKBOX ] ==========================================================
            .alecta-checkbox {
                // TAB CHECKBOX ==========================================================
                &.focus {
                    &::after {
                        content: '';
                        position: absolute;
                        top: 0;
                        right: 0;
                        bottom: 0;
                        left: 18px;
                        z-index: -1;
                        background-color: $tab-default-color;
                    }
                }
            }

            // === [ RADIOBUTTON ] =======================================================
            .alecta-radiobutton {
                // TAB RADIOBUTTON =======================================================
                &.focus {
                    &::after {
                        content: '';
                        position: absolute;
                        top: 0;
                        right: 0;
                        bottom: 0;
                        left: 18px;
                        z-index: -1;
                        background-color: $tab-default-color;
                    }
                }
            }
        }
    }
}

// =======================================================================================
// === [ DROPNERMENY ] ===
// =======================================================================================
.form-dropnermeny,
.FormSelection {
    width: auto;
    padding: 0 0 6px;

    .selectric-wrapper {
        position: relative;
        display: block;
        cursor: pointer;

        // === [ DROPNERMENY » DÖJLER SELECT ] ===========================================
        .selectric-hide-select {
            position: relative;
            width: 0;
            height: 0;
            overflow: hidden;

            &.selectric-is-native {
                position: absolute;
                z-index: 10;
                width: 100%;
                height: 100%;

                select {
                    position: absolute;
                    top: 0;
                    right: 0;
                    left: 0;
                    z-index: 1;
                    box-sizing: border-box;
                    width: 100%;
                    height: 100%;
                    font-size: 16px;
                    border: 0;
                    opacity: 0;
                }
            }
        }

        // === [ DROPNERMENY » SYNLIGT DEL MED PIL ] =====================================
        .selectric {
            @include border-radius(.4rem .4rem 0 0);
            position: relative;
            width: auto;
            height: 56px;
            padding: rem(18) rem(15) 0 rem(14);
            background-color: $ik-textfalt-bg;

            // === [ DROPNERMENY » SYNLIGT DEL MED PIL » TEXTEN SOM SYNS I FÄLTET ] ======
            .label {
                @include text-truncate;
                display: block;
                width: auto;
                color: $standard-font-farg;
                font-size: rem(16);
                font-weight: 300;
                line-height: rem(20);
                user-select: none;
                -moz-user-select: none;
                -ms-user-select: none;
                -webkit-user-select: none;
            }

            // === [ DROPNERMENY » SYNLIGT DEL MED PIL » PIL TILL HÖGER ] ================
            .button {
                @include border(all, solid 6px, $ik-oversikt-ikon-farg);
                position: absolute;
                top: rem(26);
                right: rem(10);
                display: block;
                width: rem(9);
                height: rem(5);
                text-indent: rem(-100);
                border-bottom: 0;
                border-right-color: $trans;
                border-left-color: $trans;
                overflow: hidden;
            }
        }

        &.selectric-open {
            z-index: 9999;

            .selectric-items {
                display: block;
                height: auto !important;
            }
        }
    }

    // === [ DROPNERMENY » DROPNERDELEN ] ================================================
    .selectric-above {
        .selectric-items {
            top: auto;
            bottom: 100%;
        }
    }

    .selectric-items {
        @include box-shadow(0, .3rem, .3rem, rgba(176, 176, 176, .5));
        position: absolute;
        top: 100%;
        left: 0;
        z-index: -1;
        display: none;
        background-color: $white;

        .selectric-scroll {
            height: 100%;
            max-height: 400px;
            overflow: auto;
        }

        ul,
        li {
            min-height: rem(20);
            margin: 0;
            padding: 0;
            list-style: none;
        }

        li {
            @include alecta-font($alecta-freight, 400, normal);
            @include border(bottom, solid 1px, $ik-textfalt-bg);
            display: block;
            padding: 18px 10px 20px;
            color: $default-font-color;
            font-size: rem(16);
            font-weight: 300;
            line-height: rem(18);
            cursor: pointer;

            &[data-index='0'] {
                @include border(top, solid 2px, $standard-lank-farg);
            }

            &.selected {
                background-color: $ik-textfalt-bg;
            }

            &.highlighted {
                background-color: $ik-textfalt-bg;
            }

            &:hover {
                background-color: $ik-textfalt-bg !important;
            }
        }

        .selectric-group {
            li {
                padding-left: 21px;

                &.selectric-group-label {
                    padding: 20px 11px;
                    color: $inaktiverad-text;
                    font-size: 13px;
                    font-weight: 600;
                    line-height: 17px;
                    text-transform: uppercase;
                }
            }

        }

        ul {
            &:hover {
                li {
                    &.highlighted {
                        color: $standard-font-farg;
                        background-color: $trans;
                    }
                }
            }
        }
    }

    // === [ DROPNERMENY » SISTA TAGGEN?!?!?!?!? ] =======================================
    .selectric-input {
        position: absolute;
        top: 0;
        left: 0;
        width: rem(1);
        height: rem(1);
        margin: 0;
        padding: 0;
        background: none;
        border: 0;
        overflow: hidden;
        clip: rect(0, 0, 0, 0);
        outline: none;
    }

    .selectric-responsive {
        width: 100%;
    }

    .selectric-disabled {
        cursor: default;
        opacity: .5;
        filter: alpha(opacity=50);
        user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        -webkit-user-select: none;
    }

    .selectric-temp-show {
        position: absolute;
        display: block;
        visibility: hidden;
    }

    // === [ ERROR ] =====================================================================
    &.falt-valliderings-fel {
        .selectric-wrapper {
            .selectric {
                @include border(bottom, solid 2px, $rod-murk);
            }
        }
    }
}

.pensionsplaneraren-forsakring {
    .FormSelection {
        .selectric-wrapper {
            .selectric {
                background-color: $ik-textfalt-rakna-bg;
            }
        }
    }

    &.uttag-ej-mojligt {
        .FormSelection {
            .selectric-wrapper {
                .selectric {
                    background-color: $select-uttag-ej-mojlig;
                }
            }
        }
    }
}

// =======================================================================================
// === [ KNAPP ] ===
// =======================================================================================
button,
input {
    &[type='submit'],
    &[type='button'] {
        @include border(all, solid 0, $alecta-bla-2);
        @include border-radius(2rem);
        position: relative;
        display: block;
        height: auto;
        margin: 1rem auto 1.5rem;
        padding: .7rem $margin-right-left-320;
        color: $white;
        font-size: 1.5rem;
        background-color: $alecta-bla-2;

        &:hover {
            color: $white;
            background-color: lighten($alecta-bla-2, 10%);
        }

        &:focus {
            color: $white;
            background-color: darken($alecta-bla-2, 10%);
        }

        &.active-alecta-gron {
            background-color: $alecta-gron;
        }

        &.active-alecta-orange {
            background-color: $alecta-orange;
        }

        &:disabled,
        &.disabled {
            @include border(all, solid 0, $form-btn-disable);
            color: $form-btn-disable-text;
            background-color: $form-btn-disable;
        }

        &.knapp-laddar {
            text-indent: rem(-500);
            overflow: hidden;

            &::after {
                @include bg-img('../img/ikon-spinner-laddar-btn.svg', center center, no-repeat, 13px 13px);
                content: '';
                position: absolute;
                top: rem(12);
                left: 50%;
                width: rem(13);
                height: rem(13);
                margin-left: rem(-7);
                animation: spin 500ms infinite linear;
                -ms-animation: spin 500ms infinite linear;
                -webkit-animation: spinw 500ms infinite linear;
                -moz-animation: spinm 500ms infinite linear;
            }
            @keyframes spin {
                from { transform: scale(1) rotate(0deg);}
                to { transform: scale(1) rotate(360deg);}
            }

            @-webkit-keyframes spinw {
                from { -webkit-transform: rotate(0deg);}
                to { -webkit-transform: rotate(360deg);}
            }

            @-moz-keyframes spinm {
                from { -moz-transform: rotate(0deg);}
                to { -moz-transform: rotate(360deg);}
            }
        }

        &.knapp-bla {
            background: $alecta-bla-2;
            border: 1px solid $alecta-bla-2;
            box-sizing: border-box;
            border-radius: 3px;
            color: $vit;
            font-style: normal;
            font-weight: normal;
            font-size: rem(16);
            line-height: 19px;
            text-align: center;
            padding: 17px 20px 16px;

            &:hover {
                background: $alecta-bla-2;
                color: $vit;
                text-decoration: none;
            }
        }

        &.knapp-outline {
            background: transparent;
            border: 1px solid $alecta-bla-1;
            box-sizing: border-box;
            border-radius: 3px;
            color: $alecta-bla-1;
            font-style: normal;
            font-weight: 500;
            font-size: rem(16);
            line-height: 19px;
            text-align: center;
            padding: 17px 20px 16px;

            &:hover {
                background: transparent;
                color: $alecta-bla-1;
                text-decoration: none;
            }
        }
    }
}

// =======================================================================================
// === [ TACK FÖR DIN ANMÄLAN ] ===
// =======================================================================================
.EPiServerForms {
    .Form__Status {
        .Form__Success__Message {
            margin: 0;
            padding: 0;
            color: $default-font-color;
            background-color: transparent;
        }

        .Form__Status__Message {
            &.hide {
                display: none !important;
            }
        }
    }

    .Form__Element {
        .Form__Element__ValidationError {
            font-size: 15px;
        }
    }
}

// =======================================================================================
// Min-width 768px
// =======================================================================================
@media (min-width: 768px) {

    // === [ FORM-TAGG RUNT ALLA FÄLT OCH KNAPPAR ] ======================================
    form {

        // === [ BESKRIVANDE TEXT EFTER KNAPP-TRYCKNING ] ================================
        div {
            p {
                span {
                    font-size: 1.8rem;
                    line-height: 2.4rem;
                    text-align: left;
                }
            }
        }

        // === [ STEG "FÖRGÅENDE" "NÄSTA" ] ==============================================
        .Form__MainBody {
            .Form__NavigationBar {
                .btnPrev {
                    left: $margin-right-left-768;
                }

                .btnNext {
                    right: $margin-right-left-768;
                }
            }
        }
    }

    // === [ MEJLA OSS - PRIVATKUND ] ====================================================
    .formcontainerblock {
        form {
            padding-bottom: 0;
        }

        .Form__MainBody {
            padding: 0 30px;

            .FormStep {
                margin-bottom: 20px;

                aside {
                    display: none;
                }

                // === [ TEXT INPUT / DROPDOWNMENU ] =====================================
                div {
                    &.Form__Element {
                        display: inline-block;
                        width: 50%;
                        padding-right: 10px;
                        padding-left: 10px;

                        label {
                            left: 20px;
                        }

                        input {
                            width: 100%;
                        }

                        .selectric-wrapper {
                            .selectric {
                                top: 1px;
                                padding: 20px 15px 0 10px;
                            }
                        }

                        button {
                            &[type='submit'] {
                                display: block;
                                padding-right: 20px;
                                padding-left: 20px;
                            }
                        }

                        &.FormSubmitButton {
                            display: block;
                            width: auto;
                            margin-left: 10px;
                            padding: 7px 25px;
                        }
                    }

                    // === [ RADIOKANPPAR / CHECKBOXAR ] =================================
                    &.FormChoice {
                        width: 100%;

                        label {
                            left: 0;
                        }
                    }

                    // === [ TEXTAREA ] ==================================================
                    &.FormTextbox--Textarea { // sass-lint:disable-line no-vendor-prefixes
                        width: 100%;
                    }
                }

                button {
                    &[type='submit'] {
                        margin-left: 10px;
                    }
                }

                .FormParagraphText {
                    width: 100%;
                }
            }

            .FormTextbox--Textarea { // sass-lint:disable-line no-vendor-prefixes
                display: block;
                width: 93%;
                margin-bottom: 30px;

                textarea {
                    min-height: 60px;
                }
            }

            .FormChoice {
                display: block;
                width: 100%;
                margin-bottom: 10px;
            }

            button {
                width: auto !important;

                &[type='submit'] {
                    margin: 0 rem(15) rem(40) 0;
                }
            }
        }
    }

    // === [ DROPDOWN ] ==================================================================
    .FormSelection {
        .Form__Element__Caption {
            left: $margin-right-left-768;
        }
    }

    // === [ CHECKBOX/RADIOBUTTON ] ======================================================
    .FormChoice {
        label {
            left: 0;
        }
    }

    // === [ INPUT[TYPE=TEXT], INPUT[TYPE=EMAIL] ] =======================================
    button,
    input {
        &[type='text'],
        &[type='email'],
        &[type='password'],
        &[type='tel'],
        &[type='number'] {
            width: 21rem;
        }

        // === [ KNAPP ] =================================================================
        &[type='submit'],
        &[type='button'] {
            margin: 1rem 0  1.5rem $margin-right-left-768;
            padding: .7rem $margin-right-left-768;
        }
    }

    [type='reset'] {
        margin: 1rem 0 0 $margin-right-left-768 !important;
        padding: .7rem $margin-right-left-768;
    }

    .Form__Element {
        width: auto;
    }
}

.formcontainerblock {
    .Form__MainBody {
        .FormStep {
            div {
                &.Form__Element {
                    width: 100%;
                }
            }
        }
    }
}

// =======================================================================================
// Min-width 992px
// =======================================================================================
@media (min-width: 992px) {

    // === [ FORM-TAGG RUNT ALLA FÄLT OCH KNAPPAR ] ======================================
    form {

        // === [ STEG "FÖRGÅENDE" "NÄSTA" ] ==============================================
        .Form__MainBody {
            .Form__NavigationBar {
                .btnPrev {
                    left: $margin-right-left-992;
                }

                .btnNext {
                    right: $margin-right-left-992;
                }
            }
        }
    }

    // === [ MEJLA OSS - PRIVATKUND ] ====================================================
    .formcontainerblock {
        .Form__MainBody {
            padding: 0 10px;

            button {
                &[type='submit'] {
                    margin: 0 rem(15) rem(20) 0;
                }
            }
        }
    }

    // === [ CHECKBOX/RADIOBUTTON ] ======================================================
    .FormChoice {
        label {
            left: 0;
        }
    }

    // === [ DROPDOWN ] ==================================================================
    .FormSelection {
        .Form__Element__Caption {
            left: $margin-right-left-992;
        }
    }

    // === [ KNAPP ] =====================================================================
    button,
    input {
        &[type='submit'],
        &[type='button'] {
            margin: 1rem 0  1.5rem $margin-right-left-992;
            padding: .7rem $margin-right-left-992;
        }
    }

    [type='reset'] {
        margin: 1rem 0 0 $margin-right-left-992;
        padding: .7rem $margin-right-left-992;
    }
}
