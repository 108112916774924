﻿
// =======================================================================================
// === [ VIDEOBLOCK ] ===
// =======================================================================================
.videoblock {
    position: relative;
    width: 100%;
    height: 0;
    margin-bottom: $block-margin-bottom-320;
    padding-bottom: 56.25%;
}

.video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

// =======================================================================================
// === [ VIDEOBLOCK FÖR MOTORVÄG "MODAL" ] ===
// =======================================================================================
#videoModal { // sass-lint:disable-line no-ids
    &.modal {
        @include alpha-bg-color($black, .4);
        z-index: 2001;
        padding-right: $margin-right-left-320 - 1rem;
        padding-left: $margin-right-left-320 - 1rem;
    }

    .modal-dialog {
        width: auto;
        margin: 10rem auto $margin-right-left-320;
        padding: 1px;
    }

    .modal-body {
        position: relative;
        padding: 0;
        line-height: 0;

        button {
            @include bg-img('../img/icon-modal-close.svg', center center, no-repeat, 31px 31px);
            position: absolute;
            top: -4rem;
            right: 0;
            width: 3.1rem;
            height: 3.1rem;
            margin: 0 !important;
            padding: 0;
            background-color: transparent;
            border: 0;
        }

        iframe {
            border-width: 0;
        }
    }
}

// =======================================================================================
// Min-width 768px
// =======================================================================================
@media screen and (min-width: 768px) {
    .videoblock {
        margin-bottom: $block-margin-bottom-768;
    }

    #videoModal {
        &.modal {
            padding-right: $margin-right-left-768;
            padding-left: $margin-right-left-768;
        }

        .modal-dialog {
            margin: 12rem auto $margin-right-left-768;
        }
    }
}

// =======================================================================================
// Min-width 992px
// =======================================================================================
@media screen and (min-width: 992px) {
    .videoblock {
        margin-bottom: $block-margin-bottom-992;
        padding-left: $margin-right-left-992;
    }

    #videoModal {
        &.modal {
            padding-right: $margin-right-left-992;
            padding-left: $margin-right-left-992;
        }

        .modal-content {
            padding: 0;
        }

        .modal-dialog {
            margin: 12rem auto $margin-right-left-992;
        }

        .modal-body {
            button {
                top: -3.1rem;
                right: -3.4rem;
            }
        }
    }
}
