﻿/// <reference path="../base/mixins" />
/// <reference path="../components/bas-mixin" />
// =======================================================================================
// === [ MINTJANSTEPENSION-MODAL] ===
// === [ MODAL » FORM  ] ===
// =======================================================================================
.popup-modal {
    .invisible {
        display: none;
    }

    .modal {
        padding-left: rem(21) !important;
        padding-right: rem(20) !important;

        .modal-dialog {
            margin: 5rem 0 3rem;
        }

        .modal-content {
            background: $grey-extra-light;
        }

        .modal-header {
            background-color: transparent;
            padding: 0;

            img {
                width: 100%;
                height: rem(140);
            }

            button {
                @include alpha-bg-color($alecta-bla-1, .6);

                &:hover {
                    @include alpha-bg-color($alecta-bla-1, .6);
                }
            }
        }

        .modal-body {
            padding-bottom: 0;

            h2 {
                font-size: rem(32);
                line-height: rem(34);
                margin-bottom: rem(12);
                text-align: left;
            }

            p {
                @include alecta-font($alecta-freight-text, normal, normal);
                max-width: none;
                font-size: rem(19);
                line-height: rem(25);
            }
        }

        .modal-footer {
            padding: 0 2rem 5rem;

            .popup-knapp-spara {
                background: $alecta-bla-2;
                border: 1px solid $alecta-bla-2;
                box-sizing: border-box;
                border-radius: 3px;
                color: $vit;
                font-style: normal;
                font-weight: normal;
                font-size: rem(16);
                line-height: 19px;
                text-align: center;
                padding: 17px 20px 16px;

                &:hover {
                    background: $alecta-bla-2;
                    color: $vit;
                    text-decoration: none;
                }
            }
        }

        form {
            margin: 0;

            .form-input {
                display: flex;
                flex-direction: column;

                .Form__Element {
                    position: relative;
                    display: block;
                    margin: 0;
                    padding: 0 0 rem(14);

                    .error-active {
                        color: $fel-meddelande-farg !important;
                    }

                    .input-validation-error {
                        border: 1px solid $fel-meddelande-farg !important;
                    }

                    input {
                        background-color: $vit;
                        border: 1px solid $table-gron-border-top-bottom;
                        box-sizing: border-box;
                        border-radius: 3px;
                        width: 100%;
                        margin-bottom: rem(8);
                        max-height: rem(56);
                        padding-left: rem(17);

                        &:focus {
                            border: 1px solid $alecta-bla-2;
                            border-radius: 3px;
                        }
                    }

                    label {
                        position: absolute;
                        top: 15px;
                        left: rem(17);
                        font-size: 18px;
                        font-weight: 300;
                        line-height: 23px;



                        &.active {
                            top: 11px !important;
                            color: $ik-label-farg;
                            font-size: 13px;
                            line-height: 15px;


                            &.focusIn {
                                color: $ik-label-farg;
                            }
                        }
                    }

                    .popup-cirkel {
                        position: absolute;
                        top: rem(64);
                        width: rem(14);
                        height: rem(15);
                    }
                }

                .popup-utropstecken {
                    position: absolute;
                    top: rem(66);
                    left: 6px;
                    width: 2px;
                    height: 11px;
                }

                .text-danger {
                    padding: 0 18px;
                    padding-bottom: 8px;
                    font-style: normal;
                    font-weight: normal;
                    font-size: rem(14);
                    line-height: rem(16);
                    color: $fel-meddelande-farg;

                    &.field-validation-error {
                        width: 100%;

                        &::before {
                            content: url('../img/error.svg');
                            position: absolute;
                            top: 59px;
                            left: 0;
                            padding-right: 5px;
                            padding-top: 8px;
                        }
                    }
                }
            }
        }

        .label-fel-meddelande {
            color: $fel-meddelande-farg !important;

            label {
                color: $fel-meddelande-farg !important;
            }

            input {
                border: 1px solid $fel-meddelande-farg !important;

                &:focus {
                    border: 1px solid $fel-meddelande-farg;
                }
            }
        }
    }
}

// =======================================================================================
// Min-width 768px
// =======================================================================================
@media (min-width: 768px) {
    .popup-modal {
        .modal {
            padding-left: 7.2rem !important;
            padding-right: 7.2rem !important;

            .modal-dialog {
                margin: rem(50) auto;
            }

            .modal-header {
                img {
                    height: rem(330);
                }
            }

            .modal-body {
                padding-left: 2rem;
                padding-right: 2rem;

                h2 {
                    font-size: rem(36);
                    line-height: rem(44);
                }

                // sass-lint:disable-all
                .form-input {
                    flex-direction: row;
                    justify-content: space-between;
                    flex-wrap: nowrap;
                    gap: 2rem;

                    .Form__Element {
                        flex: 1 1 315px;
                    }
                }
                // sass-lint:enable-all
            }
        }
    }
}

// =======================================================================================
// Min-width 992px
// =======================================================================================
@media (min-width: 992px) {
    .popup-modal {
        .modal {
            padding: 0 !important;

            .modal-dialog {
                max-width: 770px;
                margin: 12vh auto;
            }

            .modal-body {
                padding-left: 4.3rem;
                padding-right: 4.3rem;
            }
        }
    }
}
