﻿
// =======================================================================================
// === [ UTLOGGAD - LANDNINGSSIDA ] ===
// =======================================================================================
.utloggad {
    max-width: rem(920);
    margin: 0 auto rem(50);
    padding-top: rem(20);

    .utloggad-bild,
    .utloggad-text,
    h3 {
        text-align: center;
    }

    .utloggad-bild {
        @include border(bottom, solid 1px, $kanter);
        margin-bottom: rem(30);

        img {
            display: inline-block;
            width: rem(650);
        }
    }

    .utloggad-text {
        @include border(top, solid 1px, $kanter);
        margin-top: rem(60);

        p {
            @include alecta-font($alecta-freight, 300, normal);
            max-width: 50rem;
            margin: 0 auto;
            padding: .5rem 2.5rem;
            font-size: 1.4rem;
            line-height: 2rem;
        }
    }
}
