﻿
// =======================================================================================
// === [ CITATBLOCK ] ===
// =======================================================================================
.citatblock {
    @include border(top, solid 1px, $citat-kanter);
    @include border(bottom, solid 1px, $citat-kanter);
    margin-bottom: $block-margin-bottom-320;
    padding: rem(23) rem(25) rem(25);
    border-left: 0;

    p {
        @include alecta-font($alecta-freight-text, 400, italic);
        max-width: none;
        margin: 0;
        padding: 0;
        font-size: rem(28);
        line-height: rem(34);
        text-align: center;
    }

    img {
        @include bild-noll-stall;
        display: block !important;
        width: initial !important;
        margin: rem(-3) auto rem(11) !important;
    }
}

.citat-float-top-right,
.citat-float-top-left {
    @include border(top, solid 1px, $citat-kanter);
    @include border(bottom, solid 1px, $citat-kanter);
    @include alecta-font($alecta-freight-text, 400, italic);
    margin-bottom: rem(20);
    padding: rem(23) rem(25) rem(25);
    font-size: rem(30);
    line-height: rem(34);
    text-align: center;
    border-left: 0;

    img {
        @include bild-noll-stall;
        display: block !important;
        width: initial !important;
        margin: rem(-3) auto rem(11) !important;
    }
}

.citatblock,
.citat-float-top-right,
.citat-float-top-left {
    a {
        font-size: 1.9rem;
        line-height: 2.5rem;
    }
}

span {
    &.citat-float-top-right,
    &.citat-float-top-left {
        display: block;
        margin: 1rem 0;
    }
}

// =======================================================================================
// Min-width 768px
// =======================================================================================
@media (min-width: 768px) {
    .citatblock {
        margin-bottom: $block-margin-bottom-768;
    }

    .citat-float-top-right {
        float: right;
        width: 50%;
        margin: rem(6) 0 rem(15) rem(20);
    }

    .citat-float-top-left {
        float: left;
        width: 50%;
        margin: rem(6) rem(20) rem(15) 0;
    }

    span {
        &.citat-float-top-right {
            margin: rem(6) 0 rem(6) rem(20);
        }

        &.citat-float-top-left {
            margin: rem(6) rem(20) rem(6) 0;
        }
    }
}

// =======================================================================================
// Min-width 992px
// =======================================================================================
@media (min-width: 992px) {
    .citatblock {
        margin-bottom: $block-margin-bottom-992;
        margin-left: $margin-right-left-992;
    }

    .citat-float-top-left {
        margin-left: $margin-right-left-992;
    }
}
