﻿/// <reference path="../base/mixins" />
/// <reference path="../components/bas-mixin" />

// =======================================================================================
// === [ INFOGRAFIKBLOCK ] ===
// =======================================================================================
.infografikblock {
    @include border(top, solid 1px, $infografik-border);
    @include border(bottom, solid 1px, $infografik-border);
    float: none;
    margin-top: -1px;
    font-size: 15px;

    .col-xs-12 {
        padding: 0;
    }

    h2 {
        @include border(bottom, solid 1px, $infografik-border);
        max-width: 100%;
        margin: 0;
        padding: 2.5rem 0 2.8rem;
        text-align: center;
    }

    h4 {
        text-align: center;
    }

    p {
        @include alecta-font($alecta-freight, 500, normal);
        max-width: 100%;
        margin-right: $margin-right-left-320;
        margin-bottom: 0;
        margin-left: $margin-right-left-320;
        font-size: 1.6rem;
        line-height: 2.2rem;

        a {
            font-family: inherit;
            font-size: inherit;
            background-position: 3px center;
        }
    }

    img {
        width: 100%;
        margin-bottom: 0;
    }

    h3 {
        @include alecta-font($alecta-freight-text, 500, italic);
        width: 17rem;
        margin: 0 auto;
        padding: 2.2rem 0 2.4rem;
        font-size: 1.9rem;
        line-height: 2.5rem;
        text-align: center;
    }

    ul {
        margin-top: -1.5rem;

        li {
            @include alecta-font($alecta-freight, 500, normal);
            font-size: inherit;
            line-height: inherit;

            &::after {
                top: .9rem;
            }
        }
    }

    .infografik-bild-text {
        .col-xs-12 {
            &:last-of-type {
                padding: 2rem 0 3rem;
            }

            img {
                @include border(bottom, solid 1px, $infografik-border);
            }
        }

        h3 {
            padding-top: 0;
        }
    }

    .infografik-text-bild {
        .row {
            display: flex;
            flex-direction: column;
        }

        .col-xs-12 {
            order: 1;

            &:first-of-type {
                order: 2;
                padding: 2rem 0 3rem;
            }

            img {
                @include border(bottom, solid 1px, $infografik-border);
            }
        }
    }

    .infografik-text {
        padding: 2.2rem 0 3rem;

        h2 {
            margin-top: -2.5rem;
            margin-bottom: 2.2rem;
            padding: 2.5rem 0 2.8rem;
        }

        h4 {
            padding: 0;
        }

        p {
            padding: 0;
        }
    }

    .infografik-bild {
        .infografik-bild-wrapper {
            @include overflow-x(auto);

            img {
                width: auto;
                max-width: none !important;
            }
        }
    }

    &:last-of-type {
        margin-bottom: $block-margin-bottom-320;
    }

    ol,
    ul {
        + p {
            margin-top: -1.6rem;
        }
    }
}

// =======================================================================================
// Min-width 768px
// =======================================================================================
@media (min-width: 768px) {
    .infografikblock {
        h4 {
            margin-bottom: 0 !important;
        }

        h3 {
            width: 100%;
            margin: 0 !important;
        }

        .infografik-bild-text {
            .col-xs-12 {
                &:last-of-type {
                    position: relative;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    padding: 2rem 3rem 3rem;
                    resize: vertical;
                }

                img {
                    border: 0;
                }
            }

            h3 {
                padding-top: 2.2rem;
            }
        }

        ul {
            padding-left: 9.25rem;

            li {
                margin-left: 4rem;
                margin-right: 4rem;
            }
        }

        .infografik-text-bild {
            .row {
                display: block;
                flex-direction: unset;
            }

            .col-xs-12 {
                &:first-of-type {
                    position: relative;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    padding: 2rem 3rem 3rem;
                    resize: vertical;
                }

                img {
                    border: 0;
                }
            }
        }

        .infografik-text {
            padding: 2rem 0 4rem;

            h4 {
                padding: 0;
            }

            p {
                padding: 0 8rem;
            }

            h3 {
                padding: 2.2rem 0 0;
            }
        }

        .infografik-bild {
            .infografik-bild-wrapper {
                img {
                    width: 100%;
                    max-width: 100% !important;
                }
            }
        }

        &:last-of-type {
            margin-bottom: $block-margin-bottom-768;
        }
    }
}

// =======================================================================================
// Min-width 992px
// =======================================================================================
@media (min-width: 992px) {
    .infografikblock {
        margin-left: $margin-right-left-992;

        &:last-of-type {
            margin-bottom: $block-margin-bottom-992;
        }

        ol,
        ul {
            + p {
                margin-top: -2.6rem;
            }
        }
    }
}
