﻿
// =======================================================================================
// === [ HÄNVISNINGSPUFF MED IKON ] ===
// =======================================================================================
.hanvisningspuffmedikonblock {
    display: block;
    float: none;
    width: auto;
    margin-right: $margin-right-left-320;
    margin-bottom: $block-margin-bottom-320;
    margin-left: $margin-right-left-320;
    padding: 0;

    .a-hanvispuff-ikon {
        position: relative;
        display: block;
        height: 20rem;
        padding: 0;
        overflow: hidden;

        .a-hanvispuff-ikon-text {
            position: relative;
            top: 50%;
            z-index: 2;
            display: inline-block;
            padding: 0 $margin-right-left-320;
            transform: translateY(-50%);

            h2 {
                margin-right: 0;
                margin-bottom: .5rem;
                margin-left: 0;
                padding: 0;
                color: $white;
                font-size: rem(32);
                font-weight: 400;
                line-height: rem(33);
            }

            p {
                @include alecta-font($alecta-freight, 400, normal);
                margin-right: 0;
                margin-bottom: 1rem;
                margin-left: 0;
                padding: 0;
                color: $white;
            }
        }

        img {
            position: absolute;
            right: 0;
            bottom: 0;
            z-index: 1;
            width: auto !important;
            height: 16rem !important;
            margin: 0;
            opacity: .3;
        }
    }
}

.accordion-inner {
    .hanvisningspuffmedikonblock {
        margin-right: 0;
        margin-left: 0;
    }
}

// =======================================================================================
// Min-width 768px
// =======================================================================================
@media (min-width: 768px) {
    .hanvisningspuffmedikonblock {
        margin-right: 0;
        margin-left: 0;
        padding-right: $margin-right-left-768;
        padding-left: $margin-right-left-768;

        // === [ 50% ] ===================================================================
        &.col-sm-6,
        &.col-sm-5,
        &.col-sm-4,
        &.col-sm-3,
        &.col-sm-2,
        &.col-sm-1 {
            display: inline-block;
            width: 49.6%;

            &:nth-of-type(odd) {
                padding-left: 0;
            }

            &:nth-of-type(even) {
                padding-right: 0;
            }
        }

        .a-hanvispuff-ikon {
            .a-hanvispuff-ikon-text {
                padding: 0 0 0 $margin-right-left-768;
            }
        }
    }

    .accordion-inner {
        .hanvisningspuffmedikonblock {
            padding-right: 0;
            padding-left: 0;
        }
    }
}

// =======================================================================================
// Min-width 992px
// =======================================================================================
@media (min-width: 992px) {
    .hanvisningspuffmedikonblock {
        padding-right: 0;
        padding-left: $margin-right-left-992;

        .a-hanvispuff-ikon {
            .a-hanvispuff-ikon-text {
                padding: 0 $margin-right-left-992;
            }
        }
    }
}
