﻿
// =======================================================================================
// === [ VÄGVISARE ] ===
// =======================================================================================
.a-main {
    section {
        .a-vagvisare {
            margin-bottom: $block-margin-bottom-320;
            padding: 0 $margin-right-left-320;
            text-align: center;

            h2 {
                max-width: 100%;
                margin-bottom: 1.7rem;
                padding: 0;
                font-size: 2rem;
                font-weight: 500;
                text-align: center;
                text-transform: uppercase;
            }

            a {
                @include border(all, solid 1px, $vagvisare-kanter);
                @include border-radius(.4rem);
                display: inline-block;
                margin: 0 .5rem .9rem 0;
                padding: 1.3rem 2rem 1.2rem;
                color: $default-link-color;
                font-size: 1.5rem;
                line-height: 2.5rem;

                &:hover {
                    @include border(all, solid 1px, $vagvisare-kanter-hover);
                    text-decoration: none;
                }
            }
        }

        .kontaktaossblock {
            .a-vagvisare {
                h2 {
                    text-transform: none;
                }
            }

            .text-center {
                margin-right: auto;
                margin-left: auto;
            }
        }
    }

    .fullbredd {
        section {
            .a-vagvisare {
                margin-top: 3rem;
            }
        }
    }
}

// =======================================================================================
// Min-width 768px
// =======================================================================================
@media (min-width: 768px) {
    .a-main {
        section {
            .a-vagvisare {
                margin-bottom: $block-margin-bottom-768;
            }
        }
    }
}

// =======================================================================================
// Min-width 992px
// =======================================================================================
@media (min-width: 992px) {
    .a-main {
        section {
            .a-vagvisare {
                margin-bottom: $block-margin-bottom-992;
            }
        }
    }
}
