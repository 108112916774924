﻿
// =======================================================================================
// === [ MOTORVÄGARNA (UTGÅR FRÅN 3 I RAD) ] ===
// =======================================================================================
.a-motorvagar {
    margin-top: rem(-30);

    .motorvagblock {
        display: block;
        float: left;
        width: 100%;
        margin-bottom: $block-margin-bottom-320;
        padding: 0;

        &:focus {
            outline: solid transparent 0;
        }

        .a-motorvag {
            display: block;

            &:focus {
                outline: 5px auto $focus-border;
            }
        }

        .a-motorvag-person {
            position: relative;
            float: left;
            width: 100%;
            height: 20rem;
            overflow: hidden;

            img {
                position: absolute;
                top: -9999rem;
                right: -9999rem;
                bottom: -9999rem;
                left: -9999rem;
                display: block;
                width: auto !important;
                height: 100% !important;
                max-width: none !important;
                margin: auto;
            }

            h5,
            p {
                @include alpha-bg-color($black, .4);
                @include alecta-font($alecta-freight, 500, normal);
                position: absolute;
                right: 0;
                bottom: 0;
                left: 0;
                max-width: none;
                margin: 0;
                padding: .7rem 0 .7rem $margin-right-left-320;
                color: $white;
                font-size: 1.4rem;
                font-weight: 500;
                line-height: 1.1;
            }

            &.a-motorvag-movie-btn {
                &::after {
                    @include bg-img('../img/icon-movie-btn.svg', center center, no-repeat, 100px 100px);
                    content: '';
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    display: block;
                    width: 10rem;
                    height: 10rem;
                    margin: -5rem 0 0 -5rem;
                }
            }
        }

        .a-motorvag-info {
            float: left;
            width: 100%;
            height: auto;
            padding: 2rem 0;
            text-align: center;
            background-color: $alecta-bla-3;

            h2,
            span {
                max-width: 100%;
                margin-bottom: .5rem;
                padding: 0;
                color: $white;
                font-size: 2.5rem;
                line-height: 2.5rem;
            }

            p {
                @include alecta-font($alecta-freight, 500, normal);
                max-width: none;
                margin: 0;
                padding: 0 $margin-right-left-320 - 1rem;
                color: $motorvag-link;
                font-size: rem(16);
                line-height: rem(19);

                .a-icon-arrow {
                    display: none;
                }
            }
        }

        // === [ MOTORVÄG 2ST I BREDD ] ==================================================
        &.col-sm-6,
        &.col-sm-4 {
            width: 100%;

            .a-motorvag-person {
                width: 100%;
                height: 20rem;
            }

            .a-motorvag-info {
                @include border(bottom, solid 1px, $motorvag-border);
                width: 100%;
                height: auto;
                padding: 2rem 0;
                text-align: center;
                background-color: $white;

                h2,
                span {
                    margin-bottom: .5rem;
                    color: $default-font-color;
                    font-size: 2.5rem;
                    font-weight: 500;
                    line-height: 2.5rem;
                }
            }

            // === [ HÖGER OCH VÄNSTER ] =================================================
            &:nth-child(odd) {
                .a-motorvag-person {
                    float: left;
                }

                .a-motorvag-info {
                    float: right;
                }
            }

            &:nth-child(even) {
                .a-motorvag-person {
                    float: right;
                }

                .a-motorvag-info {
                    float: left;
                }
            }
        }

        &::after {
            clear: both;
        }
    }
}

// =======================================================================================
// Min-width 480px
// =======================================================================================
@media (min-width: 480px) {

    // === [ MOTORVÄGARNA (UTGÅR FRÅN 3 I RAD) ] =========================================
    .a-motorvagar {
        margin-top: 0;
        margin-right: $margin-right-left-320 - .75rem;
        margin-left: $margin-right-left-320 - .75rem;
        padding: 0;

        .motorvagblock {
            margin-bottom: $block-margin-bottom-768;
            padding: 0 .75rem;

            // === [ MOTORVÄGARNA :: MOTORVÄG 1 (ENSAM, SINGEL) ] ========================
            .a-motorvag-person {
                width: 55%;
                height: 23rem;
            }

            .a-motorvag-info {
                position: relative;
                display: flex;
                flex-direction: column;
                justify-content: center;
                width: 45%;
                height: 23rem;
                padding: 0;
                text-align: left;
                resize: vertical;

                h2,
                span {
                    margin-bottom: 1rem;
                    padding: 0 $margin-right-left-768;
                    font-size: 3.6rem;
                    font-weight: 400;
                    line-height: 3.6rem;
                }

                p {
                    padding: 0 $margin-right-left-768;

                    .a-icon-arrow {
                        display: none;
                    }
                }
            }

            // === [ MOTORVÄG 2ST I BREDD ] ==============================================
            &.col-sm-6,
            &.col-sm-4 {

                .a-motorvag-person {
                    width: 55%;
                    height: 23rem;
                }

                .a-motorvag-info {
                    width: 45%;
                    height: 23rem;
                    padding: 0;
                    text-align: left;
                    border-bottom: 0;

                    h2,
                    span,
                    p {
                        margin-right: $margin-right-left-768 - 1.5rem;
                        margin-left: $margin-right-left-768 - 1.5rem;
                        padding: 0;
                    }
                }
            }
        }
    }
}

// =======================================================================================
// Min-width 768px
// =======================================================================================
@media (min-width: 768px) {
    .a-motorvagar {
        margin-right: $margin-right-left-768 - .75rem;
        margin-left: $margin-right-left-768 - .75rem;

        .motorvagblock {
            margin-bottom: $block-margin-bottom-768;

            .a-motorvag-person {
                h5,
                p {
                    padding-left: rem(18);
                }
            }
        }
    }
}

// =======================================================================================
// Min-width 992px
// =======================================================================================
@media (min-width: 992px) {

    // === [ MOTORVÄGARNA (UTGÅR FRÅN 3 I RAD) ] =========================================
    .a-motorvagar {
        margin-right: $margin-right-left-992 - 2.75rem;
        margin-left: $margin-right-left-992 - 2.75rem;
        padding: 0;

        .motorvagblock {
            margin-bottom: $block-margin-bottom-992;

            // === [ MOTORVÄGARNA :: MOTORVÄG 1 (ENSAM, SINGEL) ] ========================
            .a-motorvag {
                .a-motorvag-person {
                    width: 50%;
                    height: 28rem;

                    h5,
                    p {
                        height: 3.5rem;
                        padding: 1rem 0 1rem rem(18);
                        font-size: 1.7rem;
                    }
                }

                .a-motorvag-info {
                    width: 50%;
                    height: 28rem;
                    padding: 0 0 4rem 6rem;

                    h2,
                    span {
                        margin-bottom: 0;
                        padding: 0 4rem 0 0;
                        color: $white;
                        font-size: 5.2rem;
                        font-weight: 300;
                        line-height: 6rem;
                        text-align: left;
                    }

                    p {
                        position: absolute;
                        right: 0;
                        bottom: 0;
                        left: 0;
                        height: 3.8rem;
                        margin: 0;
                        padding: .7rem 0 .9rem 6rem;
                        text-align: left;
                    }
                }
            }

            // === [ MOTORVÄGARNA :: MOTORVÄG 2ST I BREDD ] ==============================
            &.col-sm-6,
            &.col-sm-4 {
                width: 50%;

                .a-motorvag-person {
                    width: 100%;
                    padding: 0;

                    h5,
                    p {
                        padding: 1rem 0 .7rem rem(18);
                        font-size: 1.4rem;
                    }
                }

                .a-motorvag-info {
                    width: 100%;
                    height: auto;
                    padding: 2rem 0;
                    text-align: center;

                    h2,
                    span {
                        margin-bottom: .7rem;
                        padding: 0 1.5rem;
                        color: $default-font-color;
                        font-size: 2.6rem;
                        font-weight: 500;
                        line-height: 2.7rem;
                        text-align: center;
                    }

                    p {
                        position: relative;
                        height: auto;
                        margin-bottom: 5px;
                        padding: 0 1.5rem;
                        text-align: center;

                        .a-icon-arrow {
                            display: none;
                        }
                    }
                }
            }

            // === [ MOTORVÄGARNA :: MOTORVÄG 3ST I BREDD ] ==============================
            &.col-sm-4 {
                width: 33.33333%;

                .a-motorvag-info {
                    h2,
                    span {
                        padding: 0 2rem;
                        line-height: 2.8rem;
                    }

                    p {
                        @include alecta-font($alecta-freight, 500, normal);
                        margin-bottom: 5px;
                        padding: 0 2rem;
                    }
                }
            }
        }
    }
}
