﻿/// <reference path="../base/mixins" />
/// <reference path="../components/bas-mixin" />

// =======================================================================================
// === [ VIDEO ] ===
// =======================================================================================
.video-wrapper {
    position: relative;
    height: 100%;
    overflow: hidden;

    video {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .inner-text {
        position: absolute;
    }
}


.controls {
    width: 750px;
    max-width: none;
    overflow: hidden;
    list-style-type: none;
    background: transparent;
    margin: 0;
    margin-top: -25px;
    padding: 0;

    li {
        float: left;
        width: 10%;
        margin: 0;
        margin-left: .3%;
        padding: 0;

        &:first-child {
            margin-left: 0;
        }

        &::after {
            content: none;
        }
    }

    .progress {
        width: 38%;
        cursor: pointer;
    }

    button {
        width: 100%;
        overflow: hidden;
        margin: 0;
        padding: 0;
        white-space: nowrap;
        font-family: $alecta-freight;
        line-height: 1;
        text-align: center;
        text-overflow: ellipsis;
    }

    progress {
        display: block;
        width: 100%;
        height: 1.25rem;
        margin-top: .125rem;
        overflow: hidden;
        border-radius: 5px;

        &::-webkit-progress-value {
            background: $alecta-bla-1;
        }

        span {
            width: 0%;
            height: 100%;
            display: inline-block;
            background-color: $alecta-bla-1;
        }
    }
}

// =======================================================================================
// === [ PLYR ] ===
// =======================================================================================

// sass-lint:disable-all
:root {
    --plyr-color-main: #009ed7;
    --plyr-font-size-base: 13px;
    --plyr-font-size-small: 12px;
    --plyr-font-size-time: 14px;
    --plyr-font-size-badges: 9px;
    --plyr-font-size-menu: var(--plyr-font-size-base);
    --plyr-font-weight-regular: 500;
    --plyr-font-weight-bold: 600;
    --plyr-font-size-captions-medium: 18px;
    --plyr-font-size-captions-large: 21px;
}

.plyr__controls {
    button {
        width: auto;
        margin: 0;
        padding: calc(var(--plyr-control-spacing, 10px) * .7);
        background: none;
        border-radius: 0;
    }
}

.plyr__control {
    &.plyr__control--overlaid {
        display: block;
        position: absolute;
        margin: 0;
        padding: 1.7rem;
        background-color: var(--plyr-color-main);
        border-radius: 50%;
    }
}
// sass-lint:enable-all
