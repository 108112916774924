﻿
// =======================================================================================
// === [ ORDLISTA ] ===
// =======================================================================================
.ordlista {

    // === [ ALFABET ÖVERST ] ============================================================
    .ordlista-alfabet {
        display: none;
    }

    // === [ ORDEN I ACCORDION ] =========================================================
    .accordion {
        margin-top: -1.1rem;

        .bokstaver {
            margin-bottom: 1.8rem;
            padding-top: 4.4rem;
            padding-right: $margin-right-left-320;
            padding-left: $margin-right-left-320;

            &:first-of-type {
                padding-top: .3rem;
            }

            h2 {
                margin-right: 0;
                margin-left: 0;
                text-transform: uppercase;
            }
        }

        .accordion-heading {
            h2 {
                max-width: 100%;
                margin: 0;
                padding: 0;
            }
        }

        .accordion-toggle {
            padding: 1.4rem 6.5rem 1.3rem 0;

            .a-icon-arrow-big {
                height: 100%;
            }

            .a-accordion-section-text {
                color: $default-link-color;
                font-size: 2rem;
                font-weight: 500;
            }
        }
    }
}

// =======================================================================================
// Min-width 768px
// =======================================================================================
@media (min-width: 768px) {
    .ordlista {
        padding-top: 1.7rem;

        // === [ ALFABET ÖVERST ] ========================================================
        .ordlista-alfabet {
            position: relative;
            z-index: 2;
            display: block;
            padding: 0 3rem;
            border-top: solid 1px $ordlista-border;

            .ordlista-alfabet-inner {
                @include border-radius(2rem);
                display: flex;
                flex: 1;
                flex-flow: row wrap;
                margin-top: -1.7rem;
                background-color: $default-bg-color;
                border: solid 1px $ordlista-border;
                overflow: auto;
            }

            a {
                position: relative;
                display: inline-flex;
                flex: 1 0 auto;
                justify-content: center;
                padding: .4rem 0;
                font-size: 1.4rem;
                text-transform: uppercase;
                border-right: solid 1px $ordlista-border;

                &:first-of-type {
                    padding-left: .5rem;
                }

                &:last-of-type {
                    padding-right: .5rem;
                    border-right: 0;
                }
            }
        }

        // === [ ORDEN I ACCORDION ] =====================================================
        .accordion {
            .bokstaver {
                padding-right: $margin-right-left-768;
                padding-left: $margin-right-left-768;

                &:first-of-type {
                    padding-top: 4.4rem;
                }
            }

            .accordion-toggle {
                padding: 1.2rem 6.5rem 1.1rem 0;
            }
        }
    }
}

// =======================================================================================
// Min-width 992px
// =======================================================================================
@media (min-width: 992px) {
    .ordlista {

        // === [ ORDEN I ACCORDION ] =====================================================
        .accordion {
            .bokstaver {
                padding-right: $margin-right-left-992;
                padding-left: $margin-right-left-992;
            }
        }
    }
}
