﻿
// =======================================================================================
// Inloggningssida - Gammal design men används av CGI's inloggningssida
// === [ ACCORDION ] ===
// =======================================================================================
.loggain {
    max-width: rem(750);
    margin: 0 auto rem(50);
    padding-top: rem(30);

    // ===================================================================================
    // === [ MOBILT BANKID ] ===
    // ===================================================================================
    .mobiltbankid,
    .bankidnordea,
    .telia {
        padding-bottom: 0;
        text-align: center;
        background-color: $white;

        .mobiltbankid-logo {
            display: inline-block;
            width: rem(50);
        }

        .mobiltbankid-bild {
            position: absolute;
            right: rem(40);
            bottom: rem(-20);
            width: rem(105);
            height: rem(215);
        }

        form {
            @include clearfix;
            width: rem(250);
            margin: 0 auto;
            padding: rem(64) 0 rem(118);
            text-align: left;
            background-color: $trans;

            .form-group {
                padding: 0;
            }

            // === [ PERSONNUMMER MINIRUBRIK ] ===========================================
            .col-xs-12 {
                padding-bottom: 0;
                line-height: rem(16);

                .Form__Element__Caption {
                    position: relative;
                    top: 0;
                    left: 0;
                    font-size: rem(12);
                }
            }

            // === [ TEXTFÄLT ] ==========================================================
            .col-md-8 {

                //label {
                //    top: rem(4);
                //    left: 0;
                //    font-size: rem(16);

                //    &.active {
                //        top: rem(-20);
                //        font-size: rem(12);
                //    }
                //}

                input {
                    &[type='text'] {
                        width: rem(153);
                        //padding: rem(5) 0 rem(4);
                    }
                }
            }

            // === [ KNAPP ] =============================================================
            .col-md-4 {
                display: inline-block;
                padding: rem(9) 0 0;

                button {
                    &[type='submit'] {
                        margin: 0 !important;
                        padding: rem(5) rem(20) rem(6);
                        font-size: rem(12);
                        line-height: rem(14);
                    }
                }
            }

            &::after {
                clear: both;
            }

            // === [ ERROR FÄLT, SYNS VID ERROR ] ========================================
            .field-validation-error {
                display: inline-block;
                padding-top: rem(3);
                color: $form-input-error;
                font-size: rem(14);
                font-style: normal !important;
                line-height: rem(14);
            }
        }

        p {
            @include alecta-font($alecta-freight, 500, normal);
            max-width: 100%;
            margin-bottom: rem(25);
            font-size: rem(14);
            line-height: rem(16);
            text-align: center;
        }
    }

    // ===================================================================================
    // === [ BANKID ] ===
    // ===================================================================================
    .bankidnordea {
        min-height: rem(325);

        .mobiltbankid-logo {
            margin-bottom: rem(27);
        }

        form {
            padding: rem(19) 0 rem(123);

            .col-md-4 {
                display: block;
                float: none;
                width: rem(80);
                margin: 0 auto;
                padding: 0;
            }
        }

        .bankidnordea-e-legitimationfel-info {
            max-width: rem(350);
            margin: rem(26) auto rem(105);
        }

        .bankidnordea-avbryt {
            padding-top: rem(11);

            a {
                color: $default-font-color;
                font-size: rem(16);
                line-height: rem(18);
            }
        }
    }

    // ===================================================================================
    // === [ TELIA ] ===
    // ===================================================================================
    .telia {
        padding-top: rem(47);

        .mobiltbankid-logo {
            margin-bottom: rem(27);
        }

        form {
            padding: rem(38) 0 rem(200);

            .col-md-4 {
                display: block;
                float: none;
                width: rem(80);
                margin: 0 auto;
                padding: 0;

                button {
                    &[type='submit'] {
                        padding: rem(9) rem(20) rem(10);
                    }
                }
            }
        }

        p {
            max-width: rem(350);
            margin: rem(26) auto rem(-10);
            text-align: left;

            &.telia-e-legimitation-lank {
                margin-top: rem(54);
                margin-bottom: rem(45);
                text-align: center;
            }
        }
    }

    // === [ LADDARIKON ] ================================================================
    .dynamisktinnehall-laddar {
        min-height: 7.3rem;

        &::after {
            top: rem(6);
            width: rem(38);
            height: rem(38);
            margin-left: -1.9rem;
            background-size: rem(38) rem(38);
        }
    }
}

// =======================================================================================
// === [ INLOGGNINSSIDA - CGI ] ===
// =======================================================================================

.external-use {
    section {
        &.loggain {
            max-width: none;
            margin: 0;
            padding-top: rem(39);
            text-align: center;

            h3 {
                margin-bottom: 0;
                padding-top: rem(6);
                padding-bottom: rem(75);
                font-size: rem(24);
                line-height: rem(28);

                a {
                    margin-bottom: 0;
                    font-size: rem(24);
                    line-height: rem(28);
                }
            }

            .dynamisktinnehall-laddar {
                height: rem(123);
            }

            a {
                display: inline-block;
                margin-bottom: rem(78);
                font-size: rem(19);
                line-height: rem(22);

                &.uppdatera {
                    margin-bottom: rem(60);
                }
            }

            .hjalp {
                @include alecta-font($alecta-freight, 500, normal);
                max-width: none;
                padding-bottom: rem(50);

                a {
                    margin-bottom: 0;
                }
            }

            p {
                max-width: none;
                margin-bottom: 2rem;
            }

            &.bankid {
                p {
                    @include alecta-font($alecta-freight, 500, normal);
                }
            }

            &.bankid-felsida {
                h3 {
                    &.bankid-appen {
                        padding-bottom: rem(23);

                        + h3 {
                            padding-bottom: rem(108);
                        }
                    }
                }
            }

            &.telia {
                h3 {
                    max-width: rem(600);
                    margin: 0 auto;
                    padding-bottom: rem(17);

                    &.telia-e-legitimation {
                        padding-bottom: rem(50);
                    }

                    a {
                        font-size: rem(24);
                        line-height: rem(28);
                    }
                }

                a {
                    @include alecta-font($alecta-freight, 500, normal);
                    font-size: rem(24);
                    line-height: rem(28);
                }
            }
        }
    }
}

// =======================================================================================
// === [ INLOGGNINSSIDA - V2 (NY DESIGN) ] ===
// =======================================================================================
.loggain-bankid-valsida {
    max-width: rem(750);
    margin: 0 auto rem(50);
    padding-top: rem(15);

    .a-vagvisare {
        margin-bottom: rem(15);

        form {
            margin: 0;
            padding: 0;
            background: none;
        }
    }

    .loggain-bankid-footer {
        padding-top: rem(35);
        text-align: center;

        p {
            @include alecta-font($alecta-freight, 500, normal);
            max-width: 100%;
            margin-bottom: rem(25);
            font-size: rem(14);
            line-height: rem(16);
            text-align: center;
        }

        .mobiltbankid-logo {
            display: inline-block;
            width: rem(120);
            padding-bottom: 0;
        }
    }
}

// =======================================================================================
// === [ INLOGGNINSSIDA - CGI - V2 (NY DESIGN) ] ===
// =======================================================================================

.external-use {
    #mobiltbankid {
        .form-group {
            display: flex;
            justify-content: center;
            width: 100%;
            margin-bottom: 15px;

            input {
                width: 100%;
            }

            button {
                width: auto;
                height: 36px;
            }
        }

        form {
            padding: rem(44) 0 rem(68);
        }

        .mobiltbankid-bild {
            display: none;
        }
    }
}

// =======================================================================================
// Min-width 768px
// =======================================================================================
@media (min-width: 768px) {
    .external-use {
        #mobiltbankid {
            .mobiltbankid-bild {
                display: block;
            }
        }
    }
}
