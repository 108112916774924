﻿
// =======================================================================================
// === [ GILLA | GILLA INTE ] ===
// =======================================================================================
.gillagillainte {
    @include border(top, solid 1px, $gillagillainte-border);
    margin: 6rem 0 3rem;
    padding: 2.5rem 0;

    // === [ STEG 1 ] ====================================================================
    .ggi-steg1 {
        padding: 0 0 2.3rem;
        text-align: center;

        .ggi-hander {
            margin-bottom: 1.8rem;
            font-size: 0;
        }

        h3 {
            margin-bottom: .9rem;
        }

        .ggi-hand-v {
            @include border(all, solid 1px, $gillagillainte-border);
            @include border-radius(1.7rem 0 0 1.7rem);
            display: inline-block;
            height: 3.4rem;
            padding: .9rem 1.6rem 0 3.7rem;
            color: $alecta-bla-2;
            font-size: 1.5rem;
            line-height: 1.5rem;

            &.ggi-hand-default {
                @include bg-img('../img/gillagillainte_hand_v_default.svg', 21px 8px, no-repeat, 14px 13px);
            }

            &.ggi-hand-active {
                @include border(all, solid 1px, $alecta-gron);
                @include bg-img('../img/gillagillainte_hand_v_active.svg', 21px 8px, no-repeat, 14px 13px);
                color: $white;
                background-color: $alecta-gron;
            }

            &.ggi-hand-inactive {
                @include border(all, solid 1px, $gillagillainte-btn-bg-inactive);
                @include bg-img('../img/gillagillainte_hand_v_active.svg', 21px 8px, no-repeat, 14px 13px);
                color: $white;
                background-color: $gillagillainte-btn-bg-inactive;
                pointer-events: none;
            }
        }

        .ggi-hand-h {
            @include border(top right bottom, solid 1px, $gillagillainte-border);
            @include border-radius(0 1.7rem 1.7rem 0);
            display: inline-block;
            height: 3.4rem;
            padding: .9rem 2.1rem 0 2.5rem;
            color: $alecta-bla-2;
            font-size: 1.5rem;
            line-height: 1.5rem;

            &.ggi-hand-default {
                @include bg-img('../img/gillagillainte_hand_h_default.svg', 9px 11px, no-repeat, 14px 13px);
            }

            &.ggi-hand-active {
                @include border(top right bottom, solid 1px, $alecta-orange);
                @include bg-img('../img/gillagillainte_hand_h_active.svg', 9px 11px, no-repeat, 14px 13px);
                color: $white;
                background-color: $alecta-orange;
            }

            &.ggi-hand-inactive {
                @include border(top right bottom, solid 1px, $gillagillainte-btn-bg-inactive);
                @include bg-img('../img/gillagillainte_hand_h_active.svg', 9px 11px, no-repeat, 14px 13px);
                color: $white;
                background-color: $gillagillainte-btn-bg-inactive;
                pointer-events: none;
            }
        }

        h4 {
            height: 2.5rem;
            margin-bottom: 0;
            text-transform: none;

            p {
                @include alecta-font($alecta-freight, 500, normal);
                max-width: initial;
                margin: 0;
                padding: 0;
                font-size: 1.5rem;
            }
        }

        p {
            &.a-ingress {
                height: 2.5rem;
                max-width: none;
                margin-bottom: 0;
                font-size: 2rem;
            }
        }
    }

    // === [ STEG 2 ] ====================================================================
    .ggi-steg2 {
        display: none;
        margin: 0 0 2.5rem;
        padding: 2.5rem 0 1.5rem;
        background-color: $gillagillainte-mobil-bg;

        h3 {
            margin-bottom: 2rem;
        }

        p {
            margin-top: -1.4rem;
            margin-bottom: 1.8rem;
        }

        form {
            margin-bottom: 0;
            padding: 0 25px 9px;
            background-color: transparent;

            .form-inline {
                padding-bottom: 0;
            }

            .Form__Element {
                margin-left: 0;
                padding-right: 0;
                padding-left: 0;
            }

            input {
                &[type='text'],
                &[type='email'],
                &[type='password'] {
                    width: 100%;
                }
            }
        }

        &.ggi-steg2-ja {
            @include border(top bottom, solid 1px, $alecta-gron);
            display: block;

            button {
                &[type='submit'],
                &[type='button'] {
                    margin: 1rem auto 1.5rem !important;
                }
            }
        }

        &.ggi-steg2-nej {
            @include border(top bottom, solid 1px, $alecta-orange);
            display: block;
        }
    }

    // === [ STEG 3 ] ====================================================================
    .ggi-steg3 {
        display: none;
        margin: 0 0 2.5rem;
        padding: 3.4rem 0 1.2rem;
        text-align: center;
        background-color: $gillagillainte-mobil-bg;

        p {
            max-width: none;
            font-size: 2rem;
        }

        &.ggi-steg2-ja {
            @include border(top bottom, solid 1px, $alecta-gron);
            display: block;
        }

        &.ggi-steg2-nej {
            @include border(top bottom, solid 1px, $alecta-orange);
            display: block;
        }
    }
}

// =======================================================================================
// Min-width 768px
// =======================================================================================
@media (min-width: 768px) {
    .gillagillainte {

        // === [ STEG 2 ] ================================================================
        .ggi-steg2 {

            .form-group {
                padding-right: $margin-right-left-768;
                padding-left: $margin-right-left-768;
            }

            .form-inline {
                padding-right: $margin-right-left-768 - 1rem;
                padding-left: $margin-right-left-768 - 1rem;

                .form-group {
                    padding-right: $margin-right-left-768 - 3rem;
                    padding-left: $margin-right-left-768 - 3rem;
                }
            }

            form {
                padding-right: 40px;
                padding-left: 40px;

                .Form__Element {
                    margin-bottom: 18px;
                    padding-right: 0;
                    padding-left: 0;
                }

                .form-inline {
                    padding-right: 0;
                    padding-left: 0;

                    .Form__Element {
                        width: auto;
                        padding-right: 20px;

                        label {
                            left: $margin-right-left-768 - 3rem;
                        }
                    }
                }

                input {
                    &[type='text'],
                    &[type='email'],
                    &[type='password'] {
                        width: 21rem;
                    }
                }

                .ggi-steg2-btn {
                    button {
                        margin-left: 0;
                    }
                }
            }

            &.ggi-steg2-ja {
                button {
                    &[type='submit'],
                    &[type='button'] {
                        margin: 1rem 0 1.5rem !important;
                    }
                }
            }
        }
    }
}

// =======================================================================================
// Min-width 992px
// =======================================================================================
@media (min-width: 992px) {
    .gillagillainte {
        margin-left: $margin-right-left-992;

        // === [ STEG 2 ] ================================================================
        .ggi-steg2 {
            background-color: transparent;

            h3,
            p {
                margin-left: 2rem;
            }

            .form-group {
                padding-right: $margin-right-left-992;
                padding-left: $margin-right-left-992;
            }

            .form-inline {
                padding-right: $margin-right-left-992 - 2rem;
                padding-left: $margin-right-left-992 - 2rem;

                .form-group {
                    padding-right: $margin-right-left-992 - 1rem;
                    padding-left: $margin-right-left-992 - 1rem;
                }
            }

            form {
                padding-right: 20px;
                padding-left: 20px;

                .form-inline {
                    .Form__Element {
                        padding-right: $margin-right-left-992;
                        padding-left: 0;
                    }
                }

                input {
                    &[type='text'],
                    &[type='email'],
                    &[type='password'] {
                        width: 21rem;
                    }
                }
            }

            &.ggi-steg2-ja {
                button {
                    &[type='submit'],
                    &[type='button'] {
                        margin: 1rem 0 1.5rem !important;
                    }
                }
            }
        }

        // === [ STEG 3 ] ================================================================
        .ggi-steg3 {
            background-color: transparent;
        }
    }
}
