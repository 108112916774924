﻿
// =======================================================================================
// === [ EPISERVER ] ===
// =======================================================================================
body {
    &[style='overflow: hidden;'],
    &.epi-mouse-focus {
        h2 {
            &::before {
                height: 0;
                margin-top: 0;
            }
        }

        .a-main {
            section {
                padding-right: 4rem;
                padding-left: 4rem;

                [data-epi-property-name='Toppbild'],
                .a-ingress {
                    margin-top: 2rem;
                }

                .hanvisningspuffmedikonblock {
                    .a-hanvispuff-ikon {
                        img {
                            width: auto !important;
                            height: 16rem !important;
                            margin: 0;
                        }
                    }
                }
            }
        }

        .citatblock {
            .epi-editContainer {
                @include alecta-font($alecta-freight-text, 400, italic);
                font-size: 3.3rem;
                line-height: 3.6rem;
                text-align: center;
            }
        }

        [data-epi-property-name='Brodtext'] {
            max-width: 57.5rem;
            margin-bottom: 2.3rem;
        }

        q {
            margin-top: 1rem;
        }

        h1 {
            &.guidepage {
                margin-top: 7rem;
            }
        }

        [data-epi-property-name='InnehallsytaBotten'] {
            min-width: 100% !important;

            [data-epi-property-name='InnehallsytaBotten'] {
                margin-top: 2.4rem;
            }
        }

        .Sleek {
            .epi-overlay-blockarea {
                .epi-overlay-blockarea-actionscontainer {
                    &>div {
                        padding: 0 2rem;
                    }
                }
            }
        }

        .a-motorvag {
            .a-motorvag-person {
                &.a-motorvag-movie-btn {
                    &::after {
                        content: '';
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        display: block;
                        width: 10rem;
                        height: 10rem;
                        margin: -5rem 0 0 -5rem;
                        background-image: url('../img/icon-movie-btn.svg');
                        background-position: center center;
                        background-repeat: no-repeat;
                        background-size: 10rem 10rem;
                    }
                }
            }
        }
    }

    .galleriblock-text {
        .epi-editContainer {
            padding: 0 2.5rem;
            font-size: 1.5rem;
            line-height: 1.8rem;
        }
    }
}

// =======================================================================================
// === [ TINY EDITOR ] ===
// =======================================================================================
.epi-lightSkin {
    .mceMenu {
        span {
            &.mceText {
                &[title='Citat Float Right'],
                &[title='Citat Float Left'],
                &[title='Image Float Right'],
                &[title='Image Float Left'] {
                    font-size: 12px !important;
                }
            }
        }
    }
}

// =======================================================================================
// Max-width 400px
// =======================================================================================
@media (max-width: 400px) {
    body {
        &[style='overflow: hidden;'] {
            .a-main {
                section {
                    padding-right: 0;
                    padding-left: 0;
                }
            }
        }
    }
}

// =======================================================================================
// Min-width 992px
// =======================================================================================
@media (min-width: 992px) {
    body {
        &[style='overflow: hidden;'],
        &.epi-mouse-focus {
            .Sleek {
                .epi-overlay-blockarea {
                    .epi-overlay-blockarea-actionscontainer {
                        &>div {
                            padding: 0;
                        }
                    }
                }
            }

            .col-xs-12 {
                display: inline-block;
            }

            .a-main {
                section {
                    padding-right: 0;
                    padding-left: 0;
                }
            }
        }
    }
}
