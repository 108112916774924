﻿/// <reference path="../base/mixins" />
/// <reference path="../components/bas-mixin" />


// =======================================================================================
// === [ ARSREDOVISNING ] ===
// =======================================================================================

.arsredovisningsida {
    #innehall {
        overflow: hidden;
    }

    section {
        padding: 0;

        h2 {
            @include alecta-font($alecta-freight, 300, normal);
            font-size: 2.6rem;
            line-height: 3.4rem;

            &.text-center {
                font-size: 2.6rem;
                line-height: 3.4rem;
            }

            &.h1 {
                margin-top: 4rem;
                margin-bottom: 4rem;
                color: $svart;
                font-size: 2.8rem;
                line-height: 3.6rem;
            }
        }

        h4 {
            font-weight: 500;
            text-transform: none;
        }

        .a-vagvisare {
            a {
                height: 4rem;
                color: $alecta-bla-1;
                font-size: 1.2rem;
                font-weight: 600;
                line-height: 1.5rem;
                text-transform: uppercase;
                background-color: transparent;
                border: solid 1px $alecta-bla-1;

                &:hover {
                    color: $white;
                    background-color: $alecta-bla-1;
                    border: solid 1px $alecta-bla-1;
                }
            }
        }
    }

    #sidhuvud {
        position: relative;

        h1 {
            margin: 3.1rem 1rem 1.5rem;
            font-size: 2.8rem;
            line-height: 3rem;
        }

        #toppVideo {
            width: 100%;
            height: auto;
        }
    }

    .bild-stor {
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;

        h1 {
            color: $white;
            font-size: 3rem;
            font-weight: 300;
            line-height: 4rem;
        }

        img {
            width: 100%;
            margin: auto;
        }

        .movie-btn {
            &::after {
                @include bg-img('../img/icon-movie-btn.svg', center center, no-repeat, 100px 100px);
                content: '';
                position: absolute;
                top: 50%;
                left: 50%;
                display: block;
                width: 10rem;
                height: 10rem;
                margin: -5rem 0 0 -5rem;
            }
        }
    }

    .topp-bild {
        height: 20rem;
    }

    .bild-platta {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        color: $white;
        background-image: url('/gui/img/bild-platta-bla.svg');
        background-repeat: no-repeat;
        background-size: 200%;
        transform-origin: bottom;

        &.platta-vit {
            color: $svart;
            background-image: url('/gui/img/bild-platta-vit.svg');
        }
    }

    .a-bg-image-mask {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        height: 100%;
        align-items: center;
    }

    .a-bg-image,
    .a-bg-illustration {
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }

    .a-bg-image {
        .a-bg-image-mask {
            background-color: $bild-mask-bla;
        }
    }

    #navbar-sticky {
        z-index: 10000;
        display: none;
        justify-content: center;
        height: 6rem;
        align-items: center;

        .sticky-links {
            display: flex;
            justify-content: center;
            align-items: flex-start;

            a {
                padding: 1.8rem 1rem 1rem;
                color: $white;
                font-size: 1.9rem;
                white-space: nowrap;
                background-color: $alecta-bla-1;
                border-bottom: 8px solid $alecta-bla-1;

                &.selected {
                    border-bottom: 0;
                }
            }
        }

        .sticky-spacer {
            flex: 2 1;
            padding: 1.8rem 1rem 1rem;
            background-color: $alecta-bla-1;
            border-bottom: 8px solid $alecta-bla-1;
            align-self: stretch;

            .logotyp {
                display: none;
                width: 80px;
                height: 21px;
                margin-left: 2rem;
                background-position: center;
                background-repeat: no-repeat;
            }
        }
    }

    .aret-i-korthet {
        border-bottom: 2px solid $gra-ljus;

        .belopp-rad {
            margin-right: 1rem;
            margin-left: 1rem;

            .h1 {
                margin-top: 2rem;
                margin-bottom: 1rem;
                white-space: nowrap;
            }

            p {
                @include alecta-font($alecta-freight, 500, normal);
                color: $alecta-bla-1;
                font-size: 2.2rem;
                line-height: 2.6rem;
            }
        }
    }

    #karusell {
        float: left;
        width: 100%;
        margin-bottom: 3rem;

        .slick-dotted {
            &.slick-slider {
                margin-bottom: 3rem;
            }
        }

        .slick-dots {
            position: absolute;
            bottom: -2.5rem;
            width: 100%;
            margin: 0;
            padding: 0;
            text-align: center;
            list-style: none;

            li {
                position: relative;
                display: inline-block;
                width: 2rem;
                height: 2rem;
                margin: 0 .5rem;
                padding: 0;
                cursor: pointer;

                &::after {
                    content: none;
                }

                button {
                    display: block;
                    width: 2rem;
                    height: 2rem;
                    padding: .5rem;
                    color: transparent;
                    font-size: 0;
                    line-height: 0;
                    background: transparent;
                    border: 0;
                    cursor: pointer;
                    outline: none;

                    :hover,
                    :focus {
                        background-color: transparent;
                    }

                    &::before {
                        content: '\2022';
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 2rem;
                        height: 2rem;
                        color: $alecta-bla-1;
                        font-size: 2.5rem;
                        line-height: 2rem;
                        text-align: center;
                        opacity: .3;
                        -webkit-font-smoothing: antialiased;
                        -moz-osx-font-smoothing: grayscale;

                        &.slick-active {
                            opacity: 1;
                        }
                    }
                }

                &.slick-active {
                    button {
                        &::before {
                            opacity: 1;
                        }
                    }
                }
            }
        }

        .slick-prev,
        .slick-next {
            position: absolute;
            top: 50%;
            z-index: 100;
            display: block;
            width: 5.4rem;
            height: 5.4rem;
            padding: 0;
            color: transparent;
            font-size: 0;
            background: transparent;
            background-repeat: no-repeat;
            border: 0;
            outline: none;
            transform: translate(0, -50%);
            -webkit-transform: translate(0, -50%);
            -ms-transform: translate(0, -50%);

            &:hover,
            &:focus {
                &::before {
                    opacity: 1;
                }
            }

            &::before {
                content: none;
                opacity: .3;
            }
        }

        .slick-next {
            @include bg-img('../img/arrow-right.svg', null, null, null);
            right: 3.9rem;
        }

        .slick-prev {
            @include bg-img('../img/arrow-left.svg', null, null, null);
            left: 3.9rem;
        }

        .slick-track {
            height: 33rem;
        }

        .slick-slide {
            margin: 0 1.5rem;
            opacity: .1;
        }

        .slick-active {
            opacity: 1;
        }

        .carousel-control {
            background-image: none;
        }

        .slideshowblock {
            display: none;

            .slick-initialized & {
                display: inline-block;
            }

            .block-inner {
                display: flex;
                flex-direction: column;
                justify-content: space-evenly;
                box-shadow: 3px 9px 10px 0 $slick-slide-shadow;
                height: 310px;
                align-items: center;
                overflow: hidden;

                .a-bg-image-mask {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    width: 100%;
                    height: 100%;
                    align-items: center;
                }

                &.a-bg-image {
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: cover;

                    .a-bg-image-mask {
                        background-color: $bild-mask-bla;
                    }
                }

                p {
                    @include alecta-font($alecta-freight, 300, normal);
                    font-size: 1.6rem;
                    line-height: 1.8rem;
                }

                .inner-text {
                    width: 100%;
                    padding: 2rem 1rem 1rem;

                    h2,
                    p {
                        color: $white;
                    }

                    h2 {
                        margin-top: 3rem;
                        font-size: 1.9rem;
                        font-weight: 500;
                        line-height: 2.2rem;
                    }
                }

                .a-vagvisare {
                    width: 100%;
                    margin-bottom: 0;
                    margin-left: 1rem;
                    padding: 0 2.5rem 2.5rem;

                    a {
                        @include alpha-bg-color($vit, 1);
                        @include border(all, $vit, solid 1px);
                        @include border-radius(.4rem);
                        display: inline-block;
                        margin: 0 0 1rem;
                        padding: 1.2rem 2.5rem 1.3rem;
                        color: $alecta-bla-1;
                        font-size: 1.2rem;
                        font-weight: 600;
                        line-height: 1.5rem;
                        text-transform: uppercase;

                        &:hover {
                            @include alpha-bg-color($alecta-bla-1, 1);
                            @include border(all, $alecta-bla-1, solid 1px);
                            color: $vit;
                        }
                    }
                }

                .text-yta {
                    width: 100%;
                    height: 100%;
                    background-image: url('/gui/img/tema-block-polygon.svg');
                    background-repeat: no-repeat;
                    background-size: 110%;

                    p {
                        padding: 3.5rem 1rem 0;
                    }

                    .a-vagvisare {
                        a {
                            @include alpha-bg-color($alecta-bla-1, 1);
                            @include border(all, $alecta-bla-1, solid 1px);
                            color: $vit;


                            &:hover {
                                @include alpha-bg-color($vit, 1);
                                @include border(all, $vit, solid 1px);
                                color: $alecta-bla-1;
                            }
                        }
                    }
                }

                .ikon {
                    position: absolute;
                    right: 20px;
                    bottom: 0;
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-end;
                    width: 100%;
                    height: 100%;
                    max-width: 120px;
                    max-height: 120px;
                    margin-left: 15px;
                }

                &.a-textcolor-alecta-bla-1,
                &.a-textcolor-alecta-bla-vit,
                &.a-textcolor-alecta-ruby {
                    .inner-text {
                        h2,
                        p {
                            color: $alecta-bla-1;
                        }
                    }

                    .a-vagvisare {
                        a {
                            @include alpha-bg-color($alecta-bla-1, 1);
                            @include border(all, $alecta-bla-1, solid 1px);
                            color: $vit;

                            &:hover {
                                @include alpha-bg-color($vit, 1);
                                @include border(all, $vit, solid 1px);
                                color: $alecta-bla-1;
                            }
                        }
                    }
                }

                &.a-textcolor-alecta-ruby {
                    .inner-text {
                        h2,
                        p {
                            color: $alecta-ruby;
                        }
                    }
                }

                &.a-textcolor-alecta-bla-3 {
                    .inner-text {
                        h2,
                        p {
                            color: $alecta-bla-3;
                        }
                    }
                }
            }
        }
    }

    .ingress {
        display: flex;
        justify-content: center;
        padding: 2.5rem 0 .5rem;
        font-style: normal !important;

        .ingress-inner {
            margin-bottom: 3rem;

            p {
                @include alecta-font($alecta-freight-text, 300, normal);
                margin: 0 1rem 2rem;
                font-size: 1.9rem;
                line-height: 2.5rem;
            }

            h2 {
                margin-right: 1rem;
                margin-left: 1rem;
            }

            .graf-yta {
                position: relative;

                h3 {
                    padding-bottom: 1rem;
                }

                &.hallbarhet {
                    padding-top: 0;
                }
            }

            .graf-rubrik,
            .graf-beskrivning,
            .graf-undertext,
            .graf-popover {
                @include alecta-font($alecta-freight, 300, normal);

                p {
                    @include alecta-font($alecta-freight, 300, normal);
                }
            }

            .graf-rubrik {
                display: block;
                float: left;
                width: 50%;
                margin: 1.5rem 0 0;
                text-align: center;
            }

            .graf-beskrivning {
                margin-bottom: 1rem;
                font-size: 2.2rem;
                line-height: 2.1rem;
                text-align: center;
            }

            .graf-undertext {
                padding-top: .5rem;

                p {
                    margin-bottom: 0;
                    padding: 0 .5rem;
                    font-size: 1.6rem;
                    line-height: 2rem;
                }
            }

            .graf-popover {
                margin: 0 1rem 2rem;

                p {
                    margin: 0;
                    padding: 2rem;
                    color: $white;
                    font-size: 1.6rem;
                    line-height: 2rem;
                }
            }
        }
    }

    .mal-yta {
        min-height: 1px;
        transition: background-color .1s linear;

        .ingress {
            padding-top: 1.5rem;

            .trygga-kunder-bild {
                padding: 0 10px;
                margin: 0 auto;
            }

            .ahr-rubrik {
                margin: 20px 10px 0 10px;
            }
        }

        h2 {
            padding-top: 0;
        }

        h3 {
            @include alecta-font($alecta-freight, 300, normal);
            font-size: 2.4rem;
            line-height: 3.1rem;
        }

        &.active {
            background-color: $mal-bg-active;

            .pratbubbla-mini {
                span {
                    &::before {
                        border-color: $mal-bg-active;
                    }
                }
            }
        }

        &.nyckeltal {
            &.active {
                background-color: $default-bg-color;
            }
        }
    }

    .vd-ord {
        border-bottom: 1px solid $gra-ljus;

        .ingress-inner {
            margin-bottom: 0;
        }

        .topp-bild {
            height: 30rem;
        }

        .bild-platta {
            position: relative;
            color: $svart;
            background-size: 0;
        }

        .video-knapp {
            position: relative;
            top: 32%;
            left: 44%;
            display: block;
            width: 50px;
            height: 50px;
        }

        .a-vagvisare {
            a {
                color: $white;
                background-color: $alecta-bla-1;
                border: solid 1px $alecta-bla-1;

                &:hover {
                    color: $alecta-bla-1;
                    background-color: $white;
                    border: solid 1px $white;
                }
            }
        }
    }

    .verksamhetsmal {
        .h1 {
            margin-bottom: 1rem;
        }

        .a-vagvisare {
            margin-top: 2.5rem;
        }

        .graf-beskrivning {
            margin: 0 0 1rem;
            font-size: 2.2rem;
            line-height: 2.1rem;
        }

        .graf-popover {
            @include border-radius(.3rem);
            @include box-shadow(3px, 9px, 10px, 0 rgba(43, 43, 43, .5));
            position: absolute;
            height: auto;
            background-color: $alecta-bla-3;

            .arrow {
                visibility: hidden;
            }
        }

        .kundnojdhet {
            .graf-yta {
                padding-bottom: 3rem;
                border: 0;
            }

            .graf-kundnojdhet-foretag,
            .graf-kundnojdhet-privat {
                margin-right: auto;
                margin-bottom: 7rem;
                margin-left: auto;
                padding: 2rem 3.5rem 0 2.5rem;

                .bar-rubrik {
                    position: relative;
                    top: 2.5rem;
                    margin: 0;
                    font-size: 1.8rem;
                    line-height: 2.1rem;
                }
            }
        }

        .driftkostnad {
            .kostnad-rubrik,
            .kostnad-enhet {
                font-style: normal;
                font-weight: 500;
            }

            .kostnad-mal,
            .kostnad-utfall {
                margin: 0 auto;
                padding: 2rem 1rem;
                color: $white;
                text-align: center;
                border-radius: 50%;

                &:hover {
                    cursor: pointer;

                    & + .graf-popover {
                        opacity: 1;
                        visibility: visible;
                        transition: visibility 0s, opacity .1s linear;
                    }
                }

                .h1 {
                    margin: 0;

                    .belopp {
                        color: $white;
                        font-size: 6.2rem;
                        line-height: 6.8rem;
                        white-space: nowrap;
                    }
                }

                .kostnad-rubrik,
                .kostnad-enhet {
                    font-size: 1.6rem;
                }
            }

            .kostnad-mal {
                width: 14rem;
                height: 14rem;
                padding-top: 3rem;
                background-color: $alecta-bla-2;

                .kostnad-inner {
                    position: relative;
                    top: -12px;
                }
            }

            .kostnad-utfall {
                width: 14rem;
                height: 14rem;
                background-color: $alecta-bla-2;

                &.utfall-stor {
                    width: 16rem;
                    height: 16rem;
                    padding-top: 3rem;
                }
            }

            .graf-driftkostnad,
            .driftkostnad-etiketter {
                display: flex;
                flex-direction: row;
                justify-content: center;
            }

            .graf-driftkostnad {
                margin-right: auto;
                margin-left: auto;
                border-bottom: 1px solid $gra-medel-mork;
                align-items: flex-end;
            }

            .driftkostnad-etiketter {
                padding: 2rem 0 0;
                color: $alecta-bla-1;
                font-size: 1.6rem;
                font-style: normal;
                line-height: 2rem;

                span {
                    display: block;
                    width: 16rem;
                    margin: 0 auto;
                    text-align: center;

                    &.etikett-utfall {
                        width: 14rem;

                        &.utfall-stor {
                            width: 16rem;
                        }
                    }
                }
            }

            .graf-popover {
                top: -20%;
                max-width: 45.5rem;
                opacity: 0;
                visibility: hidden;
                transition: visibility .2s, opacity .2s linear;

                .arrow {
                    position: absolute;
                    top: 50%;
                    right: -29px;
                    width: 3rem;
                    height: 2rem;
                    margin-left: 0;
                    background-color: $alecta-bla-3;
                    border: 0;
                    overflow: hidden;
                    visibility: hidden;
                    transition: visibility .2s linear;

                    &::after {
                        content: '';
                        position: absolute;
                        z-index: 1;
                        width: 6rem;
                        height: 6rem;
                        margin-top: -3.5rem;
                        margin-left: 0;
                        background-color: $default-bg-color;
                        border-radius: 3.5rem;
                        visibility: hidden;
                        transition: visibility .2s linear;
                    }
                }

                &.popover-mal {
                    @include box-shadow(-3px, 9px, 10px, 0 rgba(43, 43, 43, .5));
                    background-color: $alecta-bla-2;

                    .arrow {
                        background-color: $alecta-bla-2;
                    }
                }

                &.popover-utfall {
                    background-color: $alecta-bla-2;

                    .arrow {
                        left: -14px;
                        background-color: $alecta-bla-2;

                        &::after {
                            margin-left: -45px;
                        }
                    }

                    p {
                        color: $white;
                    }
                }
            }

            .should-animate {
                .kostnad-mal,
                .kostnad-utfall {
                    opacity: 0;
                }
            }

            .animate {
                .kostnad-mal {
                    opacity: 1;
                    transition: opacity 1.2s .1s ease;
                }

                .kostnad-utfall {
                    opacity: 1;
                    transition: opacity 1s .4s ease;
                }
            }

            &.active {
                .graf-popover {
                    .arrow {
                        &::after {
                            background-color: $mal-bg-active;
                        }
                    }
                }
            }
        }

        .avkastning {
            .graf-popover {
                @include box-shadow(3px, 9px, 10px, 0 rgba(190, 190, 190, .5));
                position: absolute;
                opacity: 0;
                visibility: hidden;
                transition: visibility .1s, opacity .2s linear;

                &.popover-formansbestamd {
                    background-color: $alecta-bla-2;
                }

                &.synlig {
                    position: static;
                    opacity: 1;
                    visibility: visible;
                    transition: visibility 1s, opacity .2s linear;
                }
            }

            .etiketter {
                li {
                    &::before {
                        content: '';
                        position: relative;
                        top: 1px;
                        display: inline-block;
                        width: 1.2rem;
                        height: 1.2rem;
                        margin-right: 6px;
                        background-clip: padding-box;
                        background-color: $gra-medel-mork;
                        border-radius: 50%;
                    }

                    &.etikett-bla {
                        &::before {
                            background-color: $alecta-bla-3;
                        }
                    }

                    &.etikett-morkbla {
                        &::before {
                            background-color: $alecta-bla-2;
                        }
                    }
                }
            }

            .avkastning-kommentar {
                @include alecta-font($alecta-freight, 500, normal);
                padding-top: 2rem;
                padding-bottom: 1rem;
                font-size: 16px;
                text-align: center;

                a {
                    + .pratbubbla-mini {
                        visibility: visible;
                    }
                }
            }
        }
    }

    .hallbarhetsmal {

        .ingress {
            .ingress-inner {
                .graf-beskrivning {
                    margin-right: auto;
                    margin-left: auto;
                    font-size: 1.8rem;
                    line-height: 2.1rem;
                }
            }
        }

        .h1 {
            color: $alecta-bla-1;
        }

        h2 {
            margin-top: 3rem;
        }

        .a-vagvisare {
            margin-top: 3.5rem;
        }

        .hallbarhet-intro {
            position: relative;
            border-bottom: 1px solid $gra-ljus;

            .bild-stor {
                height: 30rem;
            }

            .bild-platta {
                position: relative;
                background-size: 0;
            }

            .ingress {
                .ingress-inner {
                    margin-bottom: 0;
                }
            }

            .a-vagvisare {
                a {
                    color: $white;
                    background-color: $alecta-bla-1;
                    border: solid 1px $alecta-bla-1;

                    &:hover {
                        color: $alecta-bla-1;
                        background-color: $white;
                        border: solid 1px $white;
                    }
                }
            }
        }

        .graf-yta {
            padding-top: 1rem;
            border-bottom: 1px solid $graf-bg-gron;

            .h1 {
                margin: 0 0 1rem;
            }
        }

        .hallbarhet {
            .graf-hallbarhet {
                display: flex;
                justify-content: center;
                text-align: center;
                align-items: center;

                .graf-inner {
                    width: 100%;
                }
            }

            img {
                position: relative;
                display: none;
                align-self: flex-end;
            }
        }

        .resursanvandning {
            .a-bg-image-mask {
                background-color: $bild-mask-bla-mork;
            }
        }

        .graf-mangfald {
            display: flex;
            flex-direction: column;
            justify-content: center;
            overflow: hidden;

            .graf-outer {
                display: flex;
                justify-content: center;
                align-items: flex-end;

                img {
                    position: relative;
                    bottom: -1rem;
                }

                &:first-child {
                    img {
                        right: 2rem;
                    }
                }

                &:last-child {
                    img {
                        left: 2rem;
                    }
                }
            }

            .graf-inner {
                padding: 0 5rem;
            }

            &.should-animate {
                .graf-outer {
                    img {
                        opacity: 0;
                    }
                }
            }

            &.animate {
                .graf-outer {
                    img {
                        opacity: 1;
                        transition: opacity .7s .1s linear;
                    }
                }
            }
        }

        .mal-yta {
            &.active {
                background-color: $a-bg-ljusgron;
            }
        }
    }

    .hallbartpensionssystem {
        padding-top: 3rem;
        padding-bottom: 2rem;
        border-top: 1px solid $graf-bg-gron;

        .pyramid-pussel {
            max-width: 61.5rem;
            margin: 2rem auto;
            text-align: center;

            .modal-content {
                text-align: left;
            }

            .pyramidblock {
                margin-bottom: .9rem;

                > a,
                > span {
                    display: flex;
                    justify-content: center;
                    width: 100%;
                    height: 100%;
                    padding: 2.5rem 1.5rem;
                    color: $white;
                    font-size: 2.2rem;
                    line-height: 2.6rem;
                    align-items: center;
                }
            }

            .pyramid-top {
                .pyramidblock {
                    width: 100%;
                    height: 110px;
                    background-image: url('/gui/img/polygon-upp-bla.svg');
                    background-position: top left;
                    background-repeat: no-repeat;
                    background-size: cover;

                    a {
                        padding-top: 5.8rem;

                        &.mouseover,
                        &:hover {
                            text-decoration: underline;
                        }
                    }
                }
            }

            .pyramid-middle {
                .pyramidblock {
                    display: flex;
                    justify-content: center;
                    width: 100%;
                    background-color: $pyramid-middle;
                    align-items: center;
                }
            }

            .pyramid-bottom {
                .pyramidblock {
                    background-color: $pyramid-bottom;
                }
            }
        }
    }

    .finansiell-rapport {
        margin-top: 3rem;
        margin-bottom: 13rem;

        .rapport-outer {
            display: flex;
            justify-content: center;
            align-items: flex-end;

            .rapport-inner {
                text-align: center;
            }

            img {
                display: none;
                margin: 0 2.5rem;
            }

            .fil-info {
                display: block;
                text-align: center;
            }
        }

        .rapport-beskrivning {
            display: flex;
            justify-content: center;
            padding-top: 1rem;

            .liten-text {
                max-width: 56rem;
                font-size: 1.6rem;
                font-weight: 300;
                line-height: 2rem;
                text-align: center;
            }
        }
    }

    .modal-dialog {
        width: 100%;

        .modal-content {
            padding: 0;

            .modal-header {
                button {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 3rem;
                    height: 3rem;
                    color: $vit;
                    background-color: $modal-knapp-bla;
                    background-image: url('/gui/img/icon-modal-close-white.svg');
                    background-size: .8rem;
                }

                &.a-bg-image {
                    height: 203px;
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: cover;
                }
            }

            .modal-body {
                padding: 2.6rem 2.5rem;

                h3,
                h4,
                h5 {
                    margin-right: 0;
                    margin-left: 0;
                }

                h2 {
                    margin: 0 0 2rem;
                    font-size: 2.4rem;
                    line-height: 2.8rem;
                }

                .block {
                    margin-right: 0;
                    margin-left: 0;
                    padding-right: 0;
                    padding-left: 0;

                    &.citatblock {
                        img {
                            max-width: 100% !important;
                        }
                    }
                }
            }
        }
    }

    section {
        .a-vagvisare {
            text-align: left;

            &.text-center {
                text-align: center;
            }
        }
    }

    .vagn {
        position: relative;
        min-height: 1px;
    }

    .belopp {
        color: $alecta-bla-1;
        font-size: 9.2rem;
        font-weight: 300;
        line-height: 10.5rem;
    }

    .procent {
        &::after {
            content: '\00a0%';
            font-size: 50%;
        }
    }

    .text-vit {
        color: $white !important;
    }

    .pratbubbla-mini {
        @include border-radius(50%);
        position: relative;
        top: -.8rem;
        display: inline-block;
        width: rem(20);
        height: rem(14);
        margin: 0 0 0 rem(4);
        padding: 0;
        color: $default-bg-color;
        font-size: rem(14);
        line-height: rem(14);
        text-align: center;
        background-color: $alecta-bla-3;
        border: 0;
        visibility: hidden;

        span {
            position: absolute;
            bottom: -.3rem;
            left: 0;
            display: inline-block;
            width: rem(11);
            height: rem(5);
            margin-left: rem(1);
            background: transparent;
            border: 0;
            overflow: hidden;

            &::before {
                content: '';
                position: absolute;
                top: rem(-8);
                left: rem(-10);
                z-index: 2;
                width: rem(8);
                height: rem(8);
                border: solid rem(8) $default-bg-color;
                border-bottom-right-radius: rem(20);
            }

            &::after {
                content: '';
                position: absolute;
                top: rem(-11);
                left: rem(-2);
                z-index: 1;
                width: rem(15);
                height: rem(15);
                border: solid rem(8) $alecta-bla-3;
                border-bottom-right-radius: rem(30);
            }
        }

        &:focus {
            outline: 0;
        }
    }
}
// =======================================================================================
// Min-width 768px
// =======================================================================================
@media (min-width: 768px) {
    .arsredovisningsida {

        .topp-bild {
            height: 30rem;
        }

        #sidhuvud {
            .bild-platta {
                padding-top: 3rem;
            }
        }

        .ingress {
            .ingress-inner {
                margin-bottom: 1.5rem;
                padding: 1.5rem 8rem .5rem;

                p {
                    font-size: 1.9rem;
                    line-height: 2.4rem;
                }

                .graf-rubrik {
                    padding-right: 10rem;

                    &:first-child {
                        padding-right: 0;
                        padding-left: 9rem;
                    }
                }

                .graf-undertext {
                    p {
                        padding: 0;
                    }
                }
            }
        }

        #karusell {
            .slick-slide {
                width: 445px;
                margin: 0 1.5rem;
            }

            .slick-dots {
                position: static;
                display: flex;
                flex-direction: row;
                justify-content: center;
                width: 400px;
                margin: auto;
            }
        }

        .vd-ord {
            .topp-bild {
                height: 40rem;
            }
        }

        .verksamhetsmal {
            .driftkostnad {
                .graf-driftkostnad {
                    padding-right: 0;
                }

                .kostnad-mal,
                .kostnad-utfall {
                    margin: 0 2.5rem;
                }

                .driftkostnad-etiketter {
                    span {
                        margin: 0 2.5rem;
                    }
                }
            }
        }

        .hallbarhetsmal {
            .hallbarhet-intro {
                .ingress {
                    padding-top: 0;
                }
            }

            .graf-hallbarhet {
                h2 {
                    margin-bottom: 2rem;
                }
            }

            .graf-koldioxidavtryck {
                .y-axis {
                    left: -13px;
                }
            }

            .graf-mangfald {
                flex-direction: row;

                .graf-outer {
                    width: 50%;

                    &:first-child {
                        img {
                            right: 1rem;
                        }
                    }

                    &:last-child {
                        img {
                            left: 1rem;
                        }
                    }
                }
            }
        }

        .hallbartpensionssystem {
            .pyramid-pussel {
                .pyramid-top {
                    .pyramidblock {
                        height: 138px;
                    }
                }

                .pyramid-middle {
                    display: flex;
                    flex-direction: row;

                    .pyramidblock {
                        width: 33%;

                        &:first-child {
                            margin-right: .9rem;
                        }

                        &:last-child {
                            margin-left: .9rem;
                        }
                    }
                }
            }
        }

        .finansiell-rapport {
            .rapport-outer {
                img {
                    display: block;
                }

                .fil-info {
                    display: inline;
                }
            }
        }

        .modal-dialog {
            .modal-content {
                .modal-header {
                    button {
                        width: 4rem;
                        height: 4rem;
                    }
                }

                .modal-body {
                    padding: 2.6rem 9rem;
                }
            }
        }

        .center {
            margin-right: auto;
            margin-left: auto;
        }
    }
}
// =======================================================================================
// Min-width 992px
// =======================================================================================
@media (min-width: 992px) {
    .arsredovisningsida {

        .mal-yta {
            .ingress {
                .trygga-kunder-bild {
                    max-width: 63.5rem;
                    padding: 0;
                }
            }
        }

        #innehall {
            margin-top: 0;

            &.sticky {
                margin-top: 6rem;
            }
        }

        section {
            h2 {
                &.h1 {
                    font-size: 3.6rem;
                    line-height: 4.6rem;
                }
            }
        }

        #sidhuvud {
            h1 {
                margin-bottom: 3.1rem;
                font-size: 3.6rem;
                line-height: 4.6rem;
            }
        }

        .topp-bild {
            height: 50rem;

            h1 {
                font-size: 3.6rem;
                line-height: 4.6rem;
            }
        }

        .aret-i-korthet {

            .belopp-rad {
                margin-right: 13.5rem;
                margin-left: 13.5rem;
            }
        }

        #karusell {
            .slideshowblock {
                height: 310px;

                .block-inner {
                    .inner-text {
                        h2 {
                            font-size: 2.8rem;
                            line-height: 3.2rem;
                        }
                    }
                }
            }
        }

        .slideshowblock {
            .block-inner {
                .inner-text {
                    padding-top: 4rem;

                    h2 {
                        font-size: 2.4rem;
                        font-weight: 500;
                        line-height: 2.8rem;
                    }
                }
            }
        }

        .ingress {
            .ingress-inner {
                .graf-yta {
                    max-width: 63.5rem;
                    margin-right: 0;
                    margin-left: 0;

                    &.avkastning {
                        .row {
                            width: 70%;
                            margin-left: 15%;
                        }
                    }
                }

                .graf-popover {
                    margin: 0 0 2rem;
                }
            }
        }

        .vd-ord {
            .bild-platta {
                position: absolute;
                color: $white;
                background-size: 200%;
            }

            .topp-bild {
                height: 84.4rem;
            }

            .video-knapp {
                top: 28%;
                left: 48%;
                width: 80px;
                height: 80px;
            }

            .a-vagvisare {
                a {
                    color: $alecta-bla-1;
                    background-color: $white;
                    border: solid 1px $white;

                    &:hover {
                        color: $white;
                        background-color: $alecta-bla-1;
                        border: solid 1px $alecta-bla-1;
                    }
                }
            }
        }

        .verksamhetsmal {
            .graf-popover {
                @include box-shadow(3px, 9px, 10px, 0 rgba(190, 190, 190, .5));
            }

            .kundnojdhet {
                .graf-kundnojdhet {
                    width: 100%;
                    padding-right: 0;
                }
            }

            .driftkostnad {
                .graf-driftkostnad {
                    padding: 2rem 8rem 0;
                }

                .kostnad-mal,
                .kostnad-utfall {
                    z-index: 10;

                    &:hover {
                        & + .graf-popover {
                            .arrow {
                                visibility: visible;

                                &::after {
                                    visibility: visible;
                                }
                            }
                        }
                    }
                }

                .driftkostnad-etiketter {
                    padding: 2rem 8rem 0 6rem;
                }

                .graf-popover {
                    top: 20%;
                    width: 240px;

                    &.popover-mal {
                        @include box-shadow(-3px, 9px, 10px, 0 rgba(190, 190, 190, .5));
                        left: -21%;
                    }

                    &.popover-utfall {
                        @include box-shadow(3px, 9px, 10px, 0 rgba(190, 190, 190, .5));
                        right: -21%;
                    }
                }
            }

            .avkastning {
                .graf-popover {
                    right: 3%;
                    bottom: 98px;
                    width: 330px;

                    &.synlig {
                        position: absolute;
                    }

                    .popover-triangel {
                        position: absolute;
                        right: 329px;
                        bottom: 0;
                        width: 1.8rem;
                        height: 100%;
                        background-image: url('/gui/img/triangel-vanster-bla-3.svg');
                        background-repeat: no-repeat;
                        background-size: 100% 100%;
                    }

                    &.popover-formansbestamd {
                        @include box-shadow(-3px, 9px, 10px, 0 rgba(190, 190, 190, .5));
                        right: 82%;

                        .popover-triangel {
                            left: 329px;
                            background-image: url('/gui/img/triangel-hoger-bla-2.svg');
                        }
                    }

                    &.popover-premiebetamd {
                        @include box-shadow(3px, 9px, 10px, 0 rgba(190, 190, 190, .5));
                        left: 82%;
                    }
                }

                .avkastning-kommentar {
                    position: relative;
                    top: -72px;
                    left: 24px;
                    display: block;
                    padding: 0;
                    text-align: right;

                    a {
                        + .pratbubbla-mini {
                            visibility: hidden;
                        }

                        &:hover + .pratbubbla-mini {
                            visibility: visible;
                        }
                    }
                }

                .a-vagvisare {
                    margin-top: 0;
                }
            }
        }

        .hallbarhetsmal {

            .ingress {
                .ingress-inner {
                    h2,
                    p {
                        margin: 0 0 1.5rem;
                    }
                }
            }

            .hallbarhet-intro {
                border: 0;

                .ingress {
                    padding-top: 2.5rem;
                }

                .bild-stor {
                    height: 70rem;
                }

                .bild-platta {
                    position: absolute;
                    background-size: 200%;
                }
            }

            .hallbarhet {
                .ingress {
                    .ingress-inner {
                        padding-right: 0;
                        padding-left: 0;
                    }
                }

                .graf-hallbarhet {
                    .graf-inner {
                        width: 63%;
                    }
                }

                img {
                    display: block;
                }
            }
        }

        .modal-dialog {
            width: 79rem;

            .modal-content {
                padding: 0;

                .modal-body {
                    padding: 2.6rem 12.5rem .9rem;

                    h2 {
                        margin: 0 0 2rem;
                        font-size: 2.4rem;
                        line-height: 2.8rem;
                    }
                }
            }
        }

        .bild-platta {
            padding-top: 5rem;
        }

        .parallax-bild {
            background-attachment: fixed;
        }
    }
}

// =======================================================================================
// Min-width 1024px
// =======================================================================================
@media (min-width: 1024px) {
    .arsredovisningsida {
        #navbar-sticky {
            display: flex;

            &.sticky {
                position: fixed;
                top: 0;
                left: 0;
                width: 100%;

                .logotyp {
                    display: block;
                    float: right;
                }
            }
        }
    }
}

// =======================================================================================
// Min-width 1280px
// =======================================================================================
@media (min-width: 1280px) {
    .mal-med-bilder-pa-sidor {
        width: 92%;
    }
}

