﻿
// =======================================================================================
// === [ TYPOGRAFI ] ===
// =======================================================================================
section {
    h1,
    h2,
    h3,
    h4,
    h5,
    p,
    .darrad,
    q,
    li {
        @include alecta-font($alecta-freight, 500, normal);
        margin: 0 $margin-right-left-320;
        word-wrap: break-word;
        overflow-wrap: break-word;
    }

    // === [ H1 ] ========================================================================
    h1 {
        margin-bottom: 2.5rem;
        font-size: 3.2rem;
        font-weight: 400;
        line-height: 3.3rem;
    }

    // === [ H2 ] ========================================================================
    h2 {
        margin-bottom: .6rem;
        font-size: 2.2rem;
        line-height: 2.3rem;
    }

    // === [ H3 ] ========================================================================
    h3 {
        margin-bottom: .5rem;
        font-size: 2rem;
        line-height: 2.1rem;
    }

    // === [ H4 ] ========================================================================
    h4 {
        margin-bottom: .2rem;
        font-size: 1.5rem;
        font-weight: 600;
        line-height: 1.7rem;
        text-transform: uppercase;
    }

    // === [ INGRESS ] ===================================================================
    .a-ingress {
        @include alecta-font($alecta-freight-text, 400, italic);
        margin-bottom: 2.8rem;
    }

    .ingress {
        font-style: italic !important;
    }

    // === [ "A" LÄNKAR ] ================================================================
    a {
        &:link,
        &:visited,
        &:active,
        &:focus {
            color: $default-link-color;
            text-decoration: none;
        }

        &.hover,
        &:hover {
            color: $default-link-color;
            text-decoration: underline;
        }

        &:active,
        &:focus {
            text-decoration: none;
        }
    }

    // === [ FÖRSTÄRKT LÄNK ] ============================================================
    .forstarkt-lank {
        position: relative;

        &:link,
        &:visited {
            @include border(all, solid 1px, $alecta-bla-2);
            @include border-radius(2rem);
            display: inline-block;
            margin-bottom: 0;
            padding: rem(5) rem(30) rem(6);
            color: $default-link-color;
            font-size: 1.8rem;
            text-align: center;
            text-decoration: none;
            white-space: nowrap;
        }

        &:hover {
            text-decoration: underline;
            background-color: lighten($default-link-color, 68%);
        }

        &:active,
        &:focus {
            text-decoration: underline;
            background-color: lighten($default-link-color, 55%);
        }

        &.disable {
            color: lighten($grey-medium, 50%);
            text-decoration: none;
            background-color: lighten($black, 80%);
            border-color: lighten($black, 65%);
            cursor: default;
        }

        &.knapp-laddar {
            width: rem(75);
            text-indent: rem(-500);
            overflow: hidden;

            &::after {
                @include bg-img('../img/ikon-spinner-laddar.svg', center center, no-repeat, 13px 13px);
                content: '';
                position: absolute;
                top: rem(12);
                left: 50%;
                width: rem(13);
                height: rem(13);
                margin-left: rem(-7);
                animation: spin 500ms infinite linear;
            }
            @keyframes spin {
                from { transform: scale(1) rotate(0deg);}
                to { transform: scale(1) rotate(360deg);}
            }
        }
    }

    // === [ LOGGA IN KNAPP ] ============================================================
    .knapp-lank {
        @include border-radius(2rem);
        display: inline-block;
        margin: rem(14) rem(2) 0;
        padding: rem(10) rem(23) rem(9);
        color: $white !important;
        font-size: 1.4rem;
        line-height: 16px;
        background-color: $alecta-bla-2;
        border: 0;

        &:hover {
            text-decoration: none;
        }

        &:link {
            color: $white !important;
        }
    }

    // === [ LOGGA IN KNAPP ] ============================================================
    .knapp-loggain {
        @include border(all, solid 1px, $kanter);
        @include border-radius(.4rem);
        display: inline-block;
        height: rem(33);
        padding: rem(6) rem(22) rem(32);
        color: $default-font-color !important;
        font-size: 1.4rem;

        &:hover {
            color: $minasidor-lank-hover;
            text-decoration: none;
        }
    }

    // === [ LOGGA IN LÄNK ] =============================================================
    .loginlank {
        color: $default-link-color;
        font-family: inherit;
        font-size: inherit;

        &.loginlanklock {
            @include bg-img('../img/icon-personummersok-las.svg', 3px center, no-repeat, 9px 11px);
            padding-left: rem(17);
        }
    }

    // === [ PARAGRAF ] ==================================================================
    p {
        @include alecta-font($alecta-freight-text, 400, normal);
        max-width: $textblock-max-bredd-desktop;
        margin-bottom: 2rem;
        font-size: 1.9rem;
        line-height: 2.5rem;

        + ul,
        + ol {
            margin-top: -1.7rem;
        }

        a {
            &:link {
                color: $brodtext-link-color;
            }
        }
    }

    // === [ LITEN TEXT ] ================================================================
    .liten-text {
        @include alecta-font($alecta-freight, 500, normal);
        @include versala-siffror;
        display: block;
        max-width: $textblock-max-bredd-desktop;
        margin-top: .5rem;
        margin-bottom: 1rem;
        padding-right: 0;
        font-size: 1.4rem;
        line-height: 1.8rem;
    }

    // === [ DÅRAD ] =====================================================================
    .darrad {
        @include alecta-font($alecta-freight, 500, normal);
        display: block;
        margin-bottom: .5rem;
        font-size: 1.4rem;
        line-height: 2.4rem;
    }

    // === [ QUOTE ] =====================================================================
    q {
        @include alecta-font($alecta-freight-text, 400, italic);
        max-width: $textblock-max-bredd-desktop;
        margin: 0 0 2rem;
        font-size: 1.6rem;
        line-height: 1.9rem;
        quotes: '' '' '' '';
    }

    // === [ LISTA ] =====================================================================
    ul,
    ol {
        max-width: $textblock-max-bredd-desktop;
        margin-bottom: 2.9rem;
        padding-left: $margin-right-left-320 + .5rem;
        list-style: none;

        li {
            @include alecta-font($alecta-freight-text, 400, normal);
            position: relative;
            padding-left: 0;
            font-size: 1.9rem;
            line-height: 2.9rem;

            &::after {
                @include bg-img('../img/icon-li-list-bullet.svg', center center, no-repeat, 7px 7px);
                content: '';
                position: absolute;
                top: 1.2rem;
                left: -1.9rem;
                width: .7rem;
                height: .7rem;
            }

            a {
                &:link {
                    color: $brodtext-link-color;
                }
            }
        }

        + p {
            margin-top: -2.6rem;
        }

        + h4 {
            margin-top: -.8rem;
        }
    }

    ol {
        margin-left: -.6rem;
        list-style: decimal;

        li {
            padding-left: $margin-right-left-320 - 1.9rem;

            &::after {
                display: none;
            }
        }
    }

    // === [ BILDER ] ====================================================================
    img {
        display: block;
        height: auto;
        max-width: 100%;
    }

    .toppbild {
        img {
            width: 100%;
            margin-bottom: $block-margin-bottom-320;
        }
    }

    // === [ SIFFROR PÅ LINJE ] ==========================================================
    .antal-noll {
        @include versala-siffror;
    }

    // === [ FÖRKORTNING ] ==========================================================
    abbr {
        &[data-original-title],
        &[title] {
            text-decoration: none;
            border-bottom: 1px dotted;
            border-color: inherit;
            cursor: help;
        }
    }
}

// =======================================================================================
// Min-width 768px
// =======================================================================================
@media (min-width: 768px) {
    section {
        h1,
        h2,
        h3,
        h4,
        h5,
        p,
        .darrad,
        q,
        li {
            margin-right: $margin-right-left-768;
            margin-left: $margin-right-left-768;
        }

        h1 {
            font-size: 3.6rem;
            line-height: 3.6rem;

            &.guidepage {
                margin-bottom: 2.5rem;
                font-size: 3.6rem;
            }
        }

        h2 {
            margin-bottom: 1.1rem;
            font-size: 2.4rem;
            line-height: 2.4rem;
        }

        h3 {
            font-size: 2rem;
            line-height: 2.4rem;
        }

        h4 {
            font-size: 1.5rem;
            line-height: 2.4rem;
        }

        p,
        q {
            margin-bottom: 3.1rem;
            font-size: 1.8rem;
            line-height: 2.4rem;

            &.a-ingress {
                font-size: 2.1rem;
                line-height: 2.6rem;
            }
        }

        p {
            + ul,
            + ol {
                margin-top: -2.7rem;
            }
        }

        q {
            margin-bottom: 2.5rem;
        }

        ul,
        ol {
            li {
                font-size: 1.8rem;
                line-height: 2.4rem;

                &::after {
                    top: 1rem;
                }
            }
        }

        ol {
            li {
                padding-left: $margin-right-left-768 - 3.4rem;
            }
        }

        // === [ BILDER ] ================================================================
        .toppbild {
            img {
                margin-bottom: $block-margin-bottom-768;
            }
        }
    }
}

// =======================================================================================
// Min-width 992px
// =======================================================================================
@media (min-width: 992px) {
    section {
        h1,
        h2,
        h3,
        h4,
        h5,
        p,
        .darrad,
        q,
        li {
            margin-right: $margin-right-left-992;
            margin-left: $margin-right-left-992;
        }

        h1 {
            margin-bottom: 3.1rem;
        }

        h2 {
            margin-bottom: 1.2rem;
            font-size: 2.6rem;
            line-height: 2.8rem;
        }

        h3 {
            font-size: 2.2rem;
            line-height: 2.4rem;
        }

        h4 {
            margin-bottom: .3rem;
            font-size: 1.6rem;
            line-height: 2.4rem;
        }

        p {
            font-size: 1.9rem;
            line-height: 2.5rem;

            + h3 {
                margin-top: -.8rem;
            }

            &.a-ingress {
                font-size: 2.2rem;
                line-height: 2.8rem;
            }
        }

        q {
            font-size: 1.6rem;
            line-height: 1.9rem;
        }

        ul,
        ol {
            margin-bottom: 3.1rem;

            li {
                padding-bottom: .4rem;
                font-size: 1.9rem;
                line-height: 2.5rem;
            }
        }

        ol {
            li {
                padding-left: $margin-right-left-992 - 1.4rem;
            }
        }

        .darrad {
            max-width: $textblock-max-bredd-desktop;
        }
    }
}
